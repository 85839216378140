import { ReactNode, useEffect } from "react";
import Modal, { ModalActions } from "@/common/Modal";
import * as yup from "yup";
import Button from "@/common/Button";
import { useAddTeamMemberMutation } from "@/services/apis/api.ts";
import Form from "@/common/Form";
import isEmptyObject from "@/util/is-empty-object.ts";
import { Select } from "@/catalyst/select";

const NewTeamMemberSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
    name: yup.string().min(2).required("Required"),
});

export default function NewTeamMemberDialog({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}): ReactNode {
    const [addTeamMember, { isLoading: isAddingTeamMember, isSuccess, reset }] =
        useAddTeamMemberMutation();

    useEffect(() => {
        // We need useEffect here, in order to prevent
        // "Cannot update a component while rendering a different component" error
        if (!isSuccess) {
            return;
        }
        onClose();
        reset();
    }, [isSuccess]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={"Add new Team Member"}
            description={
                "This person will receive an invite email to Spectacle"
            }
        >
            <>
                <Form
                    initialValues={{
                        email: "",
                        name: "",
                        permissions_type: "editor",
                    }}
                    onSubmit={(values) => {
                        addTeamMember({
                            email: values.email,
                            name: values.name,
                            permissions_type: values.permissions_type,
                        });
                    }}
                    validationSchema={NewTeamMemberSchema}
                >
                    {({ errors, touched }) => (
                        <>
                            <Form.Field
                                name="name"
                                autoFocus={true}
                                error={errors.name as string}
                                touched={touched.name as boolean}
                                label={"Name"}
                                description={"The Team Member's name"}
                            />
                            <Form.Field
                                name="email"
                                type={"email"}
                                error={errors.email as string}
                                touched={touched.email as boolean}
                                label={"Email"}
                                description={"The Team Member's email"}
                                autoComplete={"off"}
                            />
                            <Form.Field
                                name="permissions_type"
                                as={(props) => {
                                    return (
                                        <Select {...props}>
                                            <option value="editor">
                                                Editor
                                            </option>
                                            <option value="admin">Admin</option>
                                        </Select>
                                    );
                                }}
                                error={errors.permissions_type as string}
                                touched={touched.permissions_type as boolean}
                                label={"Permissions"}
                                description={
                                    "Editors can create reports and edit workspace settings. Admins can edit organization settings like team members and change subscription settings"
                                }
                                autoComplete={"off"}
                            />
                            <ModalActions>
                                <Button
                                    type="primary"
                                    nativeType={"submit"}
                                    loading={isAddingTeamMember}
                                    disabled={!isEmptyObject(errors)}
                                >
                                    Submit
                                </Button>
                            </ModalActions>
                        </>
                    )}
                </Form>
            </>
        </Modal>
    );
}
