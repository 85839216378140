export default function SegmentIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="32"
            viewBox="0 0 7.938 8.467"
        >
            <path d="M7.623 3.704H3.466a.288.288 0 0 1-.288-.283v-.49c0-.156.132-.283.288-.283h4.183c.159 0 .288.13.288.284v.49c-.026.155-.158.282-.314.282ZM.291 5.82A.294.294 0 0 1 0 5.53v-.503c0-.132.132-.264.291-.264h4.196c.16 0 .291.132.291.29v.503a.34.34 0 0 1-.317.265zM1.574 7.673a.53.53 0 0 0 .53-.53.53.53 0 0 0-.53-.528.53.53 0 0 0-.529.529c0 .29.238.529.53.529zM6.337 1.852a.53.53 0 0 0 .529-.53.53.53 0 0 0-.53-.528.53.53 0 0 0-.528.529c0 .291.238.53.529.53zM3.956 7.408c-.292 0-.583-.053-.847-.132-.132-.026-.265.027-.318.159l-.185.503c-.053.132.027.29.185.343.37.133.768.186 1.165.186a4.233 4.233 0 0 0 3.862-2.514c.053-.132 0-.317-.158-.37l-.503-.185c-.132-.053-.265 0-.318.132-.502 1.111-1.587 1.878-2.883 1.878zM3.956 1.058c.29 0 .582.053.846.133.132.026.265-.027.318-.16L5.305.53C5.358.397 5.278.24 5.12.185A3.461 3.461 0 0 0 3.956 0 4.233 4.233 0 0 0 .093 2.514c-.053.132 0 .317.158.37l.503.185c.132.053.265 0 .318-.132.502-1.111 1.587-1.879 2.884-1.879z" />
        </svg>
    );
}
