import classNames from "classnames";
import { ReactNode } from "react";
type TextType = "default" | "subtle";

export default function Text({
    children,
    type = "default",
    className,
    weight,
}: {
    children: ReactNode;
    type?: TextType;
    className?: string;
    weight?: "bold";
}) {
    return (
        <span
            className={classNames(
                {
                    ["text-subtle"]: type === "subtle",
                    ["font-bold"]: weight === "bold",
                },
                className,
            )}
        >
            {children}
        </span>
    );
}
