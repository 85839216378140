import { ReactNode, useEffect } from "react";
import * as yup from "yup";
import { useCreateWorkspaceMutation } from "@/services/apis/api.ts";
import Button from "@/common/Button";
import Form from "@/common/Form";
import Modal, { ModalActions } from "@/common/Modal";
import isEmptyObject from "@/util/is-empty-object.ts";
import { setSelectedWorkspace } from "@/features/Workspace/Workspace.slice.ts";
import { useDispatch } from "react-redux";

const NewWorkspaceSchema = yup.object().shape({
    name: yup
        .string()
        .max(255, "Workspace name must be less than 255 characters")
        .required("Required"),
});

const NewWorkspaceDialog = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}): ReactNode => {
    const [createWorkspace, { isLoading, isSuccess, data: newWorkspace }] =
        useCreateWorkspaceMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isSuccess || typeof newWorkspace === "undefined") {
            return;
        }

        onClose();
        dispatch(setSelectedWorkspace(newWorkspace));
    }, [isSuccess]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={"Add new Workspace"}
            description={
                "Typically a workspace corresponds to a website being tracked. Each workspace's data is completely isolated from other workspaces."
            }
        >
            <Form
                initialValues={{
                    name: "",
                }}
                onSubmit={(values) => {
                    createWorkspace({
                        name: values.name,
                    });
                }}
                validationSchema={NewWorkspaceSchema}
            >
                {({ errors, touched }) => {
                    return (
                        <>
                            <Form.Field
                                name="name"
                                autoFocus={true}
                                error={errors.name as string}
                                touched={touched.name as boolean}
                                label={"Name"}
                                placeholder={"Your product"}
                                description={
                                    "The name of the workspace. Typically this is the name of your product"
                                }
                            />
                            <ModalActions>
                                <Button plain onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    nativeType={"submit"}
                                    loading={isLoading}
                                    disabled={!isEmptyObject(errors)}
                                >
                                    Create
                                </Button>
                            </ModalActions>
                        </>
                    );
                }}
            </Form>
        </Modal>
    );
};

export default NewWorkspaceDialog;
