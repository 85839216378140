import Panel from "@/common/Panel/Panel.tsx";
import Code from "@/common/Code";
import Button from "@/common/Button";
import { Square2StackIcon } from "@heroicons/react/24/solid";
import IntegrationsGrid from "@/features/Workspace/Settings/IntegrationsGrid.tsx";
import CommonSettingsPanel from "@/features/Workspace/Settings/CommonSettingsPanel.tsx";
import Status from "@/common/Status/Status.tsx";
import { useGetWorkspaceMetaQuery } from "@/services/apis/data.ts";
import Link from "@/common/Link";
import { getSnippet } from "@/features/Workspace/Settings/snippet.ts";
import { integrations } from "@/features/Workspace/Settings/integrations.tsx";
import { Container } from "@/common/Container";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import NameAndAvatarPanel from "./NameAndAvatarPanel";
import { Description, Fieldset, Label } from "@/catalyst/fieldset";
import { Field, FieldGroup } from "@/catalyst/fieldset.tsx";
import FieldTrackingProxy from "@/features/Workspace/Settings/FieldTrackingProxy.tsx";

export default function Settings() {
    const selectedWorkspace = useSelectedWorkspace();
    const { data: workspaceMetaResponse } = useGetWorkspaceMetaQuery({
        workspaceId: selectedWorkspace.id,
    });

    const snippet = getSnippet(
        selectedWorkspace.id,
        "javascript",
        selectedWorkspace.trackingProxyDomain
            ? `${selectedWorkspace.trackingProxySubdomain}.${selectedWorkspace.trackingProxyDomain}`
            : undefined,
    );

    return (
        <Container className={"space-y-8"}>
            <h2 className="text-3xl font-medium text-default leading-6">
                Workspace settings
            </h2>{" "}
            <NameAndAvatarPanel />
            <Panel
                title={"Tracking"}
                subtitle={"You Spectacle snippet and related settings"}
            >
                <div className={"mb-6 flex gap-6"}>
                    <Status
                        status={
                            workspaceMetaResponse
                                ? workspaceMetaResponse.data.hasPageTouchpoints
                                : undefined
                        }
                        statusDescription={"Tracking page views"}
                    />{" "}
                    <Status
                        status={
                            workspaceMetaResponse
                                ? workspaceMetaResponse.data.hasIdentifications
                                : undefined
                        }
                        statusDescription={"Tracking user identifies"}
                    />{" "}
                    <Status
                        status={
                            workspaceMetaResponse
                                ? workspaceMetaResponse.data.events.length > 0
                                : undefined
                        }
                        statusDescription={"Tracking events"}
                    />{" "}
                </div>
                <div>
                    <Fieldset className={"mt-6"}>
                        <FieldGroup>
                            <Field>
                                <Label>Tracking snippet</Label>
                                <Description>
                                    Copy the Spectacle snippet and paste it high
                                    in the {`<head>`} of your website. Learn
                                    more about setting up Spectacle in{" "}
                                    <Link
                                        external
                                        to={
                                            "https://www.spectaclehq.com/docs/spectacle-js/track"
                                        }
                                    >
                                        the JS installation guide.
                                    </Link>
                                </Description>
                                <Code
                                    className={
                                        "my-4 w-[20rem] sm:w-[30rem] md:w-[40rem] xl:w-[55rem]"
                                    }
                                >
                                    {snippet}
                                </Code>

                                <Button
                                    type={"primary"}
                                    icon={Square2StackIcon}
                                    onClick={async (_, setBanner) => {
                                        await navigator.clipboard.writeText(
                                            snippet,
                                        );
                                        setBanner("Copied!");
                                    }}
                                >
                                    Copy snippet
                                </Button>
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </div>
                <FieldTrackingProxy />
            </Panel>
            <CommonSettingsPanel />
            <Panel
                title={"Integrations"}
                subtitle={
                    "Connect your ad networks and payment solutions to Spectacle"
                }
            >
                <IntegrationsGrid
                    showSuggestBlock={true}
                    integrations={integrations}
                />
            </Panel>
        </Container>
    );
}
