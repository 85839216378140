export default function InstagramIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="grad"
                    x1="328.27"
                    x2="183.73"
                    y1="508.05"
                    y2="3.95"
                >
                    <stop offset="0" stopColor="#ffdb73" />
                    <stop offset=".08" stopColor="#fdad4e" />
                    <stop offset=".15" stopColor="#fb832e" />
                    <stop offset=".19" stopColor="#fa7321" />
                    <stop offset=".23" stopColor="#f6692f" />
                    <stop offset=".37" stopColor="#e84a5a" />
                    <stop offset=".48" stopColor="#e03675" />
                    <stop offset=".55" stopColor="#dd2f7f" />
                    <stop offset=".68" stopColor="#b43d97" />
                    <stop offset=".97" stopColor="#4d60d4" />
                    <stop offset="1" stopColor="#4264db" />
                </linearGradient>
            </defs>
            <rect
                height="465.06"
                rx="107.23"
                ry="107.23"
                width="465.06"
                x="23.47"
                y="23.47"
                style={{ fill: "url(#grad)" }}
            />
            <path
                fill={"#f2f2f2"}
                d="M331 115.22a66.92 66.92 0 0 1 66.65 66.65v148.26A66.92 66.92 0 0 1 331 396.78H181a66.92 66.92 0 0 1-66.65-66.65V181.87A66.92 66.92 0 0 1 181 115.22h150m0-31H181c-53.71 0-97.66 44-97.66 97.66v148.25c0 53.71 44 97.66 97.66 97.66h150c53.71 0 97.66-44 97.66-97.66V181.87c0-53.71-43.95-97.66-97.66-97.66Z"
            />
            <path
                fill={"#f2f2f2"}
                d="M256 198.13A57.87 57.87 0 1 1 198.13 256 57.94 57.94 0 0 1 256 198.13m0-31A88.87 88.87 0 1 0 344.87 256 88.87 88.87 0 0 0 256 167.13Z"
            />
            <circle fill={"#f2f2f2"} cx="346.81" cy="163.23" r="21.07" />
        </svg>
    );
}
