import { Heading } from "@/catalyst/heading.tsx";
import IntegrationsGrid from "@/features/Workspace/Settings/IntegrationsGrid.tsx";
import { integrations } from "@/features/Workspace/Settings/integrations.tsx";
import { Divider } from "@/catalyst/divider.tsx";
import Button from "@/common/Button";
import Link from "@/common/Link";

export default function StepConnectPaymentProviders({
    onClickNext,
}: {
    onClickNext: () => void;
}) {
    const paymentProviderIntegrations = Object.fromEntries(
        Object.entries(integrations).filter(
            ([, integration]) => integration.type === "payment_provider",
        ),
    );

    return (
        <>
            <div className={"w-full flex justify-between"}>
                <div className={"flex flex-col"}>
                    <Heading level={2} className={"inline mr-8"}>
                        Connect payment processors
                    </Heading>
                    <span className={"text-subtle text-sm"}>
                        Sync revenue data to Spectacle
                    </span>
                </div>
            </div>
            <Divider className={"mt-4 mb-4"} />

            <div className={"text-sm mb-8"}>
                <IntegrationsGrid integrations={paymentProviderIntegrations} />
            </div>
            <Divider className={"mt-8 mb-4"} />
            <div className={"text-sm"}>
                <span className={"font-semibold"}>Important</span>: If your
                payment processor is not listed above, you can still track
                revenue by{" "}
                <Link
                    external
                    to={
                        "https://www.spectaclehq.com/docs/installation/javascript#call-to-record-events-and-build-your-model"
                    }
                >
                    tracking it as part of an event
                </Link>
            </div>
            <div className={"mt-8"}>
                <Button type={"primary"} onClick={onClickNext}>
                    Next
                </Button>
            </div>
        </>
    );
}
