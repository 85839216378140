export default function RedditIcon({ className }: { className?: string }) {
    return (
        <svg
            viewBox="0 -28 512 512"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M512 232.14c0-30.968-25.05-56.019-56.02-56.019-15.136 0-28.816 5.992-38.878 15.754-38.27-27.664-91.059-45.492-149.844-47.566L292.77 24.203l83.375 17.754c1 21.207 18.363 38.113 39.804 38.113 22.13 0 40.035-17.906 40.035-40.035S438.078 0 415.95 0c-15.754 0-29.203 9.145-35.734 22.36L287.082 2.534c-2.613-.539-5.3-.074-7.531 1.383-2.227 1.46-3.766 3.687-4.301 6.3 0 0-27.973 133.247-27.973 134.016-59.859 1.536-113.648 19.442-152.53 47.489-10.067-9.684-23.669-15.602-38.727-15.602C25.05 176.12 0 201.245 0 232.14c0 22.742 13.602 42.34 33.117 51.097a112.766 112.766 0 0 0-1.305 16.985c0 86.215 100.356 156.066 224.149 156.066s224.148-69.851 224.148-156.066c0-5.688-.46-11.297-1.304-16.828C498.246 274.633 512 255.039 512 232.14"
                fill="#fff"
            />
            <path
                d="M343.945 312.207c-22.054 0-40.035-17.98-40.035-40.035s17.98-40.035 40.035-40.035c22.055 0 40.035 17.98 40.035 40.035s-17.98 40.035-40.035 40.035m7.071 65.703c-27.356 27.277-79.688 29.43-95.055 29.43-15.367 0-67.774-2.153-95.055-29.43a10.35 10.35 0 0 1 0-14.68 10.35 10.35 0 0 1 14.68 0c17.21 17.215 54.02 23.364 80.375 23.364 26.281 0 63.164-6.149 80.379-23.364a10.345 10.345 0 0 1 14.676 0c3.996 4.075 3.996 10.606 0 14.68M127.79 272.176c0-22.055 17.98-40.035 40.034-40.035 22.055 0 40.035 17.98 40.035 40.035 0 22.05-17.98 40.035-40.035 40.035-22.054-.004-40.035-17.984-40.035-40.035"
                fill="#ff4500"
            />
        </svg>
    );
}
