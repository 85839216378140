import classNames from "classnames";
import { ReactNode } from "react";

const statuses = {
    Yes: "text-green-400 bg-green-400/10",
    No: "text-rose-400 bg-rose-400/10",
    Loading: "text-zinc-400 bg-zinc-400/10",
};
export default function Status({
    status,
    statusDescription,
}: {
    status?: boolean;
    statusDescription?: ReactNode;
}) {
    const statusString =
        typeof status === "undefined" ? "Loading" : status ? "Yes" : "No";
    return (
        <div
            className={"flex items-center justify-end gap-x-2 sm:justify-start"}
        >
            <div
                className={classNames(
                    statuses[statusString],
                    "flex-none rounded-full p-1",
                )}
            >
                <div className="h-1.5 w-1.5 rounded-full bg-current" />
            </div>
            <div>{statusDescription || statusString}</div>
        </div>
    );
}
