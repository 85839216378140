import { WorkspaceMetaResponse } from "@/types/generated/WorkspaceMetaResponse.ts";
import { Heading } from "@/catalyst/heading.tsx";
import { Divider } from "@/catalyst/divider.tsx";
import FieldCutOffEvents from "@/features/Workspace/Settings/FieldCutOffEvents.tsx";
import Button from "@/common/Button";
import { FieldGroup, Fieldset } from "@/catalyst/fieldset";
import { useGetWorkspacesQuery } from "@/services/apis/api.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace";

export default function StepCutOffEvents({
    workspaceMetaResponse,
    onClickNext,
}: {
    workspaceMetaResponse?: WorkspaceMetaResponse;
    onClickNext: () => void;
}) {
    const selectedWorkspaceFromState = useSelectedWorkspace();

    const { data: workspaces } = useGetWorkspacesQuery(undefined);
    const selectedWorkspace =
        workspaces?.find((ws) => ws.id === selectedWorkspaceFromState.id) ||
        selectedWorkspaceFromState;

    return (
        <>
            <div className={"w-full flex justify-between"}>
                <div className={"flex flex-col"}>
                    <Heading level={2} className={"inline mr-8"}>
                        Select cut-off event(s)
                    </Heading>
                    <span className={"text-subtle text-sm"}>
                        Specify when a contact should be considered converted
                        into a lead.
                    </span>
                </div>
            </div>
            <Divider className={"mt-4 mb-4"} />
            <Fieldset>
                <FieldGroup>
                    <FieldCutOffEvents
                        shouldConfigureCutOffEvents={false}
                        events={workspaceMetaResponse?.data.events || []}
                        selectedWorkspace={selectedWorkspace}
                    />
                </FieldGroup>
            </Fieldset>
            <div className={"mt-8"}>
                <Button
                    type={"primary"}
                    disabled={selectedWorkspace.cutOffEvents.length === 0}
                    onClick={onClickNext}
                >
                    Finish
                </Button>
            </div>
        </>
    );
}
