import { AdNetworkIntegrationDetails } from "@/features/Workspace/Settings/integrations.tsx";

export const decorateExistingTrackingTemplate = (
    trackingTemplate: string,
    integrationParameters: AdNetworkIntegrationDetails["trackingParameters"],
): string => {
    const {
        hasHash,
        parameters: existingParameters,
        isUrl,
        hasQuestionMark,
    } = parseTrackingTemplate(trackingTemplate);
    const parametersToAdd: Record<string, string> = {};
    for (const [key, value] of Object.entries(integrationParameters)) {
        // Parameter doesn't exist yet. We can safely add it
        if (!Object.hasOwn(existingParameters, key)) {
            parametersToAdd[key] = value;
            continue;
        }

        const existingValue = existingParameters[key];

        // Parameter already has the correct value. We can safely skip it
        if (existingValue === value) {
            continue;
        }

        // For "utm_" keys, we have an override mechanism
        if (key.startsWith("utm_")) {
            const overrideKey = "sp_c" + key.split("utm_")["1"];
            parametersToAdd[overrideKey] = value;
            continue;
        }

        // In the unlikely event someone is using one of our "reserved" parameters AND it has a different value, we throw an error
        throw new Error(
            `Parameter ${key} already exists with a different value. This is quite unexpected. Please contact support.`,
        );
    }

    let paramsToAddString = Object.entries(parametersToAdd).reduce(
        (acc, [key, value]) => {
            return `${acc}&${key}=${value}`;
        },
        "",
    );

    const optionalQuestionMark = isUrl && !hasQuestionMark ? "?" : "";
    if (optionalQuestionMark && paramsToAddString.startsWith("&")) {
        // If we have a question mark and the paramsToAddString starts with a &, we need to remove the &
        paramsToAddString = paramsToAddString.slice(1);
    }

    if (hasHash) {
        // Inject paramsToAddString before the hash
        return `${trackingTemplate.split("#")[0]}${optionalQuestionMark}${paramsToAddString}#${trackingTemplate.split("#")[1] || ""}`;
    }

    return `${trackingTemplate}${optionalQuestionMark}${paramsToAddString}`;
};

/**
 * Get query parameters from tracking template
 */
export const parseTrackingTemplate = (
    trackingTemplate: string,
): {
    parameters: Record<string, string>;
    hasHash: boolean;
    hasQuestionMark: boolean;
    isUrl: boolean;
} => {
    let trackingTemplateQueryString = trackingTemplate;
    const hasQuestionMark = trackingTemplate.includes("?");
    const hasHash = trackingTemplate.includes("#");

    if (hasQuestionMark) {
        trackingTemplateQueryString =
            trackingTemplateQueryString.split("?")[1] || "";
    }

    if (hasHash) {
        trackingTemplateQueryString =
            trackingTemplateQueryString.split("#")[0] || "";
    }

    const parameters = Object.fromEntries(
        new URLSearchParams(trackingTemplateQueryString),
    );

    return {
        hasHash,
        parameters,
        hasQuestionMark,
        isUrl: trackingTemplate.startsWith("http"),
    };
};
