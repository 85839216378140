export const FeatureEnum = ["team-member-limit","workspace-limit","unified-currency","funnels","organization-attribution","tracking-proxy"]

export const UserPermissionsTypeEnum = ["owner","admin","editor"]

export const DismissableDialogEnum = []

export const IntegrationEnum = ["stripe","recurly","lemon_squeezey","salesforce","hubspot","callrail","google_ads","tiktok_ads","meta_ads","linkedin_ads","microsoft_ads","reddit_ads","pinterest_ads","snapchat_ads"]

export const WorkspaceIntegrationStatusEnum = ["pending","pending_account_selection","active","disconnected","connection_failed"]

export const PlanEnum = ["essential","growth","enterprise","beta","free"]

export const TrackableEventTypeEnum = ["Organization Created","Organization Deleted","User Signed Up","User Signed In","User Signed Out","User Was Invited","User Invited Team Member","User Upgraded","User Downgraded","User Provided Payment Method","Error Encountered","Upgrade Dialog Opened","Newsletter Sign-Up","Expressed Integration Interest","Suggested Integration","Expressed Roadmap Feature Interest","Setup Guide Opened","Setup Guide Step Completed","Workspace Created","Workspace Tracking Proxy Enabled","Workspace Initial Touchpoint Tracked","Workspace Integration Activated"]

export const RoadmapFeatureEnum = ["ad-network-audience-sync"]

