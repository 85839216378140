import { FunnelIcon } from "@heroicons/react/24/solid";

export default function FunnelEmptyState() {
    return (
        <div className="text-center">
            <FunnelIcon className="mx-auto h-12 w-12 text-default" />
            <h3 className="mt-2 text-sm font-semibold text-default">
                Select two funnel steps to get started.
            </h3>
            <p className="mt-1 text-sm text-subtle">
                Funnel steps can be configured in the right panel.
            </p>
        </div>
    );
}
