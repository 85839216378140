import { useContext } from "react";
import UserContext from "@/app/UserContext.ts";
import { assertNotUndefined } from "@/util/typeguards.ts";

export function useUser() {
    const user = useContext(UserContext);
    assertNotUndefined(user);

    return user;
}
