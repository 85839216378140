export default function GoogleAdsIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M202.33 53.44a80.7 80.7 0 0 0-18.8 22.06L22.29 353.58A81.62 81.62 0 0 0 51.87 464.8a81.62 81.62 0 0 0 111.22-29.58l138.59-239c-30.58-43.9-75.19-107.93-99.35-142.78z"
                fill="#febd00"
            />
            <circle cx="92.69" cy="394.4" fill="#25a84a" r="81.38" />
            <rect
                fill="#2e8add"
                height="484.21"
                rx="81.38"
                transform="rotate(150 334.165 255.516)"
                width="162.76"
                x="252.78"
                y="13.4"
            />
        </svg>
    );
}
