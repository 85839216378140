import { combineReducers, configureStore, isAnyOf } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { dataApi } from "@/services/apis/data";
import { api } from "@/services/apis/api.ts";
import uiStateSliceReducer from "../features/UiState.slice";
import workspaceSliceReducer, {
    setSelectedWorkspace,
} from "@/features/Workspace/Workspace.slice.ts";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const createRootReducer = () =>
    combineReducers({
        [dataApi.reducerPath]: dataApi.reducer,
        [api.reducerPath]: api.reducer,
        uiState: persistReducer(
            {
                key: "uiState",
                storage,
            },
            uiStateSliceReducer,
        ),
        workspace: persistReducer(
            {
                key: "workspace",
                storage,
            },
            workspaceSliceReducer,
        ),
    });

const invalidateWorkspaceDataListener = createListenerMiddleware();

invalidateWorkspaceDataListener.startListening({
    matcher: isAnyOf(
        setSelectedWorkspace,
        api.endpoints.getWorkspaces.matchFulfilled,
        api.endpoints.updateWorkspace.matchFulfilled,
    ),
    effect: async (_, listenerApi) => {
        const prevState = listenerApi.getOriginalState() as RootState;
        const currentState = listenerApi.getState() as RootState;

        // Only invalidate if the workspace actually changed
        if (
            prevState.workspace.selectedWorkspace &&
            currentState.workspace.selectedWorkspace &&
            prevState.workspace.selectedWorkspace.id !==
                currentState.workspace.selectedWorkspace.id
        ) {
            // reset api stater
            listenerApi.dispatch(api.util.resetApiState());
            listenerApi.dispatch(dataApi.util.resetApiState());
        }
    },
});

export const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(dataApi.middleware)
            .concat(api.middleware)
            .concat(invalidateWorkspaceDataListener.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

setupListeners(store.dispatch);
