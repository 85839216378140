import { ReactNode, RefObject } from "react";
import Panel from "../Panel/Panel";
import Tooltip from "@/common/Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

export default function BigNumberWidget({
    title,
    subtitle,
    number,
    tooltipContent,
    rightItem,
    loading,
    ref,
}: {
    title: ReactNode;
    subtitle: ReactNode;
    number: ReactNode;
    rightItem?: ReactNode;
    loading?: boolean;
    tooltipContent?: ReactNode;
    ref?: RefObject<HTMLDivElement | null>;
}) {
    return (
        <Panel ref={ref}>
            {loading && (
                <div className="flex flex-col animate-pulse w-full">
                    <div>
                        <div className="h-5 bg-zinc-200 rounded-full dark:bg-zinc-800 w-[90%] mb-2"></div>
                        <div className="w-[40%] h-8 bg-zinc-200 rounded-full dark:bg-zinc-800"></div>
                        <div className="h-3 bg-zinc-200 rounded-full dark:bg-zinc-800 w-[70%] mt-2"></div>
                    </div>
                </div>
            )}
            {!loading && (
                <>
                    <div className={"flex items-center justify-between"}>
                        <h2 className="text-lg font-medium leading-6 mb-2 flex items-center gap-1">
                            <span>{title}</span>
                            {tooltipContent && (
                                <Tooltip content={tooltipContent}>
                                    <QuestionMarkCircleIcon
                                        className={
                                            "ml-1 h-4 w-4 text-subtle cursor-pointer"
                                        }
                                    />
                                </Tooltip>
                            )}
                        </h2>
                        {rightItem}
                    </div>
                    <div className={"text-4xl font-medium number-container"}>
                        {number}
                    </div>
                    <span className={"text-xs text-subtle"}>{subtitle}</span>
                </>
            )}
        </Panel>
    );
}
