export default function FirstTouchIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M5.625 2.25c1.035 0 1.875.84 1.875 1.875v15.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.876 1.876 0 0 1 3 19.875V4.125c0-1.036.84-1.875 1.875-1.875Zm8.625 17.44c0-1.036-.84-1.875-1.875-1.875h-.75c-1.036 0-1.875.84-1.875 1.875v.185c0 1.035.84 1.875 1.875 1.875h.75c1.036 0 1.875-.84 1.875-1.875v-.185zm6.75-.007c0-1.036-.84-1.875-1.875-1.875h-.75c-1.036 0-1.875.84-1.875 1.875v.192c0 1.035.84 1.875 1.875 1.875h.75c1.036 0 1.875-.84 1.875-1.875Z" />
        </svg>
    );
}
