export function assertNotUndefined<T>(
    param: T | undefined,
    message = "Parameter was unexpectedly undefined",
): asserts param is T {
    if (typeof param === "undefined") {
        throw new Error(message);
    }
}

export function assertNotNully<T>(
    param: T | undefined | null,
    message = "Parameter was unexpectedly undefined or null",
): asserts param is T {
    if (typeof param === "undefined" || param === null) {
        throw new Error(message);
    }
}

export function assertIsString(
    param: unknown,
    message = "Parameter was unexpectedly undefined or not a string",
): asserts param is string {
    if (typeof param === "undefined" || typeof param !== "string") {
        throw new Error(message);
    }
}

export function assertIsDate(
    param: unknown,
    message = "Parameter was unexpectedly undefined or not a date",
): asserts param is Date {
    if (typeof param === "undefined" || !(param instanceof Date)) {
        throw new Error(message);
    }
}

export function assertIsError(error: unknown): asserts error is Error {
    if (!(error instanceof Error)) {
        throw error;
    }
}
