import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/common/ui/chart.tsx";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { assertNotUndefined } from "@/util/typeguards";
import { getFormatterForResolution, sortYYYYMMArray } from "@/util/date.ts";
import { AdSpendByNetwork } from "@/types/generated/AdSpendByNetwork.ts";
import { getChartConfigFromNetworks } from "@/features/Dashboard/Widgets/util.ts";
import Panel from "@/common/Panel/Panel.tsx";
import { Resolution } from "@/types/generated/Resolution.ts";
import { useCallback } from "react";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
import ChartSkeleton from "@/common/Skeleton/ChartSkeleton.tsx";
import { clsx } from "clsx";

const heightClass = "h-[287px]";

export default function AdSpendByNetworkWidget({
    className,
    adSpendByNetwork,
    resolution,
    dateRangeDescription,
    currency,
}: {
    className?: string;
    adSpendByNetwork?: AdSpendByNetwork[];
    resolution?: Resolution;
    dateRangeDescription?: string;
    currency: string;
}) {
    const showEmptyState = adSpendByNetwork?.length === 0;

    return (
        <Panel
            className={className}
            title={"Ad spend"}
            subtitle={`Showing ad spend by network for the last ${dateRangeDescription}`}
        >
            {!adSpendByNetwork && <ChartSkeleton className={heightClass} />}
            {adSpendByNetwork && !showEmptyState && (
                <AdSpendChart
                    resolution={resolution}
                    adSpendByNetwork={adSpendByNetwork}
                    currency={currency}
                />
            )}
            {showEmptyState && (
                <WidgetEmptyState
                    subtitle={"No spend data found for the current date range."}
                />
            )}
        </Panel>
    );
}

function AdSpendChart({
    adSpendByNetwork,
    resolution,
    currency,
}: {
    adSpendByNetwork: AdSpendByNetwork[];
    resolution?: Resolution;
    currency: string;
}) {
    // Get unique networks
    const networks = [...new Set(adSpendByNetwork.map((item) => item.network))];

    // Get unique yearsMonths
    const dates = [...new Set(adSpendByNetwork.map((item) => `${item.tick}`))];

    // Sort by year and then month
    switch (resolution) {
        case "Days":
        case "Years":
            // YYYY-MM-DD
            dates.sort();
            break;
        case "Months":
            // YYYYMM
            dates.sort(sortYYYYMMArray);
            break;
        default:
            throw new Error("Unexpected resolution");
    }

    // Prepare empty networks object ({[network]: 0})
    const networksEmpty = networks.reduce(
        (carry, item) => {
            carry[item] = 0;
            return carry;
        },
        {} as Record<string, number>,
    );

    const chartData = dates.map((d) => ({
        tick: d,
        ...networksEmpty,
    })) as ({ tick: string } & Record<string, number>)[];

    adSpendByNetwork.forEach((item) => {
        const networkData = chartData.find((x) => x.tick === item.tick);
        assertNotUndefined(networkData);
        networkData[item.network] = Number(item.spend[currency] || 0);
    });

    const chartConfig = getChartConfigFromNetworks(networks, false, true);
    const tickFormatterFunc = getFormatterForResolution(resolution);
    const tickFormatter = useCallback(tickFormatterFunc, [tickFormatterFunc]);

    return (
        <ChartContainer
            config={chartConfig}
            className={clsx("aspect-auto w-full", heightClass)}
        >
            <AreaChart accessibilityLayer data={chartData} margin={{ top: 20 }}>
                <CartesianGrid vertical={false} />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(x) => x.toLocaleString()}
                />
                <XAxis
                    dataKey="tick"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={tickFormatter}
                />
                <ChartTooltip
                    cursor={false}
                    labelClassName="text-white"
                    labelFormatter={tickFormatter}
                    content={<ChartTooltipContent indicator="line" />}
                />
                <defs>
                    {Object.keys(chartConfig).map((key) => (
                        <linearGradient
                            key={`gradient-${key}`}
                            id={`gradient-${key}`}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor={`var(--color-${key})`}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="95%"
                                stopColor={`var(--color-${key})`}
                                stopOpacity={0.5}
                            />
                        </linearGradient>
                    ))}
                </defs>
                {networks.map((network) => (
                    <Area
                        key={network}
                        dataKey={network}
                        type="monotone"
                        fill={`url(#gradient-${network})`}
                        fillOpacity={0.4}
                        stroke={`var(--color-${network})`}
                        stackId="a"
                    />
                ))}
            </AreaChart>
        </ChartContainer>
    );
}
