import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import { components } from "@/types/generated/api-schema.ts";
type Workspace = components["schemas"]["Workspace"];

export default function WorkspaceDependentRoute({
    children,
}: {
    children: (selectedWorkspace?: Workspace) => React.ReactNode;
}) {
    const selectedWorkspace = useSelectedWorkspace();

    return children(selectedWorkspace);
}
