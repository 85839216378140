import { User } from "@/model/User.ts";
import Intercom, { show, showNewMessage } from "@intercom/messenger-js-sdk";
import { assertNotUndefined } from "@/util/typeguards.ts";

const support = {
    configure: (user: User) => {
        if (!import.meta.env.PROD) {
            return;
        }

        assertNotUndefined(user.intercomUserHash, "Expected intercomUserHash");

        Intercom({
            app_id: "b7u8871e",
            region: "eu",
            user_id: user.id,
            name: user.name,
            email: user.email,
            created_at: new Date(user.createdAt).getTime() / 1000,
            hide_default_launcher: true,
            company: {
                id: user.organization.id,
                created_at: new Date(user.organization.createdAt).getTime(),
                plan: {
                    name: user.organization.plan,
                },
                has_payment_method: user.organization.hasPaymentMethod,
                trial_ends: user.organization.trialEndsAt
                    ? new Date(user.organization.trialEndsAt).getTime() / 1000
                    : undefined,
            },
            user_hash: user.intercomUserHash,
        });
    },
    openChat(message?: string) {
        if (!import.meta.env.PROD) {
            console.log("Only available in production");
            return;
        }

        message ? showNewMessage(message) : show();
    },
};

export default support;
