import { PlusIcon } from "@heroicons/react/20/solid";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import Button from "@/common/Button";

interface NoWorkspacesEmptyStateProps {
    onNewButtonClick?: () => void;
}

export default function NoWorkspaceEmptyState({
    onNewButtonClick,
}: NoWorkspacesEmptyStateProps) {
    return (
        <div className="text-center">
            <GlobeAltIcon className="mx-auto h-12 w-12 text-default" />
            <h3 className="mt-2 text-sm font-semibold text-default">
                No workspaces
            </h3>
            <p className="mt-1 text-sm text-subtle">
                Get started by adding a domain to monitor
            </p>
            <div className="mt-6">
                <Button onClick={onNewButtonClick}>
                    <PlusIcon
                        className="-ml-0.5 mr-1.5 h-5 w-5"
                        aria-hidden="true"
                    />
                    New workspace
                </Button>
            </div>
        </div>
    );
}
