import Button from "@/common/Button";
import { StarIcon } from "@heroicons/react/24/solid";
import Link from "@/common/Link";
import { Description, Fieldset, Label } from "@/catalyst/fieldset";
import { Field, FieldGroup } from "@/catalyst/fieldset.tsx";
import { useContext, useEffect, useState } from "react";
import FeatureFlagManagerContext from "@/services/featureFlags/FeatureFlagManagerContext";
import WithFeatureFlagCheck from "@/common/WithFeatureFlagCheck";
import { useUser } from "@/hooks/use-user.ts";
import Modal, { ModalActions } from "@/common/Modal";
import Form from "@/common/Form";
import { useUpdateWorkspaceMutation } from "@/services/apis/api";
import * as yup from "yup";
import isEmptyObject from "@/util/is-empty-object.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "@/catalyst/description-list";

const EnableTrackingProxySchema = yup.object({
    domain: yup
        .string()
        .required()
        .matches(
            /^(?!www\.)([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
            "Please enter a valid domain without www or http(s):// prefix",
        )
        .required("Required"),
    // other fields...
});

export default function FieldTrackingProxy() {
    const featureFlagManager = useContext(FeatureFlagManagerContext);
    const user = useUser();
    const [trackingProxyDialogOpen, setTrackingProxyDialogOpen] =
        useState(false);
    const [updateWorkspace, { isLoading, isSuccess }] =
        useUpdateWorkspaceMutation();
    const selectedWorkspace = useSelectedWorkspace();

    useEffect(() => {
        if (!isSuccess) {
            return;
        }

        setTrackingProxyDialogOpen(false);
    }, [isSuccess]);

    return (
        <Fieldset className={"mt-10"}>
            <FieldGroup>
                <Field>
                    <Label>Tracking proxy</Label>
                    <Description>
                        Improve tracking accuracy by using your own domain to
                        load the Spectacle snippet and to track events.{" "}
                        <Link
                            to={
                                "https://www.spectaclehq.com/docs/features/tracking-proxy"
                            }
                            external={true}
                        >
                            Learn more
                        </Link>
                    </Description>
                    <div className={"mt-4"}>
                        {!!selectedWorkspace?.trackingProxyDomain && (
                            <DescriptionList>
                                <DescriptionTerm>
                                    Your proxy domain
                                </DescriptionTerm>
                                <DescriptionDetails>
                                    {selectedWorkspace.trackingProxySubdomain}.
                                    {selectedWorkspace.trackingProxyDomain}
                                </DescriptionDetails>
                                <DescriptionTerm>NS Records</DescriptionTerm>
                                <DescriptionDetails>
                                    {!selectedWorkspace.trackingProxyNameservers
                                        ?.length &&
                                        "Your NS records should appear here shortly. If not please contact support."}
                                    {selectedWorkspace.trackingProxyNameservers?.join(
                                        ", ",
                                    )}
                                </DescriptionDetails>
                            </DescriptionList>
                        )}
                        {!selectedWorkspace?.trackingProxyDomain && (
                            <>
                                <WithFeatureFlagCheck
                                    passesCheck={featureFlagManager.isEnabled(
                                        "tracking-proxy",
                                    )}
                                    opener={"upgrade-button-tracking-proxy"}
                                >
                                    {(needsToUpgrade) => (
                                        <Button
                                            disabled={
                                                !user.hasAdminPermissions()
                                            }
                                            outline
                                            onClick={() =>
                                                // If user needs to upgrade, WithFeatureFlagCheck will hijack the button click
                                                setTrackingProxyDialogOpen(true)
                                            }
                                            icon={
                                                needsToUpgrade
                                                    ? StarIcon
                                                    : undefined
                                            }
                                        >
                                            Enable tracking proxy
                                        </Button>
                                    )}
                                </WithFeatureFlagCheck>
                                <Modal
                                    open={trackingProxyDialogOpen}
                                    onClose={() =>
                                        setTrackingProxyDialogOpen(false)
                                    }
                                    title={"Enable Tracking Proxy"}
                                    description={
                                        "Improve tracking accuracy by using your own domain to load the Spectacle snippet and to track events."
                                    }
                                >
                                    <Form
                                        initialValues={{
                                            domain: "",
                                        }}
                                        onSubmit={(values) => {
                                            updateWorkspace({
                                                id: selectedWorkspace.id,
                                                trackingProxyDomain:
                                                    values.domain,
                                            });
                                        }}
                                        validationSchema={
                                            EnableTrackingProxySchema
                                        }
                                    >
                                        {({ errors, touched }) => {
                                            return (
                                                <>
                                                    <Form.Field
                                                        name="domain"
                                                        autoFocus={true}
                                                        error={
                                                            errors.domain as string
                                                        }
                                                        touched={
                                                            touched.domain as boolean
                                                        }
                                                        label={"Domain"}
                                                        placeholder={
                                                            "yourdomain.com"
                                                        }
                                                        description={
                                                            "Your website's domain."
                                                        }
                                                    />
                                                    <ModalActions>
                                                        <Button
                                                            plain
                                                            onClick={() =>
                                                                setTrackingProxyDialogOpen(
                                                                    false,
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            nativeType={
                                                                "submit"
                                                            }
                                                            loading={isLoading}
                                                            disabled={
                                                                !isEmptyObject(
                                                                    errors,
                                                                )
                                                            }
                                                        >
                                                            Enable
                                                        </Button>
                                                    </ModalActions>
                                                </>
                                            );
                                        }}
                                    </Form>
                                </Modal>
                            </>
                        )}
                    </div>
                </Field>
            </FieldGroup>
        </Fieldset>
    );
}
