import Button from "../Button";

import type { JSX } from "react";
import support from "@/services/support";

const ErrorBase = ({
    code,
    title,
    description,
}: {
    code: string | number;
    title: string;
    description: string;
}): JSX.Element => {
    return (
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="mx-auto max-w-max">
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
                        {code}
                    </p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                                {title}
                            </h1>
                            <p className="mt-1 text-base text-gray-500">
                                {description}
                            </p>
                        </div>
                        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <a href="/">
                                <Button type={"primary"}>Go back home</Button>
                            </a>
                            <Button outline onClick={() => support.openChat()}>
                                Contact support
                            </Button>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ErrorBase;
