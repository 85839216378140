import { Popover, PopoverContent } from "@/common/Popover";
import FunnelStepButton from "@/features/Funnel/FunnelStepButton.tsx";
import FunnelStepButtonListItem from "./FunnelStepButtonListItem";
import FunnelStepActionSelectorPanel from "./FunnelStepActionSelectorPanel";
import { PlusIcon } from "@heroicons/react/24/solid";
import { produce } from "immer";
import { assertNotUndefined } from "@/util/typeguards.ts";
import { FunnelConfig, Predicate } from "@/features/Funnel/types.ts";
import { components } from "@/types/generated/api-schema.ts";
import { DragEndEvent, useDroppable } from "@dnd-kit/core";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback } from "react";
import { FunnelStepMaybeNew } from "@/features/Funnel/types.ts";

type Workspace = components["schemas"]["Workspace"];

export default function FunnelConfigPanelBlockSteps({
    onNewClick,
    funnelConfig,
    steps,
    funnelsLoading,
    selectedWorkspace,
    selectedStep,
    setSelectedStep,
    hasStepSelected,
    onPredicateChange,
    onChange,
}: {
    onNewClick?: () => void;
    funnelConfig: FunnelConfig;
    steps: FunnelStepMaybeNew[];
    funnelsLoading: boolean;
    selectedWorkspace: Workspace;
    selectedStep?: number;
    setSelectedStep: (step: number | undefined) => void;
    hasStepSelected: boolean;
    onChange: (config: FunnelConfig) => void;
    onPredicateChange: (predicate: Predicate, index: number) => void;
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: "funnel-config-steps-droppable",
    });

    const style = {
        color: isOver ? "green" : undefined,
    };

    const sensors = useSensors(useSensor(PointerSensor));

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;
            if (!over) return;

            if (active.id !== over.id) {
                const updatedSteps = produce(steps, (draft) => {
                    const oldIndex = draft.findIndex((i) => i.id === active.id);
                    const newIndex = draft.findIndex((i) => i.id === over.id);

                    return arrayMove(draft, oldIndex, newIndex);
                });

                const newConfig = produce(funnelConfig, (draft) => {
                    draft.steps = updatedSteps;
                });

                onChange(newConfig);
            }
        },
        [funnelConfig, onChange, steps],
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={steps}
                strategy={verticalListSortingStrategy}
            >
                <div className={"flex flex-col gap-2 group"}>
                    <h3 className={"text-lg font-medium text-default"}>
                        Steps
                    </h3>
                    {!funnelsLoading && (
                        <>
                            <Popover
                                open={hasStepSelected}
                                onOpenChange={(open) => {
                                    !open && setSelectedStep(undefined);
                                }}
                                autoUpdatePosition={false}
                            >
                                <div
                                    ref={setNodeRef}
                                    className={"flex flex-col gap-2"}
                                    style={style}
                                >
                                    {steps.map((step, index) => {
                                        return (
                                            <FunnelStepButtonListItem
                                                key={`step-${step.id}`}
                                                index={index}
                                                step={step}
                                                isDraggable={true}
                                                setSelectedStep={
                                                    setSelectedStep
                                                }
                                                onPredicateChange={
                                                    onPredicateChange
                                                }
                                                onChange={onChange}
                                                funnelConfig={funnelConfig}
                                                totalSteps={
                                                    funnelConfig.steps.length
                                                }
                                            />
                                        );
                                    })}
                                </div>

                                <PopoverContent>
                                    <FunnelStepActionSelectorPanel
                                        selectedWorkspace={selectedWorkspace}
                                        onStepActionUpdated={(updatedStep) => {
                                            assertNotUndefined(selectedStep);

                                            const updatedConfig = produce(
                                                funnelConfig,
                                                (draft) => {
                                                    draft.steps[selectedStep] =
                                                        updatedStep;
                                                },
                                            );

                                            onChange(updatedConfig);
                                            setSelectedStep(undefined);
                                        }}
                                    />
                                </PopoverContent>
                            </Popover>
                            <FunnelStepButton
                                key={"new"}
                                className={
                                    "cursor-pointer opacity-50 hover:opacity-100"
                                }
                                disabled={funnelConfig.steps.some(
                                    (step) => step.needsSetup,
                                )}
                                onClick={onNewClick}
                            >
                                <PlusIcon className={"h-5 w-5"} />
                                <span>Add step</span>
                            </FunnelStepButton>
                        </>
                    )}
                </div>
            </SortableContext>
        </DndContext>
    );
}
