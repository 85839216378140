import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { assertNotUndefined } from "@/util/typeguards";

// 20 height
const heights = [
    "h-72",
    "h-56",
    "h-72",
    "h-64",
    "h-80",
    "h-72",
    "h-80",
    "h-64",
    "h-80",
    "h-72",
    "h-64",
    "h-56",
    "h-72",
    "h-64",
    "h-80",
    "h-56",
    "h-80",
    "h-64",
    "h-72",
    "h-64",
    "h-80",
    "h-72",
    "h-80",
    "h-72",
    "h-56",
    "h-72",
    "h-64",
    "h-80",
    "h-72",
    "h-80",
    "h-64",
    "h-80",
    "h-72",
    "h-64",
    "h-56",
    "h-72",
    "h-64",
    "h-80",
    "h-56",
    "h-80",
    "h-64",
    "h-72",
    "h-64",
    "h-80",
    "h-72",
    "h-80",
];

// An algorithm to shuffle an array, then walk through it making sure two adjacent items are never the same
const shuffleWithNoSimilarAdjacent = (arr: string[]) => {
    const shuffled = [...arr];
    for (let i = 0; i < shuffled.length - 1; i++) {
        const j = Math.floor(Math.random() * (shuffled.length - i));
        const itemOne = shuffled[i + j];
        const itemTwo = shuffled[i];
        assertNotUndefined(itemOne);
        assertNotUndefined(itemTwo);
        [shuffled[i], shuffled[i + j]] = [shuffled[i + j]!, shuffled[i]!];
    }
    return shuffled;
};

export default function WidgetSkeleton({ bars = 20 }: { bars?: number }) {
    const [heightsShuffled, setHeightsShuffled] = useState(heights);
    useEffect(() => {
        const shuffled = shuffleWithNoSimilarAdjacent(heights);
        setHeightsShuffled(shuffled);
    }, []);

    return (
        <div role="status" className="w-full rounded animate-pulse">
            <div className="flex items-baseline justify-between">
                {Array.from({ length: bars }).map((_, index) => (
                    <div
                        key={index}
                        className={clsx(
                            "w-full bg-zinc-200 rounded-t-lg dark:bg-zinc-800",
                            heightsShuffled[index],
                            index !== 0 && "ms-3",
                        )}
                    ></div>
                ))}
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    );
}
