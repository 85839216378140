import { Popover, PopoverContent } from "@/common/Popover";

// const selectClassNames = clsx([
//     // Basic layout
//     "relative block appearance-none rounded-lg",
//     // Horizontal padding
//     // Options (multi-select)
//     "[&_optgroup]:font-semibold",
//     // Typography
//     "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white dark:*:text-white",
//     // Border
//     "border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20",
//     // Background color
//     "bg-transparent dark:bg-white/10 dark:*:bg-zinc-800",
//     // Hide default focus styles
//     "focus:outline-none",
//     // Invalid state
//     "data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-600 data-[invalid]:data-[hover]:dark:border-red-600",
//     // Disabled state
//     "data-[disabled]:border-zinc-950/20 data-[disabled]:opacity-100 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]",
//     "w-40 z-20",
//     "text-left",
// ]);

export default function PopoverAsSelect({
    onClose,
    options,
    onChange,
}: {
    onClose: () => void;
    options: Record<string, string | number>;
    onChange: (value: string) => void;
}) {
    return (
        <Popover open={true} autoUpdatePosition={false} onOpenChange={onClose}>
            {(popover) => (
                <div ref={popover.refs.setReference}>
                    <PopoverContent>
                        <div className={"py-2"}>
                            {Object.entries(options).map(([value, label]) => (
                                <div key={value} className={"full-w px-2"}>
                                    <div
                                        className="full-w px-1 py-1 hover:bg-blue-500 hover:text-white rounded-lg"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onChange(value.toString());
                                        }}
                                    >
                                        {label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </PopoverContent>
                </div>
            )}
        </Popover>
    );
}
