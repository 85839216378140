import { TouchpointPredicate } from "@/types/generated/TouchpointPredicate";
import { FunnelConfig, FunnelStepMaybeNew, Predicate } from "./types";
import { EventPredicate } from "@/types/generated/EventPredicate";
import { FunnelRequestStepType } from "@/types/generated/FunnelRequestStepType.ts";
import { PopoverTrigger } from "@/common/Popover";
import FunnelStepButton from "@/features/Funnel/FunnelStepButton.tsx";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { produce } from "immer";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Bars3Icon } from "@heroicons/react/16/solid";
import { assertNotUndefined } from "@/util/typeguards.ts";

const DEFAULT_PREDICATE: Record<FunnelRequestStepType["type"], Predicate> = {
    touchpoint: {
        key: null,
        property: "path",
        comparator: "equals",
        value: "/",
    } as TouchpointPredicate,
    event: {
        property: "eventProperty",
        key: "my_prop",
        comparator: "equals",
        value: "my value",
    } as EventPredicate,
};

const eventPredicateProperties: EventPredicate["property"][] = [
    "eventProperty",
    "currency",
    "email",
];

const touchpointPredicateProperties: TouchpointPredicate["property"][] = [
    "path",
    "url",
    "domain",
    "campaignName",
    "campaignId",
    "campaignMedium",
    "campaignTerm",
    "campaignContent",
    "keywordId",
    "adGroupId",
    "adId",
    "sourceType",
    "referrer",
];

export default function FunnelStepButtonListItem({
    index,
    funnelConfig,
    step,
    setSelectedStep,
    onPredicateChange,
    onChange,
    totalSteps,
    isDraggable,
}: {
    index: number;
    funnelConfig: FunnelConfig;
    step: FunnelStepMaybeNew;
    setSelectedStep: (index: number) => void;
    onPredicateChange: (predicate: Predicate, index: number) => void;
    onChange: (config: FunnelConfig) => void;
    totalSteps: number;
    isDraggable: boolean;
}) {
    const canDelete = index >= 2 || totalSteps > 2;

    const predicateProperties =
        !step.needsSetup && step.props
            ? step.props.type === "event"
                ? eventPredicateProperties
                : touchpointPredicateProperties
            : undefined;

    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({ id: step.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <PopoverTrigger key={`step-${step.id}`}>
            <div ref={setNodeRef} style={style}>
                <FunnelStepButton
                    className={"relative"}
                    onClick={() => setSelectedStep(index)}
                    key={index}
                    predicate={step.props?.predicate}
                    predicateProperties={predicateProperties}
                    onPredicateChange={(predicate) =>
                        onPredicateChange(predicate, index)
                    }
                >
                    <>
                        <div
                            className={clsx(
                                "absolute left-[4px] hidden p-1 -m-1",
                                isDraggable && "cursor-grab  group-hover:block",
                            )}
                            ref={setActivatorNodeRef}
                            {...listeners}
                            {...attributes}
                        >
                            <Bars3Icon className={"h-2 w-2 text-subtle"} />
                        </div>
                        <div className={"flex items-center gap-2 w-full"}>
                            <div
                                className={
                                    "h-5 w-5 bg-indigo-500 rounded-md p-2 flex items-center justify-center text-xs mr-2 dark:text-default text-white"
                                }
                            >
                                {index + 1}
                            </div>
                            <div className={"w-4/5 truncate text-left"}>
                                {step.name}
                            </div>
                            <div className={"flex gap-2"}>
                                {!step.needsSetup && step.props && (
                                    <FunnelIcon
                                        className={clsx(
                                            "h-4 w-4 text-zinc-500 hover:text-zinc-300 cursor-pointer hidden group-hover:block",
                                        )}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            const updatedConfig = produce(
                                                funnelConfig,
                                                (draft) => {
                                                    if (
                                                        !step.props?.predicate
                                                    ) {
                                                        assertNotUndefined(
                                                            draft.steps[index],
                                                        );
                                                        assertNotUndefined(
                                                            draft.steps[index]
                                                                .props,
                                                        );
                                                        const type =
                                                            draft.steps[index]
                                                                .props.type;

                                                        draft.steps[
                                                            index
                                                        ].props.predicate =
                                                            DEFAULT_PREDICATE[
                                                                type
                                                            ];
                                                    } else {
                                                        assertNotUndefined(
                                                            draft.steps[index],
                                                        );
                                                        assertNotUndefined(
                                                            draft.steps[index]
                                                                .props,
                                                        );
                                                        // remove predicate
                                                        draft.steps[
                                                            index
                                                        ].props.predicate =
                                                            null;
                                                    }
                                                },
                                            );

                                            onChange(updatedConfig);
                                        }}
                                    />
                                )}
                                <XMarkIcon
                                    className={clsx(
                                        "h-4 w-4 text-zinc-500 hover:text-zinc-300 cursor-pointer hidden",
                                        canDelete && "group-hover:block",
                                    )}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        const updatedConfig = produce(
                                            funnelConfig,
                                            (draft) => {
                                                draft.steps.splice(index, 1);
                                            },
                                        );

                                        onChange(updatedConfig);
                                    }}
                                />
                            </div>
                        </div>
                    </>
                </FunnelStepButton>
            </div>
        </PopoverTrigger>
    );
}
