import { safeDivide } from "@/util/math.ts";

export default function PercentageChangeFunnel({
    width,
    height,
    from,
    to,
    className,
}: {
    width: number;
    height: number;
    from?: number | string;
    to?: number | string;
    className?: string;
}) {
    if (width < 0 || height < 0) {
        return null;
    }

    const startsAtZero = Number(from || 0) === 0;
    const endsAtZero = Number(to || 0) === 0;

    if (startsAtZero && endsAtZero) {
        return (
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    stroke={"hsl(221.2 83.2% 53.3%"}
                    d={`M0,${height / 2}
                            L${width},${height / 2}
                            `}
                />
            </svg>
        );
    }

    const percentageChange = safeDivide(to, from);
    const funnelEndRate = (1 - Math.min(percentageChange, 1)) / 2;

    const funnelEndTop = height * funnelEndRate;
    const funnelEndBottom = height * (1 - funnelEndRate);

    const bezierFirstPointX = width * 0.3;
    const bezierSecondPointX = width * 0.7;

    const bezierBottomFirstPointY =
        funnelEndBottom + (height - funnelEndBottom) * 0.2;
    const bezierBottomSecondPointY =
        funnelEndBottom + (height - funnelEndBottom) * 0.1;
    const bezierBottomFirstPoint = `${bezierFirstPointX},${bezierBottomFirstPointY}`;
    const bezierBottomSecondPoint = `${bezierSecondPointX},${bezierBottomSecondPointY}`;
    const bezierBottomEndPoint = `${width},${funnelEndBottom}`;

    const bezierTopFirstPointY = funnelEndTop - funnelEndTop * 0.2;
    const bezierTopSecondPointY = funnelEndTop - funnelEndTop * 0.1;
    const bezierTopFirstPoint = `${bezierSecondPointX},${bezierTopFirstPointY}`;
    const bezierTopSecondPoint = `${bezierFirstPointX},${bezierTopSecondPointY}`;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient id="Gradient1" x1="0" x2="1" y1="0" y2="0">
                    <stop
                        className="stop1"
                        offset="5%"
                        stopOpacity="0.8"
                        stopColor="hsl(212 95% 68%)"
                    />
                    <stop
                        className="stop2"
                        offset="95%"
                        stopOpacity="0.4"
                        stopColor="hsl(212 95% 68%)"
                    />
                </linearGradient>
            </defs>
            <path
                d={`M0,0
                            L0,${height}
                            C${bezierBottomFirstPoint} ${bezierBottomSecondPoint} ${bezierBottomEndPoint}
                            L${width},${funnelEndTop}
                            C${bezierTopFirstPoint} ${bezierTopSecondPoint} 0,0
                            `}
                fill="url(#Gradient1)"
            />
        </svg>
    );
}
