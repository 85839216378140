const currencies = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    CAD: "$",
    AUD: "$",
    NZD: "$",
    SEK: "Kr. ",
    DKK: "Kr. ",
    NOK: "Kr. ",
    HUF: "Ft. ",
    ZAR: "R. ",
};

export function getCurrencyPrefix(currency: string): string {
    return currencies[currency as keyof typeof currencies] ?? currency + "  ";
}
