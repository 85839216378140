import Panel from "@/common/Panel/Panel.tsx";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
import { ReportResponse } from "@/types/generated/ReportResponse.ts";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/catalyst/table";
import { useMemo } from "react";
import Money from "@/common/Money";
import { snakeToSentence } from "@/util/case.ts";
import PlatformIcon from "@/common/PlatformIcon";
import ChangeBadge from "@/common/ChangeBadge";
import InfoTooltip from "@/common/InfoTooltip";
import Link from "@/common/Link";
import { WidgetQuery } from "@/features/Dashboard/Dashboard.tsx";
import { Report } from "@/types/generated/Report.ts";

type SourceData = {
    name: string;
    leads: number;
    leads_compare: number;
    revenue: number;
};

type Sources = Record<Report["source"], SourceData>;

export default function TopSourcesWidget({
    reportData,
    currency,
    currentUrlQuery,
}: {
    reportData?: ReportResponse;
    currency: string;
    currentUrlQuery: WidgetQuery;
}) {
    const isLoading = !reportData?.data;
    const sources: Sources = useMemo(
        () => calculateSourceData(currency, reportData?.data),
        [currency, reportData],
    );
    const showEmptyState =
        !isLoading &&
        (reportData?.data.length === 0 ||
            Object.entries(sources).filter(([, source]) => source.leads > 0)
                .length === 0);

    return (
        <Panel
            title={"Top sources"}
            subtitle={"Showing top lead generating sources"}
        >
            {!showEmptyState && (
                <div className={"flex flex-col gap-4"}>
                    <Table
                        dense={true}
                        striped={true}
                        className={"h-64 overflow-scroll"}
                    >
                        <TableHead>
                            <TableRow>
                                <TableHeader>Source</TableHeader>
                                <TableHeader>Leads</TableHeader>
                                <TableHeader className={"flex items-center"}>
                                    Revenue{" "}
                                    <InfoTooltip>
                                        Revenue includes all revenue that can be
                                        attributed to a source's touch points in
                                        the selected date range, even if the
                                        revenue itself was generated outside the
                                        selected date range. This means that
                                        this amount can increase/change over
                                        time, for the same, selected date range.
                                    </InfoTooltip>
                                </TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading &&
                                Array.from({ length: 10 }).map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell className="font-medium">
                                            <div className="flex items-center gap-2">
                                                <div className="h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-800 w-32 animate-pulse"></div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-800 w-32 animate-pulse"></div>
                                        </TableCell>
                                        <TableCell className="flex items-center">
                                            <div className="h-2.5 bg-zinc-200 rounded-full dark:bg-zinc-800 w-32 animate-pulse"></div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {!isLoading &&
                                Object.entries(sources)
                                    .filter(([, source]) => source.leads > 0)
                                    .sort(
                                        ([, aSource], [, bSource]) =>
                                            bSource.leads - aSource.leads,
                                    )
                                    .map(([sourceId, source]) => {
                                        return (
                                            <TableRow key={sourceId}>
                                                <TableCell className="font-medium">
                                                    <div className="flex items-center gap-2">
                                                        <PlatformIcon
                                                            className={
                                                                "h-6 w-6"
                                                            }
                                                            platform={sourceId.replace(
                                                                "_organic",
                                                                "",
                                                            )}
                                                        ></PlatformIcon>
                                                        {source.name}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {source.leads.toFixed(2)}
                                                    <ChangeBadge
                                                        className={"ml-2"}
                                                        value={source.leads}
                                                        compareValue={
                                                            source.leads_compare
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className="flex items-center">
                                                    <Money
                                                        amount={source.revenue}
                                                        currency={currency}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                    <div
                        className={
                            "flex items-center justify-center gap-4 text-subtle text-xs underline"
                        }
                    >
                        <Link
                            to={`/reporting?q=${btoa(JSON.stringify(currentUrlQuery))}`}
                        >
                            Show all sources
                        </Link>
                    </div>
                </div>
            )}
            {showEmptyState && (
                <WidgetEmptyState
                    subtitle={"No leads or revenue for the selected date range"}
                />
            )}
        </Panel>
    );
}

const calculateSourceData = (
    currency: string,
    data?: ReportResponse["data"],
) => {
    return (data || []).reduce((carry, report) => {
        if (!report.source) {
            return carry;
        }

        carry[report.source] = carry[report.source] || {
            name: snakeToSentence(report.source),
            leads: 0,
            leads_compare: 0,
            revenue: 0,
        };

        carry[report.source].leads += Number(report.leads[currency] || 0);
        carry[report.source].leads_compare += Number(
            report.leadsCompare[currency] || 0,
        );
        carry[report.source].revenue += Number(report.revenue[currency] || 0);

        return carry;
    }, {} as Sources);
};
