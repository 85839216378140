import { clsx } from "clsx";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    EqualsIcon,
} from "@heroicons/react/16/solid";

const iconClasses = "-ml-1 mr-0.5 size-3 shrink-0 self-center ";

export default function ChangeBadge({
    value,
    compareValue,
    className,
    inverted = false,
}: {
    value: number;
    compareValue?: number;
    className?: string;
    inverted?: boolean;
}) {
    const change = getChangePercentage(value, compareValue);
    const up = change > 0;
    const equal = change === 0;
    const formatter = new Intl.NumberFormat(undefined, {
        notation: "compact",
        maximumFractionDigits: 0,
    });
    return (
        <div className={clsx("inline-flex", className)}>
            <div
                className={clsx(
                    !equal &&
                        ((up && !inverted) || (!up && inverted)
                            ? "bg-green-100 dark:bg-green-300 text-green-500 dark:text-green-800 border border-green-500 dark:border-green-800"
                            : "bg-red-100 dark:bg-red-300 text-red-500 dark:text-red-800 border border-red-500 dark:border-red-800"),
                    equal &&
                        "bg-gray-100 dark:bg-gray-300 text-gray-500 border border-gray-500 dark:text-gray-800 dark:border-gray-800",
                    "inline-flex h-5 rounded-full px-1.5 text-xs items-center font-medium md:mt-2 lg:mt-0 opacity-95 dark:opacity-80 dark:font-semibold",
                )}
            >
                {!equal &&
                    (up ? (
                        <ArrowUpIcon
                            aria-hidden="true"
                            className={clsx(
                                iconClasses,
                                !inverted
                                    ? "text-green-500 dark:text-green-800"
                                    : "text-red-500 dark:text-red-800",
                            )}
                        />
                    ) : (
                        <ArrowDownIcon
                            aria-hidden="true"
                            className={clsx(
                                iconClasses,
                                inverted
                                    ? "text-green-500 dark:text-green-800"
                                    : "text-red-500 dark:text-red-800",
                            )}
                        />
                    ))}
                {equal && (
                    <EqualsIcon
                        className={clsx(
                            iconClasses,
                            "text-gray-500 dark:text-gray-800",
                        )}
                    />
                )}

                {change === Infinity || change === -Infinity
                    ? (!up ? "-" : "") + "∞"
                    : `${formatter.format(change)}%`}
            </div>
        </div>
    );
}

const getChangePercentage = (value: number, compareValue?: number): number => {
    if (value === compareValue) return 0;

    if (compareValue === undefined || compareValue === 0) {
        if (value === 0) return 0;
        return value > 0 ? Infinity : -Infinity;
    }

    if (Math.abs(compareValue) === Infinity) {
        return compareValue > value ? -Infinity : Infinity;
    }

    return ((value - compareValue) / compareValue) * 100;
};
