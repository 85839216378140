import { ReactNode } from "react";

export default function WidgetEmptyState({
    subtitle,
    title = "No data yet",
}: {
    subtitle: ReactNode;
    title?: string;
}) {
    return (
        <div
            className={"flex items-center justify-center h-full grow min-h-52"}
        >
            <div className={"text-center"}>
                <h2 className={"text-lg font-medium text-default leading-6"}>
                    {title}
                </h2>
                <p className={"mt-1 text-sm text-subtle"}>{subtitle}</p>
            </div>
        </div>
    );
}
