import Button from "@/common/Button";
import { format } from "date-fns";
import { User } from "@/model/User.ts";
import { useCallback } from "react";
import { useGetCheckoutSetupSessionMutation } from "@/services/apis/api.ts";
import { assertNotUndefined } from "@/util/typeguards.ts";

export default function TrialSidebarWidget({ user }: { user: User }) {
    const [getCheckoutSetupSession, { isLoading }] =
        useGetCheckoutSetupSessionMutation();
    const startPaymentMethodFlow = useCallback(async () => {
        const result = await getCheckoutSetupSession(undefined);

        assertNotUndefined(result.data);

        window.location.href = result.data.url;
    }, [getCheckoutSetupSession]);

    return (
        <div
            className={
                "border-2 border-zinc-300 dark:border-zinc-700 border-dashed p-5 text-sm"
            }
        >
            {user.organization.trialEndsAt ? (
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex items-start justify-between gap-2"}>
                        <div>
                            <h3 className={"text-md font-bold text-default"}>
                                Free Trial
                            </h3>
                            <p
                                className={
                                    "text-zinc-500 dark:text-zinc-400 mb-2"
                                }
                            >
                                Your trial ends on{" "}
                                {format(
                                    new Date(user.organization.trialEndsAt),
                                    "MMM. d yyyy",
                                )}
                            </p>{" "}
                        </div>
                        <div className="relative w-14 h-14">
                            <svg viewBox="0 0 100 100">
                                <circle
                                    className="text-gray-200 stroke-current"
                                    strokeWidth="10"
                                    cx="50"
                                    cy="50"
                                    r="40"
                                    fill="transparent"
                                ></circle>
                                <circle
                                    className="text-indigo-500  progress-ring__circle stroke-current"
                                    strokeWidth="10"
                                    strokeLinecap="round"
                                    cx="50"
                                    cy="50"
                                    r="40"
                                    fill="transparent"
                                    strokeDasharray="251.2"
                                    strokeDashoffset={`calc(251.2px - (251.2px * ${(user.organization.getTrialDaysLeft() / 14) * 100}) / 100)`}
                                ></circle>

                                <text
                                    x="50"
                                    y="50"
                                    fontSize="20"
                                    textAnchor="middle"
                                    className="font-bold fill-text-default"
                                    alignmentBaseline="middle"
                                >
                                    {user.organization.getTrialDaysLeft()}{" "}
                                </text>
                            </svg>
                        </div>
                    </div>
                    <Button
                        type={"primary"}
                        loading={isLoading}
                        onClick={startPaymentMethodFlow}
                    >
                        <span className={"truncate"}>Add payment method</span>
                    </Button>
                </div>
            ) : (
                <div>No trial left</div>
            )}
        </div>
    );
}
