import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export type SetQuery<T extends object> = (
    queryUpdater: T | ((prev: T) => T),
) => void;

export function useUrlPersistedQuery<T extends object>(
    defaults: T,
    transform?: (query: T) => T,
): [T, SetQuery<T>] {
    const [searchParams, setSearchParams] = useSearchParams();

    const encodedUrlQuery = searchParams.get("q");
    let urlQuery = decodeUrlQuery<T>(encodedUrlQuery);
    if (transform && !!urlQuery) {
        urlQuery = transform(urlQuery);

        // Remove null and undefined values
        urlQuery = Object.fromEntries(
            Object.entries(urlQuery).filter(
                ([, value]) => value !== null && value !== undefined,
            ),
        ) as T;
    }

    const [query, setQuery] = useState<T>({ ...defaults, ...urlQuery });

    const encodedCurrentQuery = encodeUrlQuery(query);

    // Update query in url when state changes
    useEffect(() => {
        if (encodedCurrentQuery === encodedUrlQuery) {
            return;
        }

        setSearchParams({
            q: encodedCurrentQuery,
        });
    }, [encodedCurrentQuery]);

    return [query, setQuery];
}

export function decodeUrlQuery<T>(query: string | null): T | null {
    if (!query) {
        return null;
    }

    return JSON.parse(atob(query));
}

export function encodeUrlQuery<T>(query: T): string {
    return btoa(JSON.stringify(query));
}
