import {
    useGetDashboardWidgetsQuery,
    useGetReportQuery,
} from "@/services/apis/data.ts";
import { format } from "date-fns";
import AdSpendByNetworkWidget from "@/features/Dashboard/Widgets/AdSpendByNetworkWidget.tsx";
import TouchpointsByNetworkWidget from "@/features/Dashboard/Widgets/TouchpointsByNetworkWidget.tsx";
import RecentLeadsWidget from "@/features/Dashboard/Widgets/RecentLeadsWidget.tsx";
import DateRangePicker from "@/common/DateRangePicker";
import { useUrlPersistedQuery } from "@/hooks/use-url-persisted-query.ts";
import { DateRangeStrings, getDateRangeDescription } from "@/util/date.ts";
import { assertNotUndefined } from "@/util/typeguards";
import { useContext, useEffect, useState } from "react";
import UserContext from "@/app/UserContext.ts";
import NumberRowWidget from "@/features/Dashboard/Widgets/NumberRowWidget.tsx";
import TopSourcesWidget from "@/features/Dashboard/Widgets/TopSourcesWidget.tsx";
import { clsx } from "clsx";
import ListBox from "@/common/ListBox";
import { useUiProperty } from "@/features/UiState.slice.ts";
import RevenueBySourceTypeWidget from "@/features/Dashboard/Widgets/RevenueBySourceTypeWidget.tsx";
import { Container } from "@/common/Container";
import { getDefaultDateRangeAndCompareWithMinDate } from "@/common/DateRangePicker/util.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

export type WidgetQuery = {
    dateRange: DateRangeStrings;
    compareDateRange: DateRangeStrings;
    currency: string;
};

export default function Dashboard() {
    const selectedWorkspace = useSelectedWorkspace();
    const [isDarkMode] = useUiProperty<boolean>("darkMode");

    const [query, setQuery] = useUrlPersistedQuery<WidgetQuery>({
        ...getDefaultDateRangeAndCompareWithMinDate(
            selectedWorkspace.createdAt,
        ),
        currency:
            selectedWorkspace.reportingCurrency ||
            selectedWorkspace.defaultCurrency,
    });

    const user = useContext(UserContext);
    assertNotUndefined(user);

    const { data: widgets } = useGetDashboardWidgetsQuery({
        workspace_id: selectedWorkspace.id,
        date_from: query.dateRange.from,
        date_to: query.dateRange.to,
        cut_off_events: selectedWorkspace.cutOffEvents,
        default_currency: selectedWorkspace.defaultCurrency,
        timezone: user.timezone || null,
        reporting_currency: selectedWorkspace.reportingCurrency || null,
    });

    const { currentData: reportData } = useGetReportQuery({
        workspace_id: selectedWorkspace.id,
        date_from: query.dateRange.from,
        date_to: query.dateRange.to,
        compare_date_from: query.compareDateRange.from,
        compare_date_to: query.compareDateRange.to,
        attribution_model: "uShaped",
        cut_off_events: selectedWorkspace.cutOffEvents,
        default_currency: selectedWorkspace.defaultCurrency,
        timezone: user.timezone || null,
        reporting_currency: selectedWorkspace.reportingCurrency || null,
        organization_attribution: selectedWorkspace.organizationAttribution,
    });

    const [resultCurrencies, setResultCurrencies] = useState<string[]>([]);
    // Update resultCurrencies when data is loaded
    useEffect(() => {
        if (!reportData?.data) {
            return;
        }
        const resultCurrencies = reportData.currencies;
        setResultCurrencies(resultCurrencies);
    }, [reportData?.data, setResultCurrencies, reportData?.currencies]);

    const dateRangeDescription = getDateRangeDescription(
        query.dateRange,
        widgets?.resolution,
    );

    return (
        <Container>
            <div className={"mb-4 flex justify-between items-center"}>
                <h2 className="text-3xl font-medium text-default leading-6">
                    Dashboard
                </h2>
                <div
                    className={
                        "grid grid-cols-1 2xl:grid-cols-2 gap-x-6 gap-y-4"
                    }
                >
                    <div
                        className={clsx(
                            "flex flex-col transition-all opacity-0",
                            resultCurrencies.length > 1 && "opacity-100",
                        )}
                    >
                        <div
                            className={
                                "text-subtle text-xs mb-2 flex flex-row lg:justify-end"
                            }
                        >
                            Currency
                        </div>

                        <ListBox
                            value={query.currency}
                            buttonBold={isDarkMode}
                            options={resultCurrencies.map((value) => ({
                                value,
                                label: value,
                            }))}
                            onChange={(value) =>
                                setQuery((prev) => ({
                                    ...prev,
                                    currency: value,
                                }))
                            }
                        />
                    </div>
                    <div className={"flex flex-col"}>
                        <div
                            className={
                                "text-subtle text-xs mb-2 flex flex-row justify-end"
                            }
                        >
                            Date range
                        </div>
                        <DateRangePicker
                            value={{
                                from: new Date(query.dateRange.from),
                                to: new Date(query.dateRange.to),
                            }}
                            onChange={(value, compareValue) =>
                                setQuery((prev) => ({
                                    ...prev,
                                    dateRange: {
                                        from: format(value.from, "yyyy-MM-dd"),
                                        to: format(value.to, "yyyy-MM-dd"),
                                    },
                                    compareDateRange: {
                                        from: format(
                                            compareValue.from,
                                            "yyyy-MM-dd",
                                        ),
                                        to: format(
                                            compareValue.to,
                                            "yyyy-MM-dd",
                                        ),
                                    },
                                }))
                            }
                        />
                    </div>
                </div>
            </div>
            <div
                className={
                    "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6"
                }
            >
                <NumberRowWidget
                    reportData={reportData}
                    currency={query.currency}
                />
                {/*<SpendVsRevenueWidget*/}
                {/*    className={"2xl:col-span-2 lg:col-span-2"}*/}
                {/*    resolution={widgets?.resolution}*/}
                {/*    spendVsRevenue={widgets?.data.spend_vs_revenue}*/}
                {/*    dateRangeDescription={dateRangeDescription}*/}
                {/*/>*/}
                <AdSpendByNetworkWidget
                    className={"2xl:col-span-2 lg:col-span-2"}
                    adSpendByNetwork={widgets?.data.adSpendByNetwork}
                    resolution={widgets?.resolution}
                    dateRangeDescription={dateRangeDescription}
                    currency={query.currency}
                />
                <TopSourcesWidget
                    currency={query.currency}
                    reportData={reportData}
                    currentUrlQuery={query}
                />
                <RevenueBySourceTypeWidget
                    reportData={reportData}
                    currency={query.currency}
                />
                <RecentLeadsWidget recentLeads={widgets?.data.lastContacts} />
                <TouchpointsByNetworkWidget
                    touchpointByNetwork={widgets?.data.touchpointsByNetwork}
                    dateRangeDescription={dateRangeDescription}
                />
            </div>
        </Container>
    );
}
