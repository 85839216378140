import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";
import Money from "@/common/Money";
import MultiCurrencyMoney from "@/common/Money/MultiCurrencyMoney.tsx";

const positiveColorClass = "text-green-500";
const negativeColorClass = "text-red-500";

export default function Result({
    amount,
    children,
}: {
    amount: number;
    children: ReactNode;
}) {
    return (
        <span
            className={classNames(
                amount > 0 && positiveColorClass,
                amount < 0 && negativeColorClass,
            )}
        >
            {children}
        </span>
    );
}

export function MoneyResult({
    amount,
    currency,
}: {
    amount: number;
    currency: string;
}) {
    return (
        <Result amount={amount}>
            <Money amount={amount} currency={currency} />
        </Result>
    );
}

export function MultiCurrencyMoneyResult({
    items,
    defaultCurrency,
}: {
    items: ComponentProps<typeof MultiCurrencyMoney>["items"];
    defaultCurrency: string;
}) {
    return (
        <Result amount={parseFloat(items[defaultCurrency] || "0")}>
            <MultiCurrencyMoney
                items={items}
                defaultCurrency={defaultCurrency}
            />
        </Result>
    );
}
