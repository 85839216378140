import { useGetFunnelQuery } from "@/services/apis/data.ts";

import { FullHeightMainContainer } from "@/common/Container";
import FunnelChartWidget, {
    FunnelChartData,
} from "@/features/Funnel/FunnelChartWidget.tsx";
import { useUrlPersistedQuery } from "@/hooks/use-url-persisted-query.ts";
import { useUser } from "@/hooks/use-user.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    createFunnelStepColDef,
    funnelDataColDefs,
} from "@/features/Funnel/ColDefs.tsx";
import { ColDef } from "ag-grid-community";
import { FunnelResponseStepResult } from "@/types/generated/FunnelResponseStepResult.ts";
import ReportTable from "@/common/ReportTable";
import { AttributionModel } from "@/types/generated/AttributionModel.ts";
import ControlBar from "@/common/ReportTable/ControlBar.tsx";
import FunnelConfigPanel from "@/features/Funnel/FunnelConfigPanel.tsx";
import { FunnelConfig } from "@/features/Funnel/types.ts";
import FunnelEmptyState from "@/features/Funnel/FunnelEmptyState.tsx";
import {
    useDeleteFunnelMutation,
    useGetFunnelsQuery,
    useUpdateFunnelMutation,
} from "@/services/apis/api";
import { assertNotUndefined } from "@/util/typeguards.ts";
import { usePrevious } from "@uidotdev/usehooks";
import Loading from "@/common/Loading";
import { produce } from "immer";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { DateRangeWithCompare } from "@/common/ReportTable/types.ts";
import { FunnelRequestStep } from "@/types/generated/FunnelRequestStep.ts";
import { safeDivide, sum } from "@/util/math.ts";
import { capitalize } from "@/util/case.ts";
import { getDefaultDateRangeWithMinDate } from "@/common/DateRangePicker/util.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

type FunnelQuery = {
    id?: string;
    dateRange: {
        from: string;
        to: string;
    };
};

export const emptyFunnelTitle = "My new funnel";
const emptyFunnelConfig: FunnelConfig = {
    name: emptyFunnelTitle,
    reportType: "source",
    attributionModel: "uShaped",
    steps: [],
    filters: [],
    drillDownDimensions: [],
};

export default function Detail() {
    const selectedWorkspace = useSelectedWorkspace();

    const [query, setQuery] = useUrlPersistedQuery<FunnelQuery>({
        id: undefined,
        ...getDefaultDateRangeWithMinDate(selectedWorkspace.createdAt),
    });

    const user = useUser();

    const [funnelConfig, setFunnelConfig] =
        useState<FunnelConfig>(emptyFunnelConfig);

    const validSteps = funnelConfig.steps.filter(
        (step) => !step.needsSetup,
    ) as FunnelRequestStep[];

    const needsFunnelSetup = validSteps.length < 2;

    const { data, isLoading, isFetching } = useGetFunnelQuery(
        {
            workspaceId: selectedWorkspace.id,
            dateFrom: query.dateRange.from,
            dateTo: query.dateRange.to,
            timezone: user.timezone || null,
            attributionModel: funnelConfig.attributionModel,
            steps: validSteps,
            cutOffStep: funnelConfig.cutOffStep ?? validSteps.length - 1,
            organizationAttribution: selectedWorkspace.organizationAttribution,
        },
        {
            skip: needsFunnelSetup,
        },
    );

    const { data: funnels, isLoading: isLoadingFunnels } =
        useGetFunnelsQuery(undefined);

    const extendedFunnelDataColDefs: ColDef[] = useMemo(() => {
        const steps = data?.data[0]?.steps;
        if (!steps) {
            return [];
        }

        return [
            ...funnelDataColDefs,
            ...steps.map((_step, index) =>
                createFunnelStepColDef(index, funnelConfig.steps[index]?.name),
            ),
        ];
    }, [data?.data]);

    const loading = isFetching || isLoading;

    const previousFunnels = usePrevious(funnels);
    useEffect(() => {
        if (!previousFunnels && funnels?.length) {
            if (query.id) {
                const funnel = funnels.find((funnel) => funnel.id === query.id);
                if (funnel) {
                    setFunnelConfig(funnel);
                    return;
                }
            }
            if (funnels[0]) {
                setFunnelConfig(funnels[0]);
            }
        }
    }, [query.id, funnels, previousFunnels]);

    const [deleteFunnel] = useDeleteFunnelMutation();

    const [updateFunnel] = useUpdateFunnelMutation();
    const onTitleChange = useCallback(
        (newTitle?: string) => {
            if (typeof newTitle === "undefined") {
                return;
            }

            const newFunnelConfig = produce(funnelConfig, (draft) => {
                draft.name = newTitle;
            });

            setFunnelConfig(newFunnelConfig);

            if (newFunnelConfig.id) {
                // Update only the title in the database. Updating all changes would be unexpected for the user.
                const originalPersistedFunnel = funnels?.find(
                    (f) => f.id === funnelConfig.id,
                );
                if (originalPersistedFunnel) {
                    const updatedOriginalFunnel = produce(
                        originalPersistedFunnel,
                        (draft) => {
                            draft.name = newFunnelConfig.name;
                        },
                    );
                    updateFunnel({
                        id: updatedOriginalFunnel.id!,
                        ...updatedOriginalFunnel,
                    });
                }
            }
        },
        [funnelConfig, funnels, updateFunnel],
    );

    const filteredTableData = useMemo(() => {
        if (!funnelConfig.filters) {
            return data?.data || [];
        }

        return (
            data?.data.filter((report) => {
                return funnelConfig.filters.every((filter) => {
                    const value = report[filter.property];
                    if (value === null || typeof value === "undefined") {
                        return false;
                    }

                    const valueToFilter = value.toString();
                    switch (filter.comparator) {
                        case "equals":
                            return valueToFilter === filter.value;
                        case "contains":
                            return valueToFilter.includes(filter.value);
                        case "startsWith":
                            return valueToFilter.startsWith(filter.value);
                        case "endsWith":
                            return valueToFilter.endsWith(filter.value);
                        default:
                            return false;
                    }
                });
            }) || []
        );
    }, [data?.data, funnelConfig.filters]);

    const chartData = useMemo(
        () => groupByDimension(funnelConfig.reportType, filteredTableData),
        [filteredTableData, funnelConfig.reportType],
    );

    const setDimension = useCallback(
        (value: AttributionDimension) => {
            setFunnelConfig({ ...funnelConfig, reportType: value });
        },
        [funnelConfig],
    );

    const setFilters = useCallback(
        (value: AttributionFilter[]) => {
            setFunnelConfig({ ...funnelConfig, filters: value });
        },
        [funnelConfig],
    );

    const setAttributionModel = useCallback(
        (value: AttributionModel) => {
            setFunnelConfig({ ...funnelConfig, attributionModel: value });
        },
        [funnelConfig],
    );

    const setDrillDownDimensions = useCallback(
        (value: AttributionDimension[]) => {
            setFunnelConfig({ ...funnelConfig, drillDownDimensions: value });
        },
        [funnelConfig],
    );

    const setDateRange = useCallback(
        (value: DateRangeWithCompare) => {
            setQuery({
                ...query,
                dateRange: value.dateRange,
            });
        },
        [query, setQuery],
    );

    return (
        <>
            <div className={"flex h-full w-full"}>
                <FullHeightMainContainer>
                    <div className={"flex flex-col gap-4 h-full"}>
                        <ControlBar
                            filters={funnelConfig.filters}
                            dimension={funnelConfig.reportType}
                            attributionModel={funnelConfig.attributionModel}
                            dateRange={query.dateRange}
                            setDimension={setDimension}
                            setFilters={setFilters}
                            setAttributionModel={setAttributionModel}
                            setDateRange={setDateRange}
                            drillDownDimensions={
                                funnelConfig.drillDownDimensions || []
                            }
                            setDrillDownDimensions={setDrillDownDimensions}
                            dateRangeDescription={
                                <ul className={"list-outside list-disc"}>
                                    <li>
                                        All{" "}
                                        <span className={"font-semibold"}>
                                            Visitors
                                        </span>{" "}
                                        that performed the first funnel step
                                        within the selected date range
                                    </li>
                                    <li>
                                        Any funnel step performed after the end
                                        of the selected date range is not
                                        counted
                                    </li>
                                    <li>
                                        Their relative contribution is counted
                                        by attributing them to all visits before
                                        the selected cut-off step. By default
                                        this is the last funnel step, but this
                                        can be changed on the right. can change
                                        this on the right.
                                    </li>
                                </ul>
                            }
                        />
                        {!isLoadingFunnels && (
                            <>
                                {!needsFunnelSetup && (
                                    <>
                                        <FunnelChartWidget
                                            isLoading={loading}
                                            funnelConfig={funnelConfig}
                                            chartData={chartData}
                                            onChange={setFunnelConfig}
                                            onTitleChange={onTitleChange}
                                        />
                                        <ReportTable
                                            loading={loading}
                                            colDefs={extendedFunnelDataColDefs}
                                            rowData={
                                                (!isFetching
                                                    ? data?.data
                                                    : undefined) || []
                                            }
                                            dimension={funnelConfig.reportType}
                                            filters={funnelConfig.filters}
                                            drillDownDimensions={
                                                funnelConfig.drillDownDimensions ||
                                                []
                                            }
                                            setFilters={(filters) =>
                                                setFunnelConfig({
                                                    ...funnelConfig,
                                                    filters,
                                                })
                                            }
                                        />
                                    </>
                                )}
                                {needsFunnelSetup && (
                                    <div
                                        className={
                                            "h-full flex items-center justify-center"
                                        }
                                    >
                                        <FunnelEmptyState />
                                    </div>
                                )}
                            </>
                        )}
                        {isLoadingFunnels && <Loading />}
                    </div>
                </FullHeightMainContainer>
                <FunnelConfigPanel
                    funnelConfig={funnelConfig}
                    onChange={setFunnelConfig}
                    funnels={funnels}
                    needsFunnelSetup={needsFunnelSetup}
                    onNewFunnel={() => {
                        setQuery((query) => ({
                            ...query,
                            id: undefined,
                        }));
                        setFunnelConfig({ ...emptyFunnelConfig });
                    }}
                    onSelectFunnel={(id: string) => {
                        setQuery((query) => ({
                            ...query,
                            id,
                        }));

                        assertNotUndefined(funnels);
                        const newFunnel = funnels.find(
                            (funnel) => funnel.id === id,
                        );

                        assertNotUndefined(newFunnel);
                        setFunnelConfig(newFunnel);
                    }}
                    onDelete={async () => {
                        if (!funnelConfig.id) {
                            return;
                        }

                        const filteredFunnels = produce(funnels, (draft) => {
                            return draft?.filter(
                                (funnel) => funnel.id !== funnelConfig.id,
                            );
                        });

                        setFunnelConfig(
                            filteredFunnels?.[0] || { ...emptyFunnelConfig },
                        );

                        await deleteFunnel({
                            id: funnelConfig.id,
                        });
                    }}
                    onDuplicate={async () => {
                        const duplicatedFunnel = produce(
                            funnelConfig,
                            (draft) => {
                                delete draft.id;
                                draft.name = `${draft.name} (copy)`;
                            },
                        );

                        setFunnelConfig(duplicatedFunnel);
                    }}
                />
            </div>
        </>
    );
}

/**
 * Groups and sums attribution data by a specified dimension
 * @param {Array} data - Array of attribution data objects
 * @param {string} dimension - The dimension key to group by
 * @param {Function} toSentence - Function to convert dimension values to labels (optional)
 * @returns {Object} Object containing the summed data, dimension lookup, and step summaries
 */
function groupByDimension(
    dimension: AttributionDimension,
    data?: FunnelResponseStepResult[],
): FunnelChartData {
    if (!data) {
        return {
            summedSteps: [],
            dimensionLabels: {},
            stepSummaries: [],
        };
    }

    const shouldCapitalize = dimension === "source";

    // First pass: build dimension index mapping
    const dimensionMap = new Map();
    const dimensionValues = [];

    // Add "Not attributed" as the first entry (for null values)
    const nullIndex = dimensionValues.length;
    dimensionMap.set(null, nullIndex);
    dimensionValues.push(null);

    // Collect unique dimension values
    for (const item of data) {
        const dimensionValue = item[dimension];
        if (!dimensionMap.has(dimensionValue)) {
            const index = dimensionValues.length;
            dimensionMap.set(dimensionValue, index);
            dimensionValues.push(dimensionValue);
        }
    }

    // Determine how many steps we have (assuming all items have same number of steps)
    const stepCount = data.length > 0 ? data[0]!.steps.length : 0;

    // Initialize summed steps array
    const summedSteps: Record<string, number>[] = Array(stepCount)
        .fill(null)
        .map(() => ({}));

    // Second pass: sum the values
    for (const item of data) {
        const dimensionValue = item[dimension];
        const dimIndex = dimensionMap.get(dimensionValue);
        const dimKey = `d${dimIndex}`; // Simple numeric keys without spaces

        item.steps.forEach((step, stepIndex) => {
            const stepValue = Number(step.total);
            summedSteps[stepIndex]![dimKey] =
                (summedSteps[stepIndex]![dimKey] || 0) + stepValue;
        });
    }

    // Create label lookup object
    const dimensionLabels: Record<string, string> = {};
    dimensionValues.forEach((value, index) => {
        if (value === null) {
            dimensionLabels[`d${index}`] = "Not attributed";
        } else {
            dimensionLabels[`d${index}`] = shouldCapitalize
                ? capitalize(value)
                : value;
        }
    });

    // Calculate step totals and conversion rates
    const totals = summedSteps.map((step) =>
        Object.values(step).reduce(sum, 0),
    );

    // Generate step summaries with totals and conversion rates
    const stepSummaries = totals.map((total, index) => ({
        total,
        conversion: index > 0 ? safeDivide(total, totals[index - 1]) : 1,
    }));

    return {
        summedSteps,
        dimensionLabels,
        stepSummaries,
    };
}
