import { clsx } from "clsx";

function Skeleton({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={clsx(
                "animate-pulse rounded-md bg-zinc-200 dark:bg-zinc-800",
                className,
            )}
            {...props}
        />
    );
}

export default Skeleton;
