import { clsx } from "clsx";
import VerticalProgress, { Step } from "@/common/VerticalProgress";
import { useCallback, useEffect, useState } from "react";
import { Heading } from "@/catalyst/heading.tsx";
import { useGetWorkspaceMetaQuery } from "@/services/apis/data.ts";

import StepInstallation from "@/features/Onboarding/StepInstallation.tsx";
import StepConnectAdNetworks from "@/features/Onboarding/StepConnectAdNetworks.tsx";
import StepConnectPaymentProviders from "@/features/Onboarding/StepConnectPaymentProviders.tsx";
import StepCutOffEvents from "@/features/Onboarding/StepCutOffEvents.tsx";
import {
    useGetWorkspaceIntegrationsQuery,
    useUpdateWorkspaceMutation,
} from "@/services/apis/api.ts";
import { integrations } from "@/features/Workspace/Settings/integrations.tsx";
import { toast } from "@/common/Toast";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import { Divider } from "@/catalyst/divider.tsx";
import useAnalytics from "@/services/analytics/useAnalytics.ts";
import support from "@/services/support";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

export default function SetupGuide({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) {
    const selectedWorkspace = useSelectedWorkspace();
    const workspaceId = selectedWorkspace.id;
    const analytics = useAnalytics();

    const keyDownHandler = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose();
            }
        },
        [
            // Intentionally empty
        ],
    );

    useEffect(() => {
        if (open) {
            analytics?.trackSetupGuideOpenedEvent();
            document.addEventListener("keydown", keyDownHandler);
        } else {
            document.removeEventListener("keydown", keyDownHandler);
        }
    }, [open]);

    const [steps, setSteps] = useState<Step[]>([
        {
            name: "Install",
            description: "Install Spectacle on your website",
            status: "current",
        },
        {
            name: "Connect ad networks",
            description: "Connect ad networks",
            status: "upcoming",
        },
        {
            name: "Connect payment processor(s)",
            description: "Connect payment processor(s)",
            status: "upcoming",
        },
        {
            name: "Choose cut-off events",
            description: "Choose cut-off events man",
            status: "upcoming",
        },
    ]);

    const { data: workspaceMetaResponse } = useGetWorkspaceMetaQuery({
        workspaceId: workspaceId,
    });
    const { data: workspaceIntegrations } =
        useGetWorkspaceIntegrationsQuery(undefined);

    const [currentStep, setCurrentStep] = useState<number>(0);
    const navigateToStep = useCallback(
        (step: number, markStepAsComplete?: number) => {
            const newSteps = [...steps];

            if (typeof markStepAsComplete === "number") {
                newSteps[markStepAsComplete]!.status = "complete";
            }

            if (workspaceMetaResponse?.data.hasPageTouchpoints) {
                newSteps[0]!.status = "complete";
            }

            const hasConnectedAdNetworks = workspaceIntegrations?.some(
                (workspaceIntegration) => {
                    if (workspaceIntegration.status === "active") {
                        return (
                            integrations[workspaceIntegration.integration]
                                ?.type === "ad_network"
                        );
                    }
                },
            );

            if (hasConnectedAdNetworks) {
                newSteps[1]!.status = "complete";
            }

            const hasConnectedPaymentProviders = workspaceIntegrations?.some(
                (workspaceIntegration) => {
                    if (workspaceIntegration.status === "active") {
                        return (
                            integrations[workspaceIntegration.integration]
                                ?.type === "payment_provider"
                        );
                    }
                },
            );

            if (hasConnectedPaymentProviders) {
                newSteps[2]!.status = "complete";
            }

            if (selectedWorkspace.cutOffEvents.length > 0) {
                newSteps[3]!.status = "complete";
            }

            if (
                newSteps[step - 1] &&
                newSteps[step - 1]!.status !== "complete"
            ) {
                return;
            }

            newSteps[step]!.status = "current";

            setSteps(newSteps);
            setCurrentStep(step);
        },
        [steps],
    );

    useEffect(() => {
        navigateToStep(currentStep);
    }, [workspaceMetaResponse]);

    const [updateWorkspace] = useUpdateWorkspaceMutation();

    const openSupportPop = useCallback(() => {
        support.openChat(
            "Hi! I would like integration help. My website platform, builder or framework is: ",
        );
    }, []);

    if (!open) return null;

    return (
        <div
            className={
                "z-40 absolute left-0 top-0 bg-zinc-950/80 w-full h-full flex items-center justify-center"
            }
        >
            <div className={"w-4/5 h-4/5"}>
                <div className={"flex h-full"}>
                    <div
                        className={clsx(
                            "hidden lg:flex",
                            "min-w-96",
                            "mb-4 w-[20rem] sm:w-[30rem] md:w-[40rem]",
                            "h-full p-10 rounded-l-2xl flex-col justify-between",
                            "shadow dark:shadow-none dark:bg-zinc-900 bg-zinc-100 border-zinc-950/10 dark:border-white/10 border-solid border",
                        )}
                    >
                        <div>
                            <Heading level={2}>Setup guide</Heading>
                            <div className={"text-subtle text-sm"}>
                                Install Spectacle in to your website
                            </div>
                            <div className={"mt-12"}>
                                <VerticalProgress
                                    steps={steps}
                                    onStepClick={(step) => {
                                        const stepIndex = steps.findIndex(
                                            (s) => s.name === step.name,
                                        );

                                        if (stepIndex === -1) {
                                            return;
                                        }
                                        navigateToStep(stepIndex);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={"cursor-pointer text-subtle"}
                            onClick={onClose}
                        >
                            Skip for now →
                        </div>
                    </div>
                    <div
                        className={
                            "grow bg-white dark:bg-zinc-800 w-full h-full p-10 rounded-r-2xl rounded-l-2xl lg:rounded-l-none text-default overflow-scroll flex flex-col justify-between gap-4"
                        }
                    >
                        <div>
                            {currentStep === 0 && (
                                <StepInstallation
                                    workspaceId={workspaceId}
                                    workspaceMetaResponse={
                                        workspaceMetaResponse
                                    }
                                    onClickNext={() => {
                                        navigateToStep(1, 0);
                                        analytics?.trackSetupGuideStepCompletedEvent(
                                            "install",
                                        );
                                    }}
                                />
                            )}
                            {currentStep === 1 && (
                                <StepConnectAdNetworks
                                    onClickNext={() => {
                                        navigateToStep(2, 1);
                                        analytics?.trackSetupGuideStepCompletedEvent(
                                            "connect-ad-networks",
                                        );
                                    }}
                                />
                            )}
                            {currentStep === 2 && (
                                <StepConnectPaymentProviders
                                    onClickNext={() => {
                                        navigateToStep(3, 2);
                                        analytics?.trackSetupGuideStepCompletedEvent(
                                            "connect-payment-processors",
                                        );
                                    }}
                                />
                            )}
                            {currentStep === 3 && (
                                <StepCutOffEvents
                                    workspaceMetaResponse={
                                        workspaceMetaResponse
                                    }
                                    onClickNext={() => {
                                        updateWorkspace({
                                            id: selectedWorkspace.id,
                                            installationCompleted: true,
                                        });
                                        toast.success(
                                            "🎉 Installation guide completed.",
                                        );
                                        analytics?.trackSetupGuideStepCompletedEvent(
                                            "choose-cut-off-events",
                                        );
                                        onClose();
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            <Divider className={"mt-4 mb-8"} />
                            <div className={"flex justify-end"}>
                                <div
                                    className={
                                        "cursor-pointer flex items-center gap-2 rounded-lg"
                                    }
                                    onClick={openSupportPop}
                                >
                                    <LifebuoyIcon className={"h-6 w-6"} /> Get
                                    integration help →
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
