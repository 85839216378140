export default function BingIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 297.161 297.161"
        >
            <path d="M268.595 127.69c0-2.75-2.151-5.66-4.78-6.467L122.483 77.936c-2.63-.805-3.838.58-2.684 3.076l26.297 57.1c1.151 2.496 4.144 5.467 6.649 6.6l36.055 16.332c2.506 1.131 2.566 3.105.133 4.387L35.085 246.372c-2.434 1.281-2.747.828-.695-1.002l59.108-52.648c2.053-1.83 3.732-5.576 3.732-8.326l.042-159.609c-.002-2.75-2.154-5.662-4.783-6.469L33.341.208c-2.63-.803-4.782.791-4.782 3.541v242.139c0 2.75 1.843 6.291 4.095 7.869L92.76 295.88c2.252 1.578 6.027 1.719 8.39.311l163.156-97.199c2.362-1.408 4.296-4.809 4.296-7.559l-.007-63.743z" />
        </svg>
    );
}
