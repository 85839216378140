import { Listbox, ListboxLabel, ListboxOption } from "@/catalyst/listbox";
import { ReactNode } from "react";

type Option = { value: string; label: ReactNode };

export default function ListBox<T extends string | null>({
    options,
    value,
    onChange,
    className,
    buttonBold = false,
    extraOptions,
    placeholder,
}: {
    options: Option[];
    value?: T;
    onChange: (value: T) => void;
    className?: string;
    buttonBold?: boolean;
    extraOptions?: ReactNode;
    placeholder?: string;
}) {
    return (
        <Listbox
            className={className}
            name="status"
            value={value}
            onChange={onChange}
            buttonBold={buttonBold}
            placeholder={placeholder}
        >
            {options.map((option) => {
                return (
                    <ListboxOption key={option.value} value={option.value}>
                        <ListboxLabel>{option.label}</ListboxLabel>
                    </ListboxOption>
                );
            })}
            {extraOptions}
        </Listbox>
    );
}
