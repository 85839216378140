import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store.ts";
import { components } from "@/types/generated/api-schema.ts";
import { api } from "@/services/apis/api.ts";
type Workspace = components["schemas"]["Workspace"];

const initialState: { selectedWorkspace?: Workspace } = {
    selectedWorkspace: undefined, // or any default value
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        setSelectedWorkspace(state, action) {
            state.selectedWorkspace = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // @ts-expect-error action type is not correct
            .addCase(
                "api/executeQuery/fulfilled",
                (
                    state,
                    action: {
                        payload: Workspace[];
                        meta: { arg: { endpointName: string } };
                    },
                ) => {
                    if (
                        action.meta.arg.endpointName ===
                        api.endpoints.getWorkspaces.name
                    ) {
                        for (const workspace of action.payload) {
                            if (
                                !state.selectedWorkspace?.id ||
                                workspace.id === state.selectedWorkspace?.id
                            ) {
                                state.selectedWorkspace = workspace;
                                return;
                            }
                        }
                    }
                },
            )
            // @ts-expect-error action type is not correct
            .addCase(
                "api/executeMutation/fulfilled",
                (
                    state,
                    action: {
                        payload: Workspace;
                        meta: { arg: { endpointName: string } };
                    },
                ) => {
                    if (
                        action.meta.arg.endpointName ===
                        api.endpoints.updateWorkspace.name
                    ) {
                        if (action.payload.id === state.selectedWorkspace?.id) {
                            state.selectedWorkspace = action.payload;
                            return;
                        }
                    }
                },
            );
    },
});

// Selectors
export const getSelectedWorkspace = (state: RootState): Workspace | undefined =>
    state.workspace.selectedWorkspace;

export const { setSelectedWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;
