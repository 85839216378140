import { useUiProperty } from "@/features/UiState.slice.ts";

export default function ReportingIcon({ className }: { className?: string }) {
    const [darkMode] = useUiProperty<boolean>("darkMode");
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            data-slot={"icon"}
        >
            <path
                strokeWidth={0}
                d="M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.96 3 16.84 3 14.6z"
            />
            <path
                stroke={darkMode ? "black" : "white"}
                d="m16 10-3.5 3.5-2-2L8 14"
            />
        </svg>
    );
}
