export default function LemonSqueezyIcon({
    className,
}: {
    className?: string;
}) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="m7.492 10.835 2.374-6.511a3.15 3.15 0 0 0-.065-2.342C9.031.183 6.943-.398 5.293.265c-1.65.664-2.583 2.17-1.781 4.04l2.82 6.561c.219.51.97.49 1.16-.03m1.68 1.097 6.533-2.776c2.17-.922 2.722-3.69 1.022-5.29l-.068-.063c-1.667-1.547-4.422-1.002-5.37 1.036l-2.932 6.295c-.234.503.295 1.02.816.798m.372.87 6.646-2.512c2.208-.835 4.62.745 4.588 3.04l-.002.09c-.048 2.236-2.393 3.737-4.553 2.947l-6.672-2.442a.595.595 0 0 1-.006-1.123M9.16 14.73l6.437 2.976a3.3 3.3 0 0 1 1.666 1.7c.769 1.8-.283 3.639-1.933 4.3-1.65.663-3.41.236-4.21-1.635l-2.802-6.57c-.217-.508.328-1.008.842-.771" />
        </svg>
    );
}
