import {
    Authenticator,
    CheckboxField,
    Theme,
    ThemeProvider,
    useAuthenticator,
    useTheme,
    View,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { ReactNode, useEffect } from "react";
import { Logomark } from "@/common/Logo/Logo.tsx";
import resolveConfig from "tailwindcss/resolveConfig";
import { signInWithRedirect, signUp, SignUpInput } from "aws-amplify/auth";

// @ts-expect-error Tailwind config is not typed
import tailwindConfig from "../../tailwind.config";
import { clsx } from "clsx";
import { Divider } from "@/catalyst/divider.tsx";
import { useDispatch } from "react-redux";
import { setSelectedWorkspace } from "@/features/Workspace/Workspace.slice.ts";
import { setCookie } from "@/util/cookie.ts";
import useAnalytics from "@/services/analytics/useAnalytics";
const fullConfig = resolveConfig(tailwindConfig);

const COGNITO_CLIENT_ID = import.meta.env.VITE_COGNITO_CLIENT_ID as string;
const COGNITO_USER_POOL_ID = import.meta.env
    .VITE_COGNITO_USER_POOL_ID as string;
const VITE_COGNITO_ENDPOINT = import.meta.env.VITE_COGNITO_ENDPOINT as string;

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: COGNITO_USER_POOL_ID,
            userPoolClientId: COGNITO_CLIENT_ID,
            identityPoolId: "",
            loginWith: {
                username: true,
                oauth: {
                    domain: `spectacle-${import.meta.env.MODE}.auth.eu-west-1.amazoncognito.com`,
                    scopes: [
                        "email",
                        "openid",
                        "profile",
                        "aws.cognito.signin.user.admin",
                    ],
                    redirectSignIn: [
                        `https://app.${import.meta.env.VITE_DOMAIN}`,
                    ],
                    redirectSignOut: [`https://${import.meta.env.VITE_DOMAIN}`],
                    responseType: "code",
                },
            },
            userPoolEndpoint: VITE_COGNITO_ENDPOINT,
            signUpVerificationMethod: "code",
            userAttributes: {
                email: {
                    required: true,
                },
                name: {
                    required: true,
                },
                picture: {
                    required: false,
                },
            },
            allowGuestAccess: false,
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
                requireSpecialCharacters: true,
            },
        },
    },
});

const components = {
    Header() {
        const { tokens } = useTheme();
        const { route } = useAuthenticator((context) => [context.user]);
        const includeSocialButtons = ["signIn", "signUp"].includes(route);
        const analytics = useAnalytics();
        const pathname = window.location.pathname;
        useEffect(() => {
            analytics?.page();
        }, [analytics, pathname]);

        return (
            <View
                textAlign="center"
                paddingTop={tokens.space.xl}
                paddingLeft={tokens.space.xl}
                paddingRight={tokens.space.xl}
                className={"flex items-center justify-start flex-col"}
            >
                <Logomark
                    className={clsx("h-8", includeSocialButtons && "mb-8")}
                />
                {includeSocialButtons && (
                    <>
                        <button
                            className={
                                "px-4 py-2 shadow ring-1 ring-black/10 w-full flex items-center justify-center rounded-full"
                            }
                            onClick={async () => {
                                setCookie("potentialSignUp", "true", 14);
                                await signInWithRedirect({
                                    provider: "Google",
                                });
                            }}
                        >
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                className={"h-6"}
                                style={{ display: "block" }}
                            >
                                <path
                                    fill="#EA4335"
                                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                ></path>
                                <path
                                    fill="#4285F4"
                                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                ></path>
                                <path
                                    fill="#FBBC05"
                                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                ></path>
                                <path
                                    fill="#34A853"
                                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                ></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>{" "}
                            <span className={"ml-2.5"}>
                                Continue with Google
                            </span>
                        </button>
                        <div className={"text-xs mt-4"}>
                            By continuing, you agree to the{" "}
                            <a
                                href={`https://www.${import.meta.env.VITE_DOMAIN}/tos`}
                                target={"_blank"}
                                className={"underline"}
                            >
                                Terms of Service
                            </a>
                        </div>
                        <div className={"relative h-fit mt-10 mb w-full px-4"}>
                            <Divider soft={false} />
                            <div
                                className={
                                    "absolute -top-2 left-1/2 right-1/2 w-12 -ml-6 bg-white text-subtle text-xs"
                                }
                            >
                                OR
                            </div>
                        </div>
                    </>
                )}
            </View>
        );
    },
    Footer() {
        const pathname = window.location.pathname;
        const isSignUp = pathname === "/signup";
        return (
            <View
                textAlign="center"
                className={"text-default text-xs mb-5 -mt-2"}
            >
                {isSignUp ? (
                    <>
                        Already have an account?{" "}
                        <a href="/" className={"underline inline"}>
                            Sign in
                        </a>
                    </>
                ) : (
                    <>
                        No account yet?{" "}
                        <a href="/signup" className={"text-blue-500 font-bold"}>
                            Sign up
                        </a>
                    </>
                )}
            </View>
        );
    },
    SignUp: {
        FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
                <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    <div className={"mt-1 mb-1"}>
                        <CheckboxField
                            errorMessage={
                                validationErrors.acknowledgement as string
                            }
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label={
                                <span
                                    className={
                                        "flex items-center text-sm gap-1"
                                    }
                                >
                                    <span>I agree to the</span>
                                    <a
                                        href={`https://www.${import.meta.env.VITE_DOMAIN}/tos`}
                                        target={"_blank"}
                                        className={"underline"}
                                    >
                                        Terms of Service
                                    </a>
                                </span>
                            }
                        />
                    </div>
                </>
            );
        },
    },
};

const generateColor = (color: typeof fullConfig.theme.colors) => {
    return {
        10: { value: color[950] },
        20: { value: color[900] },
        40: { value: color[700] },
        60: { value: color[600] },
        80: { value: color[500] },
        90: { value: color[400] },
        100: { value: color[300] },
    };
};

const indigo = fullConfig.theme.colors.indigo;
const theme: Theme = {
    name: "spectacle",
    tokens: {
        fonts: {
            body: "Inter var",
        },
        components: {
            authenticator: {
                footer: {
                    paddingBottom: "0",
                },
                router: {
                    borderWidth: "0",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                },
                container: {
                    widthMax: "100%",
                },
            },
            tabs: {
                panel: {
                    paddingBlock: "0",
                },
            },
            fieldcontrol: {
                borderRadius: fullConfig.theme.borderRadius.lg,
            },
        },
        // 'block w-full rounded-lg border border-transparent shadow ring-1 ring-black/10',
        //             'px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6',
        //             'data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black',
        //       fieldcontrol: {
        //
        //       },
        colors: {
            primary: generateColor(indigo),
            brand: {
                primary: generateColor(indigo),
                // secondary: {
                //     10: { value: "{colors.neutral.10}" },
                //     20: { value: "{colors.neutral.20}" },
                //     40: { value: "{colors.neutral.40}" },
                //     60: { value: "{colors.neutral.60}" },
                //     80: { value: "{colors.neutral.80}" },
                //     90: { value: "{colors.neutral.90}" },
                //     100: { value: "{colors.neutral.100}" },
                // },
            },
            // border: {
            //     primary: { value: "{colors.neutral.40}" },
            //     secondary: { value: "{colors.neutral.20}" },
            //     tertiary: { value: "{colors.neutral.10}" },
            // },
        },
        // radii: {
        //     small: { value: fullConfig.theme.borderRadius.sm}
        //     medium: { value: fullConfig.theme.borderRadius.smd },
        //     large: { value: fullConfig.theme.borderRadius.smd },
        //     xl: { value: fullConfig.theme.borderRadius.smd },
        // },
    },
    // overrides: [defaultDarkModeOverride],
};

export default function CognitoAuthenticator({
    children,
}: {
    children: ReactNode;
}) {
    const { user, authStatus } = useAuthenticator((context) => [context.user]);
    const pathname = window.location.pathname;
    const isSignUp = pathname === "/signup";
    const dispatch = useDispatch();

    useEffect(() => {
        if (authStatus === "unauthenticated") {
            dispatch(setSelectedWorkspace(undefined));
        }
    }, [authStatus, dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <main
                className={clsx(
                    "h-full",
                    !user && "overflow-hidden bg-gray-50",
                )}
            >
                {!user && <GradientBackground />}
                <div
                    className={clsx(
                        "h-full",
                        !user &&
                            "isolate flex min-h-dvh items-center justify-center p-6 lg:p-8",
                    )}
                >
                    <div
                        className={clsx(
                            user && "h-full",
                            !user &&
                                "w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5",
                        )}
                    >
                        <Authenticator
                            components={components}
                            services={{
                                async validateCustomSignUp(
                                    formData,
                                    touchData,
                                ) {
                                    if (
                                        touchData.email &&
                                        touchData.password &&
                                        touchData.confirm_password &&
                                        touchData.name &&
                                        !formData.acknowledgement
                                    ) {
                                        return {
                                            acknowledgement:
                                                "You must agree to the Terms of Service",
                                        };
                                    }
                                },
                                async handleSignUp(input: SignUpInput) {
                                    setCookie("potentialSignUp", "true", 14);
                                    return signUp(input);
                                },
                            }}
                            hideSignUp={!isSignUp}
                            loginMechanisms={["email"]}
                            initialState={isSignUp ? "signUp" : "signIn"}
                        >
                            {children}
                        </Authenticator>
                    </div>
                </div>
            </main>
        </ThemeProvider>
    );
}

export function GradientBackground() {
    return (
        <div className="relative mx-auto max-w-7xl">
            <div
                className={clsx(
                    "absolute -right-60 -top-44 h-60 w-[36rem] transform-gpu md:right-0",
                    "bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff]",
                    "rotate-[-10deg] rounded-full blur-3xl",
                )}
            />
        </div>
    );
}
