import { Heading } from "@/catalyst/heading.tsx";
import IntegrationsGrid from "@/features/Workspace/Settings/IntegrationsGrid.tsx";
import { integrations } from "@/features/Workspace/Settings/integrations.tsx";
import { Divider } from "@/catalyst/divider.tsx";
import Button from "@/common/Button";
import { Switch } from "@/catalyst/switch.tsx";
import { useState } from "react";

export default function StepConnectAdNetworks({
    onClickNext,
}: {
    onClickNext: () => void;
}) {
    const adNetworkIntegrations = Object.fromEntries(
        Object.entries(integrations).filter(
            ([, integration]) => integration.type === "ad_network",
        ),
    );

    const [hasUpdateCampaignUrls, setHasUpdatedCampaignUrls] = useState(false);

    return (
        <>
            <div className={"w-full flex justify-between"}>
                <div className={"flex flex-col"}>
                    <Heading level={2} className={"inline mr-8"}>
                        Connect ad networks
                    </Heading>
                    <span className={"text-subtle text-sm"}>
                        Sync ad spend and performance data
                    </span>
                </div>
            </div>
            <Divider className={"mt-4 mb-4"} />
            <div className={"text-sm mb-4"}>
                Connect your ad networks to sync ad spend and performance data.
            </div>
            <div className={"text-sm mb-8"}>
                <IntegrationsGrid integrations={adNetworkIntegrations} />
            </div>
            <Divider className={"mt-8 mb-4"} />
            <div className={"text-sm"}>
                <span className={"font-semibold"}>Important</span>: Make sure to
                update your campaign urls with the UTM tags that can be found by
                clicking any of the ad networks above.
            </div>
            <div className={"mt-4 flex gap-3 items-center text-sm"}>
                <Switch
                    checked={hasUpdateCampaignUrls}
                    onChange={setHasUpdatedCampaignUrls}
                />{" "}
                I have updated my campaign urls
            </div>
            <div className={"mt-8"}>
                <Button
                    type={"primary"}
                    disabled={!hasUpdateCampaignUrls}
                    onClick={onClickNext}
                >
                    Next
                </Button>
            </div>
        </>
    );
}
