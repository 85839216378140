import Button from "@/common/Button";
import { useState } from "react";
import SetupGuide from "@/features/Onboarding/SetupGuide.tsx";
import { clsx } from "clsx";
import { PresentationChartLineIcon } from "@heroicons/react/24/solid";

export default function ContinueSetupBanner() {
    const [setupOpen, setSetupOpen] = useState(false);
    return (
        <div
            className={clsx(
                "mb-6 flex justify-between items-center gap-4 p-4  rounded-lg",
                "shadow dark:shadow-none bg-indigo-500/10 border-indigo-950/10 dark:border-indigo/10 border-solid border",
            )}
        >
            <div className={"flex gap-6 items-center"}>
                <div>
                    <PresentationChartLineIcon
                        className={
                            "h-10 w-10 dark:text-near-white text-indigo-500 opacity-80"
                        }
                    />
                </div>
                <div className={"flex flex-col"}>
                    <span className={"text-default"}>
                        {" "}
                        Unlock advertising insights
                    </span>
                    <span className={"text-subtle"}>
                        Set up Spectacle in less than 20 minutes
                    </span>
                </div>
            </div>
            <Button
                className={"text-default"}
                type={"primary"}
                onClick={() => {
                    setSetupOpen(true);
                }}
            >
                Continue setup
            </Button>
            <SetupGuide open={setupOpen} onClose={() => setSetupOpen(false)} />
        </div>
    );
}
