import { platformBranding } from "@/common/PlatformIcon/platformBranding.ts";
import { snakeToSentence } from "@/util/case.ts";
import { Report } from "@/types/generated/Report.ts";

export const getChartConfigFromNetworks = (
    networks: string[],
    monotone?: boolean,
    reverse?: boolean,
) => {
    const length = networks.length;
    return networks.reduce(
        (carry, item, index) => {
            const _index = reverse ? length - index : index;
            carry[item] = {
                label: snakeToSentence(item),
                color: monotone
                    ? `hsl(var(--theme-1))`
                    : _index <= 6
                      ? `hsl(var(--theme-${_index + 1}))`
                      : platformBranding[item as keyof typeof platformBranding]
                            ?.brandingColor || "hsl(var(--theme-3))",
            };
            return carry;
        },
        {} as Record<string, { label: string; color: string }>,
    );
};

const sourceTypes: Report["sourceType"][] = [
    "search",
    "direct",
    "social",
    "referral",
    "other",
];

export const getChartConfigFromSourceTypes = (monotone?: boolean) => {
    return sourceTypes.reduce(
        (carry, item, index) => {
            carry[item] = {
                label: snakeToSentence(item),
                color: monotone
                    ? `hsl(var(--theme-1))`
                    : `hsl(var(--theme-${index + 1}))`,
            };
            return carry;
        },
        {} as Record<string, { label: string; color: string }>,
    );
};
