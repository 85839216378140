import { components } from "@/types/generated/api-schema";
import { differenceInDays } from "date-fns";
type Organization = components["schemas"]["Organization"];
type Plan = components["schemas"]["Plan"];

export class OrganizationModel implements Organization {
    id: string;
    plan: Plan;
    isRegisteredCustomer: boolean;
    hasPaymentMethod: boolean;
    needsSubscriptionSetUp: boolean;
    trialEndsAt?: string;
    cancelSubscriptionAt?: string;
    stripeCustomerSessionClientSecret?: string;
    createdAt: string;

    constructor({
        id,
        plan,
        isRegisteredCustomer,
        hasPaymentMethod,
        needsSubscriptionSetUp,
        trialEndsAt,
        cancelSubscriptionAt,
        stripeCustomerSessionClientSecret,
        createdAt,
    }: Organization) {
        this.id = id;
        this.plan = plan;
        this.isRegisteredCustomer = isRegisteredCustomer;
        this.hasPaymentMethod = hasPaymentMethod;
        this.needsSubscriptionSetUp = needsSubscriptionSetUp;
        this.trialEndsAt = trialEndsAt;
        this.cancelSubscriptionAt = cancelSubscriptionAt;
        this.stripeCustomerSessionClientSecret =
            stripeCustomerSessionClientSecret;
        this.createdAt = createdAt;
    }

    static create(organizationData: Organization): OrganizationModel {
        return new this(organizationData);
    }

    public isFreePlan(): boolean {
        return ["free", "beta"].includes(this.plan);
    }

    public getTrialDaysLeft(): number {
        if (!this.trialEndsAt) {
            return 0;
        }

        const now = new Date();
        const trialEndsAt = new Date(this.trialEndsAt);

        return differenceInDays(trialEndsAt, now) + 1; // Difference in days only measures full days, so add 1 to get the number of days left
    }
}
