import { ReactNode } from "react";
import Skeleton from "@/common/Skeleton/Skeleton.tsx";
import classNames from "classnames";

export default function StatsBar({
    stats,
    loading,
}: {
    stats: { title: string; value: ReactNode; widget?: boolean }[];
    loading: boolean;
}) {
    return (
        <div
            className={classNames("grid gap-px grid-cols-1 pb-[1px]", {
                "lg:grid-cols-4": stats.length >= 4,
                "lg:grid-cols-3": stats.length === 3,
                "lg:grid-cols-2": stats.length === 2,
                "lg:grid-cols-1": stats.length === 1,
            })}
        >
            {stats.map((stat, i) => (
                <div key={i} className="flex flex-col">
                    <p className="text-sm font-medium leading-6">
                        {stat.title}
                    </p>
                    <div
                        className={classNames(
                            "mt-2 flex gap-x-2",
                            stat.widget
                                ? "items-center grow"
                                : "items-baseline",
                        )}
                    >
                        {!stat.widget && (
                            <span className="text-3xl font-semibold tracking-tight">
                                {loading ? <Skeleton /> : stat.value}
                            </span>
                        )}
                        {stat.widget && (loading ? <Skeleton /> : stat.value)}
                    </div>
                </div>
            ))}
        </div>
    );
}
