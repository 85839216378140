import { FunnelConfig } from "./types";
import { CursorArrowRaysIcon, DocumentIcon } from "@heroicons/react/24/solid";
import { useGetWorkspaceMetaQuery } from "@/services/apis/data.ts";
import { components } from "@/types/generated/api-schema.ts";
import { nanoid } from "@reduxjs/toolkit";
import Loading from "@/common/Loading";
type Workspace = components["schemas"]["Workspace"];

export default function FunnelStepActionSelectorPanel({
    selectedWorkspace,
    onStepActionUpdated,
}: {
    selectedWorkspace: Workspace;
    onStepActionUpdated: (step: FunnelConfig["steps"][number]) => void;
}) {
    const className =
        "flex items-center cursor-pointer hover:bg-blue-500 hover:text-white px-4 py-2 gap-2 rounded-lg";

    const { data, isLoading } = useGetWorkspaceMetaQuery({
        workspaceId: selectedWorkspace.id,
    });

    return (
        <div className={"px-1 py-3 min-w-64"}>
            <div className={"flex flex-col text-md"}>
                <h3 className={"font-semibold px-4 py-2 text-base"}>Action</h3>
                <div
                    className={className}
                    key={"viewed_page"}
                    onClick={() =>
                        onStepActionUpdated({
                            id: nanoid(),
                            name: "Viewed page",
                            props: {
                                type: "touchpoint",
                                predicate: null,
                            },
                        })
                    }
                >
                    <DocumentIcon className={"h-4 w-4"} /> Viewed page
                </div>
                <h3 className={"font-semibold text-sm px-4 py-2"}>
                    Triggered event
                </h3>
                {isLoading ? (
                    <div className={"flex items-center gap-2 px-4 py-2"}>
                        <Loading />
                    </div>
                ) : (
                    <>
                        {!!data?.data.events.length &&
                            data?.data.events
                                .slice() // Copy array
                                .sort() // Sort alphabetically
                                .map((e) => (
                                    <div
                                        className={className}
                                        key={e}
                                        onClick={() =>
                                            onStepActionUpdated({
                                                id: nanoid(),
                                                name: e,
                                                props: {
                                                    type: "event",
                                                    eventName: e,
                                                    predicate: null,
                                                },
                                            })
                                        }
                                    >
                                        <CursorArrowRaysIcon
                                            className={"h-4 w-4"}
                                        />{" "}
                                        {e}
                                    </div>
                                ))}
                        {!data?.data.events.length && (
                            <div className={className + " text-gray-500"}>
                                No events tracked yet
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
