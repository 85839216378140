import { WorkspaceMetaResponse } from "@/types/generated/WorkspaceMetaResponse.ts";
import { ReactNode, useEffect, useState } from "react";
import { getSnippet } from "@/features/Workspace/Settings/snippet.ts";
import { Heading, Subheading } from "@/catalyst/heading.tsx";
import Tooltip from "@/common/Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Input, InputGroup } from "@/catalyst/input.tsx";
import { clsx } from "clsx";
import { DocumentDuplicateIcon } from "@heroicons/react/16/solid";
import { Divider } from "@/catalyst/divider.tsx";
import Button from "@/common/Button";
import Status from "@/common/Status/Status.tsx";
import {
    CodeBracketSquareIcon,
    Square2StackIcon,
} from "@heroicons/react/24/solid";
import Code from "@/common/Code";
import Text from "@/common/Text";
import JavascriptIcon from "@/common/Icon/Integration/JavascriptIcon.tsx";
import SegmentIcon from "@/common/Icon/Integration/SegmentIcon.tsx";
import Link from "@/common/Link";

export default function StepInstallation({
    workspaceId,
    workspaceMetaResponse,
    onClickNext,
}: {
    workspaceId: string;
    workspaceMetaResponse?: WorkspaceMetaResponse;
    onClickNext: () => void;
}) {
    const [wasJustCopied, setWasJustCopied] = useState<boolean>(false);
    const [selectedIntegration, setSelectedIntegration] = useState<
        "javascript" | "segment" | "rest" | undefined
    >();

    useEffect(() => {
        if (!wasJustCopied) {
            return;
        }

        setTimeout(() => {
            setWasJustCopied(false);
        }, 750);
    }, [wasJustCopied]);
    const snippet = ["javascript", "segment"].includes(
        selectedIntegration || "",
    )
        ? getSnippet(
              workspaceId,
              selectedIntegration as "javascript" | "segment",
          )
        : "";

    return (
        <>
            <div className={"w-full flex justify-between"}>
                <div className={"flex flex-col"}>
                    <Heading level={2} className={"inline mr-8"}>
                        Choose a connection method
                    </Heading>
                    <span className={"text-subtle text-sm"}>
                        Start sending data to Spectacle
                    </span>
                </div>
                <div className={"flex flex-col w-48"}>
                    <div
                        className={
                            "text-subtle text-xs mb-2 flex flex-row justify-end"
                        }
                    >
                        Copy workspace id
                        <Tooltip
                            content={
                                <div className={"p-2"}>
                                    This id is unique to the workspace this
                                    guide is setting up. A workspace is a
                                    container of your analytics data.
                                </div>
                            }
                        >
                            <QuestionMarkCircleIcon
                                className={
                                    "ml-1 h-4 w-4 text-subtle cursor-pointer"
                                }
                            />
                        </Tooltip>
                    </div>
                    <InputGroup>
                        <Input
                            className={clsx(
                                "transition-colors font-mono text-sm",
                                wasJustCopied && "bg-indigo-500/50",
                            )}
                            inputClassName={"cursor-pointer"}
                            onClick={async () => {
                                setWasJustCopied(true);
                                await navigator.clipboard.writeText(
                                    workspaceId,
                                );
                            }}
                            name="workspaceId"
                            value={wasJustCopied ? "Copied!" : workspaceId}
                            readOnly={true}
                        />
                        <DocumentDuplicateIcon />
                    </InputGroup>
                </div>
            </div>
            <Divider className={"mt-4 mb-8"} />
            <div
                className={
                    "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-8"
                }
            >
                <IntegrationButton
                    selected={selectedIntegration === "javascript"}
                    title={"Javascript"}
                    onClick={() => {
                        setSelectedIntegration("javascript");
                    }}
                >
                    <JavascriptIcon className={"text-[#F7DF1E] h-8 w-8"} />
                </IntegrationButton>
                <IntegrationButton
                    selected={selectedIntegration === "segment"}
                    onClick={() => {
                        setSelectedIntegration("segment");
                    }}
                    title={"Segment"}
                >
                    <SegmentIcon className={"text-[#6addb2] h-8 w-8"} />
                </IntegrationButton>
                <IntegrationButton
                    selected={selectedIntegration === "rest"}
                    onClick={() => {
                        setSelectedIntegration("rest");
                    }}
                    title={"REST Api"}
                >
                    <CodeBracketSquareIcon
                        className={"text-blue-600 h-8 w-8"}
                    />
                </IntegrationButton>
            </div>
            {selectedIntegration === "javascript" && (
                <>
                    <div className={"mt-8"}>
                        <Subheading level={3} className={"mb-2"}>
                            Integrating with Javascript
                        </Subheading>
                        <div className={"mb-4 text-sm"}>
                            <Text>
                                Copy the Spectacle snippet and paste it high in
                                the {`<head>`} of your website.
                            </Text>
                        </div>
                        <Code
                            className={
                                "mb-4 w-[20rem] sm:w-[30rem] md:w-[40rem] xl:w-[55rem] !bg-zinc-900"
                            }
                        >
                            {snippet}
                        </Code>
                        <Button
                            outline
                            icon={Square2StackIcon}
                            onClick={async (_, setBanner) => {
                                await navigator.clipboard.writeText(snippet);
                                setBanner("Copied!");
                            }}
                        >
                            Copy snippet
                        </Button>
                        <Subheading level={4} className={"mt-4 mb-2"}>
                            Identifying Contacts and Tracking Events
                        </Subheading>
                        <div className={"text-sm"}>
                            <Text>
                                After installing the basic snippet, you can
                                start{" "}
                                <Link
                                    external
                                    to={
                                        "https://www.spectaclehq.com/docs/installation/javascript#call-to-recognize-your-users"
                                    }
                                >
                                    identifying contacts and tracking events
                                </Link>
                                .
                            </Text>
                        </div>
                    </div>
                </>
            )}
            {selectedIntegration === "segment" && (
                <>
                    <div className={"mt-8"}>
                        <Subheading level={3} className={"mb-2"}>
                            Integrating with Segment
                        </Subheading>
                        <div className={"mb-4 text-sm"}>
                            <Text>
                                While we hope to have an official integration in
                                the Segment directory in the near future, for
                                now you can use the following code to install
                                Spectacle with Segment.
                                <br />
                                <br />
                                In Segment, go to <i>Connections</i> →{" "}
                                <i>Catalog</i> → Select the "Functions" tab →{" "}
                                <i>New Function</i>
                                <br />
                                <br />
                                Then, paste the following code into the{" "}
                                <i>Edit source code</i> field:
                            </Text>
                        </div>
                        <Code
                            className={
                                "mb-4 w-[20rem] sm:w-[30rem] md:w-[40rem] xl:w-[55rem] !bg-zinc-900 max-h-96 overflow-scroll"
                            }
                        >
                            {snippet}
                        </Code>
                        <Button
                            outline
                            icon={Square2StackIcon}
                            onClick={async (_, setBanner) => {
                                await navigator.clipboard.writeText(snippet);
                                setBanner("Copied!");
                            }}
                        >
                            Copy snippet
                        </Button>
                    </div>
                </>
            )}
            {selectedIntegration === "rest" && (
                <>
                    <div className={"mt-8"}>
                        <Subheading level={3} className={"mb-2"}>
                            Integrating with the REST Api
                        </Subheading>
                        <div className={"mb-4 text-sm"}>
                            <Text>
                                Please refer to the Spectacle{" "}
                                <Link
                                    external
                                    to={
                                        "https://spectaclehq.com/docs/installation/rest-api"
                                    }
                                >
                                    REST Tracking Api documentation
                                </Link>{" "}
                                for more information.
                            </Text>
                        </div>
                    </div>
                </>
            )}
            <Divider className={"mt-8 mb-4"} />
            <Subheading level={3} className={"mb-2"}>
                Status
            </Subheading>
            <div className={"flex gap-6 text-sm"}>
                <Status
                    status={
                        workspaceMetaResponse
                            ? workspaceMetaResponse.data.hasPageTouchpoints
                            : undefined
                    }
                    statusDescription={"Tracking page views"}
                />{" "}
                <Status
                    status={
                        workspaceMetaResponse
                            ? workspaceMetaResponse.data.hasIdentifications
                            : undefined
                    }
                    statusDescription={"Tracking user identifies"}
                />{" "}
                <Status
                    status={
                        workspaceMetaResponse
                            ? workspaceMetaResponse.data.events.length > 0
                            : undefined
                    }
                    statusDescription={"Tracking events"}
                />{" "}
            </div>
            <div className={"text-subtle text-sm mt-4"}>
                It can take up to 20 minutes for the status indicators to
                update.
            </div>

            <Tooltip
                content={"Please track at least a page view to continue."}
                enabled={
                    !workspaceMetaResponse ||
                    !workspaceMetaResponse.data.hasPageTouchpoints
                }
            >
                <Button
                    className={"mt-8"}
                    onClick={onClickNext}
                    type={"primary"}
                    disabled={
                        !workspaceMetaResponse ||
                        !workspaceMetaResponse.data.hasPageTouchpoints
                    }
                >
                    Next
                </Button>
            </Tooltip>
        </>
    );
}

const IntegrationButton = ({
    children,
    title,
    href,
    onClick,
    selected,
}: {
    children: ReactNode;
    title: string;
    href?: string;
    onClick?: () => void;
    selected: boolean;
}) => {
    const comp = (
        <div
            onClick={onClick}
            className={clsx(
                "p-3 border border-solid rounded-lg flex gap-4 items-center cursor-pointer",
                selected && "bg-zinc-950/40",
                !selected && "hover:bg-zinc-950/10 bg-transparent",
            )}
        >
            <div>{children}</div>
            <div>{title}</div>
        </div>
    );

    if (href) {
        return (
            <a href={href} target={"_blank"} rel={"noreferrer"}>
                {comp}
            </a>
        );
    }

    return comp;
};
