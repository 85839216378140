import Panel from "@/common/Panel/Panel.tsx";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
import { ReportResponse } from "@/types/generated/ReportResponse.ts";

import { useMemo } from "react";
import { snakeToSentence } from "@/util/case.ts";
import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/common/ui/chart.tsx";
import { Label, Pie, PieChart } from "recharts";
import { getChartConfigFromSourceTypes } from "@/features/Dashboard/Widgets/util.ts";
import PieChartSkeleton from "@/common/Skeleton/PieChartSkeleton.tsx";

type sourcesTypeData = {
    id: string;
    name: string;
    customers: number;
    revenue: number;
    fill: string;
};

type SourceTypes = Record<string, sourcesTypeData>;

export default function RevenueBySourceTypeWidget({
    reportData,
    currency,
}: {
    reportData?: ReportResponse;
    currency: string;
}) {
    const isLoading = !reportData?.data;

    const sourcesTypes: SourceTypes = useMemo(
        () =>
            (reportData?.data || []).reduce((carry, report) => {
                const sourceName = report.sourceType;
                const sourceId = report.sourceType;

                carry[sourceId] = carry[sourceId] || {
                    id: sourceId,
                    name: snakeToSentence(sourceName),
                    customers: 0,
                    revenue: 0,
                    fill: `var(--color-${sourceId})`,
                };

                carry[sourceId].customers += Number(
                    report.customers[currency] || 0,
                );
                carry[sourceId].revenue += Number(
                    report.revenue[currency] || 0,
                );

                return carry;
            }, {} as SourceTypes),
        [currency, reportData?.data],
    );

    const hasSourceTypeWithRevenue = Object.entries(sourcesTypes).some(
        ([, sourceType]) => sourceType.revenue > 0,
    );
    const showEmptyState =
        !hasSourceTypeWithRevenue || reportData?.data.length === 0;

    return (
        <Panel
            title={"Revenue by source type"}
            subtitle={`Showing distribution of revenue by source type`}
        >
            {isLoading && <PieChartSkeleton />}
            {!isLoading && !showEmptyState && (
                <SourceTypeChart sourcesTypes={sourcesTypes} />
            )}
            {!isLoading && showEmptyState && (
                <WidgetEmptyState
                    subtitle={"No revenue for the current date range."}
                />
            )}
        </Panel>
    );
}

function SourceTypeChart({ sourcesTypes }: { sourcesTypes: SourceTypes }) {
    const chartConfig = getChartConfigFromSourceTypes();
    const chartData = Object.values(sourcesTypes);
    const totalLeads = chartData.reduce(
        (carry, item) => carry + item.customers,
        0,
    );
    const formatter = new Intl.NumberFormat(undefined, {
        notation: "compact",
        maximumFractionDigits: 1,
    });

    return (
        <ChartContainer config={chartConfig} className="mx-auto aspect-square">
            <PieChart>
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                />
                <Pie
                    data={chartData}
                    dataKey="revenue"
                    nameKey="name"
                    innerRadius={60}
                    strokeWidth={5}
                >
                    <Label
                        content={({ viewBox }) => {
                            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                return (
                                    <text
                                        x={viewBox.cx}
                                        y={viewBox.cy}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                    >
                                        <tspan
                                            x={viewBox.cx}
                                            y={(viewBox.cy || 0) - 28}
                                            className="fill-text-subtle"
                                        >
                                            From
                                        </tspan>
                                        <tspan
                                            x={viewBox.cx}
                                            y={viewBox.cy}
                                            className="fill-text-default text-3xl font-bold"
                                        >
                                            {formatter.format(totalLeads)}
                                        </tspan>
                                        <tspan
                                            x={viewBox.cx}
                                            y={(viewBox.cy || 0) + 24}
                                            className="fill-text-subtle"
                                        >
                                            Customers
                                        </tspan>
                                    </text>
                                );
                            }
                        }}
                    />
                </Pie>
            </PieChart>
        </ChartContainer>
    );
}
