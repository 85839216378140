import Panel from "@/common/Panel/Panel";
import { Avatar } from "@/catalyst/avatar.tsx";
import EditableField from "@/common/EditableField";
import {
    useGetWorkspacesQuery,
    useUpdateWorkspaceMutation,
} from "@/services/apis/api.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import Modal, { ModalActions } from "@/common/Modal";
import { Button } from "@/catalyst/button";
import { useSelector } from "react-redux";
import { getUiProperty } from "@/features/UiState.slice.ts";

export default function NameAndAvatarPanel() {
    const [updateWorkspace] = useUpdateWorkspaceMutation();
    const { data: workspaces } = useGetWorkspacesQuery(undefined);
    const selectedWorkspaceFromState = useSelectedWorkspace();
    const selectedWorkspace =
        workspaces?.find((ws) => ws.id === selectedWorkspaceFromState.id) ||
        selectedWorkspaceFromState;

    const [isAvatarEditorOpen, setIsAvatarEditorOpen] =
        useState<boolean>(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [scale, setScale] = useState<number>(1.2);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<AvatarEditor | null>(null);

    const handleAvatarClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImageFile(e.target.files[0]!);
            setIsAvatarEditorOpen(true);
        }
    };

    const handleSaveAvatar = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            const base64Image = canvas.toDataURL("image/png");

            // Update the workspace with the new favicon
            updateWorkspace({
                id: selectedWorkspace.id,
                favicon: base64Image, // Store the full data URL or just the base64 part
            });

            setIsAvatarEditorOpen(false);
            setImageFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const darkMode = useSelector(getUiProperty("darkMode"));

    return (
        <Panel>
            <div className="flex items-center gap-3">
                <div onClick={handleAvatarClick}>
                    <Avatar
                        src={selectedWorkspace.favicon}
                        initials={selectedWorkspace.name.slice(0, 2)}
                        className="bg-near-white text-white w-8 h-8 cursor-pointer"
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="image/*"
                        className="hidden"
                    />
                </div>
                <h2 className="text-3xl font-medium text-default leading-6">
                    <EditableField
                        value={selectedWorkspace.name}
                        isTitle={true}
                        onSave={(newValue) => {
                            if (newValue && newValue.length > 0) {
                                updateWorkspace({
                                    id: selectedWorkspace.id,
                                    name: newValue,
                                });
                            }
                        }}
                    />
                </h2>
            </div>

            {/* Avatar Editor Dialog */}
            <Modal
                open={isAvatarEditorOpen}
                onClose={() => setIsAvatarEditorOpen(false)}
                title="Edit Avatar"
                size="sm"
            >
                <div className="flex flex-col items-center">
                    {imageFile && (
                        <AvatarEditor
                            ref={editorRef}
                            image={imageFile}
                            width={200}
                            height={200}
                            border={70}
                            borderRadius={100}
                            color={
                                darkMode
                                    ? [24, 24, 27, 0.6]
                                    : [255, 255, 255, 0.8]
                            }
                            scale={scale}
                            rotate={0}
                        />
                    )}

                    <div className="w-full mt-4 px-3">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Zoom
                        </label>
                        <input
                            type="range"
                            min="1"
                            max="3"
                            step="0.01"
                            value={scale}
                            onChange={(e) =>
                                setScale(parseFloat(e.target.value))
                            }
                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                    </div>

                    <ModalActions>
                        <Button
                            outline
                            onClick={() => setIsAvatarEditorOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleSaveAvatar}>Save</Button>
                    </ModalActions>
                </div>
            </Modal>
        </Panel>
    );
}
