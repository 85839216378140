import { useMemo, useRef } from "react";
import { ReportResponse } from "@/types/generated/ReportResponse.ts";
import Money from "@/common/Money";
import BigNumberWidget from "@/common/BigNumberWidget";
import ChangeBadge from "@/common/ChangeBadge";
import { assertNotUndefined } from "@/util/typeguards.ts";
import PercentageChangeFunnel from "@/common/PercentChangeFunnel";
import { safeDivide } from "@/util/math.ts";

export default function NumberRowWidget({
    reportData,
    currency,
}: {
    reportData?: ReportResponse;
    currency: string;
}) {
    const customerMetrics = useMemo(
        () => getMetrics(currency, "Customers", reportData?.cac),
        [currency, reportData],
    );

    const leadMetrics = useMemo(
        () => getMetrics(currency, "Leads", reportData?.cac),
        [currency, reportData],
    );

    const loading = !reportData?.data;

    return (
        <div
            className={
                "2xl:col-span-3 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-6 min-h-36"
            }
        >
            <AcquisitionCostWidget
                currency={currency}
                loading={loading}
                cacBlended={leadMetrics.cacBlended}
                cacPaid={leadMetrics.cacPaid}
                cacBlendedCompare={leadMetrics.cacBlendedCompare}
                type={"leads"}
            />
            <LeadToPayingWidget
                loading={loading}
                leads={leadMetrics.allCount}
                leadsCompare={leadMetrics.allCountCompare}
                customers={customerMetrics.allCount}
                customersCompare={customerMetrics.allCountCompare}
            />
            <AcquisitionCostWidget
                currency={currency}
                loading={loading}
                cacBlended={customerMetrics.cacBlended}
                cacPaid={customerMetrics.cacPaid}
                cacBlendedCompare={customerMetrics.cacBlendedCompare}
                type={"customers"}
            />
            <MsrWidget
                loading={loading}
                reportData={reportData}
                currency={currency}
            />
        </div>
    );
}

const LeadToPayingWidget = ({
    loading,
    leads,
    customers,
    customersCompare,
    leadsCompare,
}: {
    loading: boolean;
    leads?: number;
    leadsCompare?: number;
    customers?: number;
    customersCompare?: number;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const numberContainerWidth =
        containerRef.current?.querySelector(".number-container")?.clientWidth ||
        0;

    const conversionRate = safeDivide(customers, leads);
    const conversionRateCompare = safeDivide(customersCompare, leadsCompare);

    const funnel = useMemo(() => {
        if (!containerRef.current) {
            return null;
        }

        const funnelHeight = 40;
        const percentageTotalWidth = 100 + 25;
        const funnelWidth = numberContainerWidth - percentageTotalWidth;

        assertNotUndefined(funnelWidth);

        return (
            <div className="flex items-center">
                <div className="w-[100px] mr-[25px] text-md">
                    {(conversionRate * 100).toFixed(2)}%
                </div>
                <PercentageChangeFunnel
                    width={funnelWidth}
                    height={funnelHeight}
                    from={leads}
                    to={customers}
                />
            </div>
        );
    }, [numberContainerWidth, conversionRate]);

    return (
        <BigNumberWidget
            title={"Lead to Customer rate"}
            subtitle={`Previous period conversion rate was ${(conversionRateCompare * 100).toFixed(2)}%`}
            ref={containerRef}
            number={funnel}
            rightItem={
                <ChangeBadge
                    value={conversionRate}
                    compareValue={conversionRateCompare}
                />
            }
            loading={loading}
        />
    );
};

const AcquisitionCostWidget = ({
    loading,
    currency,
    cacPaid,
    cacBlended,
    cacBlendedCompare,
    type,
}: {
    loading: boolean;
    currency: string;
    cacPaid: number;
    cacBlended: number;
    cacBlendedCompare: number;
    type: "leads" | "customers";
}) => {
    return (
        <BigNumberWidget
            title={`${type === "customers" ? "CAC" : "CPL"} (blended)`}
            subtitle={
                <div className={"flex gap-1"}>
                    <Money currency={currency} amount={cacPaid} /> for paid
                    channels
                </div>
            }
            number={<Money amount={cacBlended} currency={currency} />}
            tooltipContent={
                type === "customers"
                    ? "Blended customer acquisition cost (CAC) is calculated by dividing ad spend by total number of customers"
                    : "Blended cost per lead (CPL) is calculated by dividing ad spend by total number of leads"
            }
            rightItem={
                <ChangeBadge
                    inverted={true}
                    className={"ml-2"}
                    value={cacBlended}
                    compareValue={cacBlendedCompare}
                />
            }
            loading={loading}
        />
    );
};

const MsrWidget = ({
    loading,
    reportData,
    currency,
}: {
    loading: boolean;
    reportData?: ReportResponse;
    currency: string;
}) => {
    const msr = useMemo(
        () => calulateMsr(currency, reportData?.data),
        [currency, reportData],
    );

    return (
        <BigNumberWidget
            title={"MSR"}
            subtitle={<>Revenue directly attributable to paid channels</>}
            number={<Money amount={msr} currency={currency} precision={0} />}
            tooltipContent={
                "Marketing sourced revenue (MSR) is calculated by dividing ad spend by the number of customers that came from paid channels. This amount includes all revenue from converted customers that had at least 1 touch point through a paid channel in the selected date range, multiplied by the relative contribution of each touch point."
            }
            loading={loading}
        />
    );
};

const calulateMsr = (currency: string, data?: ReportResponse["data"]) => {
    if (!data) {
        return 0;
    }

    return data.reduce(
        (carry, item) =>
            item.isPaid ? carry + Number(item.revenue[currency] || 0) : carry,
        0,
    );
};

const getMetrics = (
    currency: string,
    type: "Leads" | "Customers",
    cac?: ReportResponse["cac"],
) => {
    const organicCount = Number(cac?.[`organic${type}`]);
    const organicCountCompare = Number(cac?.[`organic${type}Compare`]);
    const paidCount = Number(cac?.[`paid${type}`]);
    const paidCountCompare = Number(cac?.[`paid${type}Compare`]);
    const allCount = organicCount + paidCount;
    const allCountCompare = organicCountCompare + paidCountCompare;

    const adSpend = Number(cac?.adSpend[currency] || "0");
    const adSpendCompare = Number(cac?.adSpendCompare[currency] || "0");

    const cacBlended =
        allCount === 0 ? (adSpend > 0 ? Infinity : 0) : adSpend / allCount;

    const cacBlendedCompare =
        allCountCompare === 0
            ? adSpendCompare > 0
                ? Infinity
                : 0
            : adSpendCompare / allCountCompare;

    const cacPaid =
        paidCount === 0 ? (adSpend > 0 ? Infinity : 0) : adSpend / paidCount;

    const cacPaidCompare =
        paidCountCompare === 0
            ? adSpendCompare > 0
                ? Infinity
                : 0
            : adSpendCompare / paidCountCompare;

    return {
        organicCount,
        organicCountCompare,
        paidCount,
        paidCountCompare,
        allCount,
        allCountCompare,
        cacPaid,
        cacPaidCompare,
        cacBlended,
        cacBlendedCompare,
    };
};
