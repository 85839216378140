export default function MetaIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M367.457 85.881c-41.982 0-74.801 31.62-104.509 71.788-40.825-51.981-74.967-71.788-115.825-71.788C63.824 85.881 0 194.288 0 309.03c0 71.802 34.737 117.089 92.92 117.089 41.877 0 71.995-19.743 125.536-113.335 0 0 22.319-39.414 37.673-66.564a1758.502 1758.502 0 0 1 17 28.082l25.107 42.236c48.908 81.844 76.158 109.58 125.536 109.58 56.682 0 88.227-45.906 88.227-119.201C512 186.779 446.737 85.881 367.457 85.881zM177.628 287.443c-43.41 68.048-58.427 83.3-82.596 83.3-24.873 0-39.655-21.836-39.655-60.774 0-83.3 41.533-168.477 91.043-168.477 26.811 0 49.217 15.484 83.536 64.616-32.588 49.985-52.328 81.335-52.328 81.335zm163.834-8.567-30.019-50.065c-8.124-13.212-15.909-25.353-23.421-36.484 27.056-41.759 49.373-62.567 75.916-62.567 55.142 0 99.256 81.188 99.256 180.913 0 38.013-12.451 60.07-38.247 60.07-24.726-.001-36.538-16.33-83.485-91.867z" />
        </svg>
    );
}
