import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { useState } from "react";
import { TouchTarget } from "./button";
import { Link } from "./link";
import { assertNotUndefined } from "@/util/typeguards.ts";
import { isLinkProps } from "@/catalyst/util.ts";

const colorClasses = ["bg-theme-1", "bg-theme-2", "bg-theme-3"];

const getColorIndex = (initials: string) => {
    return (
        (initials.charCodeAt(0) + initials.charCodeAt(1)) % colorClasses.length
    );
};

const getInitials = (name?: string) => {
    if (!name) {
        return null;
    }

    const str = name
        .split(" ")
        .map((n) => n[0])
        .join("");

    if (str.length <= 2) {
        return str;
    }
    assertNotUndefined(str[0]);

    return str[0] + str[str.length - 1];
};
type AvatarProps = {
    src?: string | null;
    square?: boolean;
    initials?: string;
    name?: string;
    alt?: string;
    className?: string;
    generateBgColor?: boolean;
    textClassName?: string;
};

export function Avatar({
    src = null,
    square = false,
    name,
    initials,
    alt = "",
    className,
    generateBgColor,
    textClassName = "text-near-white",
    ...props
}: AvatarProps & React.ComponentPropsWithoutRef<"span">) {
    const _initials = initials ?? getInitials(name) ?? "??";
    const [avatarError, setAvatarError] = useState(false);

    return (
        <span
            data-slot="avatar"
            {...props}
            className={clsx(
                className,
                // Basic layout
                "inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1",
                "outline outline-1 -outline-offset-1 outline-black/[--ring-opacity] dark:outline-white/[--ring-opacity]",
                // Add the correct border radius
                square
                    ? "rounded-[--avatar-radius] *:rounded-[--avatar-radius]"
                    : "rounded-full *:rounded-full",
                generateBgColor && colorClasses[getColorIndex(_initials)],
            )}
        >
            {(!src || avatarError) && _initials && (
                <svg
                    className="size-full select-none fill-current p-[5%] text-[48px] font-medium uppercase"
                    viewBox="0 0 100 100"
                    aria-hidden={alt ? undefined : "true"}
                >
                    {alt && <title>{alt}</title>}
                    <text
                        className={textClassName}
                        x="50%"
                        y="50%"
                        alignmentBaseline="middle"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        dy=".125em"
                    >
                        {_initials}
                    </text>
                </svg>
            )}
            {src && !avatarError && (
                <img
                    className="size-full"
                    src={src}
                    alt={alt}
                    onError={() => setAvatarError(true)}
                />
            )}
        </span>
    );
}

export const AvatarButton = React.forwardRef(function AvatarButton(
    {
        src,
        square = false,
        initials,
        alt,
        className,
        ...props
    }: AvatarProps &
        (
            | Omit<Headless.ButtonProps, "className">
            | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className">
        ),
    ref: React.ForwardedRef<HTMLElement>,
) {
    const classes = clsx(
        className,
        square ? "rounded-[20%]" : "rounded-full",
        "relative inline-grid focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500",
    );

    // Separate props for Link and Button to avoid type issues
    const linkProps = isLinkProps(props) ? { ...props } : {};
    const buttonProps = (!isLinkProps(props) ? { ...props } : {}) as Omit<
        Headless.ButtonProps,
        "className"
    >;

    return isLinkProps(linkProps) ? (
        <Link
            {...linkProps}
            className={classes}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        >
            <TouchTarget>
                <Avatar
                    src={src}
                    square={square}
                    initials={initials}
                    alt={alt}
                />
            </TouchTarget>
        </Link>
    ) : (
        <Headless.Button {...buttonProps} className={classes} ref={ref}>
            <TouchTarget>
                <Avatar
                    src={src}
                    square={square}
                    initials={initials}
                    alt={alt}
                />
            </TouchTarget>
        </Headless.Button>
    );
});
