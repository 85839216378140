export function getCookie(key: string) {
    const val = (
        ("; " + document.cookie).split("; " + key + "=")[1] || ""
    ).split(";")[0];
    return typeof val !== "undefined" ? decodeURIComponent(val) : undefined;
}

export function setCookie(key: string, value: string, days: number) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie =
        key +
        "=" +
        encodeURIComponent(value) +
        ";expires=" +
        expires.toUTCString();
}

export function deleteCookie(key: string) {
    setCookie(key, "", -1);
}
