import { StarIcon } from "@heroicons/react/24/solid";
import Modal, { ModalActions } from "../Modal";
import Button from "../Button";
import Text from "@/common/Text";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useGetUpgradeSubscriptionPortalSessionMutation } from "@/services/apis/api.ts";
import { assertNotUndefined } from "@/util/typeguards.ts";

const UpgradeDialog = ({
    onClose,
    open,
    title = "Upgrade Account",
    userIsAdmin,
}: {
    onClose: () => void;
    open: boolean;
    userIsAdmin: boolean;
    title?: string;
}) => {
    const [getSubscriptionPortalSession, { isLoading }] =
        useGetUpgradeSubscriptionPortalSessionMutation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            description={
                <>
                    <div>
                        Upgrade now to the Growth plan at $149/m. After clicking
                        'upgrade', you will be taken to a secure checkout page
                        where you can fill out your payment details
                    </div>
                    {!userIsAdmin && (
                        <div className={"mt-4 mb-6 flex items-start gap-2"}>
                            <ExclamationTriangleIcon
                                className={"h-10 w-10 text-yellow-500"}
                            />
                            <Text weight="bold">
                                Only admin users can upgrade. Please contact an
                                admin user in your organization
                            </Text>
                        </div>
                    )}
                </>
            }
        >
            <ModalActions>
                <Button outline onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    loading={isLoading}
                    type="primary"
                    className={"ml-5"}
                    disabled={!userIsAdmin}
                    onClick={async () => {
                        const result = await getSubscriptionPortalSession({
                            plan: "growth",
                            cadence: "monthly",
                        });
                        assertNotUndefined(result.data);
                        window.location.href = result.data.url;
                    }}
                >
                    <StarIcon className="mr-1 h-5 w-5" />
                    Upgrade
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default UpgradeDialog;
