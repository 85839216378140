import { ReactNode, useCallback } from "react";
import { Predicate } from "./types";
import { produce } from "immer";
import { clsx } from "clsx";
import { HashtagIcon } from "@heroicons/react/24/solid";
import { StringComparator } from "@/types/generated/StringComparator.ts";
import { snakeToSentence } from "@/util/case.ts";
import EditableField from "@/common/EditableField";

const stringComparators: StringComparator[] = [
    "equals",
    "contains",
    "startsWith",
    "endsWith",
];

function createPredicateOnChange(
    property: keyof Predicate,
    predicate?: Predicate | null,
    onPredicateChange?: (predicate: Predicate) => void,
) {
    if (!predicate || !onPredicateChange) {
        return () => {};
    }

    return (newValue?: string) => {
        if (typeof newValue === "undefined") {
            return;
        }

        const updatedPredicate = produce(predicate, (draft) => {
            // @ts-expect-error - We ensue that it's a comparator or string
            draft[property] = newValue;
        });

        onPredicateChange(updatedPredicate);
    };
}

export default function FunnelStepButton({
    children,
    onClick,
    className,
    predicate,
    predicateProperties,
    onPredicateChange,
    disabled,
}: {
    children: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    predicate?: Predicate | null;
    predicateProperties?: Predicate["property"][];
    onPredicateChange?: (predicate: Predicate) => void;
}) {
    const onPropertyChange = useCallback(
        createPredicateOnChange("property", predicate, onPredicateChange),
        [predicate, onPredicateChange],
    );
    const onKeyChange = useCallback(
        createPredicateOnChange("key", predicate, onPredicateChange),
        [predicate, onPredicateChange],
    );
    const onComparatorChange = useCallback(
        createPredicateOnChange("comparator", predicate, onPredicateChange),
        [predicate, onPredicateChange],
    );
    const onValueChange = useCallback(
        createPredicateOnChange("value", predicate, onPredicateChange),
        [predicate, onPredicateChange],
    );

    return (
        <div
            className={clsx(
                "flex flex-col",
                disabled && "opacity-50 pointer-events-none",
            )}
        >
            <div
                onClick={onClick}
                className={clsx(
                    "shadow-sm dark:shadow-none bg-transparent border-zinc-950/10 dark:border-white/10 border-solid border p-2.5 pl-4 flex items-center text-default text-sm",
                    className,
                    predicate && "rounded-t-lg",
                    !predicate && "rounded-lg shadow-sm ",
                )}
            >
                {children}
            </div>
            {predicate && predicateProperties && (
                <div
                    className={clsx(
                        "dark:shadow-none bg-transparent border-zinc-950/10 dark:border-white/10 border-solid border-l border-r border-b p-2.5 flex items-center text-default text-sm",
                        predicate && "rounded-b-lg",
                    )}
                >
                    <div className={"flex gap-2 items-start w-full"}>
                        <div
                            className={
                                "flex h-5 w-5 items-center justify-center"
                            }
                        >
                            <HashtagIcon
                                className={"h-4 w-4 text-indigo-500"}
                            />{" "}
                        </div>
                        <div className={"flex flex-col gap-2 w-full"}>
                            <div
                                className={
                                    "flex items-center gap-2 h-5 font-medium"
                                }
                            >
                                <span>
                                    <EditableField
                                        value={predicate.property}
                                        onSave={onPropertyChange}
                                        options={predicateProperties.reduce(
                                            (carry, property) =>
                                                Object.assign(carry, {
                                                    [property]:
                                                        snakeToSentence(
                                                            property,
                                                        ),
                                                }),
                                            {} as Record<string, string>,
                                        )}
                                        nativeType={"select"}
                                    />
                                </span>
                            </div>
                            <div className={"flex items-center gap-2 w-full"}>
                                {predicate.property === "eventProperty" && (
                                    <EditableField
                                        className={"text-xs font-mono truncate"}
                                        inputClassName={
                                            "text-xs font-mono truncate"
                                        }
                                        value={predicate.key || ""}
                                        onSave={onKeyChange}
                                    />
                                )}

                                <EditableField
                                    className={"text-xs text-subtle"}
                                    nativeType={"select"}
                                    value={predicate.comparator}
                                    options={stringComparators.reduce(
                                        (carry, comparator) =>
                                            Object.assign(carry, {
                                                [comparator]:
                                                    snakeToSentence(comparator),
                                            }),
                                        {} as Record<string, string>,
                                    )}
                                    onSave={onComparatorChange}
                                />
                                <EditableField
                                    className={"text-xs font-mono truncate"}
                                    inputClassName={
                                        "text-xs font-mono truncate"
                                    }
                                    value={predicate.value}
                                    onSave={onValueChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
