export default function StripeIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.315 129.427"
        >
            <path
                d="M209.55 237.308c0-5.547 4.551-7.68 12.09-7.68 10.808 0 24.462 3.271 35.272 9.103v-33.424c-11.805-4.693-23.468-6.542-35.273-6.542-28.872 0-48.073 15.076-48.073 40.25 0 39.255 54.047 32.997 54.047 49.922 0 6.543-5.689 8.676-13.654 8.676-11.805 0-26.88-4.836-38.828-11.378v33.85c13.227 5.69 26.597 8.107 38.828 8.107 29.583 0 49.922-14.65 49.922-40.108-.142-42.384-54.33-34.846-54.33-50.776z"
                transform="translate(-173.566 -198.765)"
            />
        </svg>
    );
}
