// Non exhaustive but tackles most common locales
const sundayWeekStartLocales = [
    "en-US", // United States
    "en-CA", // Canada
    "es-MX", // Mexico
    "es-US", // Spanish (United States)
    "en-PH", // Philippines
    "fil-PH", // Filipino (Philippines)
    "ja-JP", // Japan
    "he-IL", // Israel
    "ar-SA", // Saudi Arabia
    "ar-AE", // United Arab Emirates
    "ar-QA", // Qatar
    "ar-BH", // Bahrain
    "ar-KW", // Kuwait
    "ar-OM", // Oman
    "ar-EG", // Egypt
    "en-ZA", // South Africa
    "en-AU", // Australia (though Monday is also common)
    "pt-BR", // Brazil
    "es-AR", // Argentina
    "es-CL", // Chile
    "es-CO", // Colombia
    "es-PE", // Peru
    "es-VE", // Venezuela
    "zh-TW", // Taiwan
    "ko-KR", // South Korea
];

export const getWeekStartIndex = () => {
    const locale = getLocale();
    if (!locale) {
        return 0;
    }

    try {
        const loc = new Intl.Locale(locale);

        // @see https://caniuse.com/?search=weekinfo
        // @ts-expect-error - Bleeding edge
        const firstDay = loc.weekInfo.firstDay as number;
        if (firstDay === 7) {
            return 0;
        } else if (firstDay === 1) {
            return 1;
        }
    } catch {
        // API not available, fallback to hardcoded values
    }

    return sundayWeekStartLocales[locale as keyof typeof sundayWeekStartLocales]
        ? 0
        : 1;
};

function getLocale() {
    return navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
}
