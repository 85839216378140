import * as React from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    useMergeRefs,
    FloatingPortal,
    useDelayGroupContext,
    useDelayGroup,
    useTransitionStyles,
    safePolygon,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";
import { ReactNode } from "react";
import classNames from "classnames";

interface TooltipOptions {
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export function useTooltip({
    initialOpen = false,
    placement = "top",
    open: controlledOpen,
    onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                crossAxis: placement.includes("-"),
                fallbackAxisSideDirection: "start",
                padding: 5,
            }),
            shift({ padding: 5 }),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
        handleClose: safePolygon(),
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            ...interactions,
            ...data,
        }),
        [open, setOpen, interactions, data],
    );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
    const context = React.useContext(TooltipContext);

    if (context == null) {
        throw new Error("Tooltip components must be wrapped in <Tooltip />");
    }

    return context;
};

export default function Tooltip({
    children,
    content,
    enabled = true,
    ...options
}: {
    children: React.ReactNode;
    content: ReactNode;
    enabled?: boolean;
} & TooltipOptions) {
    if (!enabled) {
        return children;
    }

    return (
        <TooltipComp {...options}>
            <TooltipTrigger className={"inline-block"}>
                {children}
            </TooltipTrigger>
            <TooltipContent
                className={classNames(
                    "max-w-[30rem]",
                    "text-sm",
                    // Anchor positioning
                    "[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.1)] data-[anchor~=end]:[--anchor-offset:6px] data-[anchor~=start]:[--anchor-offset:-6px] sm:data-[anchor~=end]:[--anchor-offset:4px] sm:data-[anchor~=start]:[--anchor-offset:-4px]",
                    // Base styles
                    "isolate w-max rounded-xl p-2",
                    // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
                    "outline outline-1 outline-transparent focus:outline-none",
                    // Handle scrolling when menu won't fit in viewport
                    "overflow-y-auto",
                    // Popover background
                    "bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75",
                    // Shadows
                    "shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10",
                    // Define grid at the menu level if subgrid is supported
                    "supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]",
                )}
            >
                {content}
            </TooltipContent>
        </TooltipComp>
    );
}

function TooltipComp({
    children,
    ...options
}: { children: React.ReactNode } & TooltipOptions) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const tooltip = useTooltip(options);
    return (
        <TooltipContext.Provider value={tooltip}>
            {children}
        </TooltipContext.Provider>
    );
}

export const TooltipTrigger = React.forwardRef<
    HTMLElement,
    React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                // @ts-expect-error - Shows an error but works fine
                ...children.props,
                "data-state": context.open ? "open" : "closed",
            }),
        );
    }

    return (
        <div
            ref={ref}
            // The user can style the trigger based on the state
            data-state={context.open ? "open" : "closed"}
            {...context.getReferenceProps(props)}
        >
            {children}
        </div>
    );
});

export const useTooltipState = () => {
    const context = React.useContext(TooltipContext);

    if (context == null) {
        throw new Error("Tooltip components must be wrapped in <Tooltip />");
    }

    return context;
};

export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>(function TooltipContent(props, propRef) {
    const state = useTooltipState();
    const { isInstantPhase, currentId } = useDelayGroupContext();
    const ref = useMergeRefs([state.refs.setFloating, propRef]);

    useDelayGroup(state.context, { id: state.context.floatingId });

    const instantDuration = 0;
    const duration = 150;

    const { isMounted, styles } = useTransitionStyles(state.context, {
        duration: isInstantPhase
            ? {
                  open: instantDuration,
                  // `id` is this component's `id`
                  // `currentId` is the current group's `id`
                  close:
                      currentId === state.context.floatingId
                          ? duration
                          : instantDuration,
              }
            : duration,
        initial: {
            opacity: 0,
        },
    });

    if (!isMounted) return null;

    return (
        <FloatingPortal>
            <div
                ref={ref}
                style={{
                    ...state.floatingStyles,
                    ...props.style,
                    ...styles,
                }}
                {...state.getFloatingProps(props)}
            />
        </FloatingPortal>
    );
});
