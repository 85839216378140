import { assertNotUndefined } from "@/util/typeguards.ts";
import { useSelector } from "react-redux";
import { getSelectedWorkspace } from "@/features/Workspace/Workspace.slice";
import { components } from "@/types/generated/api-schema.ts";
type Workspace = components["schemas"]["Workspace"];

export function useSelectedWorkspace(): Workspace {
    const selectedWorkspace = useSelector(getSelectedWorkspace);
    assertNotUndefined(selectedWorkspace);

    return selectedWorkspace;
}

export function useOptionalSelectedWorkspace(): Workspace | undefined {
    return useSelector(getSelectedWorkspace);
}
