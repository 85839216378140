import { useGetReportQuery } from "@/services/apis/data.ts";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useUrlPersistedQuery } from "@/hooks/use-url-persisted-query.ts";

import { components } from "@/types/generated/api-schema.ts";
import UserContext from "@/app/UserContext.ts";
import { assertNotUndefined } from "@/util/typeguards";
import { FullHeightMainContainer } from "@/common/Container";
import { reportingListColDefs } from "@/features/Reporting/ColDefs.tsx";
import { ReportQuery } from "@/features/Reporting/types.ts";
import ReportTable from "@/common/ReportTable";
import ControlBar from "@/common/ReportTable/ControlBar.tsx";
import { snakeToCamel } from "@/util/case.ts";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { AttributionModel } from "@/types/generated/AttributionModel.ts";
import {
    DateRangeWithCompare,
    SetFilters,
} from "@/common/ReportTable/types.ts";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { getDefaultDateRangeAndCompareWithMinDate } from "@/common/DateRangePicker/util.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

type Workspace = components["schemas"]["Workspace"];

export default function List() {
    const selectedWorkspace = useSelectedWorkspace();

    const [query, setQuery] = useUrlPersistedQuery<ReportQuery>(
        {
            filters: [],
            ...getDefaultDateRangeAndCompareWithMinDate(
                selectedWorkspace.createdAt,
            ),
            attributionModel: "uShaped",
            dimension: "source",
            drillDownDimensions: [],
            currency:
                selectedWorkspace.reportingCurrency ||
                selectedWorkspace.defaultCurrency,
        },
        (urlQuery) => ({
            ...urlQuery,
            dimension:
                // @ts-expect-error urlQuery.dimension is deprecated and can be removed here soon.
                urlQuery.dimension === "sourceExtended"
                    ? "source"
                    : urlQuery.dimension,
            attributionModel: urlQuery.attributionModel
                ? (snakeToCamel(urlQuery.attributionModel) as AttributionModel)
                : "uShaped",
            filters:
                urlQuery.filters?.map((filter) => ({
                    ...filter,
                    property: snakeToCamel(
                        // @ts-expect-error filter.column is deprecated and can be removed here soon.
                        filter.property || filter.column,
                    ) as AttributionDimension,
                })) || [],
        }),
    );

    const [resultCurrencies, setResultCurrencies] = useState<string[]>([]);

    // Change selected currency if current selected is not in results
    useEffect(() => {
        if (
            resultCurrencies.length &&
            !resultCurrencies.includes(query.currency)
        ) {
            const selectedCurrency = resultCurrencies[0];
            assertNotUndefined(selectedCurrency);
            setQuery((prev) => ({
                ...prev,
                currency: selectedCurrency,
            }));
        }
    }, [resultCurrencies, query.currency, setQuery]);

    const setDimension = useCallback(
        (value: AttributionDimension) => {
            setQuery({ ...query, dimension: value });
        },
        [query, setQuery],
    );

    const setFilters = useCallback(
        (value: AttributionFilter[]) => {
            setQuery({ ...query, filters: value });
        },
        [query, setQuery],
    );

    const setAttributionModel = useCallback(
        (value: AttributionModel) => {
            setQuery({ ...query, attributionModel: value });
        },
        [query, setQuery],
    );

    const setCurrency = useCallback(
        (value: string) => {
            setQuery({ ...query, currency: value });
        },
        [query, setQuery],
    );

    const setDrillDownDimensions = useCallback(
        (value: AttributionDimension[]) => {
            setQuery({ ...query, drillDownDimensions: value });
        },
        [query, setQuery],
    );

    const setDateRange = useCallback(
        (value: DateRangeWithCompare) => {
            setQuery({
                ...query,
                dateRange: value.dateRange,
                compareDateRange: value.compareDateRange,
            });
        },
        [query, setQuery],
    );

    return (
        <FullHeightMainContainer>
            <ControlBar
                className={"mb-4"}
                attributionModel={query.attributionModel}
                currency={query.currency}
                dateRange={query.dateRange}
                dimension={query.dimension}
                filters={query.filters}
                setDimension={setDimension}
                setFilters={setFilters}
                setAttributionModel={setAttributionModel}
                setCurrency={setCurrency}
                setDateRange={setDateRange}
                resultCurrencies={resultCurrencies}
                drillDownDimensions={query.drillDownDimensions}
                setDrillDownDimensions={setDrillDownDimensions}
                dateRangeDescription={
                    <ul className={"list-outside list-disc"}>
                        <li>
                            All leads that had at least 1 touch point in the
                            selected date range
                        </li>
                        <li>
                            The <i>leads</i>, <i>customers</i> and{" "}
                            <i>revenue</i> sums are calculated by summing each
                            touch point's relative contribution, according to
                            the chosen attribution model. However, any partial
                            customer or revenue that is attributed to a touch
                            point outside the date range is not included in the
                            respective sum.{" "}
                        </li>
                        <li>All ad spend in the date range</li>
                    </ul>
                }
            />
            <ReportingTable
                query={query}
                setFilters={setFilters}
                setResultCurrencies={setResultCurrencies}
                selectedWorkspace={selectedWorkspace}
            />
        </FullHeightMainContainer>
    );
}

const ReportingTable = ({
    query,
    setFilters,
    setResultCurrencies,
    selectedWorkspace,
}: {
    query: ReportQuery;
    setFilters: SetFilters;
    setResultCurrencies: (resultCurrencies: string[]) => void;
    selectedWorkspace: Workspace;
}) => {
    const user = useContext(UserContext);
    assertNotUndefined(user);

    // Fetch report data
    const {
        isFetching,
        isLoading,
        currentData: response,
    } = useGetReportQuery({
        workspace_id: selectedWorkspace.id,
        date_from: query.dateRange.from,
        date_to: query.dateRange.to,
        compare_date_from: query.compareDateRange.from,
        compare_date_to: query.compareDateRange.to,
        attribution_model: query.attributionModel,
        cut_off_events: selectedWorkspace.cutOffEvents,
        default_currency: selectedWorkspace.defaultCurrency,
        timezone: user.timezone || null,
        reporting_currency: selectedWorkspace.reportingCurrency || null,
        organization_attribution: selectedWorkspace.organizationAttribution,
    });

    // Update resultCurrencies when data is loaded
    useEffect(() => {
        if (!response?.data) {
            return;
        }
        const resultCurrencies = response.currencies;
        setResultCurrencies(resultCurrencies);
    }, [response?.data, setResultCurrencies, response?.currencies]);

    const data = useMemo(() => response?.data || [], [response?.data]);

    return (
        <ReportTable
            dimension={query.dimension}
            filters={query.filters}
            setFilters={setFilters}
            currency={query.currency}
            rowData={data}
            loading={isLoading || isFetching}
            colDefs={reportingListColDefs}
            drillDownDimensions={query.drillDownDimensions}
        />
    );
};
