import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

let analytics: Analytics | undefined;
const enabled = import.meta.env.PROD;

const getAnalytics = async (): Promise<Analytics | undefined> => {
    if (enabled && !analytics) {
        const [response] = await AnalyticsBrowser.load(
            {
                writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
            },
            {
                initialPageview: false,
            },
        );

        analytics = response;
    }

    return analytics;
};

export { getAnalytics };
