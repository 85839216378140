import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { produce } from "immer";
import { PlusIcon } from "@heroicons/react/20/solid";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from "@/catalyst/dropdown.tsx";
import {
    ChangeEventHandler,
    KeyboardEventHandler,
    useCallback,
    useState,
} from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/Popover";
import { Radio, RadioField, RadioGroup } from "@/catalyst/radio.tsx";
import { Description, Fieldset, Label } from "@/catalyst/fieldset.tsx";
import { Input } from "@/catalyst/input.tsx";
import { Divider } from "@/catalyst/divider.tsx";
import Link from "@/common/Link";
import { capitalize } from "@/util/case.ts";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { StringComparator } from "@/types/generated/StringComparator.ts";

const COMPARATORS: Record<StringComparator, string> = {
    equals: "Is",
    contains: "Contains",
    startsWith: "Starts with",
    endsWith: "Ends with",
};

const FILTERABLE_PROPERTIES: Record<AttributionDimension, string> = {
    source: "Source",
    campaign: "Campaign",
    keyword: "Keyword",
    adGroup: "Ad Group",
    ad: "Ad",
    referrer: "Referrer",
    sourceType: "Source Type",
    campaignMedium: "UTM Medium",
    campaignContent: "UTM Content",
};

export default function Filters({
    filters,
    onChange,
}: {
    filters: AttributionFilter[];
    onChange: (filters: AttributionFilter[]) => void;
}) {
    const [newFilter, setNewFilter] = useState<AttributionFilter | null>(null);
    const isCreating = newFilter !== null;

    const onAdd = useCallback(() => {
        if (!newFilter) {
            return;
        }

        const newFilters = produce(filters, (draft) => {
            draft.push(newFilter);
        });

        setNewFilter(null);
        onChange(newFilters);
    }, [newFilter]);

    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            event.stopPropagation();

            if (event.key === "Enter") {
                event.preventDefault();
                onAdd();
            }
        },
        [onAdd],
    );

    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            setNewFilter(
                (currentValue) =>
                    currentValue && {
                        ...currentValue,
                        value: event.target.value,
                    },
            );
        },
        [setNewFilter],
    );

    return (
        <div className={"flex flex-1 flex-row"}>
            {filters.map((filter, index) => (
                <FilterBlock
                    key={`${filter.property} ${filter.value}`}
                    filter={filter}
                    onRemove={() => {
                        const newFilters = produce(filters, (draft) => {
                            draft.splice(index, 1);
                        });

                        onChange(newFilters);
                    }}
                />
            ))}
            {newFilter && (
                <>
                    <Popover
                        open={isCreating && !!newFilter.property}
                        onOpenChange={() => setNewFilter(null)}
                        autoUpdatePosition={false}
                    >
                        <PopoverTrigger asChild={true}>
                            <FilterBlock
                                isCreating={true}
                                filter={newFilter}
                                onRemove={() => setNewFilter(null)}
                            />{" "}
                        </PopoverTrigger>
                        <PopoverContent>
                            <div className={"p-4"}>
                                <Fieldset>
                                    <RadioGroup
                                        name="comparator"
                                        value={newFilter.comparator}
                                        className={"!mt-0"}
                                        onChange={(value: StringComparator) =>
                                            setNewFilter(
                                                (currentValue) =>
                                                    currentValue && {
                                                        ...currentValue,
                                                        comparator: value,
                                                    },
                                            )
                                        }
                                    >
                                        {Object.entries(COMPARATORS).map(
                                            ([comparator, title], index) => (
                                                <RadioField
                                                    key={comparator}
                                                    className={
                                                        index === 0
                                                            ? "!mt-0"
                                                            : "!mt-1"
                                                    }
                                                >
                                                    <Radio value={comparator} />
                                                    <Label>{title}</Label>
                                                    <Description
                                                        className={classNames(
                                                            newFilter.comparator !==
                                                                comparator &&
                                                                "hidden",
                                                        )}
                                                    >
                                                        <Input
                                                            onKeyDown={
                                                                onInputKeyDown
                                                            }
                                                            value={
                                                                newFilter.value
                                                            }
                                                            onChange={
                                                                onInputChange
                                                            }
                                                            autoFocus={true}
                                                        />
                                                    </Description>
                                                </RadioField>
                                            ),
                                        )}
                                    </RadioGroup>
                                </Fieldset>
                            </div>
                            <Divider />
                            <div
                                className={
                                    "flex full-w items-center justify-center p-2"
                                }
                            >
                                <Link onClick={onAdd}>Add</Link>
                            </div>
                        </PopoverContent>
                    </Popover>
                </>
            )}
            <Dropdown>
                <DropdownButton
                    outline
                    disabled={isCreating}
                    className={classNames(
                        "text-default text-xs flex flex-row items-center ",
                        !isCreating && "cursor-pointer",
                    )}
                >
                    <PlusIcon className={"h-4 w-4"} /> Add{" "}
                </DropdownButton>
                <DropdownMenu>
                    {Object.entries(FILTERABLE_PROPERTIES).map(
                        ([property, title]) => (
                            <DropdownItem
                                key={property}
                                href="#"
                                onClick={() =>
                                    setNewFilter({
                                        property:
                                            property as AttributionDimension,
                                        comparator: "contains",
                                        value: "",
                                    })
                                }
                            >
                                {title}
                            </DropdownItem>
                        ),
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

const FilterBlock = ({
    filter,
    onRemove,
    isCreating,
}: {
    filter: AttributionFilter;
    onRemove: () => void;
    isCreating?: boolean;
}) => (
    <div
        className={classNames(
            "flex mr-2 text-center items-center justify-center border text-default p-2 grow text-xs h-full max-w-64",
            "border border-zinc-950/10 group-data-[active]:border-zinc-950/20 group-data-[hover]:border-zinc-950/20 dark:border-white/10 dark:group-data-[active]:border-white/20 dark:group-data-[hover]:border-white/20",
            // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
            "focus:outline-none",
            "rounded-lg",
            // Focus ring
            "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:data-[focus]:ring-2 after:data-[focus]:ring-blue-500",
            // Disabled state
            "data-[disabled]:opacity-50 before:data-[disabled]:bg-zinc-950/5 before:data-[disabled]:shadow-none",
            isCreating && "border-dashed",
        )}
        key={`${filter.property} ${filter.value}`}
    >
        <div className={"truncate text-default max-w-[90%]"}>
            <span className={"font-mono"}>
                {FILTERABLE_PROPERTIES[
                    filter.property as keyof typeof FILTERABLE_PROPERTIES
                ] || capitalize(filter.property)}
            </span>{" "}
            <span className={"font-semibold text-subtle"}>
                {(
                    COMPARATORS[filter.comparator] || filter.comparator
                ).toLowerCase()}
            </span>{" "}
            <span className={"font-mono"}>{filter.value}</span>
        </div>
        <XCircleIcon
            className={"h-4 w-4 ml-2 cursor-pointer"}
            onClick={onRemove}
        />
    </div>
);
