import { clsx } from "clsx";
import { CheckIcon } from "@heroicons/react/24/solid";

export type Step = {
    name: string;
    description: string;
    status: "current" | "upcoming" | "complete";
};

export default function VerticalProgress({
    steps,
    onStepClick,
}: {
    steps: Step[];
    onStepClick: (step: Step) => void;
}) {
    return (
        <nav aria-label="Progress">
            <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li
                        key={step.name}
                        className={clsx(
                            stepIdx !== steps.length - 1 ? "pb-10" : "",
                            "relative",
                        )}
                    >
                        {step.status === "complete" ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        aria-hidden="true"
                                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                                    />
                                ) : null}
                                <div
                                    onClick={() => onStepClick(step)}
                                    className="group relative flex items-start cursor-pointer"
                                >
                                    <span className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="h-5 w-5 text-white"
                                            />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-default">
                                            {step.name}
                                        </span>
                                        <span className="text-sm text-subtle">
                                            {step.description}
                                        </span>
                                    </span>
                                </div>
                            </>
                        ) : step.status === "current" ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        aria-hidden="true"
                                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                    />
                                ) : null}
                                <div
                                    onClick={() => onStepClick(step)}
                                    aria-current="step"
                                    className="group relative flex items-start cursor-pointer"
                                >
                                    <span
                                        aria-hidden="true"
                                        className="flex h-9 items-center"
                                    >
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-indigo-600">
                                            {step.name}
                                        </span>
                                        <span className="text-sm text-subtle">
                                            {step.description}
                                        </span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div
                                        aria-hidden="true"
                                        className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                    />
                                ) : null}
                                <div
                                    onClick={() => onStepClick(step)}
                                    className="group relative flex items-start cursor-pointer"
                                >
                                    <span
                                        aria-hidden="true"
                                        className="flex h-9 items-center"
                                    >
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-subtle">
                                            {step.name}
                                        </span>
                                        <span className="text-sm text-subtle">
                                            {step.description}
                                        </span>
                                    </span>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
