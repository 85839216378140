import { RootState } from "@/app/store.ts";
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { objectKeysToCamel, objectKeysToSnake } from "@/util/case.ts";
import { getSelectedWorkspace } from "@/features/Workspace/Workspace.slice.ts";
import { toast } from "@/common/Toast";
import { fetchAuthSession } from "aws-amplify/auth";
import { deleteCookie, getCookie } from "@/util/cookie.ts";

const baseQueryFactory = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers, { getState }) => {
            const state = getState() as RootState;
            const { tokens } = await fetchAuthSession();

            if (!tokens?.accessToken) {
                throw new Error("No access token");
            }

            headers.set("authorization", `Bearer ${tokens.accessToken}`);

            const workspaceId = getSelectedWorkspace(state)?.id;
            if (workspaceId) {
                headers.set("Spectacle-Workspace-Id", workspaceId);
            }

            if (getCookie("potentialSignUp")) {
                deleteCookie("potentialSignUp");
                const tapfiliateVisitorId = getCookie("tap_vid");
                if (tapfiliateVisitorId) {
                    headers.set(
                        "Spectacle-Tapfiliate-Vid",
                        tapfiliateVisitorId,
                    );
                }

                const gaClientId = getCookie("_ga");
                if (gaClientId) {
                    const parts = gaClientId.split(".");
                    if (parts.length > 2) {
                        headers.set(
                            "Spectacle-Ga-Client-Id",
                            `${parts[2]}.${parts[3]}`,
                        );
                    }
                }

                const vwoUuid = getCookie("_vwo_uuid");
                if (vwoUuid) {
                    headers.set("Spectacle-Vwo-Uuid", vwoUuid);
                }
            }

            return headers;
        },
        credentials: "include",
    });

export function getBaseQuery(
    baseUrl: string,
): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> {
    const baseQuery = baseQueryFactory(baseUrl);
    return async (args, api, extraOptions) => {
        if (args.body) {
            args.body = objectKeysToSnake(args.body as Record<string, unknown>);
        }

        const result = await baseQuery(args, api, extraOptions);

        if (result.error) {
            const data = result.data as { message?: string } | undefined;

            const message =
                data?.message ||
                "An unknown error occurred. Please try again. If the problem persists, please contact support";
            toast.error(message);

            return result;
        }

        result.data = objectKeysToCamel(result.data);

        return result;
    };
}
