import { Radio, RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";
import { UserIcon, BuildingOfficeIcon } from "@heroicons/react/16/solid";
import { RadioField } from "@/catalyst/radio.tsx";
import { Label } from "@/catalyst/fieldset.tsx";
import { StarIcon } from "@heroicons/react/24/solid";

type AttributionScope = "individual" | "group";

type AttributionScopePickerOption = {
    value: AttributionScope;
    label: string;
    tooltip: string;
    icon: ReactNode;
};

const options: AttributionScopePickerOption[] = [
    {
        value: "individual",
        label: "Individuals",
        tooltip: "Attribute to individual users",
        icon: <UserIcon className={"h-5 w-5 text-default"} />,
    },
    {
        value: "group",
        label: "Organization",
        tooltip:
            "Attribute to groups of users. E.g. all users in a company/organization ",
        icon: <BuildingOfficeIcon className={"h-5 w-5 text-default"} />,
    },
];

export default function AttributionScopePicker({
    scope,
    onChange,
    needsToUpgradeForGroup,
}: {
    scope: AttributionScope;
    onChange: (scope: AttributionScope) => void;
    needsToUpgradeForGroup?: boolean;
}) {
    return (
        <RadioGroup
            value={scope}
            onChange={onChange}
            className={classNames(
                "group relative w-full flex flex-1 flex-row gap-4 focus:ring-0 focus:outline-none",
                // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
            )}
        >
            {options.map((option) => (
                <RadioField key={option.value}>
                    <Radio
                        value={option.value}
                        className={({ checked }) =>
                            classNames(
                                "flex w-8 aspect-square text-center items-center justify-center border text-zinc-50 p-1 grow text-xs h-full cursor-pointer",
                                "border border-zinc-950/10 group-data-[active]:border-zinc-950/20 group-data-[hover]:border-zinc-950/20 dark:border-white/10 dark:group-data-[active]:border-white/20 dark:group-data-[hover]:border-white/20",
                                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                                "focus:outline-none",
                                "rounded-lg",
                                // Focus ring
                                "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:data-[focus]:ring-2 after:data-[focus]:ring-blue-500",
                                // Disabled state
                                "data-[disabled]:opacity-50 before:data-[disabled]:bg-zinc-950/5 before:data-[disabled]:shadow-none",
                                checked && "bg-zinc-200 dark:bg-zinc-800",
                            )
                        }
                    >
                        {option.icon}
                    </Radio>
                    <Label className={"ml-2 mr-4 cursor-pointer"}>
                        <span className={"flex items-center gap-1"}>
                            {option.label}{" "}
                            {needsToUpgradeForGroup &&
                                option.value === "group" && (
                                    <StarIcon className="text-yellow-400 h-5 w-5" />
                                )}
                        </span>
                    </Label>
                </RadioField>
            ))}
        </RadioGroup>
    );
}
