import { clsx } from "clsx";
import { Pie, PieChart } from "recharts";
import { ChartConfig, ChartContainer } from "@/common/ui/chart.tsx";

const chartConfig = {
    skeleton: {
        label: "",
        color: "var(--chart-skeleton)",
    },
} satisfies ChartConfig;

export default function PieChartSkeleton({
    className,
}: {
    className?: string;
}) {
    return (
        <ChartContainer
            config={chartConfig}
            className={clsx("aspect-auto w-full animate-pulse", className)}
        >
            <PieChart>
                <Pie
                    dataKey={"value"}
                    innerRadius={60}
                    strokeWidth={5}
                    fill="var(--chart-skeleton)"
                    data={[
                        {
                            tick: "1",
                            value: 100,
                        },
                        {
                            tick: "2",
                            value: 150,
                        },
                        {
                            tick: "3",
                            value: 190,
                        },
                        {
                            tick: "4",
                            value: 260,
                        },
                    ]}
                ></Pie>
            </PieChart>
        </ChartContainer>
    );
}
