import { RefObject, ReactNode } from "react";
import classNames from "classnames";
import EditableField from "@/common/EditableField";

export default function Panel({
    children,
    title,
    subtitle,
    action,
    className,
    compact = false,
    ref,
    onTitleChange,
}: {
    children: ReactNode;
    title?: string;
    subtitle?: string;
    action?: ReactNode;
    className?: string;
    compact?: boolean;
    ref?: RefObject<HTMLDivElement | null>;
    onTitleChange?: (newTitle?: string) => void;
}) {
    return (
        <div
            ref={ref}
            className={classNames(
                className,
                "shadow-sm dark:shadow-none rounded-lg bg-transparent border-zinc-950/10 dark:border-white/10 border-solid border",
                compact ? "p-2.5" : "p-6",
            )}
        >
            <div className={"flex flex-col text-default h-full"}>
                <div className={"flex justify-between"}>
                    {title && (
                        <div className={"mb-6"}>
                            {!onTitleChange && (
                                <h2 className="text-lg font-medium leading-6">
                                    {title}
                                </h2>
                            )}
                            {onTitleChange && (
                                <EditableField
                                    isTitle={true}
                                    value={title}
                                    onSave={onTitleChange}
                                />
                            )}
                            {subtitle && (
                                <p className="mt-1 text-sm text-subtle-on-light-block">
                                    {subtitle}
                                </p>
                            )}
                        </div>
                    )}
                    {action}
                </div>
                <div className={"text-sm h-full"}>{children}</div>
            </div>
        </div>
    );
}
