import { Description, Field, Label } from "@/catalyst/fieldset.tsx";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import Link from "@/common/Link";
import { Badge } from "@/catalyst/badge.tsx";
import { Switch } from "@/catalyst/switch.tsx";
import { useUpdateWorkspaceMutation } from "@/services/apis/api.ts";
import { useCallback } from "react";
import { components } from "@/types/generated/api-schema";
type Workspace = components["schemas"]["Workspace"];
import Text from "@/common/Text";

export default function FieldCutOffEvents({
    shouldConfigureCutOffEvents,
    events,
    selectedWorkspace,
}: {
    shouldConfigureCutOffEvents: boolean;
    events: string[];
    selectedWorkspace: Workspace;
}) {
    const [updateWorkspace] = useUpdateWorkspaceMutation();

    const updateCutOffEvents = useCallback(
        (event: string, included: boolean) => {
            updateWorkspace({
                id: selectedWorkspace.id,
                cutOffEvents: included
                    ? [...(selectedWorkspace.cutOffEvents || []), event]
                    : selectedWorkspace.cutOffEvents.filter((e) => e !== event),
            });
        },
        [selectedWorkspace],
    );

    return (
        <Field className={"mb-6"}>
            <Label>
                Cut off event(s)
                {shouldConfigureCutOffEvents && (
                    <ExclamationTriangleIcon
                        className={"ml-4 text-yellow-500 h-5 w-5 inline-block"}
                    />
                )}
            </Label>
            <Description>
                Select the the cut off event(s) for attribution. Only
                touchpoints before this event will be considered for any given
                customer when their revenue is attributed to channels, keywords
                etc&hellip; This is usually the event associated with a user's
                sign up or starts of trial.{" "}
                <Link
                    external
                    to={`https://${import.meta.env.VITE_DOMAIN}/docs/getting-started/core-concepts#cut-off-event`}
                >
                    Why is this important?
                </Link>
            </Description>
            <div className={"flex gap-2 mt-4 flex-wrap"}>
                {events.map((event) => (
                    <Badge
                        key={event}
                        className={"py-1 pr-3 rounded-l-2xl rounded-r-2xl"}
                    >
                        <Switch
                            className={"mr-1"}
                            name={event}
                            checked={selectedWorkspace.cutOffEvents?.includes(
                                event,
                            )}
                            onChange={(checked) =>
                                updateCutOffEvents(event, checked)
                            }
                        />
                        {event}
                    </Badge>
                ))}
                {events.length === 0 && (
                    <Text type={"subtle"}>
                        Start tracking events to see them here
                    </Text>
                )}
            </div>
        </Field>
    );
}
