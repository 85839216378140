import { useUiProperty } from "@/features/UiState.slice.ts";
import { SidebarLayout } from "@/catalyst/sidebar-layout.tsx";
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from "@/catalyst/sidebar.tsx";
import WorkspacePicker from "@/common/WorkspacePicker";
import {
    Cog6ToothIcon,
    QuestionMarkCircleIcon,
    SparklesIcon,
    Squares2X2Icon,
} from "@heroicons/react/20/solid";
import { BuildingOfficeIcon, ChartBarIcon } from "@heroicons/react/24/solid";
import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    MoonIcon,
    UserIcon,
} from "@heroicons/react/16/solid";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "@/catalyst/dropdown.tsx";
import { Avatar } from "@/catalyst/avatar.tsx";
import DarkModeToggle from "@/common/DarkModeToggle/DarkModeToggle.tsx";
import { useContext } from "react";
import UserContext from "@/app/UserContext.ts";
import { assertNotUndefined } from "@/util/typeguards.ts";
import { useSignOut } from "@/hooks/use-signout.ts";
import StripePricingTable from "../Stripe/PricingTable";
import { FullHeightMainContainer } from "@/common/Container";
import Link from "@/common/Link";
import { Logo } from "@/common/Logo/Logo.tsx";

const STRIPE_PRICING_TABLE_ID = import.meta.env
    .VITE_STRIPE_PRICING_TABLE_ID as string;

export default function DisabledUiWithPricingTable() {
    const [darkMode] = useUiProperty<boolean>("darkMode");
    const user = useContext(UserContext);
    assertNotUndefined(user);
    const { signOut } = useSignOut();
    return (
        <div className={darkMode ? "dark" : ""}>
            <SidebarLayout
                navbar={<></>}
                sidebar={
                    <Sidebar>
                        <SidebarHeader style={{ filter: "blur(3px)" }}>
                            <WorkspacePicker
                                setNewWorkspaceDialogOpen={() => {}}
                            />
                            {/*<SidebarSection className="max-lg:hidden">*/}
                            {/*    <SidebarItem href="/search">*/}
                            {/*        <MagnifyingGlassIcon />*/}
                            {/*        <SidebarLabel>Search</SidebarLabel>*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/inbox">*/}
                            {/*        <InboxIcon />*/}
                            {/*        <SidebarLabel>Inbox</SidebarLabel>*/}
                            {/*    </SidebarItem>*/}
                            {/*</SidebarSection>*/}
                        </SidebarHeader>
                        <SidebarBody style={{ filter: "blur(3px)" }}>
                            <SidebarSection>
                                <SidebarItem
                                    className="relative"
                                    current={location.pathname === "/"}
                                >
                                    <Squares2X2Icon />
                                    <SidebarLabel>Dashboard</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/reporting"}
                                >
                                    <ChartBarIcon />
                                    <SidebarLabel>Reporting</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/contacts"}
                                >
                                    <UserIcon />
                                    <SidebarLabel>Contacts</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/settings"}
                                >
                                    <Cog6ToothIcon />
                                    <SidebarLabel>Settings</SidebarLabel>
                                </SidebarItem>
                            </SidebarSection>
                            {/*<SidebarSection className="max-lg:hidden">*/}
                            {/*    <SidebarHeading>Upcoming Events</SidebarHeading>*/}
                            {/*    <SidebarItem href="/events/1">*/}
                            {/*        Bear Hug: Live in Concert*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/2">*/}
                            {/*        Viking People*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/3">*/}
                            {/*        Six Fingers — DJ Set*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/4">*/}
                            {/*        We All Look The Same*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/4">*/}
                            {/*        */}
                            {/*    </SidebarItem>*/}
                            {/*</SidebarSection>*/}
                            <SidebarSpacer />
                            <SidebarSection>
                                <SidebarItem href="mailto:support@spectaclehq.com">
                                    <QuestionMarkCircleIcon />
                                    <SidebarLabel>Support</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    // href="/changelog"
                                    id={"changelog-button"}
                                >
                                    <SparklesIcon />
                                    <SidebarLabel>Changelog</SidebarLabel>
                                </SidebarItem>
                            </SidebarSection>
                        </SidebarBody>
                        <SidebarFooter className="max-lg:hidden">
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <span className="flex min-w-0 items-center gap-3">
                                        <Avatar
                                            generateBgColor={true}
                                            src={user.picture}
                                            className="size-10"
                                            name={user.name}
                                            square
                                            alt=""
                                        />
                                        <span className="min-w-0">
                                            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                                                {user.name}
                                            </span>
                                            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                                {user.email}
                                            </span>
                                        </span>
                                    </span>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <DropdownMenu
                                    className="min-w-64"
                                    anchor="top start"
                                >
                                    <DropdownItem>
                                        <UserIcon />
                                        <DropdownLabel>
                                            Account settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <BuildingOfficeIcon />
                                        <DropdownLabel>
                                            Organization settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem>
                                        <MoonIcon />
                                        <DarkModeToggle />
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem onClick={signOut}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Sign out</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SidebarFooter>
                    </Sidebar>
                }
            >
                <FullHeightMainContainer>
                    <div className="flex h-full w-full grow flex-col overflow-hidden justify-center items-center bg-zinc-900 rounded-sm">
                        <div
                            className={
                                "text-white w-full flex flex-col justify-center items-center gap-16"
                            }
                        >
                            <div className={"text-center max-w-md text-xl"}>
                                <Logo
                                    className={
                                        "w-80 display-block mx-auto mb-8"
                                    }
                                />
                                Your subscription has{" "}
                                <span className={"font-semibold"}>expired</span>
                                . Please choose a new plan. For full plan
                                details, please check{" "}
                                <Link
                                    to={`https://${import.meta.env.VITE_DOMAIN}/pricing`}
                                    external={true}
                                >
                                    our pricing
                                </Link>
                            </div>
                            <div className={"w-full"}>
                                <StripePricingTable
                                    clientReferenceId={user.organization.id}
                                    pricingTableId={STRIPE_PRICING_TABLE_ID}
                                    customerEmail={user.email}
                                    stripeCustomerSessionClientSecret={
                                        user.organization
                                            .stripeCustomerSessionClientSecret
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </FullHeightMainContainer>
            </SidebarLayout>
        </div>
    );
}
