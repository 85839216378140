import { ReactNode } from "react";
import { clsx } from "clsx";

export function FullHeightMainContainer({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) {
    return (
        <div
            className={clsx(
                "grow flex flex-1 flex-col pb-6 pt-6 lg:pt-10 lg:pb-10 px-6 lg:px-10",
                className,
            )}
        >
            {children}
        </div>
    );
}

export function Container({
    children,
    className,
    xCompact,
}: {
    children: ReactNode;
    className?: string;
    xCompact?: boolean;
}) {
    return (
        <div
            className={clsx(
                "full-h px-6 pt-6 lg:px-10 lg:pt-10 overflow-scroll",
                className,
                xCompact ? "px-3 lg:px-5" : "px-6 lg:px-10",
            )}
        >
            {children}
        </div>
    );
}
