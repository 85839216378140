import classNames from "classnames";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import {
    platformBranding,
    IntegrationKey,
} from "@/common/PlatformIcon/platformBranding.ts";
import { ReactNode } from "react";
import { clsx } from "clsx";

const iconClassNames = "w-4 h-4";

export default function PlatformIcon({
    platform,
    className,
}: {
    platform: IntegrationKey | string;
    className?: string;
}) {
    const [icon, colorClass] = getIconAndColorClass(platform);

    return (
        <div
            className={classNames(
                className,
                colorClass,
                "rounded-full border border-gray-300 inline-flex justify-center items-center text-white",
            )}
        >
            {icon}
        </div>
    );
}

const getIconAndColorClass = (
    platform: IntegrationKey | string,
): [ReactNode, string | undefined] => {
    const iconConfig = platformBranding[platform];
    if (iconConfig) {
        const IconComponent = iconConfig.icon;
        return [
            <IconComponent className={iconClassNames} />,
            iconConfig.bgColorClass,
        ];
    }

    return [
        <MegaphoneIcon
            className={clsx(iconClassNames, "text-near-black dark:text-white")}
        />,
        undefined,
    ];
};
