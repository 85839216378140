import { ReactNode, useMemo } from "react";
import Money from "@/common/Money";
import ChangeBadge from "@/common/ChangeBadge";
import { Report } from "@/types/generated/Report.ts";

type CompareKey = Extract<
    keyof Report,
    | "leadsCompare"
    | "customersCompare"
    | "revenueCompare"
    | "spendCompare"
    | "visitCountCompare"
>;

const compareKeysWithCurrency = [
    "revenueCompare",
    "spendCompare",
    "leadsCompare",
    "customersCompare",
] as Partial<CompareKey>[];

export const NumberWithCompare = ({
    dimension,
    compareKey,
    currentRowKey,
    compareData,
    value,
    inverted,
    precision = 2,
    currency,
    showCurrency = false,
}: {
    dimension: string;
    currentRowKey: string | null;
    compareData: Report[];
    compareKey: CompareKey;
    value: number;
    inverted?: boolean;
    precision?: number;
    currency?: string;
    showCurrency?: boolean;
}): ReactNode => {
    if (compareKeysWithCurrency.includes(compareKey) && !currency) {
        throw new Error(`Currency is required for ${compareKey} compareKey`);
    }

    // Row key is null for bottom total row
    const isTotal = currentRowKey === null;

    // Rowkey = "" for "not attributed", while this is null in the data
    const compareRowKey = currentRowKey === "" ? null : currentRowKey;

    const compareValue = useMemo(() => {
        return compareData.reduce((carry: number, r: Report) => {
            return (
                carry +
                (r[dimension as keyof Report] === compareRowKey || isTotal
                    ? getCompareValue(r, compareKey, currency)
                    : 0)
            );
        }, 0);
    }, [compareData, dimension, compareRowKey, isTotal, compareKey, currency]);

    // Attenuates jumpiness of the change badge
    const amountMinWClass = "min-w-12";

    return (
        <div className={"flex items-center gap-2"}>
            <div className={amountMinWClass}>
                <Money
                    amount={value}
                    precision={precision}
                    currency={showCurrency ? currency : undefined}
                />
            </div>
            <ChangeBadge
                value={value}
                compareValue={compareValue}
                inverted={inverted}
            />
        </div>
    );
};

const getCompareValue = (
    r: Report,
    compareKey: CompareKey,
    currency?: string,
) => {
    const compareValue =
        compareKey === "visitCountCompare"
            ? r[compareKey]
            : r[compareKey][currency!];

    return parseFloat(compareValue || "0");
};
