import classNames from "classnames";
import { getCurrencyPrefix } from "@/common/Money/currencies.ts";

export default function Money({
    currency,
    amount,
    className,
    precision = 2,
}: {
    currency?: string;
    amount: number;
    className?: string;
    precision?: number;
}) {
    const amountRounded: number = round(amount, precision);
    const isNegative = amountRounded < 0;

    return (
        <span className={classNames(className, "flex items-center")}>
            {isNegative && "-"}
            {!!currency && getCurrencyPrefix(currency)}
            {amountRounded === Infinity
                ? "∞"
                : Math.abs(amountRounded).toLocaleString(undefined, {
                      minimumFractionDigits: precision,
                      maximumFractionDigits: precision,
                  })}
        </span>
    );
}

const round = (num: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
};
