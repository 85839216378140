import {
    BaseQueryFn,
    createApi,
    EndpointBuilder,
} from "@reduxjs/toolkit/query/react";
import { paths } from "@/types/generated/api-schema";
import { getBaseQuery } from "@/services/apis/baseQuery.ts";
import { User } from "@/model/User.ts";
import merge from "lodash.merge";
import { FunnelConfig } from "@/features/Funnel/types";
import { assertNotUndefined } from "@/util/typeguards.ts";

type TagType =
    | "Workspaces"
    | "TeamMembers"
    | "WorkspaceIntegrations"
    | "AdNetworkEntities"
    | "Funnels";

const tagTypes: TagType[] = [
    "Workspaces",
    "TeamMembers",
    "WorkspaceIntegrations",
    "AdNetworkEntities",
    "Funnels",
];

// Define a service using a base URL and expected endpoints
export const api = createApi({
    reducerPath: "api",
    tagTypes,
    baseQuery: getBaseQuery(import.meta.env.VITE_MANAGEMENT_API_URL),
    endpoints: (builder) => ({
        ...getUserPaths(builder),
        ...getWorkspacePaths(builder),
        ...getTeamMemberPaths(builder),
        ...getSubscriptionPaths(builder),
        ...getWorkspaceIntegrationPaths(builder),
        ...getFunnelPaths(builder),
    }),
});

function getUserPaths(builder: EndpointBuilder<BaseQueryFn, TagType, "api">) {
    return {
        getUser: builder.query<
            paths["/user"]["get"]["responses"]["200"]["content"]["application/json"],
            undefined
        >({
            query: () => ({
                url: `user`,
            }),
        }),
        updateUser: builder.mutation<
            paths["/user"]["patch"]["responses"]["200"]["content"]["application/json"],
            paths["/user"]["patch"]["requestBody"]["content"]["application/json"]
        >({
            query: (data) => ({
                url: `user`,
                method: "PATCH",
                body: data,
            }),
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData("getUser", undefined, (draft) => {
                        Object.assign(draft, data);
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteUser: builder.mutation({
            query() {
                return {
                    url: `user`,
                    method: "DELETE",
                };
            },
        }),
        dismissDialog: builder.mutation<
            paths["/user/dismissed-dialogs"]["post"]["responses"]["204"],
            paths["/user/dismissed-dialogs"]["post"]["requestBody"]["content"]["application/json"]
        >({
            query: (data) => {
                return {
                    url: `user/dismissed-dialogs`,
                    method: "POST",
                    body: data,
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData("getUser", undefined, (draft) => {
                        draft.dismissedDialogs.push(id);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    };
}

function getWorkspacePaths(
    builder: EndpointBuilder<BaseQueryFn, TagType, "api">,
) {
    return {
        getWorkspaces: builder.query<
            paths["/workspaces"]["get"]["responses"]["200"]["content"]["application/json"],
            undefined
        >({
            query: () => ({
                url: `workspaces`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ name }) =>
                                  ({
                                      type: "Workspaces",
                                      name,
                                  }) as const,
                          ),
                          { type: "Workspaces", id: "LIST" },
                      ]
                    : [{ type: "Workspaces", id: "LIST" }],
        }),
        createWorkspace: builder.mutation<
            paths["/workspaces"]["post"]["responses"]["201"]["content"]["application/json"],
            paths["/workspaces"]["post"]["requestBody"]["content"]["application/json"]
        >({
            query(data) {
                return {
                    url: `workspaces`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: [{ type: "Workspaces", id: "LIST" }],
        }),
        updateWorkspace: builder.mutation<
            paths["/workspaces/{id}"]["patch"]["responses"]["200"]["content"]["application/json"],
            Partial<
                paths["/workspaces/{id}"]["patch"]["requestBody"]["content"]["application/json"]
            > & // @TODO Needed to make this partial because of a regression in NelmioApiDocBundle
                // @see: https://github.com/nelmio/NelmioApiDocBundle/pull/2103
                paths["/workspaces/{id}"]["patch"]["parameters"]["path"]
        >({
            query: ({ id, ...data }) => ({
                url: `workspaces/${id}`,
                method: "PATCH",
                body: data,
            }),
            async onQueryStarted(
                { id, ...data },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        "getWorkspaces",
                        undefined,
                        (draft) => {
                            draft.forEach((ws) => {
                                if (!!ws && ws.id === id) {
                                    merge(ws, data);
                                    if (Array.isArray(data.cutOffEvents)) {
                                        ws.cutOffEvents = data.cutOffEvents;
                                    }
                                }
                            });
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    };
}

function getTeamMemberPaths(
    builder: EndpointBuilder<BaseQueryFn, TagType, "api">,
) {
    return {
        getAllTeamMembers: builder.query<User[], void>({
            query: () => ({
                url: `team-members`,
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) =>
                                  ({
                                      type: "TeamMembers",
                                      id,
                                  }) as const,
                          ),
                          { type: "TeamMembers", id: "LIST" },
                      ]
                    : [{ type: "TeamMembers", id: "LIST" }],
        }),
        addTeamMember: builder.mutation<
            paths["/team-members"]["post"]["responses"]["201"]["content"]["application/json"],
            paths["/team-members"]["post"]["requestBody"]["content"]["application/json"]
        >({
            query(data) {
                return {
                    url: `team-members`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: [{ type: "TeamMembers", id: "LIST" }],
        }),
        updateTeamMember: builder.mutation<
            paths["/team-members/{id}"]["patch"]["responses"]["200"]["content"]["application/json"],
            paths["/team-members/{id}"]["patch"]["requestBody"]["content"]["application/json"] &
                paths["/team-members/{id}"]["patch"]["parameters"]["path"]
        >({
            query({ id, ...data }) {
                return {
                    url: `team-members/${id}`,
                    method: "PATCH",
                    body: data,
                };
            },
            async onQueryStarted(
                { id, ...teamMember },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        "getAllTeamMembers",
                        undefined,
                        (draft) => {
                            draft.forEach((tm) => {
                                if (!!tm && tm.id === id) {
                                    merge(tm, teamMember);
                                }
                            });
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    };
}

function getSubscriptionPaths(
    builder: EndpointBuilder<BaseQueryFn, TagType, "api">,
) {
    return {
        getCheckoutSetupSession: builder.mutation<
            paths["/user/organization/subscription/checkout-session-setup"]["post"]["responses"]["200"]["content"]["application/json"],
            undefined
        >({
            query: () => ({
                url: `user/organization/subscription/checkout-session-setup`,
                method: "POST",
            }),
        }),
        getUpgradeSubscriptionPortalSession: builder.mutation<
            paths["/user/organization/subscription/customer-portal-session-upgrade"]["post"]["responses"]["200"]["content"]["application/json"],
            paths["/user/organization/subscription/customer-portal-session-upgrade"]["post"]["requestBody"]["content"]["application/json"]
        >({
            query: (data) => ({
                url: "user/organization/subscription/customer-portal-session-upgrade",
                method: "POST",
                body: data,
            }),
        }),
        getManageSubscriptionPortalSession: builder.mutation<
            paths["/user/organization/subscription/customer-portal-session-manage"]["post"]["responses"]["200"]["content"]["application/json"],
            undefined
        >({
            query: (data) => ({
                url: "user/organization/subscription/customer-portal-session-manage",
                method: "POST",
                body: data,
            }),
        }),
    };
}

function getWorkspaceIntegrationPaths(
    builder: EndpointBuilder<BaseQueryFn, TagType, "api">,
) {
    return {
        getWorkspaceIntegrations: builder.query<
            paths["/workspace-integrations"]["get"]["responses"]["200"]["content"]["application/json"],
            undefined
        >({
            query: () => ({
                url: `workspace-integrations`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) =>
                                  ({
                                      type: "WorkspaceIntegrations",
                                      id,
                                  }) as const,
                          ),
                          { type: "WorkspaceIntegrations", id: "LIST" },
                      ]
                    : [{ type: "WorkspaceIntegrations", id: "LIST" }],
        }),
        createWorkspaceIntegration: builder.mutation<
            paths["/workspace-integrations"]["post"]["responses"]["201"]["content"]["application/json"],
            paths["/workspace-integrations"]["post"]["requestBody"]["content"]["application/json"]
        >({
            query(data) {
                return {
                    url: `workspace-integrations`,
                    method: "POST",
                    body: data,
                };
            },
        }),
        deleteWorkspaceIntegration: builder.mutation<
            paths["/workspace-integrations/{id}"]["delete"]["responses"]["204"],
            paths["/workspace-integrations/{id}"]["delete"]["parameters"]["path"]
        >({
            query: ({ id }) => ({
                url: `workspace-integrations/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "WorkspaceIntegrations", id: "LIST" }],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        "getWorkspaceIntegrations",
                        undefined,
                        (draft) => {
                            const index = draft.findIndex((wi) => wi.id === id);
                            draft.splice(index, 1);
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getWorkspaceIntegrationSelectableAccounts: builder.mutation<
            paths["/workspace-integrations/{id}/selectable-accounts"]["get"]["responses"]["200"]["content"]["application/json"],
            paths["/workspace-integrations/{id}/selectable-accounts"]["get"]["parameters"]["path"]
        >({
            query: ({ id }) => ({
                url: `workspace-integrations/${id}/selectable-accounts`,
                method: "GET",
            }),
        }),
        updateWorkspaceIntegration: builder.mutation<
            paths["/workspace-integrations/{id}"]["patch"]["responses"]["200"]["content"]["application/json"],
            paths["/workspace-integrations/{id}"]["patch"]["requestBody"]["content"]["application/json"] &
                paths["/workspace-integrations/{id}"]["patch"]["parameters"]["path"]
        >({
            query: ({ id, ...data }) => ({
                url: `workspace-integrations/${id}`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: [{ type: "WorkspaceIntegrations", id: "LIST" }],
        }),
    };
}

type ReplaceFunnelProps<T> = Omit<
    T,
    | "steps"
    | "filters"
    | "reportType"
    | "attributionModel"
    | "drillDownDimensions"
> &
    FunnelConfig;

function getFunnelPaths(builder: EndpointBuilder<BaseQueryFn, TagType, "api">) {
    return {
        getFunnels: builder.query<FunnelConfig[], undefined>({
            query: () => ({
                url: `funnels`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ name }) =>
                                  ({
                                      type: "Funnels",
                                      name,
                                  }) as const,
                          ),
                          { type: "Funnels", id: "LIST" },
                      ]
                    : [{ type: "Funnels", id: "LIST" }],
        }),
        createFunnel: builder.mutation<
            ReplaceFunnelProps<
                paths["/funnels"]["post"]["responses"]["201"]["content"]["application/json"]
            >,
            ReplaceFunnelProps<
                Omit<
                    paths["/funnels"]["post"]["requestBody"]["content"]["application/json"],
                    "id"
                >
            >
        >({
            query: (data) => ({
                url: `funnels`,
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_data, { dispatch, queryFulfilled }) {
                try {
                    const res = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            "getFunnels",
                            undefined,
                            (draft) => {
                                draft.push(res.data);
                            },
                        ),
                    );
                } catch {
                    // nothing to do
                }
            },
        }),
        updateFunnel: builder.mutation<
            ReplaceFunnelProps<
                paths["/funnels/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]
            >,
            ReplaceFunnelProps<
                paths["/funnels/{id}"]["patch"]["requestBody"]["content"]["application/json"] &
                    paths["/funnels/{id}"]["patch"]["parameters"]["path"]
            >
        >({
            query: (data) => ({
                url: `funnels/${data.id}`,
                method: "PATCH",
                body: data,
            }),
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        "getFunnels",
                        undefined,
                        (draft) => {
                            const funnel = draft.find(
                                (funnel) => funnel.id === data.id,
                            );
                            assertNotUndefined(funnel);
                            Object.assign(funnel, data);
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteFunnel: builder.mutation<
            paths["/funnels/{id}"]["delete"]["responses"]["204"],
            paths["/funnels/{id}"]["delete"]["parameters"]["path"]
        >({
            query({ id }) {
                return {
                    url: `funnels/${id}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        "getFunnels",
                        undefined,
                        (draft) => {
                            const index = draft.findIndex(
                                (funnel) => funnel.id === id,
                            );
                            draft.splice(index, 1);
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
            invalidatesTags: [{ type: "Funnels", id: "LIST" }],
        }),
    };
}

export const {
    useGetUserQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useDismissDialogMutation,
    useGetWorkspacesQuery,
    useCreateWorkspaceMutation,
    useUpdateWorkspaceMutation,
    useGetAllTeamMembersQuery,
    useAddTeamMemberMutation,
    useUpdateTeamMemberMutation,
    useGetUpgradeSubscriptionPortalSessionMutation,
    useGetManageSubscriptionPortalSessionMutation,
    useGetCheckoutSetupSessionMutation,
    useGetWorkspaceIntegrationsQuery,
    useCreateWorkspaceIntegrationMutation,
    useUpdateWorkspaceIntegrationMutation,
    useDeleteWorkspaceIntegrationMutation,
    useGetWorkspaceIntegrationSelectableAccountsMutation,
    useGetFunnelsQuery,
    useUpdateFunnelMutation,
    useDeleteFunnelMutation,
    useCreateFunnelMutation,
} = api;
