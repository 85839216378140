import ReactConfetti from "react-confetti";
import { StarIcon } from "@heroicons/react/24/solid";
import Button from "../../common/Button";
import Link from "../../common/Link";
import { useUser } from "@/hooks/use-user.ts";
import { format } from "date-fns";

const PaymentMethodProvided = () => {
    const user = useUser();
    return (
        <>
            <div className={"flex h-full grow items-center justify-center "}>
                <ReactConfetti />
                <div className="max-w-[30rem] text-center">
                    <StarIcon className="mx-auto h-12 w-12 text-yellow-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Thanks!
                    </h3>
                    <div className="mt-1 text-sm text-gray-500">
                        You're all set!{" "}
                        {!!user.organization.trialEndsAt && (
                            <>
                                Your trial will automatically continue into a
                                subscription on{" "}
                                {format(
                                    new Date(user.organization.trialEndsAt),
                                    "MMM. d yyyy",
                                )}
                            </>
                        )}
                        .
                    </div>
                    <Link to={"/"}>
                        <Button className={"mt-8"} type={"primary"}>
                            Back to Spectacle
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default PaymentMethodProvided;
