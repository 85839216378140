import { useCallback, useContext, useEffect } from "react";
import useAnalyticsContext from "./analytics-context/useAnalyticsContext";
import { useLocation } from "react-router-dom";
import { User } from "@/model/User";
import { OrganizationModel } from "@/model/Organization";
import { components } from "@/types/generated/api-schema";
import TrackableEventFactory from "./trackableEventFactory";
import UserContext from "../../app/UserContext";
import { getCookie } from "@/util/cookie.ts";
type TrackableEventWrapper = components["schemas"]["TrackableEventWrapper"];
type Integration = components["schemas"]["Integration"];
type RoadmapFeature = components["schemas"]["RoadmapFeature"];
type UpgradeDialogOpenedEvent =
    components["schemas"]["UpgradeDialogOpenedEvent"];
type SetupGuideStep =
    components["schemas"]["SetupGuideStepCompletedEvent"]["step"];

let hasTrackedLogin = false;
const isProd = import.meta.env.PROD;

const useAnalytics = () => {
    const { analytics } = useAnalyticsContext();

    if (isProd) {
        const anonymousId = getCookie("sp__anon_id");
        if (anonymousId) {
            analytics?.setAnonymousId(anonymousId);
        }
    }

    const user = useContext(UserContext);

    const identifyUser = useCallback(
        (user: User) => {
            const nameParts = user.name.split(" ");
            const firstName = nameParts.shift();
            const lastName = nameParts.join(" ");
            analytics?.identify(user.id, {
                name: user.name,
                firstName,
                lastName,
                email: user.email,
            });
        },
        [analytics],
    );

    const usePageViews = () => {
        const location = useLocation();
        useEffect(() => {
            analytics?.page();
        }, [location.pathname]);
    };

    const trackEvent = (event: TrackableEventWrapper) => {
        analytics?.track(event.event, event.properties);
    };

    const page = () => {
        analytics?.page();
    };

    const trackErrorEncounteredEvent = (message: string) => {
        if (!user) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createErrorEncounteredEvent(
                { message },
                user.organization,
            ),
        );
    };

    const trackUpgradeDialogOpenedEvent = (
        opener: UpgradeDialogOpenedEvent["opener"],
    ) => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createUpgradeDialogOpenedEvent(
                { opener },
                user.organization,
            ),
        );
    };

    const trackSignOutEvent = () => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createUserSignedOutEvent(
                { user },
                user.organization,
            ),
        );
    };

    const trackUserUpgradedEvent = () => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createUserUpgradedEvent(
                { user },
                user.organization,
            ),
        );
    };

    const trackExpressedIntegrationInterestEvent = (
        integration: Integration,
    ) => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createExpressedIntegrationInterestEvent(
                { user, integration },
                user.organization,
            ),
        );
    };

    const trackSuggestedIntegrationEvent = (integration: string) => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createSuggestedIntegrationEvent(
                { user, integration },
                user.organization,
            ),
        );
    };

    const trackExpressedRoadmapFeatureInterestEvent = (
        roadmapFeature: RoadmapFeature,
    ) => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createExpressedRoadmapFeatureInterest(
                { user, roadmapFeature },
                user.organization,
            ),
        );
    };

    const trackSetupGuideOpenedEvent = () => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createSetupGuideOpenedEvent(
                {},
                user.organization,
            ),
        );
    };

    const trackSetupGuideStepCompletedEvent = (step: SetupGuideStep) => {
        if (!user?.organization) {
            return;
        }
        trackEvent(
            TrackableEventFactory.createSetupGuideStepCompletedEvent(
                { step },
                user.organization,
            ),
        );
    };

    const useIdentifiedUser = (
        userModel: User,
        organization?: OrganizationModel,
    ) => {
        useEffect(() => {
            if (!userModel || !organization || hasTrackedLogin) {
                return;
            }

            identifyUser(userModel);

            hasTrackedLogin = true;
        }, [organization, userModel]);
    };

    const reset = () => {
        hasTrackedLogin = false;
        analytics?.reset();
    };

    return {
        reset,
        identifyUser,
        page,
        usePageViews,
        useIdentifiedUser,
        trackErrorEncounteredEvent,
        trackSignOutEvent,
        trackUserUpgradedEvent,
        trackUpgradeDialogOpenedEvent,
        trackExpressedIntegrationInterestEvent,
        trackSuggestedIntegrationEvent,
        trackExpressedRoadmapFeatureInterestEvent,
        trackSetupGuideOpenedEvent,
        trackSetupGuideStepCompletedEvent,
    };
};

export default useAnalytics;
