import {
    FunnelConfig,
    FunnelStepMaybeNew,
    FunnelStepWithId,
    Predicate,
} from "@/features/Funnel/types.ts";
import { Container } from "@/common/Container";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import {
    EllipsisVerticalIcon,
    PlusIcon,
    TrashIcon,
    DocumentDuplicateIcon,
    XMarkIcon,
} from "@heroicons/react/24/solid";

import { produce } from "immer";
import { assertNotUndefined } from "@/util/typeguards.ts";

import ListBox from "@/common/ListBox";
import { useUiProperty } from "@/features/UiState.slice.ts";
import Button from "@/common/Button";
import {
    useCreateFunnelMutation,
    useUpdateFunnelMutation,
} from "@/services/apis/api.ts";
import isDeepEqual from "@/util/deep-equal.ts";
import Tooltip from "@/common/Tooltip";
import Loading from "@/common/Loading";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "@/catalyst/dropdown";
import { ListboxLabel, ListboxOption } from "@/catalyst/listbox";

import FunnelConfigPanelBlockSteps from "./FunnelConfigPanelBlockSteps";
import { nanoid } from "@reduxjs/toolkit";
import { generateFunnelName } from "@/features/Funnel/funnelNameGenerator.ts";
import InfoTooltip from "@/common/InfoTooltip";
import { emptyFunnelTitle } from "@/features/Funnel/Detail.tsx";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

const generateNewStep = (): FunnelStepMaybeNew => {
    return {
        name: "Select step",
        id: nanoid(),
        props: undefined,
        needsSetup: true,
    };
};

export default function FunnelConfigPanel({
    onChange,
    funnelConfig,
    funnels,
    needsFunnelSetup,
    onDelete,
    onDuplicate,
    onNewFunnel,
    onSelectFunnel,
}: {
    onChange: (config: FunnelConfig) => void;
    funnelConfig: FunnelConfig;
    funnels?: FunnelConfig[];
    needsFunnelSetup: boolean;
    onDelete: () => void;
    onDuplicate: () => void;
    onNewFunnel: () => void;
    onSelectFunnel: (id: string) => void;
}) {
    const selectedWorkspace = useSelectedWorkspace();
    const [selectedStep, setSelectedStep] = useState<number | undefined>(
        undefined,
    );
    const hasStepSelected = typeof selectedStep !== "undefined";

    const onPredicateChange = useCallback(
        (predicate: Predicate, index: number) => {
            if (!funnelConfig.steps[index]) {
                return;
            }

            const updatedFunnelConfig = produce(funnelConfig, (draft) => {
                assertNotUndefined(draft.steps[index]);
                assertNotUndefined(draft.steps[index].props);
                draft.steps[index].props.predicate = predicate;
            });
            onChange(updatedFunnelConfig);
        },
        [funnelConfig, onChange],
    );

    // Fill new steps with the number of steps under the minimum of 2

    useEffect(() => {
        if (funnelConfig.steps.length < 2) {
            const stepsToAdd = 2 - funnelConfig.steps.length;
            const updatedFunnelConfig = produce(funnelConfig, (draft) => {
                for (let i = 0; i < stepsToAdd; i++) {
                    draft.steps.push(generateNewStep());
                }
            });
            onChange(updatedFunnelConfig);
        }
    }, [funnelConfig, funnelConfig.steps.length, onChange]);

    const [isDarkMode] = useUiProperty<boolean>("darkMode");
    const persistedFunnel = funnels?.find(
        (funnel) => funnel.id === funnelConfig.id,
    );

    const [updateFunnel, { isLoading: isUpdatingFunnel }] =
        useUpdateFunnelMutation();

    const [saveNewFunnel, { isLoading: isSavingNewFunnel }] =
        useCreateFunnelMutation();

    const hasChanges = !isDeepEqual(funnelConfig, persistedFunnel);

    const funnelsLoading = typeof funnels === "undefined";
    const isPersisted = !!funnelConfig.id;
    const isDeletable = isPersisted;

    const steps: FunnelStepWithId[] = funnelConfig.steps.map((step) => {
        // This is quite hacky as funnelConfig.steps is not supposed to have ids
        if ((step as MakeOptional<FunnelStepWithId, "id">).id) {
            return step as FunnelStepWithId;
        }
        return { ...step, id: nanoid() } as FunnelStepWithId;
    });

    const options = (funnels || []).map((funnel) => ({
        value: funnel.id || "new",
        label: funnel.name,
    }));
    if (!isPersisted) {
        options.push({ value: "new", label: funnelConfig.name });
    }

    const hasCutoffStep =
        funnelConfig.cutOffStep !== null &&
        typeof funnelConfig.cutOffStep !== "undefined";
    const canAddCutoffStep = funnelConfig.steps.length > 0;

    return (
        <div
            className={"w-72 border-l border-zinc-950/10 dark:border-white/10"}
        >
            <Container xCompact={true}>
                <div className={"flex flex-col gap-8"}>
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-2 w-full"}>
                            <div className={"text-subtle text-xs"}>
                                Saved funnels
                            </div>
                            <div className={"flex gap-2 w-full"}>
                                <ListBox
                                    value={funnelConfig.id || "new"}
                                    buttonBold={isDarkMode}
                                    className={clsx(
                                        "w-3/5",
                                        !isPersisted &&
                                            "[&>span]:text-subtle [&>span]:italic",
                                    )}
                                    options={options}
                                    onChange={(value) => {
                                        if (value === "new-funnel") {
                                            return;
                                        }

                                        onSelectFunnel(value);
                                    }}
                                    extraOptions={
                                        <>
                                            <ListboxOption
                                                value="new-funnel"
                                                className="give-faux-dropdown-divider"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onNewFunnel();
                                                    // Programmatically close the listbox
                                                }}
                                            >
                                                <div className={"-ml-6"}>
                                                    <ListboxLabel className="flex items-center gap-1">
                                                        <PlusIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                        New funnel
                                                    </ListboxLabel>
                                                </div>
                                            </ListboxOption>
                                        </>
                                    }
                                />
                                <Tooltip
                                    content={
                                        needsFunnelSetup
                                            ? "Select two funnel steps to get started"
                                            : "No changes to save"
                                    }
                                    enabled={
                                        !(
                                            isSavingNewFunnel ||
                                            isUpdatingFunnel
                                        ) &&
                                        (!hasChanges || needsFunnelSetup)
                                    }
                                >
                                    <Button
                                        outline
                                        className={"w-16"}
                                        loading={
                                            isSavingNewFunnel ||
                                            isUpdatingFunnel
                                        }
                                        disabled={
                                            !hasChanges || needsFunnelSetup
                                        }
                                        onClick={async () => {
                                            if (
                                                persistedFunnel &&
                                                persistedFunnel.id
                                            ) {
                                                updateFunnel({
                                                    ...funnelConfig,
                                                    id: persistedFunnel.id,
                                                    name: persistedFunnel.name,
                                                });
                                            } else {
                                                const { data } =
                                                    await saveNewFunnel({
                                                        ...funnelConfig,
                                                        name:
                                                            funnelConfig.name ===
                                                            emptyFunnelTitle
                                                                ? generateFunnelName(
                                                                      funnelConfig.steps,
                                                                  )
                                                                : funnelConfig.name,
                                                    });
                                                assertNotUndefined(data);
                                                onChange(data);
                                            }
                                        }}
                                    >
                                        <span className={"truncate"}>Save</span>
                                    </Button>
                                </Tooltip>
                                <Dropdown>
                                    <DropdownButton as={"div"}>
                                        <div
                                            className={
                                                "h-full flex items-center justify-end text-subtle hover:text-default cursor-pointer w-5"
                                            }
                                        >
                                            <EllipsisVerticalIcon
                                                className={"h-5 w-5"}
                                            />
                                        </div>
                                    </DropdownButton>
                                    <DropdownMenu
                                        className="min-w-64"
                                        anchor="bottom end"
                                    >
                                        <DropdownItem onClick={onDuplicate}>
                                            <DocumentDuplicateIcon />
                                            <DropdownLabel>
                                                Duplicate
                                            </DropdownLabel>
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={onDelete}
                                            className={clsx(
                                                !isDeletable &&
                                                    "opacity-50 pointer-events-none",
                                            )}
                                        >
                                            <TrashIcon />
                                            <DropdownLabel>
                                                Delete
                                            </DropdownLabel>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div
                            className={
                                "border-t rounded-full border-zinc-950/10 dark:border-white/10"
                            }
                        />
                        <FunnelConfigPanelBlockSteps
                            funnelConfig={funnelConfig}
                            funnelsLoading={funnelsLoading}
                            selectedWorkspace={selectedWorkspace}
                            selectedStep={selectedStep}
                            setSelectedStep={setSelectedStep}
                            hasStepSelected={hasStepSelected}
                            onPredicateChange={onPredicateChange}
                            onChange={onChange}
                            steps={steps}
                            onNewClick={() => {
                                const updatedFunnelConfig = produce(
                                    funnelConfig,
                                    (draft) => {
                                        draft.steps.push(generateNewStep());
                                    },
                                );
                                onChange(updatedFunnelConfig);
                                setSelectedStep(funnelConfig.steps.length);
                            }}
                        />
                    </div>
                    <div className={"flex flex-col gap-2 w-full"}>
                        <div
                            className={
                                "flex flex-row items-center full-w justify-between text-subtle"
                            }
                        >
                            <h3 className={"text-xs flex items-center gap-1"}>
                                Cut-off step{" "}
                                <InfoTooltip>
                                    <>
                                        <div className={"font-semibold mb-1"}>
                                            Cut-off step
                                        </div>
                                        <div className={"mb-2"}>
                                            Only visits that happened before the
                                            selected step will be considered
                                            when attributing visitors to chosen
                                            report dimension (e.g. source,
                                            campaign, keyword, etc.).
                                        </div>
                                    </>
                                </InfoTooltip>
                            </h3>
                            {!hasCutoffStep && (
                                <Tooltip
                                    content={
                                        <div className={"flex flex-col gap-1"}>
                                            Select some step actions above
                                            first.
                                        </div>
                                    }
                                    enabled={!canAddCutoffStep}
                                >
                                    <PlusIcon
                                        className={clsx(
                                            "h-5 w-5 cursor-pointer",
                                            !canAddCutoffStep &&
                                                "opacity-50 pointer-events-none",
                                        )}
                                        onClick={() => {
                                            onChange({
                                                ...funnelConfig,
                                                cutOffStep:
                                                    funnelConfig.steps.length -
                                                    1,
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        {hasCutoffStep && (
                            <div
                                className={
                                    "flex flex-row items-center full-w justify-between gap-2"
                                }
                            >
                                <ListBox
                                    className={"w-full"}
                                    value={
                                        funnelConfig.cutOffStep?.toString() ||
                                        ""
                                    }
                                    onChange={(value) => {
                                        onChange({
                                            ...funnelConfig,
                                            cutOffStep: parseInt(value),
                                        });
                                    }}
                                    options={funnelConfig.steps.map((s, i) => {
                                        return {
                                            value: i.toString(),
                                            label: `${i + 1} ${s.name}`,
                                        };
                                    })}
                                />
                                <XMarkIcon
                                    className={
                                        "h-4 w-4 text-zinc-500 hover:text-zinc-300 cursor-pointer"
                                    }
                                    onClick={() => {
                                        onChange({
                                            ...funnelConfig,
                                            cutOffStep: undefined,
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/*<div*/}
                {/*    className={*/}
                {/*        "flex flex-row items-center full-w justify-between text-subtle cursor-pointer"*/}
                {/*    }*/}
                {/*>*/}
                {/*    <h3 className={"text-sm"}>Attribution filter</h3>*/}
                {/*    <PlusIcon className={"h-5 w-5"} />*/}
                {/*</div>*/}

                {funnelsLoading && <Loading />}
            </Container>
        </div>
    );
}
