import PlatformIcon from "../../../common/PlatformIcon";
import { components } from "@/types/generated/api-schema.ts";
type Integration = components["schemas"]["Integration"];

// @TODO id: should be Integration only
import { ReactNode } from "react";

interface IntegrationDetailsBase {
    hasAccountSelector: boolean;
    title: string;
    icon: ReactNode;
    description: string;
    detail: string;
    canConnect: boolean;
    type:
        | "ad_network"
        | "payment_provider"
        | "crm"
        | "call_tracking"
        | "coming_soon";
    comingSoon?: boolean;
    connectionMethod: "oath" | "api_key";
    apiKeyInstructions?: string;
}

interface PaymentProviderIntegrationDetails extends IntegrationDetailsBase {
    id: Integration;
    type: "payment_provider";
}

interface CrmIntegrationDetails extends IntegrationDetailsBase {
    id: Integration;
    type: "crm";
}

interface CallTrackingIntegrationDetails extends IntegrationDetailsBase {
    id: Integration;
    type: "call_tracking";
}

export type Integrations = Record<Integration | string, IntegrationDetails>;

// @See https://urlmacros.com/
export interface AdNetworkIntegrationDetails extends IntegrationDetailsBase {
    id: Integration;
    trackingParameters: {
        utm_id: string; // campaign id
        utm_campaign?: string; // campaign name
        utm_term?: string; // keyword
        utm_source?: string; //site name in case network serves multiple (fixed) sites, like meta ads
        sp_kwi?: string; // target id - The id of the keyword
        sp_pl?: string; // placement - The network passed referrer of the ad
        sp_agi: string; // adgroup id
        sp_ai: string; // ad id
        sp_n: Integration; // network
    };
    trackingTemplateInstructions: string;
    trackingTemplateInstructionsHelp?: ReactNode | string;
    type: "ad_network";
}

export type IntegrationDetails =
    | PaymentProviderIntegrationDetails
    | CrmIntegrationDetails
    | AdNetworkIntegrationDetails
    | CallTrackingIntegrationDetails;

export const integrations: Integrations = {
    stripe: {
        id: "stripe",
        hasAccountSelector: false,
        title: "Stripe",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"stripe"} />,
        description:
            "Sync Stripe data to track revenue and customer activity back to your campaigns",
        detail: "By connecting your Stripe account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        canConnect: true,
        type: "payment_provider",
        connectionMethod: "oath",
    },
    recurly: {
        id: "recurly",
        hasAccountSelector: false,
        title: "Recurly",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"recurly"} />,
        detail: "By connecting your Recurly account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        description:
            "Sync Recurly data to track revenue and customer activity back to your campaigns",
        canConnect: false,
        type: "payment_provider",
        connectionMethod: "oath",
        comingSoon: true,
    },
    lemon_squeezey: {
        id: "lemon_squeezey",
        hasAccountSelector: false,
        title: "Lemon Squeezy",
        icon: (
            <PlatformIcon className={"h-6 w-6"} platform={"lemon_squeezey"} />
        ),
        detail: "By connecting your Lemon Squeezy account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        description:
            "Sync Lemon Squeezy data to track revenue and customer activity back to your campaigns",
        canConnect: false,
        type: "payment_provider",
        connectionMethod: "oath",
        comingSoon: true,
    },
    salesforce: {
        id: "salesforce",
        hasAccountSelector: false,
        title: "Salesforce",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"salesforce"} />,
        detail: "By connecting your Salesforce account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        description:
            "Sync sales funnel data to track revenue and customer activity back to your campaigns",
        canConnect: false,
        type: "crm",
        connectionMethod: "oath",
        comingSoon: true,
    },
    hubspot: {
        id: "hubspot",
        hasAccountSelector: false,
        title: "HubSpot",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"hubspot"} />,
        detail: "By connecting your HubSpot account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        description:
            "Sync sales funnel data to track revenue and customer activity back to your campaigns",
        canConnect: false,
        type: "crm",
        connectionMethod: "oath",
        comingSoon: true,
    },
    callrail: {
        id: "callrail",
        hasAccountSelector: true,
        connectionMethod: "api_key",
        title: "CallRail",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"callrail"} />,
        detail: "By connecting your CallRail account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        description:
            "Sync offline touchpoints from CallRail to Spectacle to track revenue back to your campaigns",
        canConnect: true,
        type: "call_tracking",
        apiKeyInstructions:
            "In your CallRail account, go to Integrations → API Keys → Create API V3 Key. Copy the API Key and paste it here.",
    },
    google_ads: {
        id: "google_ads",
        hasAccountSelector: true,
        title: "Google Ads",
        detail: "By connecting your Google Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"google_ads"} />,
        description:
            "Import cost and performance data from Google Ads campaigns",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "In your Google Ads account, go to Admin → Account Settings → Tracking. In the Final Url Suffix field, enter the following:",
        trackingParameters: {
            utm_id: "{campaignid}",
            utm_term: "{keyword}",
            sp_pl: "{placement}",
            sp_agi: "{adgroupid}",
            sp_ai: "{creative}",
            sp_kwi: "{targetId}",
            sp_n: "google_ads",
        },
    },
    meta_ads: {
        id: "meta_ads",
        hasAccountSelector: true,
        title: "Meta Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"meta_ads"} />,
        description:
            "Import cost and performance data from Facebook and Instagram",
        detail: "By connecting your Meta Ads account, Spectacle will be able to track revenue and customer activity back to your Facebook and Instagram campaigns.",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "{{campaign.id}}",
            utm_campaign: "{{campaign.name}}",
            utm_source: "{{site_source_name}}", // fb: facebook, ig: instagram, msg: messenger, an: audience_network
            sp_pl: "{{placement}}",
            sp_ai: "{{ad.id}}",
            sp_agi: "{{adset.id}}",
            sp_n: "meta_ads",
        },
    },
    tiktok_ads: {
        id: "tiktok_ads",
        hasAccountSelector: true,
        detail: "By connecting your TikTok Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "TikTok Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"tiktok_ads"} />,
        description:
            "Import cost and performance data from Google Ads campaigns",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "__CAMPAIGN_ID__",
            utm_campaign: "__CAMPAIGN_NAME__",
            sp_pl: "__PLACEMENT__",
            sp_ai: "__CID__",
            sp_agi: "__AID__",
            sp_n: "tiktok_ads",
        },
    },
    linkedin_ads: {
        id: "linkedin_ads",
        hasAccountSelector: true,
        detail: "By connecting your Linkedin Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "Linkedin Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"linkedin_ads"} />,
        description:
            "Import cost and performance data from Linkedin Ads campaigns",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "{{CAMPAIGN_ID}}",
            utm_campaign: "{{CAMPAIGN_NAME}}",
            sp_ai: "{{CREATIVE_ID}}",
            sp_agi: "{{CAMPAIGN_GROUP_ID}}",
            sp_n: "linkedin_ads",
        },
        // {{ACCOUNT_ID}}
        // {{ACCOUNT_NAME}}
        // {{CAMPAIGN_GROUP_NAME}}
    },
    microsoft_ads: {
        id: "microsoft_ads",
        hasAccountSelector: true,
        detail: "By connecting your Microsoft Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "Microsoft Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"microsoft_ads"} />,
        description:
            "Import cost and performance data from Microsoft Ads campaigns",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "In your Microsoft Ads account, go to Campaigns → Settings → Account Level Options. In the Final Url Suffix field, enter the following:",
        trackingTemplateInstructionsHelp: (
            <>
                <span className={"font-semibold text-default"}>Important</span>:
                If you have Google Ads sync enabled, make sure to disable
                "Account level URL options" import. Go to import → click on
                "edit" next to the Google Ads import → Continue → Find the URL
                Tracking block → If it is enabled, expand it on the right and
                make sure to disable "Account level URL options"
            </>
        ),
        trackingParameters: {
            utm_id: "{CampaignId}",
            utm_campaign: "{Campaign}",
            sp_ai: "{AdId}",
            sp_agi: "{AdGroupId}",
            sp_kwi: "{TargetId}",
            utm_term: "{keyword:substituted_because_of_url_length_limit}", // @See https://help.ads.microsoft.com/apex/index/3/en-us/56799 (Note: You should provide a default string that the system will use if including the substitution value will cause the expanded string to exceed the string limit of the URL.)
            sp_n: "microsoft_ads",
            sp_pl: "{Network}",
        },
    },
    reddit_ads: {
        id: "reddit_ads",
        hasAccountSelector: true,
        detail: "By connecting your Reddit Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "Reddit Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"reddit_ads"} />,
        description:
            "Import cost and performance data from Reddit Ads campaigns",
        canConnect: true,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "{{CAMPAIGN_ID}}",
            sp_pl: "{{POST_ID}}",
            sp_agi: "{{ADGROUP_ID}}",
            sp_ai: "{{AD_ID}}",
            sp_n: "reddit_ads",
        },
    },
    pinterest_ads: {
        id: "pinterest_ads",
        hasAccountSelector: false,
        detail: "By connecting your Pinterest Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "Pinterest Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"pinterest_ads"} />,
        description:
            "Import cost and performance data from Pinterest Ads campaigns",
        canConnect: false,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "{campaignid}",
            utm_campaign: "{campaignname}",
            utm_term: "{keyword}",
            sp_kwi: "{keyword_id}",
            sp_agi: "{adgroupid}",
            sp_ai: "{adid}",
            sp_n: "pinterest_ads",
        },
    },
    snapchat_ads: {
        id: "snapchat_ads",
        hasAccountSelector: false,
        detail: "By connecting your Snapchat Ads account, Spectacle will be able to track revenue and customer activity back to your campaigns.",
        title: "Snapchat Ads",
        icon: <PlatformIcon className={"h-6 w-6"} platform={"snapchat_ads"} />,
        description:
            "Import cost and performance data from Snapchat Ads campaigns",
        canConnect: false,
        type: "ad_network",
        connectionMethod: "oath",
        trackingTemplateInstructions:
            "Add the following url parameters to the destination url for your ad:",
        trackingParameters: {
            utm_id: "{{campaign.id}}",
            utm_campaign: "{{campaign.name}}",
            sp_pl: "{{site_source_name}}",
            sp_agi: "{{adSet.id}}",
            sp_ai: "{{ad.id}}",
            sp_n: "snapchat_ads",
        },
    },
};
