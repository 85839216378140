import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

type UiState = { [id: string]: unknown };
const initialState: UiState = {};

const uiStateSlice = createSlice({
    name: "uiState",
    initialState,
    reducers: {
        setUiProperty: function <T>(
            state: UiState,
            action: PayloadAction<{ id: string; value: T }>,
        ) {
            state[action.payload.id] = action.payload.value;
        },
    },
});

export const { setUiProperty } = uiStateSlice.actions;
export function getUiProperty<T>(id: string) {
    return (state: { uiState: UiState }) => state.uiState[id] as T | undefined;
}

export function useUiProperty<T>(
    id: string,
    defaultValue?: T,
): [T | undefined, (value: T) => void] {
    const dispatch = useDispatch();

    let currentProp = useSelector(getUiProperty<T>(id));
    if (typeof currentProp === "undefined") {
        dispatch(
            setUiProperty({
                id,
                value: defaultValue,
            }),
        );
        currentProp = defaultValue;
    }

    return [
        currentProp,
        (value: T) =>
            dispatch(
                setUiProperty({
                    id,
                    value,
                }),
            ),
    ];
}

export default uiStateSlice.reducer;
