import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { MouseEventHandler, ReactNode } from "react";
import { assertNotUndefined } from "@/util/typeguards";

export default function Link({
    children,
    to,
    className,
    external = false,
    inNewTab = true,
    onClick,
}: {
    children: ReactNode;
    to?: string;
    onClick?: MouseEventHandler<HTMLSpanElement>;
    className?: string;
    external?: boolean;
    inNewTab?: boolean;
}) {
    if (onClick && external) {
        throw new Error("External links cannot have onClick handlers");
    }

    if (!to && !onClick) {
        throw new Error("Link must have either to or onClick prop");
    }

    if (onClick) {
        return (
            <span
                onClick={onClick}
                className={classNames("cursor-pointer", className)}
            >
                {children}
            </span>
        );
    }

    if (external) {
        return (
            <a
                href={to}
                target={inNewTab ? "_blank" : undefined}
                rel="noreferrer"
                className={classNames("cursor-pointer underline", className)}
            >
                {children}
                <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 inline-block" />
            </a>
        );
    }

    assertNotUndefined(to);

    return (
        <RouterLink to={to} className={classNames("cursor-pointer", className)}>
            {children}
        </RouterLink>
    );
}
