import {
    ColDef,
    ICellRendererParams,
    ValueGetterParams,
} from "ag-grid-community";
import Money from "@/common/Money";
import Result, { MoneyResult } from "@/common/Result";
import { NumberWithCompare } from "@/features/Reporting/NumberWithCompare.tsx";
import PercentageChangeFunnel from "@/common/PercentChangeFunnel";
import { safeDivide } from "@/util/math.ts";

export const reportingListColDefs = [
    {
        field: "source",
        sortingOrder: ["desc", "asc", null],
        hide: false,
        rowGroup: true, // Default row group
        filter: true,
        headerName: "Source",
    },
    {
        field: "campaign",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "keyword",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "adGroup",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "ad",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "campaignMedium",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "campaignContent",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "referrer",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "isPaid",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
        headerName: "Traffic Type",
        valueGetter: (params: ValueGetterParams) =>
            params.data?.isPaid ? "Paid" : "Organic",
    },
    {
        field: "sourceType",
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "visitCount",
        sortingOrder: ["desc", "asc", null],
        headerName: "Visits",
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) => {
            return params.data.visitCount
                ? parseInt(params.data.visitCount)
                : 0;
        },
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <NumberWithCompare
                    dimension={params.context.dimension}
                    value={params.value}
                    currentRowKey={params.node.key}
                    precision={0}
                    compareKey="visitCountCompare"
                    compareData={params.context.compareData}
                />
            );
        },
    },
    {
        field: "leads",
        sortingOrder: ["desc", "asc", null],
        headerName: "Leads",
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) =>
            parseFloat(params.data.leads[params.context.currency] || 0),
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;

            return (
                <NumberWithCompare
                    dimension={params.context.dimension}
                    value={data.leads}
                    currency={params.context.currency}
                    currentRowKey={params.node.key}
                    compareKey="leadsCompare"
                    compareData={params.context.compareData}
                />
            );
        },
    },
    {
        field: "customers",
        sortingOrder: ["desc", "asc", null],
        headerName: "Customers",
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) => {
            return parseFloat(
                params.data.customers[params.context.currency] || 0,
            );
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            return (
                <NumberWithCompare
                    dimension={params.context.dimension}
                    value={data.customers}
                    currentRowKey={params.node.key}
                    compareKey="customersCompare"
                    currency={params.context.currency}
                    compareData={params.context.compareData}
                />
            );
        },
    },
    {
        field: "conversion",
        sortingOrder: ["desc", "asc", null],
        headerName: "Lead to customer Conv.",
        aggFunc: "sum",
        minWidth: 100,
        comparator: (_va, _vb, nodeA, nodeB) => {
            const a = safeDivide(nodeA.aggData.customers, nodeA.aggData.leads);
            const b = safeDivide(nodeB.aggData.customers, nodeB.aggData.leads);
            if (a === b) return 0;
            return a > b ? 1 : -1;
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            const height = (params.node.rowHeight || 40) - 20;
            const width = (params.column?.getActualWidth() || 0) - (50 + 10);
            const conversionRate = safeDivide(data.customers, data.leads);
            return (
                <div className={"h-full align-middle"}>
                    <div className="w-[50px] mr-[10px] text-md inline-block">
                        {(conversionRate * 100).toFixed(2)}%
                    </div>
                    <PercentageChangeFunnel
                        className={"opacity-70 inline-block"}
                        width={width}
                        height={height}
                        from={data.leads}
                        to={data.customers}
                    />
                </div>
            );
        },
    },
    {
        field: "revenue",
        sortingOrder: ["desc", "asc", null],
        headerName: "Revenue",
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            return (
                <Money
                    amount={data.revenue}
                    currency={params.context.currency}
                />
            );
        },
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) => {
            return parseFloat(
                params.data.revenue[params.context.currency] || "0",
            );
        },
    },
    {
        field: "spend",
        sortingOrder: ["desc", "asc", null],
        headerName: "Spend",
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) => {
            return parseFloat(
                params.data.spend[params.context.currency] || "0",
            );
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            return (
                <NumberWithCompare
                    dimension={params.context.dimension}
                    value={data.spend}
                    currentRowKey={params.node.key}
                    compareKey="spendCompare"
                    inverted={true}
                    currency={params.context.currency}
                    compareData={params.context.compareData}
                    showCurrency={true}
                />
            );
        },
    },
    {
        field: "costPerConversion",
        sortingOrder: ["desc", "asc", null],
        headerName: "Cost per Lead",
        aggFunc: "sum",
        minWidth: 100,
        comparator: (_va, _vb, nodeA, nodeB) => {
            const a = getCostPerItem(nodeA.aggData.spend, nodeA.aggData.leads);
            const b = getCostPerItem(nodeB.aggData.spend, nodeB.aggData.leads);
            if (a === b) return 0;
            return a > b ? 1 : -1;
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;

            const result = getCostPerItem(
                data.spend[params.context.currency] || data.spend,
                data.leads[params.context.currency] || data.leads,
            );

            return (
                <Money
                    currency={
                        result !== Infinity
                            ? params.context.currency
                            : undefined
                    }
                    amount={result}
                    precision={2}
                />
            );
        },
    },
    {
        field: "costPerCustomer",
        sortingOrder: ["desc", "asc", null],
        headerName: "Cost per Customer",
        aggFunc: "sum",
        minWidth: 100,
        comparator: (_va, _vb, nodeA, nodeB) => {
            const a = getCostPerItem(
                nodeA.aggData.spend,
                nodeA.aggData.customers,
            );
            const b = getCostPerItem(
                nodeB.aggData.spend,
                nodeB.aggData.customers,
            );
            if (a === b) return 0;
            return a > b ? 1 : -1;
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;

            const result = getCostPerItem(
                data.spend[params.context.currency] || data.spend,
                data.customers[params.context.currency] || data.customers,
            );

            return (
                <Money
                    currency={
                        result !== Infinity
                            ? params.context.currency
                            : undefined
                    }
                    amount={result}
                    precision={2}
                />
            );
        },
    },
    {
        field: "profit",
        sortingOrder: ["desc", "asc", null],
        headerName: "Profit",
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            return (
                <MoneyResult
                    amount={data.profit}
                    currency={params.context.currency}
                />
            );
        },
        aggFunc: "sum",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams) => {
            return (
                parseFloat(
                    params.data?.revenue[params.context.currency] || "0",
                ) -
                parseFloat(params.data?.spend[params.context.currency] || "0")
            );
        },
    },
    {
        field: "roas",
        sortingOrder: ["desc", "asc", null],
        headerName: "ROAS",
        aggFunc: "sum",
        minWidth: 100,
        comparator: (_va, _vb, nodeA, nodeB) => {
            const a = getRoas(nodeA.aggData.revenue, nodeA.aggData.spend);
            const b = getRoas(nodeB.aggData.revenue, nodeB.aggData.spend);
            if (a === b) return 0;
            return a > b ? 1 : -1;
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;

            const number = getRoas(
                data.revenue[params.context.currency] || data.revenue,
                data.spend[params.context.currency] || data.spend,
            );

            return (
                <Result amount={number}>
                    <Money amount={number} precision={2} />
                </Result>
            );
        },
    },
] as ColDef[];

const getCostPerItem = (spend: number, items: number): number => {
    if (!spend) {
        return 0;
    }

    if (!items) {
        return spend > 0 ? Infinity : 0;
    }

    return spend / items;
};

const getRoas = (revenue: number, spend: number): number => {
    let number;
    if (!spend) {
        number = revenue ? Infinity : 0;
    } else {
        number = revenue / spend;
        // When revenue is less than spend, number becomes a fraction. We want to turn that into a negative number
        if (number < 1) {
            number = -(1 / number);
        }
    }

    return number;
};
