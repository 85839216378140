import React, { useState, useEffect, ReactNode } from "react";
import { Analytics } from "@segment/analytics-next";
import { AnalyticsContext } from "./context";
import { getAnalytics } from "../getAnalytics";

interface AnalyticsProviderProps {
    children: (analytics?: Analytics) => ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
    children,
}) => {
    const [analytics, setAnalytics] = useState<Analytics | undefined>(
        undefined,
    );

    const loadAnalytics = async () => {
        if (analytics) {
            return;
        }

        const response = await getAnalytics();
        setAnalytics(response);
    };

    useEffect(() => {
        loadAnalytics();
    }, []);

    return (
        <AnalyticsContext.Provider value={{ analytics }}>
            {children(analytics)}
        </AnalyticsContext.Provider>
    );
};
