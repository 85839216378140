export const getSnippet = (
    workspaceId: string,
    type: "javascript" | "segment",
    trackingProxy?: string,
): string => {
    switch (type) {
        case "javascript":
            return getJsSnippet(workspaceId, trackingProxy);
        case "segment":
            return getSegmentSnippet(workspaceId);
    }
};

const getJsSnippet = (workspaceId: string, trackingProxy?: string) => {
    const src = trackingProxy
        ? `https://${trackingProxy}/${workspaceId.at(-1)}.js` // Deterministic, yet random character to avoid detection by ad blockers
        : `https://${import.meta.env.VITE_TRACKING_PIXEL_BASE_URL}/" + s + ".js`;

    return `<script>
    !function(e,t,s){e[t]=e[t]||{qs:[],ws:"",m:["identify","track","page","group","reset"]};if(e[t].m){e[t].load=function(s,c){e[t].ws=s,e[t].lo=c};for(var c,n,a=0;a<e[t].m.length;a++)!function(s){e[t][s]=e[t][s]||function(){(e[t].qs[s]=e[t].qs[s]||[]).push(arguments)}}(e[t].m[a]);(c=document.createElement("script")).type="text/javascript",c.async=!0,c.src="${src}",(n=document.getElementsByTagName("script")[0]).parentNode.insertBefore(c,n)}
        spectacle.load(s);
        spectacle.page();
    }(window,"spectacle","${workspaceId}");
</script>`;
};

const getSegmentSnippet = (workspaceId: string) => {
    return `const BASE_URL = 'https://api.spectaclehq.com/tracking/';
const WORKSPACE_ID = "${workspaceId}";

/**
 * Handle track event
 * @param  {SegmentTrackEvent} event
 * @param  {FunctionSettings} settings
 */
async function onTrack(event, settings) {
	return await proxyEvent('track', event);
}

/**
 * Handle identify event
 * @param  {SegmentIdentifyEvent} event
 * @param  {FunctionSettings} settings
 */
async function onIdentify(event, settings) {
	return await proxyEvent('identify', event);
}

/**
 * Handle page event
 * @param  {SegmentPageEvent} event
 * @param  {FunctionSettings} settings
 */
async function onPage(event, settings) {
	return await proxyEvent('page', event);
}

/**
 * Handle group event
 * @param  {SegmentGroupEvent} event
 * @param  {FunctionSettings} settings
 */
async function onGroup(event, settings) {
	return await proxyEvent('group', event);
}

/**
 * Proxy event to Spectacle API
 * @param {SegmentEvent} event
 */
async function proxyEvent(path, event) {
	const endpoint = BASE_URL + path; // replace with your endpoint
	let response;

	event.workspaceId = WORKSPACE_ID;

	try {
		response = await fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(event)
		});
	} catch (error) {
		// Retry on connection error
		throw new RetryError(error.message);
	}

	if (response.status >= 500 || response.status === 429) {
		// Retry on 5xx (server errors) and 429s (rate limits)
		throw new RetryError(\`Failed with \${response.status}\`);
	}
}

/**
 * Handle screen event
 * @param  {SegmentScreenEvent} event
 * @param  {FunctionSettings} settings
 */
async function onScreen(event, settings) {
	throw new EventNotSupported('screen is not supported');
}

/**
 * Handle alias event
 * @param  {SegmentAliasEvent} event
 * @param  {FunctionSettings} settings
 */
async function onAlias(event, settings) {
	throw new EventNotSupported('alias is not supported');
}

/**
 * Handle delete event
 * @param  {SegmentDeleteEvent} event
 * @param  {FunctionSettings} settings
 */
async function onDelete(event, settings) {
	throw new EventNotSupported('delete is not supported');
}
`;
};
