export function safeDivide(a?: string | number, b?: string | number): number {
    const left = Number(a || "0");
    const right = Number(b || "0");

    if (left === 0 && right === 0) {
        return 0;
    }

    return left / right;
}

export const sum = (a: number, b: number) => a + b;
