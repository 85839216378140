export default function CallRailIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            viewBox="0 0 124 137"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <path d="m0 70.9v-.3c0-35.9 27.4-65.5 66.3-65.5 16-.3 31.4 4.5 43.8 14.3 2.9 2.1 4.3 5.6 4.3 8.7 0 5.9-5 10.8-11.4 10.8-2.5 0-4.6-.7-6.4-2.1-8.6-7.4-19.3-11.5-30.7-11.5-24.6 0-42.4 20.2-42.4 44.9v.3c0 24.7 17.8 44.9 42.4 44.9 11.8.3 23.5-4.2 32.1-12.2 1.8-1.7 4.3-2.4 6.8-2.4 5.7 0 10.3 4.5 10.7 10.5 0 3.1-1.4 5.9-3.6 8-12.5 11.5-29.2 17.4-46.7 17.1-37.4-.4-65.2-29.3-65.2-65.5z" />
        </svg>
    );
}
