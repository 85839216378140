import { useContext, useEffect } from "react";
import Button from "@/common/Button";
import Form from "@/common/Form";
import {
    useDeleteUserMutation,
    useUpdateUserMutation,
} from "@/services/apis/api.ts";
import UserContext from "@/app/UserContext.ts";
import useAnalytics from "@/services/analytics/useAnalytics.ts";
import WithPrompt from "@/common/WithPrompt";
import isEmptyObject from "@/util/is-empty-object.ts";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { assertNotUndefined } from "@/util/typeguards";
import Panel from "@/common/Panel/Panel.tsx";
import TimezonePicker from "@/common/TimezonePicker";
import { Field, FieldGroup, Fieldset, Label } from "@/catalyst/fieldset.tsx";
import { useSignOut } from "@/hooks/use-signout.ts";
import { Container } from "@/common/Container";

export default function AccountSettings() {
    return (
        <>
            <Container className={"space-y-8"}>
                <h2 className="text-3xl font-medium text-default leading-6">
                    Account settings
                </h2>
                <SectionProfile />
                <SectionDangerZone />
            </Container>
        </>
    );
}

const SectionProfile = () => {
    const user = useContext(UserContext);
    const [updateUser] = useUpdateUserMutation();

    assertNotUndefined(user);

    return (
        <Panel
            title={"Profile"}
            subtitle={"Manage personal data and preferences"}
        >
            <div className={"grid grid-cols-1 gap-6"}>
                <div className={"col-span-2"}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Timezone</Label>
                                <TimezonePicker
                                    selectedTimezone={user.timezone}
                                    onChange={(value) =>
                                        updateUser({
                                            timezone: value,
                                        })
                                    }
                                />
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </div>
            </div>
        </Panel>
    );
};

const SectionDangerZone = () => {
    const user = useContext(UserContext);
    const [deleteUser, { isLoading, isSuccess }] = useDeleteUserMutation();
    const analytics = useAnalytics();
    const { signOut } = useSignOut();

    // Logout user after deleting
    useEffect(() => {
        if (!isSuccess) {
            return;
        }

        analytics.trackSignOutEvent();
        signOut();
    }, [isSuccess, signOut, analytics]);

    assertNotUndefined(user);

    return (
        <Panel title={"Danger zone"} subtitle={"Delete account"}>
            <p className="mt-1 text-sm text-subtle mb-6">
                {user.isOrganizationOwner() && (
                    <>
                        You're the <strong>account owner</strong>. Deleting your
                        User Account will also delete your Organization and all
                        data
                    </>
                )}
                {!user.isOrganizationOwner() && (
                    <>
                        You're a <strong>team member</strong> on this account.
                        Deleting your User Account will only delete your User
                        Account. Your Organization and data analyytics will stay
                        untouched
                    </>
                )}
            </p>
            <Form onSubmit={deleteUser}>
                {({ errors, submitForm }) => (
                    <>
                        <WithPrompt
                            description={
                                <>
                                    This will delete your User Account
                                    {user?.isOrganizationOwner()
                                        ? ", your Organization and all your data"
                                        : ""}
                                    .
                                </>
                            }
                            submitForm={submitForm}
                        >
                            <Button
                                type="danger"
                                loading={isLoading}
                                nativeType={"submit"}
                                disabled={!isEmptyObject(errors)}
                                icon={ExclamationTriangleIcon}
                            >
                                Delete account
                            </Button>
                        </WithPrompt>
                    </>
                )}
            </Form>
        </Panel>
    );
};
