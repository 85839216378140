import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import {
    SetDimension,
    SetDrillDownDimensions,
} from "@/common/ReportTable/types.ts";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { clsx } from "clsx";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import {
    Checkbox,
    CheckboxField,
    CheckboxGroup,
} from "@/catalyst/checkbox.tsx";
import { Label } from "@/catalyst/fieldset.tsx";
import { useCallback, useEffect, useState } from "react";
import Button from "../Button";

export const reportingDimensions: Record<AttributionDimension, string> = {
    source: "Source",
    campaign: "Campaign",
    keyword: "Keyword",
    adGroup: "Ad Group",
    ad: "Ad",
    referrer: "Referrer",
    sourceType: "Source Type",
    // isPaid: "Paid/Organic",
    // placement: "Placement",
    campaignMedium: "UTM Medium",
    campaignContent: "UTM Content",
};

export default function ReportTypePicker({
    dimension,
    drillDownDimensions,
    setDimension,
    setDrillDownDimensions,
}: {
    dimension: AttributionDimension;
    setDimension: SetDimension;
    drillDownDimensions: AttributionDimension[];
    setDrillDownDimensions: SetDrillDownDimensions;
}) {
    const onDrillDownDimensionsChange = useCallback(
        (value: AttributionDimension, checked: boolean) => {
            if (checked) {
                setDrillDownDimensions([
                    ...new Set([...drillDownDimensions, value]),
                ]);
            } else {
                setDrillDownDimensions(
                    drillDownDimensions.filter((d) => d !== value),
                );
            }
        },
        [drillDownDimensions, setDrillDownDimensions],
    );

    useEffect(() => {
        if (!drillDownDimensions.includes(dimension)) {
            return;
        }

        onDrillDownDimensionsChange(dimension, false);
    }, [dimension, drillDownDimensions, onDrillDownDimensionsChange]);

    const [open, setOpen] = useState(false);

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            autoUpdatePosition={false}
            placement={"bottom-end"}
        >
            <PopoverTrigger asChild={true}>
                <Button
                    outline
                    onClick={() => setOpen(!open)}
                    className={clsx(
                        "text-default text-xs flex flex-row items-center cursor-pointer w-full !justify-start truncate",
                    )}
                >
                    {reportingDimensions[dimension]}
                    {drillDownDimensions.length
                        ? ` + ${drillDownDimensions.length}`
                        : ""}
                </Button>
            </PopoverTrigger>

            <PopoverContent className={"!min-w-[30rem]"}>
                <div className={"px-6 py-4 min-w-[30rem]"}>
                    <div className={"flex flex-row"}>
                        <div className={"flex flex-col text-md w-1/2"}>
                            <h3 className={"font-semibold pb-1 text-base"}>
                                Report type
                            </h3>
                            <RadioGroup
                                value={dimension}
                                onChange={(value) => {
                                    setDimension(value);
                                }}
                                className={clsx(
                                    "relative w-full flex flex-col -ml-5",
                                    // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
                                )}
                            >
                                {Object.entries(reportingDimensions).map(
                                    ([key, name]) => (
                                        <Radio
                                            key={`report-type-picker-radio-${key}`}
                                            value={key}
                                            className={clsx(
                                                "flex mt-1.5 text-left items-center cursor-pointer group",
                                                // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                                                "focus:outline-none",
                                                "rounded-lg",
                                                // Focus ring
                                                "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent",
                                                // Disabled state
                                                "data-[disabled]:opacity-50 before:data-[disabled]:bg-zinc-950/5 before:data-[disabled]:shadow-none",
                                            )}
                                        >
                                            {({ checked }) => (
                                                <div
                                                    className={
                                                        "flex items-center"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            "w-4 h-4 mr-1"
                                                        }
                                                    >
                                                        <CheckIcon
                                                            className={clsx(
                                                                "h-4 w-4 text-default group-hover:block",
                                                                checked
                                                                    ? "block"
                                                                    : "hidden",
                                                            )}
                                                        />
                                                    </div>
                                                    <span>{name}</span>
                                                </div>
                                            )}
                                        </Radio>
                                    ),
                                )}
                            </RadioGroup>
                        </div>
                        <div
                            className={
                                "flex flex-col text-md  border-l border-zinc-950/10 dark:border-white/10 pl-5"
                            }
                        >
                            <h3 className={"font-semibold pb-1 text-base"}>
                                Drill-down
                            </h3>
                            <CheckboxGroup>
                                {Object.entries(reportingDimensions).map(
                                    ([key, name]) => (
                                        <CheckboxField
                                            key={`report-type-picker-check-${key}`}
                                            className={
                                                "!mt-1 !gap-x-2 cursor-pointer"
                                            }
                                            disabled={dimension === key}
                                        >
                                            <Checkbox
                                                name={key}
                                                value={key}
                                                checked={drillDownDimensions.includes(
                                                    key as AttributionDimension,
                                                )}
                                                onChange={(checked) =>
                                                    onDrillDownDimensionsChange(
                                                        key as AttributionDimension,
                                                        checked,
                                                    )
                                                }
                                                className={"cursor-pointer"}
                                            />
                                            <Label className={"cursor-pointer"}>
                                                {name}
                                                {drillDownDimensions.includes(
                                                    key as AttributionDimension,
                                                )
                                                    ? ` (${drillDownDimensions.indexOf(key as AttributionDimension) + 1})`
                                                    : ""}
                                            </Label>
                                        </CheckboxField>
                                    ),
                                )}
                            </CheckboxGroup>
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
}
