import React, { MouseEventHandler, ReactElement, ReactNode } from "react";
import Button from "../Button";
import Modal, { ModalActions } from "../Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { default as Form, FieldProps } from "@/common/Form";
import * as yup from "yup";
import { createRoot } from "react-dom/client";
import { Schema } from "yup";
import isEmptyObject from "@/util/is-empty-object.ts";
import { useSelector } from "react-redux";
import { getUiProperty } from "@/features/UiState.slice.ts";

type FormFields = (FieldProps & { validate?: Schema })[];

async function askForConfirmation(
    title: string,
    description: ReactNode,
    darkMode: boolean,
): Promise<boolean> {
    const container = document.createElement("div");
    document.body.append(container);

    const root = createRoot(container);

    const cleanup = () => {
        root.unmount();
        container.remove();
    };

    return new Promise((doResolve) => {
        const resolve = (confirmed: boolean) => {
            doResolve(confirmed);
            cleanup();
        };

        root.render(
            <Modal
                open={true}
                onClose={() => resolve(false)}
                title={title}
                description={description}
                darkMode={darkMode}
            >
                <ModalActions>
                    <Button outline onClick={() => resolve(false)}>
                        Cancel
                    </Button>
                    <Button
                        type="danger"
                        className={"ml-5"}
                        onClick={() => resolve(true)}
                    >
                        <ExclamationTriangleIcon className="h-5 w-5" />
                        Confirm
                    </Button>
                </ModalActions>
            </Modal>,
        );
    });
}

async function presentForm(
    title: string,
    description: ReactNode,
    formFields: FormFields,
): Promise<Record<string, string> | undefined> {
    const container = document.createElement("div");
    document.body.append(container);

    const root = createRoot(container);

    const cleanup = () => {
        root.unmount();
        container.remove();
    };

    const validationSchema: Record<string, Schema> = formFields.reduce(
        (carry, item) => {
            if (item.validate) {
                carry[item.name] = item.validate;
            }

            return carry;
        },
        {} as Record<string, Schema>,
    );

    const initialValues: Record<string, string> = formFields.reduce(
        (carry, item) => {
            carry[item.name] = "";

            return carry;
        },
        {} as Record<string, string>,
    );

    return new Promise((doResolve) => {
        const resolve = (result: Record<string, string> | undefined) => {
            doResolve(result);
            cleanup();
        };

        root.render(
            <Modal
                open={true}
                onClose={() => resolve(undefined)}
                title={title}
                description={description}
            >
                <Form
                    onSubmit={resolve}
                    validationSchema={
                        !isEmptyObject(validationSchema)
                            ? yup.object(validationSchema)
                            : undefined
                    }
                    initialValues={initialValues}
                >
                    {({ errors, touched }) => (
                        <>
                            {formFields.map((fieldProps) => (
                                <Form.Field
                                    {...fieldProps}
                                    key={fieldProps.name}
                                    error={errors[fieldProps.name] as string}
                                    touched={
                                        touched[fieldProps.name] as boolean
                                    }
                                />
                            ))}
                            <ModalActions>
                                <Button
                                    outline
                                    onClick={() => resolve(undefined)}
                                    className={"mr-5"}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    nativeType={"submit"}
                                    disabled={!isEmptyObject(errors)}
                                >
                                    Create
                                </Button>
                            </ModalActions>
                        </>
                    )}
                </Form>
            </Modal>,
        );
    });
}

const WithPrompt = ({
    children,
    submitForm,
    enabled = true,
    title = "Are you sure?",
    description = "Please confirm or cancel below",
    type = "confirmation",
    prop = "onClick",
    formFields,
}: {
    enabled?: boolean;
    children: ReactElement<any> & {
        props: {
            onClick?: (
                arg: MouseEventHandler,
                value?: Record<string, string>,
            ) => void;
            nativeType?: "submit";
        };
    };
    type?: "confirmation" | "form";
    prop?: string;
    submitForm?: () => Promise<void>;
    title?: string;
    description?: ReactNode;
    formFields?: FormFields;
}): ReactNode => {
    const darkMode = useSelector(getUiProperty("darkMode")) || false;

    if (!enabled) {
        return children;
    }

    const handler = async (
        e: React.DragEvent<HTMLDivElement>,
        droppedItemId: string,
        droppedType: string,
    ) => {
        if (typeof e.preventDefault !== "undefined") {
            e.preventDefault();
        }

        let value = undefined;
        if (type === "confirmation") {
            const shouldSubmit = await askForConfirmation(
                title,
                description,
                darkMode as boolean,
            );
            if (!shouldSubmit) {
                return;
            }
        } else if (type === "form") {
            if (!formFields) {
                throw new Error("Missing formFields");
            }

            value = await presentForm(title, description, formFields);
            if (typeof value === "undefined") {
                return;
            }
        }

        const childProps = children.props;

        if (childProps[prop]) {
            childProps[prop](e, droppedItemId, droppedType, value);
        }

        if (childProps.nativeType === "submit") {
            if (!submitForm) {
                throw new Error(
                    "Can't submit form without submitForm prop passed",
                );
            }

            submitForm();
        }
    };

    return React.cloneElement(children, {
        [prop]: handler,
    });
};

export default WithPrompt;
