import { clsx } from "clsx";
import { Area, AreaChart, CartesianGrid } from "recharts";
import { ChartConfig, ChartContainer } from "@/common/ui/chart.tsx";

const chartConfig = {
    skeleton: {
        label: "",
        color: "var(--chart-skeleton)",
    },
} satisfies ChartConfig;

export default function ChartSkeleton({ className }: { className?: string }) {
    return (
        <ChartContainer
            config={chartConfig}
            className={clsx("aspect-auto w-full animate-pulse", className)}
        >
            <AreaChart
                data={[
                    {
                        tick: "1",
                        value: 100,
                    },
                    {
                        tick: "2",
                        value: 150,
                    },
                    {
                        tick: "3",
                        value: 190,
                    },
                    {
                        tick: "4",
                        value: 260,
                    },
                ]}
            >
                <defs>
                    <linearGradient
                        id="fillSkeleton"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="var(--color-skeleton)"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="95%"
                            stopColor="var(--color-skeleton)"
                            stopOpacity={0.1}
                        />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} />

                <Area
                    dataKey="value"
                    type="natural"
                    fill="url(#fillSkeleton)"
                    stroke="var(--color-skeleton)"
                    stackId="a"
                    isAnimationActive={false}
                />
            </AreaChart>
        </ChartContainer>
    );
}
