const isEmptyObject = (maybeObject: Record<string, unknown>): boolean => {
    let name;
    for (name in maybeObject) {
        if (Object.hasOwn(maybeObject, name)) {
            return false;
        }
    }
    return true;
};

export default isEmptyObject;
