import { Field } from "@/catalyst/fieldset";
import { Input } from "@/catalyst/input.tsx";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { assertIsString } from "@/util/typeguards";
import Datepicker from "react-tailwindcss-datepicker";
import { useUiProperty } from "@/features/UiState.slice.ts";
import {
    endOfWeek,
    format,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
} from "date-fns";
import { clsx } from "clsx";
import { getCompareDateRange } from "@/common/DateRangePicker/util.ts";
import { getWeekStartIndex } from "@/util/locale.ts";

export type DateRange = {
    from: Date;
    to: Date;
};

const weekStartIndex = getWeekStartIndex();

export default function DateRangePicker({
    value,
    onChange,
    minDate,
}: {
    value: DateRange;
    onChange: (value: DateRange, compareDateRange: DateRange) => void;
    minDate?: Date;
}) {
    const [isDarkMode] = useUiProperty<boolean>("darkMode");
    return (
        <Popover>
            {({ open }) => (
                <>
                    <PopoverButton>
                        <Field>
                            <Input
                                readOnly={true}
                                className={clsx(
                                    "cursor-pointer",
                                    isDarkMode && "font-semibold",
                                )}
                                value={`${value?.from.toLocaleDateString()} - ${value?.to.toLocaleDateString()}`}
                            />
                        </Field>
                    </PopoverButton>
                    <AnimatePresence>
                        {open && (
                            <PopoverPanel
                                static
                                as={motion.div}
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.95 }}
                                anchor="bottom"
                                className={clsx(
                                    isDarkMode ? "dark" : "",
                                    "mt-4",
                                    // "flex origin-top flex-col",
                                    "[--anchor-offset:-1.625rem] [--anchor-padding:theme(spacing.4)] sm:[--anchor-offset:-1.375rem]",
                                    // Base styles
                                    "isolate w-max min-w-[calc(var(--button-width)+1.75rem)] select-none rounded-xl",
                                    // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
                                    "outline outline-1 outline-transparent focus:outline-none",
                                    // Handle scrolling when menu won't fit in viewport
                                    "overflow-y-scroll overscroll-contain",
                                    // Popover background
                                    "bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75",
                                    // Shadows
                                    "shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10",
                                )}
                            >
                                {/* @ts-expect-error - Shows an error but works */}
                                <Datepicker
                                    startWeekOn={
                                        weekStartIndex === 0 ? "sun" : "mon"
                                    }
                                    inline={true}
                                    value={{
                                        startDate: value.from,
                                        endDate: value.to,
                                    }}
                                    pickerClassName={
                                        "px-1 py-0.5 bg-white dark:bg-white/5 text-default rounded-xl w-fit"
                                    }
                                    minDate={minDate}
                                    configs={{
                                        shortcuts: {
                                            today: "Today",
                                            yesterday: "Yesterday",
                                            thisWeek: {
                                                text: "This week",
                                                period: {
                                                    start: format(
                                                        startOfWeek(
                                                            new Date(),
                                                            {
                                                                weekStartsOn:
                                                                    weekStartIndex,
                                                            },
                                                        ),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        endOfWeek(new Date(), {
                                                            weekStartsOn:
                                                                weekStartIndex,
                                                        }),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            lastWeek: {
                                                text: "Last week",
                                                period: {
                                                    start: format(
                                                        startOfWeek(
                                                            subDays(
                                                                new Date(),
                                                                7,
                                                            ),
                                                            {
                                                                weekStartsOn:
                                                                    weekStartIndex,
                                                            },
                                                        ),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        endOfWeek(
                                                            subDays(
                                                                new Date(),
                                                                7,
                                                            ),
                                                            {
                                                                weekStartsOn:
                                                                    weekStartIndex,
                                                            },
                                                        ),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            currentMonth: "This month",
                                            pastMonth: "Last month",
                                            yearToDate: {
                                                text: "Year to date",
                                                period: {
                                                    start: format(
                                                        startOfYear(new Date()),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        new Date(),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            past7Days: {
                                                text: "Past 7 days",
                                                period: {
                                                    start: format(
                                                        subDays(new Date(), 7),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        subDays(new Date(), 1),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            past14Days: {
                                                text: "Past 14 days",
                                                period: {
                                                    start: format(
                                                        subDays(new Date(), 14),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        subDays(new Date(), 1),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            past30Days: {
                                                text: "Past 30 days",
                                                period: {
                                                    start: format(
                                                        subDays(new Date(), 30),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        subDays(new Date(), 1),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                            past12Months: {
                                                text: "Past 12 months",
                                                period: {
                                                    start: format(
                                                        subMonths(
                                                            new Date(),
                                                            12,
                                                        ),
                                                        "yyyy-MM-dd",
                                                    ),
                                                    end: format(
                                                        subDays(new Date(), 1),
                                                        "yyyy-MM-dd",
                                                    ),
                                                },
                                            },
                                        },
                                    }}
                                    onChange={(newRange) => {
                                        if (!newRange) {
                                            return;
                                        }
                                        assertIsString(newRange.startDate);
                                        assertIsString(newRange.endDate);

                                        const compare = getCompareDateRange({
                                            from: newRange.startDate,
                                            to: newRange.endDate,
                                        });

                                        onChange(
                                            {
                                                from: new Date(
                                                    newRange.startDate,
                                                ),
                                                to: new Date(newRange.endDate),
                                            },
                                            {
                                                from: new Date(compare.from),
                                                to: new Date(compare.to),
                                            },
                                        );
                                    }}
                                    readOnly={true}
                                    primaryColor={"zinc"}
                                    showShortcuts={true}
                                />
                            </PopoverPanel>
                        )}
                    </AnimatePresence>
                </>
            )}
        </Popover>
    );
}
