import ErrorBase from "./ErrorBase";

import type { JSX } from "react";

const UncaughtError = (): JSX.Element => {
    return (
        <ErrorBase
            code={"Oops"}
            title={"It's not you, it's us"}
            description={
                "Something went wrong, please try again. The team has been notified. If the problem persists, please contact support"
            }
        />
    );
};

export default UncaughtError;
