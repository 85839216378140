/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from "@headlessui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export type LinkProps = React.ComponentPropsWithoutRef<"a"> &
    (
        | {
              href: string;
          }
        | { to: string }
    );

export const Link = React.forwardRef(function Link(
    props: LinkProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
) {
    return (
        <Headless.DataInteractive>
            {"href" in props ? (
                <a {...props} ref={ref} />
            ) : (
                <RouterLink {...props} ref={ref} />
            )}
        </Headless.DataInteractive>
    );
});
