import { Transition } from "@headlessui/react";
import { Toaster, resolveValue } from "react-hot-toast";
import { Fragment, ReactNode } from "react";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
export { toast } from "react-hot-toast";

const Toast = (): ReactNode => (
    <Toaster position="top-right">
        {(t) => (
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={t.visible}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {t.type === "success" && (
                                            <CheckCircleIcon
                                                className="h-6 w-6 text-green-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {t.type === "error" && (
                                            <ExclamationCircleIcon
                                                className="h-6 w-6 text-red-600"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-default">
                                            {/*{resolveValue(t.title, t)}*/}
                                            {t.type === "error" && "Oh no!"}
                                            {t.type === "success" && "Success!"}
                                        </p>
                                        <p className="mt-1 text-sm text-subtle">
                                            {resolveValue(t.message, t)}
                                        </p>
                                    </div>
                                    {/*<div className="ml-4 flex flex-shrink-0">*/}
                                    {/*    <button*/}
                                    {/*        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                                    {/*        onClick={() => {*/}
                                    {/*            //setShow(false);*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <span className="sr-only">*/}
                                    {/*            Close*/}
                                    {/*        </span>*/}
                                    {/*        <XIcon*/}
                                    {/*            className="h-5 w-5"*/}
                                    {/*            aria-hidden="true"*/}
                                    {/*        />*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        )}
    </Toaster>
);

export default Toast;
