import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { ReportResponse } from "@/types/generated/ReportResponse.ts";
import { ReportRequest } from "@/types/generated/ReportRequest";
import { ContactsResponse } from "@/types/generated/ContactsResponse.ts";
import { ContactsRequest } from "@/types/generated/ContactsRequest";
import { ContactResponse } from "@/types/generated/ContactResponse.ts";
import { ContactRequest } from "@/types/generated/ContactRequest.ts";
import { WorkspaceMetaRequest } from "@/types/generated/WorkspaceMetaRequest.ts";
import { WorkspaceMetaResponse } from "@/types/generated/WorkspaceMetaResponse.ts";
import { fetchAuthSession } from "aws-amplify/auth";
import { RootState } from "@/app/store.ts";
import { getSelectedWorkspace } from "@/features/Workspace/Workspace.slice.ts";
import { DashboardWidgetsResponse } from "@/types/generated/DashboardWidgetsResponse.ts";
import { WidgetsRequest } from "@/types/generated/WidgetsRequest.ts";
import { FunnelResponse } from "@/types/generated/FunnelResponse.ts";
import { FunnelRequest } from "@/types/generated/FunnelRequest.ts";
import { toast } from "react-hot-toast";

const baseQueryFactory = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers, { getState }) => {
            const { tokens } = await fetchAuthSession();

            if (!tokens?.accessToken) {
                throw new Error("No access token");
            }

            const state = getState() as RootState;
            const workspaceId = getSelectedWorkspace(state)?.id;
            if (workspaceId) {
                headers.set("Spectacle-Workspace-Id", workspaceId);
            }
            headers.set("authorization", `Bearer ${tokens.accessToken}`);
            headers.set("Content-Type", "application/json");

            return headers;
        },
    });

function getBaseQuery(
    baseUrl: string,
): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> {
    const baseQuery = baseQueryFactory(baseUrl);
    return async (args, api, extraOptions) => {
        const result = await baseQuery(args, api, extraOptions);

        if (result.error) {
            const data = result.data as { message?: string } | undefined;

            const message =
                data?.message ||
                "An unknown error occurred. Please try again. If the problem persists, please contact support";
            toast.error(message);

            return result;
        }

        return result;
    };
}

// Define a service using a base URL and expected endpoints
export const dataApi = createApi({
    reducerPath: "dataApi",
    baseQuery: getBaseQuery(import.meta.env.VITE_DATA_API_URL),
    endpoints: (builder) => ({
        getReport: builder.query<ReportResponse, ReportRequest>({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetReport: request } },
            }),
        }),
        getContacts: builder.query<ContactsResponse, ContactsRequest>({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetContacts: request } },
            }),
        }),
        getContact: builder.query<ContactResponse, ContactRequest>({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetContact: request } },
            }),
        }),
        getWorkspaceMeta: builder.query<
            WorkspaceMetaResponse,
            WorkspaceMetaRequest
        >({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetWorkspaceMeta: request } },
            }),
        }),
        getDashboardWidgets: builder.query<
            DashboardWidgetsResponse,
            WidgetsRequest
        >({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetWidgets: request } },
            }),
        }),
        getFunnel: builder.query<FunnelResponse, FunnelRequest>({
            query: (request) => ({
                url: `operation`,
                method: "POST",
                body: { operation: { GetFunnel: request } },
            }),
        }),
    }),
});

export const {
    useGetReportQuery,
    useGetContactsQuery,
    useGetContactQuery,
    useGetWorkspaceMetaQuery,
    useGetDashboardWidgetsQuery,
    useGetFunnelQuery,
} = dataApi;
