import { useAuthenticator } from "@aws-amplify/ui-react";
import useAnalytics from "@/services/analytics/useAnalytics.ts";

export function useSignOut() {
    const { signOut } = useAuthenticator();
    const analytics = useAnalytics();
    return {
        signOut: () => {
            // Somehow signout doesn't clear local storage in production
            clearCognitoLocalStorage();

            signOut();

            // Reset analytics
            analytics.reset();

            window.location.href = "/login";
        },
    };
}

function clearCognitoLocalStorage() {
    // Get all keys in localStorage
    const keys = Object.keys(localStorage);

    // Filter for keys that start with the Cognito prefix
    const cognitoKeys = keys.filter((key) =>
        key.startsWith("CognitoIdentityServiceProvider"),
    );

    // Remove each key
    cognitoKeys.forEach((key) => {
        localStorage.removeItem(key);
    });
}
