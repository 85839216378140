import {
    Form as FormikForm,
    Formik,
    FormikValues,
    Field as FormikField,
    FormikErrors,
    FormikTouched,
    useFormikContext,
    FieldProps as FormikFieldProps,
} from "formik";
import React, { KeyboardEventHandler, ReactNode } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

const Form = (props: {
    initialValues?: FormikValues;
    validate?: (values: FormikValues) => Record<string, string>;
    onSubmit: (
        values: FormikValues,
        actions: { setSubmitting: (isSubmitting: boolean) => void },
    ) => void;
    children: (renderProps: {
        errors: FormikErrors<FormikValues>;
        touched: FormikTouched<FormikValues>;
        values: FormikValues;
        submitForm: () => Promise<void>;
    }) => ReactNode;
    validationSchema?: unknown;
}): ReactNode => {
    return (
        <Formik
            initialValues={props.initialValues || {}}
            validate={props.validate}
            onSubmit={props.onSubmit}
            validationSchema={props.validationSchema}
        >
            {({ errors, touched, submitForm, values }) => (
                <FormikForm>
                    {props.children({
                        errors,
                        touched,
                        submitForm,
                        values,
                    })}
                </FormikForm>
            )}
        </Formik>
    );
};

export type FieldProps = {
    name: string;
    label: string;
    type?: "email" | "text" | "hidden";
    placeholder?: string;
    description?: ReactNode;
    as?: "textarea" | React.ComponentType<FormikFieldProps["field"]>;
    error?: string;
    touched?: boolean;
    autoFocus?: boolean;
    autoComplete?: "off";
    id?: string;
};

const Field = ({
    id,
    name,
    as,
    label,
    placeholder,
    description,
    type = "text",
    error,
    touched,
    autoFocus = false,
    autoComplete,
}: FieldProps): ReactNode => {
    const { submitForm } = useFormikContext();
    const onKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
            submitForm();
        }
    };
    const isTextArea = as === "textarea";

    return (
        <div
            key={name}
            className={classNames(
                "mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6",
                { hidden: type === "hidden" },
            )}
        >
            <div className="sm:col-span-6">
                <label
                    htmlFor={name}
                    className="block text-sm font-medium text-default"
                >
                    {label}
                </label>

                <div className="relative mt-1">
                    <FormikField
                        key={name}
                        name={name}
                        id={id || name}
                        as={as}
                        type={type}
                        autoFocus={autoFocus}
                        onKeyDown={onKeyDown}
                        autoComplete={autoComplete}
                        className={classNames(
                            "block w-full rounded-md border border-gray-300 shadow-sm shadow-sm sm:text-sm",
                            {
                                ["border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"]:
                                    touched && !!error,
                                ["focus:border-indigo-500 focus:ring-indigo-500 "]:
                                    !touched || !error,
                            },
                        )}
                        style={{ minHeight: isTextArea ? "200px" : undefined }}
                        placeholder={placeholder}
                        aria-describedby={`${name}-description`}
                    />
                    {!!error && touched && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                                className={"h-5 w-5 text-red-500"}
                            />
                        </div>
                    )}
                </div>
                {touched && !!error && (
                    <p className="mt-2 text-sm text-red-600">{error}</p>
                )}
                {description && (
                    <p
                        className="mt-2 text-sm text-subtle"
                        id={`${name}-description`}
                    >
                        {description}
                    </p>
                )}
            </div>
        </div>
    );
};

Form.Field = Field;

export default Form;
