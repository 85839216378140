import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import Tooltip from "@/common/Tooltip";
import { ReactNode } from "react";

export default function InfoTooltip({ children }: { children: ReactNode }) {
    return (
        <Tooltip content={<div className={"p-2"}>{children}</div>}>
            <QuestionMarkCircleIcon
                className={"ml-1 h-4 w-4 text-subtle cursor-pointer"}
            />
        </Tooltip>
    );
}
