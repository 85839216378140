import { ReactNode } from "react";
import UserContext from "./UserContext";
import { User } from "../model/User";
import { useGetUserQuery } from "@/services/apis/api.ts";
import DisabledUiWithPricingTable from "../common/DisabledUiWithPricingTable";
import { IdentifiedUser } from "@/app/IdentifiedUser.tsx";

export const UserModelProvider = ({
    children,
}: {
    children: (isLoading: boolean, user?: User) => ReactNode;
}) => {
    const { data: user, isLoading } = useGetUserQuery(undefined);

    if (!user) {
        return children(isLoading);
    }

    if (!user) {
        throw new Error("Expected User here");
    }

    const userModel = User.fromApiUser(user);

    if (userModel.organization.needsSubscriptionSetUp) {
        return (
            <UserContext.Provider value={userModel}>
                <IdentifiedUser>
                    <DisabledUiWithPricingTable />
                </IdentifiedUser>
            </UserContext.Provider>
        );
    }

    return (
        <UserContext.Provider value={userModel}>
            {children(isLoading, userModel)}
        </UserContext.Provider>
    );
};
