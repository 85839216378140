import { ElementType } from "react";
import FacebookIcon from "@/common/Icon/AdNetwork/FacebookIcon.tsx";
import LinkedInIcon from "@/common/Icon/AdNetwork/LinkedInIcon.tsx";
import GoogleAdsIcon from "@/common/Icon/AdNetwork/GoogleAdsIcon.tsx";
import BingIcon from "@/common/Icon/AdNetwork/BingIcon.tsx";
import RedditIcon from "@/common/Icon/AdNetwork/RedditIcon.tsx";
import PinterestIcon from "@/common/Icon/AdNetwork/PinterestIcon.tsx";
import InstagramIcon from "@/common/Icon/AdNetwork/InstagramIcon.tsx";
import XIcon from "@/common/Icon/AdNetwork/XIcon.tsx";
import SnapIcon from "@/common/Icon/AdNetwork/SnapIcon.tsx";
import TikTokIcon from "@/common/Icon/AdNetwork/TikTokIcon.tsx";
import StripeIcon from "@/common/Icon/Integration/StripeIcon.tsx";
import RecurlyIcon from "@/common/Icon/Integration/RecurlyIcon.tsx";
import SalesforceIcon from "@/common/Icon/Integration/SalesforceIcon.tsx";
import MetaIcon from "@/common/Icon/AdNetwork/MetaIcon.tsx";
import LemonSqueezyIcon from "@/common/Icon/Integration/LemonSqueezyIcon.tsx";
import GoogleIcon from "@/common/Icon/AdNetwork/GoogleIcon.tsx";
import YoutubeIcon from "@/common/Icon/AdNetwork/YoutubeIcon.tsx";
import BaiduIcon from "@/common/Icon/AdNetwork/BaiduIcon.tsx";
import MessengerIcon from "@/common/Icon/AdNetwork/MessengerIcon.tsx";
import VkIcon from "@/common/Icon/AdNetwork/VkIcon.tsx";
import WechatIcon from "@/common/Icon/AdNetwork/WechatIcon.tsx";
import WeiboIcon from "@/common/Icon/AdNetwork/WeiboIcon.tsx";
import QuoraIcon from "@/common/Icon/AdNetwork/QuoraIcon.tsx";
import TwitchIcon from "@/common/Icon/AdNetwork/TwitchIcon.tsx";
import DiscordIcon from "@/common/Icon/AdNetwork/DiscordIcon.tsx";
import TumblrIcon from "@/common/Icon/AdNetwork/TumblrIcon.tsx";
import DuckDuckGoIcon from "@/common/Icon/AdNetwork/DuckDuckGoIcon.tsx";
import YandexIcon from "@/common/Icon/AdNetwork/YandexIcon.tsx";
import YahooIcon from "@/common/Icon/AdNetwork/YahooIcon.tsx";
import NaverIcon from "@/common/Icon/AdNetwork/NaverIcon.tsx";
import HubSpotIcon from "@/common/Icon/Integration/HubSpotIcon.tsx";
import CallRailIcon from "@/common/Icon/Platform/CallRailIcon.tsx";
import MicrosoftIcon from "@/common/Icon/AdNetwork/MicrosoftIcon.tsx";
import { EnvelopeIcon, UserGroupIcon } from "@heroicons/react/24/solid";

export type IntegrationConfig = {
    icon: ElementType;
    iconColorClass: string;
    bgColorClass: string;
    brandingColor: string;
};

export type IntegrationKey =
    | "meta_ads"
    | "facebook_ads"
    | "linkedin_ads"
    | "google_ads"
    | "bing_ads"
    | "reddit_ads"
    | "pinterest_ads"
    | "instagram_ads"
    | "x_ads"
    | "snapchat_ads"
    | "tiktok_ads"
    | "stripe"
    | "lemon_squeezey"
    | "recurly"
    | "salesforce";

type SearchSourceKey =
    | "google"
    | "bing"
    | "duckduckgo"
    | "yandex"
    | "yahoo"
    | "naver";

type SocialSourceKey =
    | "facebook"
    | "instagram"
    | "linkedin"
    | "pinterest"
    | "tiktok"
    | "reddit"
    | "snapchat"
    | "x"
    | "youtube"
    | "baidu"
    | "vk"
    | "wechat"
    | "weibo"
    | "qq" // @todo
    | "quora"
    | "twitch"
    | "discord"
    | "vevo" // @todo
    | "tumblr"
    | "messenger"
    | "meta";

type OtherSourceKey = "email" | "affiliate";

type SourceKey = SearchSourceKey & SocialSourceKey & OtherSourceKey;

export const platformBranding: Record<
    (IntegrationKey & SourceKey) | string,
    IntegrationConfig
> = new Proxy(
    {
        meta: {
            icon: MetaIcon,
            iconColorClass: "text-near-white",
            bgColorClass: "bg-[#0081FB]",
            brandingColor: "#0081FB",
        },
        facebook: {
            icon: FacebookIcon,
            iconColorClass: "text-near-white",
            bgColorClass: "bg-[#1877F2]",
            brandingColor: "#1877F2",
        },
        linkedin: {
            icon: LinkedInIcon,
            iconColorClass: "text-near-white",
            bgColorClass: "bg-[#0077B5]",
            brandingColor: "#0077B5",
        },
        google_ads: {
            icon: GoogleAdsIcon,
            iconColorClass: "",
            bgColorClass: "dark:bg-near-black bg-near-white",
            brandingColor: "#4285F4",
        },
        google: {
            icon: GoogleIcon,
            iconColorClass: "",
            bgColorClass: "dark:bg-near-black bg-near-white",
            brandingColor: "#4285F4",
        },
        callrail: {
            icon: CallRailIcon,
            iconColorClass: "text-near-white",
            bgColorClass: "bg-[#126cd3]",
            brandingColor: "#126cd3",
        },
        bing: {
            icon: BingIcon,
            bgColorClass: "bg-[#00C7CC]",
            iconColorClass: "text-near-white",
            brandingColor: "#00C7CC",
        },
        microsoft_ads: {
            icon: MicrosoftIcon,
            bgColorClass: "dark:bg-near-black bg-near-white",
            iconColorClass: "",
            brandingColor: "#070F87",
        },
        reddit: {
            icon: RedditIcon,
            bgColorClass: "bg-[#FF4500]",
            iconColorClass: "text-near-white",
            brandingColor: "#FF4500",
        },
        pinterest: {
            icon: PinterestIcon,
            bgColorClass: "bg-[#E60023]",
            iconColorClass: "text-near-white",
            brandingColor: "#E60023",
        },
        instagram: {
            icon: InstagramIcon,
            bgColorClass: "dark:bg-near-black bg-near-white",
            iconColorClass: "",
            brandingColor: "#E1306C",
        },
        x: {
            icon: XIcon,
            bgColorClass: "bg-near-black",
            iconColorClass: "text-near-white",
            brandingColor: "#1d9bf0",
        },
        snapchat: {
            icon: SnapIcon,
            bgColorClass: "bg-[#FFFC00]",
            iconColorClass: "text-near-white",
            brandingColor: "#FFFC00",
        },
        tiktok: {
            icon: TikTokIcon,
            bgColorClass: "bg-near-white",
            iconColorClass: "text-near-white",
            brandingColor: "#ff0050",
        },
        stripe: {
            icon: StripeIcon,
            bgColorClass: "bg-[#635bff]",
            iconColorClass: "text-near-white",
            brandingColor: "#635bff",
        },
        lemon_squeezey: {
            icon: LemonSqueezyIcon,
            bgColorClass: "bg-[#F6C453]",
            iconColorClass: "text-near-white",
            brandingColor: "#F6C453",
        },
        recurly: {
            icon: RecurlyIcon,
            bgColorClass: "bg-[#893999]",
            iconColorClass: "text-near-white",
            brandingColor: "#893999",
        },
        salesforce: {
            icon: SalesforceIcon,
            bgColorClass: "bg-[#00a1e2]",
            iconColorClass: "text-near-white",
            brandingColor: "#00a1e2",
        },
        hubspot: {
            icon: HubSpotIcon,
            bgColorClass: "bg-[#FF7A59]",
            iconColorClass: "text-near-white",
            brandingColor: "#FF7A59",
        },
        youtube: {
            icon: YoutubeIcon,
            bgColorClass: "bg-[#ff0000]",
            iconColorClass: "text-near-white",
            brandingColor: "#ff0000",
        },
        baidu: {
            icon: BaiduIcon,
            bgColorClass: "bg-[#2932E1]",
            iconColorClass: "text-near-white",
            brandingColor: "#2932E1",
        },
        vk: {
            icon: VkIcon,
            bgColorClass: "bg-[#45668e]",
            iconColorClass: "text-near-white",
            brandingColor: "#0077FF",
        },
        wechat: {
            icon: WechatIcon,
            bgColorClass: "bg-[#07C160]",
            iconColorClass: "text-near-white",
            brandingColor: "#07C160",
        },
        weibo: {
            icon: WeiboIcon,
            bgColorClass: "bg-[#00a1e2]",
            iconColorClass: "text-near-white",
            brandingColor: "#00a1e2",
        },
        quora: {
            icon: QuoraIcon,
            bgColorClass: "bg-[#B92B27]",
            iconColorClass: "text-near-white",
            brandingColor: "#B92B27",
        },
        twitch: {
            icon: TwitchIcon,
            bgColorClass: "bg-[#9146FF]",
            iconColorClass: "text-near-white",
            brandingColor: "#9146FF",
        },
        discord: {
            icon: DiscordIcon,
            bgColorClass: "bg-[#5865F2]",
            iconColorClass: "text-near-white",
            brandingColor: "#5865F2",
        },
        tumblr: {
            icon: TumblrIcon,
            bgColorClass: "bg-[#36465D]",
            iconColorClass: "text-near-white",
            brandingColor: "#36465D",
        },
        messenger: {
            icon: MessengerIcon,
            bgColorClass: "bg-[#00B2FF]",
            iconColorClass: "text-near-white",
            brandingColor: "#00B2FF",
        },
        duckduckgo: {
            icon: DuckDuckGoIcon,
            bgColorClass: "bg-[#de5833]",
            iconColorClass: "text-near-white",
            brandingColor: "#de5833",
        },
        yandex: {
            icon: YandexIcon,
            bgColorClass: "bg-[#E66A53]",
            iconColorClass: "text-near-white",
            brandingColor: "#E66A53",
        },
        yahoo: {
            icon: YahooIcon,
            bgColorClass: "bg-[#400191]",
            iconColorClass: "text-near-white",
            brandingColor: "#400191",
        },
        naver: {
            icon: NaverIcon,
            bgColorClass: "bg-[#03C75A]",
            iconColorClass: "text-near-white",
            brandingColor: "#03C75A",
        },
        email: {
            icon: EnvelopeIcon,
            bgColorClass: "bg-[#000000]",
            iconColorClass: "text-near-white",
            brandingColor: "#000000",
        },
        affiliate: {
            icon: UserGroupIcon,
            bgColorClass: "bg-[#000000]",
            iconColorClass: "text-near-white",
            brandingColor: "#000000",
        },
    },
    {
        get: (target, prop: string): IntegrationConfig | undefined => {
            if (prop in target) {
                return target[prop as keyof typeof target];
            }

            const propWithoutAdsSuffix = prop.replace(/_ads$/, "");
            if (propWithoutAdsSuffix in target) {
                return target[propWithoutAdsSuffix as keyof typeof target];
            }
        },
    },
);
