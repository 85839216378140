import React, {
    useRef,
    useState,
    KeyboardEvent,
    SetStateAction,
    useEffect,
    Dispatch,
    ChangeEventHandler,
    KeyboardEventHandler,
    useCallback,
    ReactNode,
    ReactElement,
    RefObject,
} from "react";
import Text from "@/common/Text";
import classNames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import PopoverAsSelect from "@/common/EditableField/PopoverAsSelect.tsx";
type FillableInputType = "text" | "password" | "email" | "number";
type EditableNativeType = FillableInputType | "select" | "textarea";

const titleClassName = "text-lg font-medium leading-6";

const EditableField = (props: {
    onSave: (newValue?: string) => void;
    nativeType?: EditableNativeType;
    isSecret?: boolean;
    value?: string | number;
    readOnly?: boolean;
    onEditStart?: () => void;
    onEditStop?: () => void;
    inputClassName?: string;
    className?: string;
    styled?: boolean;
    singleLine?: boolean;
    as?: ReactElement;
    startInEditMode?: boolean;
    validate?: (value: string) => string | ReactNode | undefined;
    options?: Record<string, string>;
    displayAs?: (textContent: ReactNode) => ReactNode;
    isTitle?: boolean;
}) => {
    const {
        nativeType = "text",
        singleLine = false,
        styled = true,
        as,
        value,
        onSave,
        readOnly = false,
        isSecret = false,
        onEditStart,
        onEditStop,
        inputClassName,
        className,
        validate,
        startInEditMode,
        options,
        displayAs,
        isTitle,
    } = { ...props };

    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newValue, setNewValue] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | ReactNode | undefined>(
        undefined,
    );
    const [prevValue, setPrevValue] = useState<string | number | undefined>(
        undefined,
    );
    const hasEditedRef = useRef(false);

    useEffect(() => {
        if (startInEditMode && !hasEditedRef.current) {
            setIsEditing(true);
        }

        if (!isEditing) {
            return;
        }

        hasEditedRef.current = true;

        if (!inputRef.current) {
            return;
        }

        const el: HTMLInputElement = inputRef.current;

        el.focus();
    }, [isEditing]);

    if (value !== prevValue) {
        setNewValue(undefined);
        setPrevValue(value);
    }

    const save = () => {
        if (!isEditing) return;

        if (typeof newValue !== "undefined") {
            const value = newValue === "" ? undefined : newValue;
            const valueClean = trimTrailingWhitespaces(value) as string;

            if (validate) {
                const _error = validate(valueClean);
                if (_error) {
                    setError(_error);
                    return;
                }
            }

            onSave(valueClean);
            setNewValue(undefined);
            setPrevValue(undefined);
            setError(undefined);
        }

        setIsEditing(false);
        if (onEditStop) {
            onEditStop();
        }
    };

    useOnClickOutside(inputRef as RefObject<HTMLInputElement>, save);

    const theValue = typeof newValue !== "undefined" ? newValue : value;

    const hasValue = typeof value !== "undefined" && value !== "";
    const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
        useCallback((event) => setNewValue(event.target.value), []);

    if (readOnly) {
        return getForDisplay(
            hasValue,
            theValue,
            nativeType,
            isSecret,
            displayAs,
            options,
        );
    }

    const onKeyUp: KeyboardEventHandler = (event) =>
        handleKeyUp(event, setNewValue, save, setIsEditing, onEditStop);

    return (
        <div
            className={classNames(
                {
                    "break-words rounded ring-slate-200 transition duration-300 hover:ring":
                        !isEditing && styled,
                    "overflow-hidden text-ellipsis whitespace-nowrap":
                        singleLine,
                    "hover:cursor-text": nativeType !== "select",
                    "hover:cursor-pointer": nativeType === "select",
                },
                className,
            )}
            onClick={() => {
                if (isEditing) {
                    return;
                }
                setIsEditing(true);
                if (onEditStart) {
                    onEditStart();
                }
            }}
        >
            {!isEditing && isTitle && (
                <h2 className={titleClassName}>{value}</h2>
            )}
            {!isEditing &&
                !isTitle &&
                getForDisplay(
                    hasValue,
                    theValue,
                    nativeType,
                    isSecret,
                    displayAs,
                    options,
                )}
            {isEditing && (
                <React.Fragment>
                    {!as && nativeType !== "select" && (
                        <input
                            className={classNames(
                                "-m-2 w-full p-2",
                                {
                                    [" block w-full rounded-md border border-gray-300 bg-transparent p-2 shadow-sm focus:border-0 focus:ring focus:ring-indigo-500 "]:
                                        styled,
                                    ["sm:text-sm"]: styled && !isTitle,
                                    [titleClassName]: isTitle,
                                },
                                inputClassName,
                            )}
                            value={theValue}
                            ref={inputRef}
                            onKeyUp={onKeyUp}
                            onChange={onChange}
                            type={nativeType}
                        />
                    )}
                    {/*{!as && nativeType === "textarea" && (*/}
                    {/*    <textarea*/}
                    {/*        className={classNames(*/}
                    {/*            "-m-2 block h-32 w-full resize-none rounded-md border border-gray-300 bg-transparent p-2 shadow-sm focus:border-0 focus:ring focus:ring-indigo-500 ",*/}
                    {/*            {*/}
                    {/*                ["sm:text-sm"]: !isTitle,*/}
                    {/*                [titleClassName]: isTitle,*/}
                    {/*            },*/}
                    {/*        )}*/}
                    {/*        value={theValue}*/}
                    {/*        // @ts-expect-error: ignore*/}
                    {/*        ref={inputRef}*/}
                    {/*        onKeyDown={(event) => {*/}
                    {/*            if (event.key === "Enter" && !event.shiftKey) {*/}
                    {/*                event.preventDefault();*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        onKeyUp={onKeyUp}*/}
                    {/*        onChange={onChange}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {!as && nativeType === "select" && (
                        <EditableSelect
                            options={options}
                            value={theValue}
                            setNewValue={setNewValue}
                            save={save}
                        />
                    )}
                    {!!as &&
                        React.cloneElement(as, {
                            // @ts-expect-error: ignore
                            onChange,
                            value: theValue,
                            inputRef,
                            onKeyUp,
                            onFinished: save,
                        })}
                    {!!error && (
                        <p className="-ml-2 mt-4 text-sm text-red-600">
                            {error}
                        </p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

const handleKeyUp = (
    event: KeyboardEvent,
    setNewValue: Dispatch<SetStateAction<string | undefined>>,
    save: () => void,
    setIsEditing: Dispatch<SetStateAction<boolean>>,
    onEditStop?: () => void,
) => {
    event.preventDefault();

    const isEnter = event.key === "Enter";
    const isTab = event.key === "Tab";
    const isEsc = event.key === "Escape";
    const isShift = event.key === "Shift" || event.shiftKey;
    // const isTextArea = nativeType === "textarea";
    const isTextArea = false;

    // shift enter is not save
    if (isTextArea && isShift && isEnter) return event;

    if (isEnter || isTab) {
        return save();
    }

    if (isEsc) {
        setIsEditing(false);
        if (onEditStop) {
            onEditStop();
        }
        setNewValue(undefined);
    }
};

const trimTrailingWhitespaces = (value?: string) =>
    value ? value.replace(/[\s\r\n]+$/, "") : value;

const getForDisplay = (
    hasValue: boolean,
    value: unknown,
    nativeType: string,
    isSecret: boolean,
    displayAs: ((textContent: ReactNode) => ReactNode) | undefined,
    options?: Record<string, string>,
) => {
    const _value =
        nativeType === "select" &&
        typeof options === "object" &&
        typeof value === "string"
            ? options[value]
            : value?.toString();

    const textContent = (
        <>
            {hasValue && _value}
            {!hasValue && "Unknown"}
        </>
    );

    if (displayAs) {
        return displayAs(textContent);
    }

    return isSecret || nativeType === "password" ? (
        <>**********</>
    ) : (
        <Text type={"default"}>{textContent}</Text>
    );
};

function EditableSelect({
    options,
    value,
    setNewValue,
    save,
}: {
    setNewValue: Dispatch<SetStateAction<string | undefined>>;
    options?: Record<string | number, string>;
    value?: string | number;
    save: () => void;
}) {
    const prevValue = useRef(value);
    useEffect(() => {
        if (!!prevValue.current && value !== prevValue.current) {
            save();
        }
    }, [value]);

    if (!options || !Object.keys(options).length) {
        throw new Error("No options for EditableSelect");
    }

    if (!value) {
        throw new Error("Missing value for EditableSelect");
    }

    return (
        <PopoverAsSelect
            onClose={save}
            options={options}
            onChange={setNewValue}
        />
    );
}

export default EditableField;
