import { camelize, decamelize } from "@ridi/object-case-converter";

export function snakeToSentence(str: string) {
    return capitalize(str.split("_").join(" "));
}

export function camelToSentence(str: string) {
    return capitalize(
        str
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase(),
    );
}

export function capitalize(str?: string): string {
    if (!str) {
        return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeToCamel(str: string) {
    const parts = str.split("_");
    const start = parts[0];
    return start + parts.slice(1).map(capitalize).join("");
}

// Recursive function to convert object keys to snake case
export const objectKeysToSnake = (input: unknown) =>
    decamelize(input, { recursive: true });

export const objectKeysToCamel = (input: unknown) =>
    camelize(input, { recursive: true });

export const objectKeysToKebab = (input: Record<string, unknown>) => {
    const result: Record<string, unknown> = {};
    Object.keys(input).forEach((key) => {
        result[camelToKebab(key)] = input[key];
    });

    return result;
};

const camelToKebab = (str: string): string =>
    str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
