import Highlight from "react-highlight";
import { ReactNode, useCallback, useRef } from "react";
import "./github-dark-default.css";
import classNames from "classnames";

export default function Code({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) {
    const textRef = useRef(null);

    const handleSelectAll = useCallback(() => {
        if (!textRef.current) {
            return;
        }
        const range = document.createRange();
        range.selectNodeContents(textRef.current);
        const sel = window.getSelection();
        if (sel) {
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }, []);

    return (
        <div onClick={handleSelectAll} ref={textRef}>
            <Highlight
                className={classNames(
                    "javascript text-xs rounded-xl p-4 cursor-text",
                    className,
                )}
            >
                {children}
            </Highlight>
        </div>
    );
}
