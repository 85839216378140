import { ChartBarIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";

export default function FunnelReportIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <ChartBarIcon
            {...props}
            className={clsx(props.className, "scale-x-[-1]")}
        />
    );
}
