import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/common/ui/chart";

import Panel from "@/common/Panel/Panel.tsx";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
import { FunnelConfig } from "@/features/Funnel/types.ts";
import FunnelSkeleton from "@/common/Skeleton/FunnelSkeleton.tsx";
import { Props } from "recharts/types/component/Label";

type ChartDataItem = Record<string, number>;
type StepSummary = { total: number; conversion: number };
export type FunnelChartData = {
    summedSteps: ChartDataItem[];
    dimensionLabels: Record<string, string>;
    stepSummaries: StepSummary[];
};

export default function FunnelChartWidget({
    chartData,
    className,
    funnelConfig,
    onTitleChange,
    isLoading,
}: {
    chartData?: FunnelChartData;
    className?: string;
    isLoading: boolean;
    funnelConfig: FunnelConfig;
    onChange: (funnelConfig: FunnelConfig) => void;
    onTitleChange: (title?: string) => void;
}) {
    const showSkeleton = isLoading || !chartData;
    return (
        <Panel
            className={className}
            title={funnelConfig.name}
            onTitleChange={onTitleChange}
        >
            {showSkeleton ? (
                <FunnelSkeleton
                    stepCount={funnelConfig.steps.length}
                    className={"h-[20rem] w-full"}
                />
            ) : (
                <>
                    {chartData?.summedSteps.length ? (
                        <FunnelChart chartData={chartData} />
                    ) : (
                        <WidgetEmptyState subtitle={"No data yet."} />
                    )}
                </>
            )}
        </Panel>
    );
}

const CustomLabel = (props: Props) => {
    const { x, y, width } = props as { x: number; y: number; width: number };

    if (typeof props.value === "undefined") {
        return null;
    }

    const { total, conversion } = props.value as unknown as StepSummary;

    const formatter = new Intl.NumberFormat(undefined, {
        notation: "compact",
        maximumFractionDigits: 2,
    });

    const labelHeight = 48;
    const labelWidth = 60;

    return (
        <g className={"font-mono"}>
            <rect
                x={x + width / 2 - labelWidth / 2}
                y={y - labelHeight / 2}
                width={labelWidth}
                height={labelHeight}
                rx={5}
                className={
                    "text-zinc-950/30 dark:text-white/30 dark:fill-near-black fill-white"
                }
                fill="currentColor"
                stroke="currentColor"
            />
            <text
                x={x + width / 2}
                y={y - 8}
                fill="currentColor"
                className={"fill-text-default"}
                textAnchor="middle"
                dominantBaseline="middle"
                fontWeight="bold"
            >
                {Math.abs(conversion * 100).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })}
                %
            </text>
            <text
                x={x + width / 2}
                y={y + 12}
                className={"text-xs fill-text-subtle"}
                fill="currentColor"
                textAnchor="middle"
                dominantBaseline="middle"
            >
                {formatter.format(total)}
            </text>
        </g>
    );
};

function FunnelChart({ chartData }: { chartData: FunnelChartData }) {
    const data = chartData.summedSteps;
    const chartConfig = getChartConfigFromDimension(
        Object.keys(data[0] || {}),
        chartData.dimensionLabels,
    );
    const keysOrdered = Object.entries(data[0] || {});
    keysOrdered.sort((a, b) => b[1] - a[1]);
    const keys = keysOrdered.map(([key]) => key);

    return (
        <ChartContainer config={chartConfig} className={"h-[20rem] w-full"}>
            <BarChart
                accessibilityLayer
                data={data}
                margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                />
                <ChartTooltip
                    cursor={false}
                    wrapperStyle={{ zIndex: 20 }}
                    labelClassName="text-white"
                    content={<ChartTooltipContent indicator="line" />}
                />
                {keys.map((key, index) => {
                    const first = index === 0;
                    const last = index === keys.length - 1;
                    return (
                        <Bar
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={`var(--color-${key})`}
                            minPointSize={2}
                            radius={[
                                last ? 4 : 0,
                                last ? 4 : 0,
                                first ? 4 : 0,
                                first ? 4 : 0,
                            ]}
                        >
                            {last && (
                                <LabelList
                                    content={<CustomLabel />}
                                    valueAccessor={(
                                        _entry: unknown,
                                        dataIndex: number,
                                    ) => chartData.stepSummaries[dataIndex]}
                                />
                            )}
                        </Bar>
                    );
                })}
            </BarChart>
        </ChartContainer>
    );
}

const themeColorCount = 7;
const getChartConfigFromDimension = (
    dimension: string[],
    dimensionLabels: Record<string, string>,
    monotone?: boolean,
) => {
    return dimension.reduce(
        (carry, item, index) => {
            const colorIndex = (index % themeColorCount) + 1;

            carry[item] = {
                label: dimensionLabels[item]!,
                color: monotone
                    ? `hsl(var(--theme-1))`
                    : `hsl(var(--theme-${colorIndex}))`,
            };
            return carry;
        },
        {} as Record<string, { label: string; color: string }>,
    );
};
