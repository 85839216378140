import Panel from "@/common/Panel/Panel.tsx";
import { useGetWorkspaceMetaQuery } from "@/services/apis/data.ts";
import {
    Description,
    Field,
    FieldGroup,
    Fieldset,
    Label,
} from "@/catalyst/fieldset.tsx";
import Link from "@/common/Link";
import {
    useGetWorkspacesQuery,
    useUpdateWorkspaceMutation,
} from "@/services/apis/api.ts";
import ListBox from "@/common/ListBox";
import FieldCutOffEvents from "@/features/Workspace/Settings/FieldCutOffEvents.tsx";
import { useUiProperty } from "@/features/UiState.slice.ts";
import { BellIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Button from "@/common/Button";
import useAnalytics from "@/services/analytics/useAnalytics";
import { useContext } from "react";
import FeatureFlagManagerContext from "@/services/featureFlags/FeatureFlagManagerContext.ts";
import { useUser } from "@/hooks/use-user.ts";
import WithFeatureFlagCheck from "@/common/WithFeatureFlagCheck";
import support from "@/services/support";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import AttributionScopePicker from "@/common/AttributionScopePicker";

const unifiedCurrencyOptions = [
    { label: "AUD", value: "AUD" },
    { label: "CAD", value: "CAD" },
    { label: "CHF", value: "CHF" },
    { label: "CNH", value: "CNH" },
    { label: "DKK", value: "DKK" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "HKD", value: "HKD" },
    { label: "JPY", value: "JPY" },
    { label: "MXN", value: "MXN" },
    { label: "NOK", value: "NOK" },
    { label: "NZD", value: "NZD" },
    { label: "PLN", value: "PLN" },
    { label: "SEK", value: "SEK" },
    { label: "SGD", value: "SGD" },
    { label: "TRY", value: "TRY" },
    { label: "USD", value: "USD" },
    { label: "ZAR", value: "ZAR" },
];

const defaultCurrencyOptions = unifiedCurrencyOptions;

export default function CommonSettingsPanel() {
    const selectedWorkspaceFromState = useSelectedWorkspace();

    const { data: workspaces } = useGetWorkspacesQuery(undefined);
    const selectedWorkspace =
        workspaces?.find((ws) => ws.id === selectedWorkspaceFromState.id) ||
        selectedWorkspaceFromState;

    const { data } = useGetWorkspaceMetaQuery({
        workspaceId: selectedWorkspace.id,
    });

    const [updateWorkspace] = useUpdateWorkspaceMutation();

    const shouldConfigureCutOffEvents =
        selectedWorkspace.cutOffEvents.length === 0;

    const [
        notifyOfAudienceSyncAvailability,
        setNotifyOfAudienceSyncAvailability,
    ] = useUiProperty<boolean>("notifyOfAudienceSyncAvailability", false);

    const analytics = useAnalytics();
    const featureFlagManager = useContext(FeatureFlagManagerContext);

    const hasUnifiedReportingCurrency =
        featureFlagManager.isEnabled("unified-currency");

    const hasOrganizationAttribution = featureFlagManager.isEnabled(
        "organization-attribution",
    );

    const user = useUser();
    return (
        <div className={"flex flex-col gap-4"}>
            <Panel title={"Currency"}>
                <Fieldset>
                    <FieldGroup>
                        <Field>
                            <Label>Default currency</Label>
                            <Description>
                                All revenue tracked without explicit currency
                                will be denominated in this currency. N.B. Any
                                revenue tracked through a payment provider
                                integration, like Stripe and Recurly, will have
                                the currency set automatically.{" "}
                                <Link
                                    external
                                    to={
                                        "https://www.spectaclehq.com/docs/spectacle-js/track#special-properties-for-track-revenue"
                                    }
                                >
                                    Learn how to set the currency
                                </Link>{" "}
                                when tracking revenue through Events.
                            </Description>
                            <div className={"mb-3 mt-4"}>
                                <ListBox
                                    className={"max-w"}
                                    value={selectedWorkspace.defaultCurrency}
                                    onChange={(value) =>
                                        updateWorkspace({
                                            id: selectedWorkspace.id,
                                            defaultCurrency: value,
                                        })
                                    }
                                    options={defaultCurrencyOptions}
                                />
                            </div>
                            <Description>
                                Your currency missing? Please{" "}
                                <Link
                                    className={"underline"}
                                    onClick={() =>
                                        support.openChat(
                                            "I would like to have the following currency added: ",
                                        )
                                    }
                                >
                                    contact support
                                </Link>
                                .
                            </Description>
                        </Field>
                        <Field>
                            <Label>Unified reporting currency</Label>
                            <Description>
                                Automatically convert all revenue and ad spend
                                to one currency in all reporting.{" "}
                                <Link
                                    external
                                    to={
                                        "https://www.spectaclehq.com/docs/features/multi-and-unified-currency#unified-reporting-currency-select-plans"
                                    }
                                >
                                    Learn more
                                </Link>{" "}
                            </Description>
                            <div className={"mb-3 mt-4"}>
                                <WithFeatureFlagCheck
                                    passesCheck={hasUnifiedReportingCurrency}
                                    opener={"upgrade-button-unified-currency"}
                                >
                                    {(needsToUpgrade) => (
                                        <>
                                            {!needsToUpgrade && (
                                                <>
                                                    <div
                                                        className={
                                                            "flex gap-2 items-center"
                                                        }
                                                    >
                                                        <ListBox
                                                            className={"max-w"}
                                                            value={
                                                                selectedWorkspace.reportingCurrency
                                                            }
                                                            onChange={(value) =>
                                                                updateWorkspace(
                                                                    {
                                                                        id: selectedWorkspace.id,
                                                                        reportingCurrency:
                                                                            value,
                                                                    },
                                                                )
                                                            }
                                                            placeholder={
                                                                "Select a currency"
                                                            }
                                                            options={
                                                                unifiedCurrencyOptions
                                                            }
                                                        />
                                                        {!!selectedWorkspace.reportingCurrency && (
                                                            <XMarkIcon
                                                                className={
                                                                    "text-subtle h-5 w-5 cursor-pointer"
                                                                }
                                                                onClick={() =>
                                                                    updateWorkspace(
                                                                        {
                                                                            id: selectedWorkspace.id,
                                                                            reportingCurrency:
                                                                                null,
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    <Description
                                                        className={"mt-4"}
                                                    >
                                                        <span
                                                            className={
                                                                "font-semibold text-subtle"
                                                            }
                                                        >
                                                            Important
                                                        </span>
                                                        : Please{" "}
                                                        <Link
                                                            external
                                                            to={
                                                                "https://www.spectaclehq.com/docs/features/multi-and-unified-currency#unified-reporting-currency-select-plans"
                                                            }
                                                        >
                                                            check the
                                                            documentation
                                                        </Link>{" "}
                                                        to see supported
                                                        currency pairs. Missing
                                                        currency pairs can be
                                                        added by{" "}
                                                        <Link
                                                            onClick={() =>
                                                                support.openChat(
                                                                    "I would like to have the following currency pair added for unified reporting currency: ",
                                                                )
                                                            }
                                                        >
                                                            contacting support
                                                        </Link>
                                                    </Description>
                                                </>
                                            )}
                                            {needsToUpgrade && (
                                                <Button
                                                    disabled={
                                                        !user.hasAdminPermissions()
                                                    }
                                                    outline
                                                    icon={
                                                        needsToUpgrade
                                                            ? StarIcon
                                                            : undefined
                                                    }
                                                >
                                                    Set reporting currency
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </WithFeatureFlagCheck>
                            </div>
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </Panel>
            <Panel title={"Attribution"}>
                <Fieldset>
                    <FieldGroup>
                        <FieldCutOffEvents
                            shouldConfigureCutOffEvents={
                                shouldConfigureCutOffEvents
                            }
                            events={data?.data.events || []}
                            selectedWorkspace={selectedWorkspace}
                        />
                        <Field>
                            <Label>Organization attribution</Label>
                            <Description>
                                Choose whether to attribute leads and revenue to
                                individuals or to entire organizations.{" "}
                                <Link
                                    external
                                    to={
                                        "https://www.spectaclehq.com/docs/features/organization-attribution"
                                    }
                                >
                                    Read more here
                                </Link>
                            </Description>
                            <div className={"mb-3 mt-4"}>
                                <WithFeatureFlagCheck
                                    passesCheck={hasOrganizationAttribution}
                                    opener={
                                        "upgrade-button-organization-attribution"
                                    }
                                    prop={"onChange"}
                                >
                                    {(needsToUpgrade) => (
                                        <AttributionScopePicker
                                            scope={
                                                selectedWorkspace.organizationAttribution
                                                    ? "group"
                                                    : "individual"
                                            }
                                            needsToUpgradeForGroup={
                                                needsToUpgrade
                                            }
                                            onChange={(scope) =>
                                                !needsToUpgrade &&
                                                updateWorkspace({
                                                    id: selectedWorkspace.id,
                                                    organizationAttribution:
                                                        scope === "group",
                                                })
                                            }
                                        />
                                    )}
                                </WithFeatureFlagCheck>
                            </div>
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </Panel>
            <Panel title={"Other"}>
                <Fieldset>
                    <FieldGroup>
                        <Field>
                            <Label>Sync customers to ad networks</Label>
                            <Description>
                                Enabling this feature will sync your customer
                                data (email) to supported ad networks. This
                                allows you to create lookalike audiences and
                                prevent ads from being shown to existing
                                customers
                            </Description>
                            <Button
                                className={"mt-4"}
                                outline
                                disabled={notifyOfAudienceSyncAvailability}
                                onClick={(_, setBanner) => {
                                    setNotifyOfAudienceSyncAvailability(true);
                                    analytics.trackExpressedRoadmapFeatureInterestEvent(
                                        "ad-network-audience-sync",
                                    );
                                    setBanner("Access requested!");
                                }}
                            >
                                <BellIcon />
                                {notifyOfAudienceSyncAvailability
                                    ? "Access requested"
                                    : "Request access"}
                            </Button>
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </Panel>
        </div>
    );
}
