import {
    ColDef,
    ICellRendererParams,
    ValueGetterParams,
} from "ag-grid-community";
import Money from "@/common/Money";
import { safeDivide } from "@/util/math.ts";
import { ArrowDownRightIcon } from "@heroicons/react/16/solid";

export const funnelDataColDefs = [
    {
        field: "source",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: true,
        filter: true,
        headerName: "Source",
    },
    {
        field: "campaign",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "keyword",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "adGroup",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "ad",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "campaignMedium",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "campaignContent",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "referrer",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
    {
        field: "isPaid",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
        headerName: "Traffic Type",
        valueGetter: (params: ValueGetterParams) =>
            params.data?.isPaid ? "Paid" : "Organic",
    },
    {
        field: "sourceType",
        minWidth: 400,
        sortingOrder: ["desc", "asc", null],
        hide: true,
        rowGroup: false,
        filter: true,
    },
] as ColDef[];

export const createFunnelStepColDef = (
    index: number,
    name?: string | null,
): ColDef => {
    return {
        field: `step_${index}`,
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        headerName:
            typeof name === "string" ? `${index + 1}. ${name}` : `Step ${name}`,
        aggFunc: "sum",
        flex: 1,
        sort: index === 0 ? "desc" : undefined,
        valueGetter: (params: ValueGetterParams) => {
            const step = params.data?.steps?.[index];

            if (!step?.total) {
                return 0;
            }

            return Number(step.total);
        },
        cellRenderer: (params: ICellRendererParams) => {
            const data = params.node.aggData || params.node.data;
            const currentStepCount = data[`step_${index}`];
            const previousStepCount = data[`step_${index - 1}`];
            const hasPreviousStep = typeof previousStepCount !== "undefined";
            const conversionRate =
                safeDivide(currentStepCount, previousStepCount) * 100;

            return (
                <>
                    <div className={"grid items-center grid-cols-3 "}>
                        <Money precision={2} amount={currentStepCount} />
                        {hasPreviousStep && (
                            <span
                                className={
                                    "flex items-center text-subtle text-xs col-span-2"
                                }
                            >
                                <ArrowDownRightIcon
                                    className={"h-3 w-3 text-subtle"}
                                />{" "}
                                {conversionRate.toFixed(2)}%
                            </span>
                        )}
                    </div>
                </>
            );
        },
    };
};
