import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogTitle,
} from "@/catalyst/dialog";
import { ReactNode } from "react";
import { getUiProperty } from "@/features/UiState.slice.ts";
import { useSelector } from "react-redux";
import { clsx } from "clsx";

export { DialogActions as ModalActions };

export default function Modal({
    open,
    title,
    description,
    onClose,
    children,
    darkMode,
    size = "lg",
    className,
}: {
    open: boolean;
    title?: ReactNode;
    description?: ReactNode;
    onClose: () => void;
    children?: ReactNode;
    darkMode?: boolean;
    size?: React.ComponentProps<typeof Dialog>["size"];
    className?: string;
}) {
    const isDarkMode =
        typeof darkMode !== "undefined"
            ? darkMode
            : useSelector(getUiProperty("darkMode"));
    return (
        <Dialog
            open={open}
            className={clsx(className, isDarkMode ? "dark" : "")}
            onClose={onClose}
            size={size}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            {description && (
                <div
                    className={
                        "mt-2 text-pretty text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400"
                    }
                >
                    {description}
                </div>
            )}
            {children && <DialogBody>{children}</DialogBody>}
        </Dialog>
    );
}
