import { OrganizationModel } from "./Organization";
import { UserOrganizationModel } from "./UserOrganization";
import { components } from "@/types/generated/api-schema";
import { assertNotUndefined } from "@/util/typeguards";

type DismissableDialog = components["schemas"]["DismissableDialog"];
type UserPermissionsType = components["schemas"]["UserPermissionsType"];
type UserType = components["schemas"]["User"];
type FeatureFlagSet = components["schemas"]["FeatureFlagSet"];

export class User implements UserType {
    id: string;
    cognitoId: string;
    picture?: string;
    name: string;
    email: string;
    featureFlags: FeatureFlagSet;
    dismissedDialogs: DismissableDialog[];
    userOrganizations: UserOrganizationModel[];
    createdAt: string;
    organization!: OrganizationModel;
    timezone?: string;
    intercomUserHash?: string;

    private constructor(args: {
        id: string;
        cognitoId: string;
        name: string;
        email: string;
        featureFlags: FeatureFlagSet;
        picture?: string;
        userOrganizations: UserOrganizationModel[];
        dismissedDialogs?: DismissableDialog[];
        timezone?: string;
        createdAt: string;
        intercomUserHash?: string;
    }) {
        this.id = args.id;
        this.email = args.email;
        this.cognitoId = args.cognitoId;
        this.featureFlags = args.featureFlags;
        this.name = args.name;
        this.email = args.email;
        this.picture = args.picture;
        this.userOrganizations = args.userOrganizations;
        this.dismissedDialogs = args.dismissedDialogs || [];
        this.timezone = args.timezone;
        this.createdAt = args.createdAt;
        this.intercomUserHash = args.intercomUserHash;

        if (!args.userOrganizations.length) {
            throw new Error("Expected user organization here");
        }

        this.setUserOrganizations(
            args.userOrganizations.map((uo) =>
                UserOrganizationModel.create(uo),
            ),
        );
    }

    static fromApiUser(userData: UserType): User {
        return new this({
            id: userData.id,
            name: userData.name,
            cognitoId: userData.cognitoId,
            email: userData.email,
            picture: userData.picture,
            userOrganizations: userData.userOrganizations,
            dismissedDialogs: userData.dismissedDialogs,
            featureFlags: userData.featureFlags || {},
            timezone: userData.timezone,
            createdAt: userData.createdAt,
            intercomUserHash: userData.intercomUserHash,
        });
    }

    public getData(): User {
        return JSON.parse(JSON.stringify(this));
    }

    public setUserOrganizations(
        userOrganizations: UserOrganizationModel[],
    ): User {
        this.userOrganizations = userOrganizations;
        const userOrganization = userOrganizations[0];

        if (!userOrganization) {
            throw new Error("Expected UserOrganization here");
        }

        this.setOrganization(
            OrganizationModel.create(userOrganization.organization),
        );

        return this;
    }

    public setOrganization(organization: OrganizationModel): User {
        this.organization = organization;

        return this;
    }

    public hasDialog(dialogId: DismissableDialog): boolean {
        return !this.dismissedDialogs.includes(dialogId);
    }

    public getCurrentUserOrganization(): UserOrganizationModel {
        const userOrganizations = this.userOrganizations;
        const userOrganization = userOrganizations[0];
        assertNotUndefined(userOrganization, "Expected organizations here");

        return userOrganization;
    }

    public getPermissionsType() {
        return this.getCurrentUserOrganization()?.permissionsType;
    }

    public hasAdminPermissions() {
        return this.hasPermissionType("admin");
    }

    public isOrganizationOwner() {
        return this.hasPermissionType("owner");
    }

    public hasPermissionType(requestedType: UserPermissionsType) {
        const permissionsType = this.getPermissionsType();

        if (!permissionsType) {
            throw new Error("Unexpectedly no permission type");
        }

        if (
            requestedType === "editor" &&
            (permissionsType === "admin" || permissionsType === "owner")
        ) {
            return true;
        }

        if (requestedType === "admin" && permissionsType === "owner") {
            return true;
        }

        return requestedType === permissionsType;
    }

    public getCurrentOrganization() {
        return this.organization;
    }
}
