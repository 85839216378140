import React, { MouseEventHandler, ReactNode, useContext, type JSX } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { createRoot } from "react-dom/client";
import useAnalytics from "@/services/analytics/useAnalytics";
import { components } from "@/types/generated/api-schema";
import UpgradeDialog from "@/common/UpgradeDialog";
import userContext from "@/app/UserContext";
import { store } from "@/app/store";
type UpgradeDialogOpenedEvent =
    components["schemas"]["UpgradeDialogOpenedEvent"];

async function showUpgradeDialog(userIsAdmin: boolean): Promise<void> {
    const container = document.createElement("div");
    document.body.append(container);

    const root = createRoot(container);

    const cleanup = () => {
        root.unmount();
        container.remove();
    };

    return new Promise((doResolve) => {
        const resolve = () => {
            doResolve();
            cleanup();
        };

        root.render(
            <ReduxProvider store={store}>
                <UpgradeDialog
                    onClose={resolve}
                    open={true}
                    userIsAdmin={userIsAdmin}
                    title={"Please upgrade to use this feature"}
                />
            </ReduxProvider>,
        );
    });
}

const WithFeatureFlagCheck = ({
    children,
    passesCheck,
    prop = "onClick",
}: {
    children: (needsToUpgrade: boolean) => JSX.Element & {
        props: {
            onClick?: (
                arg: MouseEventHandler,
                value?: Record<string, string>,
            ) => void;
        };
    };
    passesCheck: boolean;
    opener: UpgradeDialogOpenedEvent["opener"];
    prop?: string;
}): ReactNode => {
    const analytics = useAnalytics();
    const user = useContext(userContext);

    if (passesCheck) {
        return children(false);
    }

    if (!user) {
        throw new Error("Unexpectedly no User");
    }

    const userIsAdmin = user.hasAdminPermissions() || false;

    const handler = async (e: React.DragEvent<HTMLDivElement>) => {
        if (typeof e.preventDefault !== "undefined") {
            e.preventDefault();
        }

        analytics.trackUpgradeDialogOpenedEvent(opener);

        await showUpgradeDialog(userIsAdmin);
    };

    return React.cloneElement(children(true), {
        [prop]: handler,
    });
};

export default WithFeatureFlagCheck;
