import React, { useEffect } from "react";

const STRIPE_PUBLISHABLE_KEY = import.meta.env
    .VITE_STRIPE_PUBLISHABLE_KEY as string;

export default function StripePricingTable({
    pricingTableId,
    clientReferenceId,
    customerEmail,
    stripeCustomerSessionClientSecret,
}: {
    pricingTableId: string;
    clientReferenceId: string;
    customerEmail: string;
    stripeCustomerSessionClientSecret?: string;
}) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const props = {
        "pricing-table-id": pricingTableId,
        "client-reference-id": clientReferenceId,
        "publishable-key": STRIPE_PUBLISHABLE_KEY,
    } as Record<string, string | undefined>;

    if (customerEmail && !stripeCustomerSessionClientSecret) {
        props["customer-email"] = customerEmail;
    }

    if (stripeCustomerSessionClientSecret) {
        props["customer-session-client-secret"] =
            stripeCustomerSessionClientSecret;
    }

    return React.createElement("stripe-pricing-table", props);
}
