type ObjectType = Record<string | number | symbol, unknown>;

const unsetKeysDeep = (obj: ObjectType, keysToUnset: string[]) => {
    Object.keys(obj).forEach((key) => {
        if (keysToUnset.includes(key)) {
            delete obj[key];
        }

        if (typeof obj[key] === "object" && obj[key] !== null) {
            unsetKeysDeep(obj[key] as ObjectType, keysToUnset);
        }
    });
};

const isDeepEqual = (
    a: unknown,
    b: unknown,
    ignoredPropertyNames?: string[],
): boolean => {
    if (
        typeof a === "object" &&
        typeof b === "object" &&
        ignoredPropertyNames
    ) {
        const c = JSON.parse(JSON.stringify(a)) as ObjectType;
        const d = JSON.parse(JSON.stringify(b)) as ObjectType;
        unsetKeysDeep(c, ignoredPropertyNames);
        unsetKeysDeep(d, ignoredPropertyNames);

        return JSON.stringify(c) === JSON.stringify(d);
    }

    return JSON.stringify(a) === JSON.stringify(b);
};

export default isDeepEqual;
