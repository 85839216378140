import * as Headless from "@headlessui/react";
import { Link } from "@/catalyst/link.tsx";
import { ComponentPropsWithoutRef } from "react";

export function isLinkProps(
    props: object,
): props is ComponentPropsWithoutRef<typeof Link> {
    return "to" in props || "href" in props;
}

export function couldBeButtonProps(
    props: object,
): props is Omit<Headless.ButtonProps, "className"> {
    return !isLinkProps(props);
}
