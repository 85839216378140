import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/common/ui/chart.tsx";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

import { getChartConfigFromNetworks } from "@/features/Dashboard/Widgets/util.ts";
import { TouchpointsByNetwork } from "@/types/generated/TouchpointsByNetwork.ts";
import PlatformIcon from "../../../common/PlatformIcon";
import Panel from "@/common/Panel/Panel.tsx";
import WidgetSkeleton from "@/common/Skeleton/WidgetSkeleton.tsx";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
export default function TouchpointsByNetworkWidget({
    touchpointByNetwork,
    dateRangeDescription,
}: {
    touchpointByNetwork?: TouchpointsByNetwork[];
    dateRangeDescription?: string;
}) {
    const showEmptyState = touchpointByNetwork?.length === 0;
    return (
        <Panel
            title={"Top networks by traffic"}
            subtitle={`Showing total touchpoints by network for the last ${dateRangeDescription}`}
        >
            {!touchpointByNetwork && <WidgetSkeleton />}
            {touchpointByNetwork && !showEmptyState && (
                <TouchpointChart touchpointsByNetwork={touchpointByNetwork} />
            )}
            {showEmptyState && (
                <WidgetEmptyState
                    subtitle={
                        "No touchpoints found for the current date range."
                    }
                />
            )}
        </Panel>
    );
}

function TouchpointChart({
    touchpointsByNetwork,
}: {
    touchpointsByNetwork: TouchpointsByNetwork[];
}) {
    // Get unique networks
    const networks = [
        ...new Set(touchpointsByNetwork.map((item) => item.network)),
    ];
    const chartConfig = getChartConfigFromNetworks(networks, true);
    const chartData = touchpointsByNetwork.map((data) => ({
        ...data,
        fill: `var(--color-${data.network})`,
    }));
    chartData.sort((a, b) => Number(a.touchpoints) - Number(b.touchpoints));

    const CustomTick = ({
        x,
        y,
        payload: { value },
    }: {
        x: number;
        y: number;
        payload: { value: string };
    }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <foreignObject x={-15} y={-5} width={30} height={30}>
                    <PlatformIcon className={"h-6 w-6"} platform={value} />
                </foreignObject>
            </g>
        );
    };
    const formatter = new Intl.NumberFormat(undefined, {
        notation: "compact",
        maximumFractionDigits: 2,
    });
    return (
        <ChartContainer config={chartConfig}>
            <BarChart
                accessibilityLayer
                data={chartData}
                margin={{ top: 20, bottom: 10 }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="network"
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    interval={0}
                    tick={CustomTick}
                />
                <ChartTooltip
                    cursor={false}
                    labelClassName="text-white"
                    content={<ChartTooltipContent indicator="line" />}
                />
                <Bar dataKey="touchpoints" radius={8}>
                    <LabelList
                        position="insideTop"
                        formatter={(value: number) => formatter.format(value)}
                        offset={18}
                        className="fill-near-white"
                        // className="fill-foreground"
                        fontSize={12}
                    />
                </Bar>
            </BarChart>
        </ChartContainer>
    );
}
