import classNames from "classnames";
import PlatformIcon from "../PlatformIcon";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useGetContactQuery } from "@/services/apis/data.ts";
import Loading from "@/common/Loading";
import { assertNotUndefined } from "@/util/typeguards";
import { formatDistanceToNow } from "date-fns";
import SidePanel from "@/common/SidePanel";
import { DialogTitle } from "@/catalyst/dialog.tsx";
import StatsBar from "@/common/StatsBar/StatsBar.tsx";
import { Avatar } from "@/catalyst/avatar.tsx";
import { Divider } from "@/catalyst/divider.tsx";
import MultiCurrencyMoney from "@/common/Money/MultiCurrencyMoney.tsx";
import { useContext } from "react";
import UserContext from "@/app/UserContext.ts";
import Link from "../Link";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import {
    DescriptionDetails,
    DescriptionList,
    DescriptionTerm,
} from "@/catalyst/description-list.tsx";
import { camelToSentence, snakeToSentence } from "@/util/case.ts";

export default function ContactDetailsPanel({
    userIdOrEmail,
    setOpened,
}: {
    userIdOrEmail?: string | null;
    setOpened: (opened: boolean) => void;
}) {
    return (
        <SidePanel
            open={typeof userIdOrEmail !== "undefined"}
            setOpened={setOpened}
        >
            {userIdOrEmail && (
                <ContactDetailsPanelInner userIdOrEmail={userIdOrEmail} />
            )}
            {!userIdOrEmail && <UnidentifiedCustomerPanelInner />}
        </SidePanel>
    );
}

const ContactDetailsPanelInner = ({
    userIdOrEmail,
}: {
    userIdOrEmail: string;
}) => {
    const selectedWorkspace = useSelectedWorkspace();
    assertNotUndefined(selectedWorkspace);
    const user = useContext(UserContext);
    assertNotUndefined(user);

    const { data, isLoading } = useGetContactQuery({
        workspaceId: selectedWorkspace.id,
        cutOffEvents: selectedWorkspace.cutOffEvents,
        userIdOrEmail: userIdOrEmail,
        timezone: user.timezone || null,
        defaultCurrency: selectedWorkspace.defaultCurrency,
        reportingCurrency: selectedWorkspace.reportingCurrency || null,
    });

    if (isLoading) return <Loading />;

    assertNotUndefined(data);
    const contactDetailed = data.data;
    return (
        <>
            <div className="px-4 sm:px-6">
                <DialogTitle className="text-base font-semibold leading-6">
                    <Avatar
                        className={"mr-3 w-7 h-7"}
                        initials={`${contactDetailed.firstName[0]}${contactDetailed.lastName[0]}`}
                        square
                        generateBgColor
                    />
                    {contactDetailed.firstName} {contactDetailed.lastName}
                </DialogTitle>
            </div>
            <div className="relative mt-6 px-4 sm:px-6">
                <StatsBar
                    stats={[
                        {
                            title: "LTV",
                            value: (
                                <MultiCurrencyMoney
                                    items={contactDetailed.ltvs}
                                    defaultCurrency={
                                        selectedWorkspace.reportingCurrency ||
                                        selectedWorkspace.defaultCurrency
                                    }
                                    isResult={true}
                                />
                            ),
                        },
                        {
                            title: "Payments",
                            value: contactDetailed.paymentCount,
                        },
                    ]}
                    loading={false}
                />
            </div>
            <Divider className={"my-4"} />
            <div className="px-4 sm:px-6">
                <span className="text-sm leading-6">Details</span>
            </div>
            <div className="relative mt-3 px-4 sm:px-6">
                {Object.entries(contactDetailed.traits).length ? (
                    <DescriptionList>
                        {Object.entries(contactDetailed.traits).map(
                            ([key, value]) => (
                                <>
                                    <DescriptionTerm key={`${key}-k`}>
                                        {camelToSentence(key)}
                                    </DescriptionTerm>
                                    <DescriptionDetails key={`${key}-v`}>
                                        {value}
                                    </DescriptionDetails>
                                </>
                            ),
                        )}
                    </DescriptionList>
                ) : (
                    <span className={"text-subtle text-xs"}>
                        No contact traits tracked
                    </span>
                )}
            </div>
            <Divider className={"my-4"} />
            <div className="px-4 sm:px-6">
                <span className="text-sm leading-6">Customer journey</span>
            </div>
            <div className="relative mt-6 px-4 sm:px-6">
                <ul role="list" className="space-y-6">
                    {contactDetailed.customerJourney.length > 0 ? (
                        <li key={`converted`} className="relative flex gap-x-4">
                            <div
                                className={classNames(
                                    "-bottom-6",
                                    "absolute left-0 top-0 flex w-6 justify-center",
                                )}
                            >
                                <div className="w-px bg-zinc-200" />
                            </div>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:lg:bg-zinc-900">
                                <CheckCircleIcon
                                    className="h-6 w-6 text-default"
                                    aria-hidden="true"
                                />
                            </div>
                            <p className="flex-auto py-0.5 text-xs leading-5 text-subtle">
                                <span className="font-medium text-default">
                                    {contactDetailed.firstName}{" "}
                                    {contactDetailed.lastName}
                                </span>{" "}
                                Became lead
                            </p>
                            <time
                                dateTime={contactDetailed.convertedAt}
                                className="flex-none py-0.5 text-xs leading-5 text-subtle"
                            >
                                {formatDistanceToNow(
                                    new Date(contactDetailed.convertedAt),
                                    {
                                        addSuffix: true,
                                    },
                                )}
                            </time>
                        </li>
                    ) : (
                        <li
                            key={`not-converted`}
                            className="relative flex gap-x-4"
                        >
                            <div className={"text-subtle text-sm"}>
                                No visits recorded before cut-off event
                            </div>
                        </li>
                    )}
                    {contactDetailed.customerJourney.map((visit, index) => {
                        const details = [
                            {
                                term: "Source",
                                description: snakeToSentence(visit.source),
                            },
                            {
                                term: "Campaign",
                                description: visit.campaign,
                            },
                            {
                                term: "Keyword",
                                description: visit.keyword,
                            },
                            {
                                term: "Ad Group",
                                description: visit.adGroup,
                            },
                            {
                                term: "Ad",
                                description: visit.ad,
                            },
                            {
                                term: "Medium",
                                description: visit.campaignMedium,
                            },
                            {
                                term: "Content",
                                description: visit.campaignContent,
                            },
                            {
                                term: "Referrer",
                                description: visit.referrer,
                            },
                        ];
                        return (
                            <li
                                key={`${visit.dateTime}${index}`}
                                className="relative flex gap-x-4"
                            >
                                <div
                                    className={classNames(
                                        index ===
                                            contactDetailed.customerJourney
                                                .length -
                                                1
                                            ? "h-6"
                                            : "-bottom-6",
                                        "absolute left-0 top-0 flex w-6 justify-center",
                                    )}
                                >
                                    <div className="w-px bg-zinc-200" />
                                </div>

                                <>
                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:lg:bg-zinc-900">
                                        {visit.source ? (
                                            <PlatformIcon
                                                className={"h-6 w-6"}
                                                platform={visit.source.replace(
                                                    /_organic$/,
                                                    "",
                                                )}
                                            />
                                        ) : (
                                            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                        )}
                                    </div>
                                    <div className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span className="font-medium text-default">
                                            New visit
                                        </span>{" "}
                                        {visit.source === "direct" ? (
                                            "Direct"
                                        ) : (
                                            <dl
                                                className={
                                                    "grid grid-cols-1 sm:grid-cols-[min(50%,theme(spacing.80))_auto] gap-y-2 gap-x-4 "
                                                }
                                            >
                                                {details
                                                    .filter(
                                                        (detail) =>
                                                            !!detail.description,
                                                    )
                                                    .map((detail) => (
                                                        <div key={detail.term}>
                                                            <dt
                                                                className={
                                                                    "col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-0.5 dark:border-white/5 dark:text-zinc-400 sm:dark:border-white/5"
                                                                }
                                                            >
                                                                {detail.term}
                                                            </dt>
                                                            <dd
                                                                className={
                                                                    "empty-unknown pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-0.5 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none hyphens-auto"
                                                                }
                                                            >
                                                                {
                                                                    detail.description
                                                                }
                                                            </dd>
                                                        </div>
                                                    ))}
                                            </dl>
                                        )}
                                    </div>
                                    <time
                                        dateTime={visit.dateTime}
                                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                    >
                                        {formatDistanceToNow(
                                            new Date(visit.dateTime),
                                            {
                                                addSuffix: true,
                                            },
                                        )}
                                    </time>
                                </>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

const UnidentifiedCustomerPanelInner = function () {
    return (
        <div
            className={
                "h-full w-full px-12 flex items-center justify-center flex-col text-center"
            }
        >
            <WidgetEmptyState
                title={"Unidentified contact"}
                subtitle={
                    <>
                        Can't show details for unidentified contacts.{" "}
                        <Link
                            external
                            to={
                                "https://www.spectaclehq.com/docs/spectacle-js/identify"
                            }
                        >
                            Learn how to identify contacts
                        </Link>
                    </>
                }
            />
        </div>
    );
};
