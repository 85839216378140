export default function SalesforceIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path d="M144.33 246.588a60.33 60.33 0 0 0-8.052-.688c-6.058-.075-8.635 2.149-8.617 2.146-1.787 1.261-2.653 3.142-2.653 5.738 0 1.655.301 2.948.89 3.856.386.604.548.832 1.712 1.761l.017.01c-.019-.006 2.653 2.096 8.692 1.732 4.252-.256 8.015-1.066 8.015-1.066h-.004zm177.564-17.646c-3.913 0-6.683 1.335-8.504 4.12-1.833 2.803-2.766 6.792-2.766 11.871 0 5.08.928 9.106 2.766 11.928 1.825 2.796 4.609 4.158 8.504 4.158 3.895 0 6.69-1.373 8.541-4.177 1.854-2.839 2.802-6.843 2.803-11.918-.001-5.075-.938-9.062-2.803-11.862-1.844-2.771-4.628-4.12-8.541-4.12zm-119.235-.225c-3.894 0-6.664 1.523-8.485 4.308-1.213 1.824-1.988 4.175-2.407 6.995l21.165.004c-.201-2.729-.736-5.164-1.957-6.999-1.844-2.777-4.422-4.308-8.316-4.308z" />
            <path d="M401.563 125.308a109.12 109.12 0 0 0-44.589 9.501c-15.804-28.032-45.153-46.846-79.018-46.846-25.399 0-48.389 10.648-64.908 27.844l.019.098a99.087 99.087 0 0 0-79.018-39.133c-54.936 0-99.525 44.401-99.525 99.338a98.968 98.968 0 0 0 8.184 39.509C17.196 230.669 0 258.514 0 290.497c0 47.6 38.192 86.355 85.415 86.355a84.844 84.844 0 0 0 17.572-1.828c12.981 35.182 46.658 60.204 86.355 60.204 38.004 0 70.646-23.142 84.662-56.065a80.348 80.348 0 0 0 35.37 8.128c30.102 0 56.536-16.744 70.364-41.391a111.489 111.489 0 0 0 21.824 2.163C462.52 348.063 512 298.207 512 236.686c0-61.521-49.48-111.378-110.437-111.378Z" />
            <path d="M440.278 228.717c-3.894 0-6.666 1.523-8.485 4.308-1.215 1.824-1.988 4.175-2.407 6.995l21.165.004c-.199-2.729-.734-5.164-1.957-6.999-1.844-2.777-4.422-4.308-8.316-4.308z" />
        </svg>
    );
}
