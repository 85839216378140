import defaultTheme from "tailwindcss/defaultTheme";
import formsPlugin from "@tailwindcss/forms";
import tailwindCssAnimatePlugin from "tailwindcss-animate";

/** @type {import('tailwindcss').Config} */
export default {
    darkMode: "selector",
    content: [
        "./index.html",
        "./src/**/*.{js,ts,jsx,tsx}",
        "./node_modules/react-tailwindcss-datepicker/dist/index.esm.js",
    ],
    theme: {
        // container: {
        //     center: true,
        //     padding: "2rem",
        //     screens: {
        //         "2xl": "1400px",
        //     },
        // },
        extend: {
            fontFamily: {
                sans: ["Inter var", ...defaultTheme.fontFamily.sans],
            },
            colors: {
                "near-white": "#f2f2f2",
                "near-black": "#222222",
                "theme-1": "hsl(var(--theme-1))",
                "theme-2": "hsl(var(--theme-2))",
                "theme-3": "hsl(var(--theme-3))",
                "theme-4": "hsl(var(--theme-4))",
                "theme-5": "hsl(var(--theme-5))",
                border: "hsl(var(--border))",
            },
        },
    },
    plugins: [formsPlugin, tailwindCssAnimatePlugin],
};
