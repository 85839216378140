import {
    SetAttributionModel,
    SetCurrency,
    SetDateRange,
    SetDimension,
    SetDrillDownDimensions,
    SetFilters,
} from "@/common/ReportTable/types.ts";
import { useUiProperty } from "@/features/UiState.slice.ts";
import ListBox from "@/common/ListBox";
import AttributionModelPicker from "@/common/AttributionModelPicker";
import Filters from "@/common/Filters";
import { clsx } from "clsx";
import InfoTooltip from "@/common/InfoTooltip";
import DateRangePicker from "@/common/DateRangePicker";
import { format } from "date-fns";
import { ReactNode } from "react";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { AttributionModel } from "@/types/generated/AttributionModel.ts";
import ReportTypePicker from "@/common/ReportTable/ReportTypePicker.tsx";

export default function ControlBar({
    resultCurrencies,
    className,
    dateRangeDescription,
    dimension,
    setDimension,
    drillDownDimensions,
    setDrillDownDimensions,
    filters,
    setFilters,
    attributionModel,
    setAttributionModel,
    currency,
    setCurrency,
    dateRange,
    setDateRange,
}: {
    resultCurrencies?: string[];
    className?: string;
    dateRangeDescription?: ReactNode;
    filters: AttributionFilter[];
    setFilters: SetFilters;
    dimension: AttributionDimension;
    setDimension: SetDimension;
    drillDownDimensions: AttributionDimension[];
    setDrillDownDimensions: SetDrillDownDimensions;
    attributionModel: AttributionModel;
    setAttributionModel: SetAttributionModel;
    currency?: string;
    setCurrency?: SetCurrency;
    dateRange: {
        from: string;
        to: string;
    };
    setDateRange: SetDateRange;
}) {
    const [isDarkMode] = useUiProperty<boolean>("darkMode");

    if (currency && !setCurrency) {
        throw new Error("Expected setCurrency to be set");
    }

    return (
        <div
            className={clsx(
                "flex flex-col lg:flex-row lg:justify-between gap-y-4",
                className,
            )}
        >
            <div className={"flex flex-wrap gap-x-6 gap-y-4"}>
                <div className={"w-40"}>
                    <div className={"text-subtle text-xs mb-2"}>
                        Report type
                    </div>
                    <ReportTypePicker
                        dimension={dimension}
                        setDimension={setDimension}
                        drillDownDimensions={drillDownDimensions}
                        setDrillDownDimensions={setDrillDownDimensions}
                    />
                </div>
                <div className={"flex flex-col mr-12 sm:mr-4"}>
                    <div className={"text-subtle text-xs mb-2"}>
                        Attribution model
                    </div>
                    <AttributionModelPicker
                        model={attributionModel}
                        onChange={setAttributionModel}
                    />
                </div>

                <div className={"flex flex-col"}>
                    <div className={"text-subtle text-xs mb-2"}>Filters</div>
                    <Filters filters={filters} onChange={setFilters} />
                </div>
            </div>
            <div className={"flex flex-wrap gap-x-6 gap-y-4"}>
                {!!resultCurrencies && (
                    <div
                        className={clsx(
                            "flex-col transition-all",
                            resultCurrencies.length > 1
                                ? "flex opacity-100"
                                : "hidden opacity-0",
                        )}
                    >
                        <div
                            className={
                                "text-subtle text-xs mb-2 flex flex-row lg:justify-end"
                            }
                        >
                            Currency
                        </div>

                        {setCurrency && (
                            <ListBox
                                value={currency}
                                buttonBold={isDarkMode}
                                options={resultCurrencies.map((value) => ({
                                    value,
                                    label: value,
                                }))}
                                onChange={setCurrency}
                            />
                        )}
                    </div>
                )}
                <div className={"flex flex-col"}>
                    <div
                        className={
                            "text-subtle text-xs mb-2 flex flex-row lg:justify-end"
                        }
                    >
                        Date range{" "}
                        <InfoTooltip>
                            <>
                                <div className={"font-semibold mb-1"}>
                                    Date range and calculation
                                </div>
                                <div className={"mb-2"}>
                                    This report includes data for the selected
                                    date range as follows:
                                </div>
                                <div className={"ml-4"}>
                                    {dateRangeDescription}
                                </div>
                            </>
                        </InfoTooltip>
                    </div>

                    <DateRangePicker
                        value={{
                            from: new Date(dateRange.from),
                            to: new Date(dateRange.to),
                        }}
                        onChange={(value, compareValue) =>
                            setDateRange({
                                dateRange: {
                                    from: format(value.from, "yyyy-MM-dd"),
                                    to: format(value.to, "yyyy-MM-dd"),
                                },
                                compareDateRange: {
                                    from: format(
                                        compareValue.from,
                                        "yyyy-MM-dd",
                                    ),
                                    to: format(compareValue.to, "yyyy-MM-dd"),
                                },
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
}
