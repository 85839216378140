export default function RecurlyIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.61 19.209"
        >
            <path d="M12.303 13.864c-.873-1.376-1.534-2.275-1.984-2.62 2.275-.581 4.524-2.486 4.524-5.608C14.843 1.826 12.223 0 8.07 0 4.419 0 2.302 1.72 1.826 4.71H0l3.625 3.095L7.46 4.71H5.397s.027-2.144 2.726-2.144c2.302 0 3.095 1.694 3.095 3.096 0 2.037-1.084 3.598-3.677 3.598H1.799v9.949h3.625V11.72c.794 0 1.561.291 2.91 2.275l3.228 5.213h4.048ZM7.964 0h.106zm0 0" />
        </svg>
    );
}
