import { Contact } from "@/types/generated/Contact.ts";
import Panel from "@/common/Panel/Panel.tsx";
import { Avatar } from "@/catalyst/avatar.tsx";
import { formatDistance } from "date-fns";
import Link from "@/common/Link";
import UserBoxSkeleton from "@/common/Skeleton/UserBoxSkeleton.tsx";
import WidgetEmptyState from "@/features/Dashboard/Widgets/WidgetEmptyState.tsx";

const NUMBER_OF_RECENT_LEADS = 5;

export default function RecentLeadsWidget({
    recentLeads,
}: {
    recentLeads?: Contact[];
}) {
    const showEmptyState = recentLeads?.length === 0;

    return (
        <Panel
            title={"Recent leads"}
            subtitle={
                "Showing last contacts that performed one of your cut-off events"
            }
        >
            {!showEmptyState && (
                <div className={"flex flex-col gap-4"}>
                    {!recentLeads &&
                        Array.from({ length: NUMBER_OF_RECENT_LEADS }).map(
                            (_, index) => (
                                <UserBoxSkeleton
                                    key={`userbox-skeleton-${index}`}
                                />
                            ),
                        )}
                    {recentLeads?.map((lead) => {
                        const initials = `${lead.firstName[0] || "?"}${lead.lastName[0] || "?"}`;

                        return (
                            <div
                                key={lead.firstName + lead.lastName}
                                className={
                                    "flex items-center flex-row justify-between flex-1 text-md"
                                }
                            >
                                <div className={"flex items-center"}>
                                    <Avatar
                                        className={"mr-3 h-8 w-8"}
                                        initials={initials}
                                        generateBgColor={true}
                                        square
                                    />

                                    <div
                                        className={
                                            "flex flex-col space-between"
                                        }
                                    >
                                        <span>
                                            {lead.firstName} {lead.lastName}
                                        </span>
                                        <span className={"text-subtle text-xs"}>
                                            {lead.userId}
                                        </span>
                                    </div>
                                </div>
                                <div className={"text-subtle text-xs"}>
                                    {formatDistance(
                                        new Date(lead.convertedAt),
                                        new Date(),
                                        { addSuffix: true },
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    <div
                        className={
                            "flex items-center justify-center gap-4 text-subtle text-xs underline"
                        }
                    >
                        <Link to={"/contacts"}>Show all leads</Link>
                    </div>
                </div>
            )}
            {showEmptyState && (
                <WidgetEmptyState subtitle={"No tracked leads yet"} />
            )}
        </Panel>
    );
}
