import { ReactNode, useEffect, useMemo, useReducer, useRef } from "react";
import { ColDef, FilterModel, ICellRendererParams } from "ag-grid-community";
import { camelToSentence } from "@/util/case";
import {
    AdNetworkEntityCellRenderer,
    CellRendererParams,
    FilterAddCellRenderer,
    NotAttributedCellRenderer,
    SourceCellRenderer,
    TotalCellRenderer,
} from "@/common/DataGrid/CellRenderer.tsx";
import { AgGridReact } from "ag-grid-react";
import DataGrid from "@/common/DataGrid";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { SetFilters } from "@/common/ReportTable/types.ts";
import { reportingDimensions } from "@/common/ReportTable/ReportTypePicker.tsx";

type ExtendRecordType = Record<string, string | number | boolean | null>;
type ExtendedRecord = Record<
    string,
    | string
    | number
    | boolean
    | null
    | undefined
    | ExtendRecordType
    | ExtendRecordType[]
>;

export default function ReportTable<D extends ExtendedRecord>({
    rowData,
    colDefs,

    loading,
    dimension,
    filters,
    setFilters,
    currency,
    drillDownDimensions,
}: {
    rowData: D[];
    colDefs: ColDef[];
    loading: boolean;
    dimension: AttributionDimension;
    filters: AttributionFilter[];
    setFilters: SetFilters;
    currency?: string;
    drillDownDimensions: AttributionDimension[];
}) {
    const autoGroupColumnDef = useMemo<ColDef>(
        () =>
            getAutoGroupColumnDef(
                dimension,
                drillDownDimensions,
                filters,
                setFilters,
                colDefs,
            ),
        [colDefs, filters, dimension, setFilters],
    );

    const gridRef = useRef<AgGridReact>(null);
    const gridApi = gridRef.current?.api;

    // Set left most row according to current selected dimension
    useEffect(() => {
        gridApi?.applyColumnState({
            state: [
                {
                    colId: dimension,
                    rowGroup: true,
                    rowGroupIndex: 0,
                },
                ...(drillDownDimensions || []).map((d, i) => ({
                    colId: d,
                    rowGroup: true,
                    rowGroupIndex: i + 1,
                })),
            ],
            defaultState: {
                rowGroup: false,
            },
        });
    }, [dimension, drillDownDimensions, gridApi, colDefs]);

    const aggData =
        gridRef.current?.api?.getDisplayedRowAtIndex(0)?.parent?.aggData;

    // // Update pinned totals row
    useEffect(() => {
        if (!aggData) {
            gridApi?.setGridOption("pinnedBottomRowData", undefined);
            return;
        }

        gridApi?.setGridOption("pinnedBottomRowData", [
            {
                ...aggData,
                isBottomRow: true,
            },
        ]);
    }, [gridApi, aggData]);

    // Update grid filter model when filters change
    useEffect(() => {
        gridApi?.setFilterModel(
            filters.reduce(
                (carry, filter) => {
                    carry[filter.property] = {
                        filterType: "text",
                        type: filter.comparator,
                        filter: filter.value,
                    };
                    return carry;
                },
                {} as Record<string, FilterModel>,
            ),
        );
        gridApi?.onFilterChanged();
    }, [filters, gridApi]);

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const onRowDataUpdated = () => {
        if (!aggData) {
            // Component doesn't automatically re-render when aggData becomes available
            // Force re-render when aggData is undefined
            forceUpdate();
        }
    };

    /**
     * Unfortunately, ag-grid doesn't support hiding columns that have aggregated data.
     * That's why we need to filter the data manually.
     * @TODO: Check once in a while if this feature will be supported by ag-grid
     */
    const compareData = useMemo(() => {
        if (!filters) {
            return rowData;
        }

        return rowData.filter((report) => {
            return filters.every((filter) => {
                const value = report[filter.property as keyof D];
                if (value === null || typeof value === "undefined") {
                    return false;
                }

                const valueToFilter = value.toString();

                switch (filter.comparator) {
                    case "equals":
                        return valueToFilter === filter.value;
                    case "contains":
                        return valueToFilter.includes(filter.value);
                    case "startsWith":
                        return valueToFilter.startsWith(filter.value);
                    case "endsWith":
                        return valueToFilter.endsWith(filter.value);
                    default:
                        return false;
                }
            });
        });
    }, [rowData, filters]);

    const context = useMemo(() => {
        return {
            compareData,
            dimension,
            currency,
        };
    }, [compareData, currency, dimension]);

    useEffect(() => {
        // Hack to force redraw of rows after currency change
        gridApi?.onFilterChanged();
        gridApi?.redrawRows();
    }, [currency, gridApi]);

    return (
        <DataGrid
            onRowDataUpdated={onRowDataUpdated}
            loading={loading}
            rowData={rowData}
            columnDefs={colDefs}
            pivotMode={true}
            suppressExpandablePivotGroups={true}
            groupDisplayType={"groupRows"}
            autoGroupColumnDef={autoGroupColumnDef}
            ref={gridRef}
            context={context}
        />
    );
}

const getAutoGroupColumnDef = (
    dimension: AttributionDimension,
    drillDownDimensions: AttributionDimension[],
    filters: AttributionFilter[],
    setFilters: SetFilters,
    colDefs: ColDef[],
): ColDef => {
    let headerName =
        colDefs.find((colDef) => colDef.field === dimension)?.headerName ||
        camelToSentence(dimension);

    if (drillDownDimensions.length) {
        headerName += ` → ${drillDownDimensions.map((d) => reportingDimensions[d]).join(" → ")}`;
    }

    return {
        minWidth: 300,
        headerName,
        cellRendererParams: {
            suppressCount: true,
        },
        cellRendererSelector: (params: ICellRendererParams) => {
            const renderer: {
                component?: ((props: CellRendererParams) => ReactNode) | string;
                params: {
                    filters: AttributionFilter[];
                    setFilters: SetFilters;
                    dimension: AttributionDimension;
                    innerRenderer?: (props: CellRendererParams) => ReactNode;
                };
            } = {
                params: { setFilters, dimension, filters },
            };

            let component:
                | ((props: CellRendererParams) => ReactNode)
                | string
                | undefined = undefined;

            const field = params.node.field || dimension;

            if (field === "source") {
                component = SourceCellRenderer;
            }

            if (["campaign", "ad_group", "ad", "keyword"].includes(field)) {
                component = AdNetworkEntityCellRenderer;
            }

            if (params.value === null) {
                component = NotAttributedCellRenderer;
            }

            if (params.data?.isBottomRow) {
                component = TotalCellRenderer;
            }

            if (!component) {
                component = FilterAddCellRenderer;
            }

            if (params.node.group) {
                renderer.params.innerRenderer = component;
                renderer.component = "agGroupCellRenderer";
            } else {
                renderer.component = component;
            }

            return renderer;
        },
    };
};
