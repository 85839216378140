import { Button as CatalystButton } from "@/catalyst/button";
import {
    ComponentProps,
    MouseEventHandler,
    useCallback,
    useEffect,
    useState,
} from "react";
import classNames from "classnames";
import Spinner from "@/common/Spinner/Spinner.tsx";

export type ButtonProps = Omit<
    ComponentProps<typeof CatalystButton>,
    "onClick"
> & {
    nativeType?: "button" | "submit" | "reset";
    icon?: React.ElementType;
    loading?: boolean;
    disabled?: boolean;
    type?: ButtonType;
    onClick?: (
        event: React.MouseEvent,
        setBanner: (message: string) => void,
    ) => void;
};

type ButtonType = "primary" | "danger";

export default function Button(props: ButtonProps) {
    const {
        children,
        loading,
        icon: Icon,
        className,
        nativeType,
        type,
        onClick,
        ...buttonProps
    } = props;

    const newClassName = classNames(
        className,
        {
            ["pointer-events-none opacity-50"]: loading,
        },
        "transition-[width]",
    );

    const size = "md";

    const iconClassNames = classNames("text-inherit", {
        // ["-ml-0.5 mr-1 h-2 w-2"]: size === "xs",
        // ["-ml-0.5 mr-2 h-4 w-4"]: size === "sm",
        ["-ml-1 mr-1 h-5 w-5"]: size === "md",
        // ["h-3.5 w-3.5"]: size === "round",
        // ["-ml-1 mr-3 h-5 w-5"]: ["xl", "lg"].includes(size),
    });

    let color: "indigo" | "red" | "zinc";
    switch (type) {
        case "primary":
            color = "indigo";
            break;
        case "danger":
            color = "red";
            break;
        default:
            color = "zinc";
    }

    const [banner, setBanner] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!banner) {
            return;
        }

        setTimeout(() => {
            setBanner(undefined);
        }, 750);
    }, [banner]);

    const cbOnClick = useCallback<MouseEventHandler>(
        (event) => {
            onClick && onClick(event, setBanner);
        },
        [onClick],
    );

    return (
        // @ts-expect-error Don't know why this is an error
        <CatalystButton
            {...buttonProps}
            type={nativeType}
            color={color}
            onClick={onClick ? cbOnClick : undefined}
            className={newClassName}
        >
            {!loading && Icon && (
                <Icon className={iconClassNames} aria-hidden={true} />
            )}
            {loading && (
                <Spinner className={iconClassNames} color={"default"} />
            )}
            {banner || children}
        </CatalystButton>
    );
}
