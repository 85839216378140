import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import DataGridLoadingOverlay from "@/common/DataGrid/CustomLoadingOverlay.tsx";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getUiProperty } from "@/features/UiState.slice.ts";
import {
    GroupFilterModule,
    PivotModule,
    RowGroupingModule,
    RowGroupingPanelModule,
    TreeDataModule,
} from "ag-grid-enterprise";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import { ModuleRegistry } from "ag-grid-community";
import {
    AllCommunityModule,
    ClientSideRowModelModule,
} from "ag-grid-community";
import { ClipboardModule } from "ag-grid-enterprise";

import { provideGlobalGridOptions } from "ag-grid-community";

// Mark all grids as using legacy themes
provideGlobalGridOptions({
    theme: "legacy",
});

LicenseManager.setLicenseKey(
    "Peace_OTY2OTQ1OTQ1Njk3Mw==7e213e88aef89910e528cf77b5ac1af0",
);
ModuleRegistry.registerModules([
    RowGroupingModule,
    RowGroupingPanelModule,
    GroupFilterModule,
    TreeDataModule,
    PivotModule,
    ClientSideRowModelModule,
    AllCommunityModule,
    ClipboardModule,
]);

export default function DataGrid({
    onRowClicked,
    ref,
    ...props
}: AgGridReactProps & { loading: boolean; ref?: React.Ref<AgGridReact> }) {
    const darkMode = useSelector(getUiProperty("darkMode"));

    return (
        <div
            className={classNames(
                `ag-theme-quartz${darkMode ? "-dark" : ""}`,
                "grow",
            )}
        >
            <AgGridReact
                suppressAggFuncInHeader={true}
                {...props}
                loading={props.loading}
                onRowClicked={onRowClicked}
                suppressGroupRowsSticky={true}
                className={classNames({
                    "ag-grid-row-clickable": !!onRowClicked,
                })}
                ref={ref}
                loadingOverlayComponent={DataGridLoadingOverlay}
            />
        </div>
    );
}
