import { useEffect, useState } from "react";
import Money from "@/common/Money/index.tsx";
import { Dropdown, DropdownItem, DropdownMenu } from "@/catalyst/dropdown.tsx";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import Result from "@/common/Result";
import { MenuButton } from "@headlessui/react";

export type MultiCurrencyMoneyItems = Record<string, string>;

export default function MultiCurrencyMoney({
    items,
    defaultCurrency,
    isResult,
}: {
    items: MultiCurrencyMoneyItems;
    defaultCurrency: string;
    isResult?: boolean;
}) {
    const dc = Object.hasOwn(items, defaultCurrency)
        ? defaultCurrency
        : Object.keys(items)[0];
    const [currency, setCurrency] = useState(dc);
    useEffect(() => {
        if (!currency) {
            setCurrency(dc);
        }
    }, [currency, dc]);

    const value = items[currency as keyof typeof items];

    if (typeof value === "undefined") {
        return null;
    }

    const amount = parseFloat(value);

    const component =
        Object.entries(items).length > 1 ? (
            <div className={"flex flex-row justify-between"}>
                <Money amount={amount} currency={currency} />
                <Dropdown>
                    <MenuButton className={"bg-transparent ml-2"}>
                        <ChevronDownIcon className={"h-4 w-4 text-zinc-500"} />
                    </MenuButton>
                    <DropdownMenu>
                        {Object.keys(items).map((cur: string) => (
                            <DropdownItem
                                key={cur}
                                onClick={() => {
                                    setCurrency(cur);
                                }}
                            >
                                {cur}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        ) : (
            <Money
                amount={amount}
                currency={currency} // Don't show "for $" when we have single currency
            />
        );

    if (isResult) {
        return <Result amount={amount}>{component}</Result>;
    }

    return component;
}
