import { clsx } from "clsx";

export default function FunnelSkeleton({
    className,
    stepCount = 2,
}: {
    className?: string;
    stepCount?: number;
}) {
    const count = Math.max(stepCount, 2);
    const stepSize = 100 / count;
    return (
        <div
            role="status"
            className={clsx(className, "w-full rounded animate-pulse")}
        >
            <div className="flex items-baseline h-full justify-between gap-8">
                {Array.from({ length: stepCount }, (_, i) => i).map(
                    (hClass, index) => (
                        <div
                            key={hClass}
                            style={{ height: `${100 - index * stepSize}%` }}
                            className={clsx(
                                "w-full bg-zinc-200 rounded-t-lg dark:bg-zinc-800",
                                index !== 0 && "ms-3",
                            )}
                        ></div>
                    ),
                )}
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    );
}
