import { useGetWorkspaceIntegrationsQuery } from "@/services/apis/api.ts";
import { ReactNode, useRef, useState } from "react";
import { components } from "@/types/generated/api-schema.ts";
import { CheckBadgeIcon } from "@heroicons/react/16/solid";
import Button from "@/common/Button";
import { BellIcon } from "@heroicons/react/20/solid";
type Integration = components["schemas"]["Integration"];
type WorkspaceIntegration = components["schemas"]["WorkspaceIntegration"];
import useAnalytics from "@/services/analytics/useAnalytics.ts";
import IntegrationDetailsPanel from "@/features/Workspace/Settings/IntegrationDetailsPanel.tsx";
import { Integrations } from "@/features/Workspace/Settings/integrations.tsx";
import { useUiProperty } from "@/features/UiState.slice.ts";
import { assertNotUndefined } from "@/util/typeguards.ts";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Input } from "@/catalyst/input.tsx";
import { toast } from "@/common/Toast";

export default function IntegrationsGrid({
    integrations,
    showSuggestBlock = false,
}: {
    integrations: Integrations;
    showSuggestBlock?: boolean;
}) {
    const { data } = useGetWorkspaceIntegrationsQuery(undefined);

    const [selectedIntegration, setSelectedIntegration] = useState<
        Integration | undefined
    >(undefined);

    const analytics = useAnalytics();
    const [integrationNotificationMap, setIntegrationNotificationMap] =
        useUiProperty<Record<Integration, boolean>>(
            "integrationNotificationMap",
            {} as Record<Integration, boolean>,
        );

    assertNotUndefined(integrationNotificationMap);

    return (
        <>
            <IntegrationDetailsPanel
                selectedIntegration={selectedIntegration}
                onClose={() => setSelectedIntegration(undefined)}
                connectedAccounts={(data || []).filter(
                    (x) => x.integration === selectedIntegration,
                )}
            />
            <div className={"flex gap-6 flex-wrap"}>
                {Object.values(integrations).map((integration) => {
                    const workspaceIntegration = data?.find(
                        (wi: WorkspaceIntegration) =>
                            wi.integration === integration.id,
                    );
                    return (
                        <IntegrationBlock
                            key={integration.id}
                            connected={
                                workspaceIntegration?.status === "active"
                            }
                            icon={integration.icon}
                            title={integration.title}
                            description={integration.description}
                            comingSoon={integration.comingSoon || false}
                            canConnect={
                                !integration.comingSoon &&
                                integration.canConnect
                            }
                            willBeNotified={
                                integrationNotificationMap[
                                    integration.id as keyof typeof integrationNotificationMap
                                ]
                            }
                            onClick={() => {
                                if (!integration.comingSoon) {
                                    setSelectedIntegration(
                                        integration.id as Integration,
                                    );
                                } else if (
                                    !integrationNotificationMap[
                                        integration.id as keyof typeof integrationNotificationMap
                                    ]
                                ) {
                                    analytics.trackExpressedIntegrationInterestEvent(
                                        integration.id as Integration,
                                    );
                                    setIntegrationNotificationMap({
                                        ...integrationNotificationMap,
                                        [integration.id as keyof typeof integrationNotificationMap]:
                                            true,
                                    });
                                }
                            }}
                        />
                    );
                })}
                {showSuggestBlock && <SuggestIntegrationBlock />}
            </div>
        </>
    );
}

const IntegrationBlock = ({
    icon,
    title,
    description,
    comingSoon,
    connected,
    onClick,
    canConnect,
    willBeNotified,
}: {
    icon: ReactNode;
    title: string;
    description: string;
    comingSoon: boolean;
    willBeNotified?: boolean;
    connected?: boolean;
    onClick?: (setBanner: (message: string) => void) => void;
    canConnect: boolean;
}) => {
    return (
        <div
            className={
                "w-full dark:border-white/10 border max-w-60 border-solid rounded-lg p-4 flex flex-col justify-between relative"
            }
        >
            {connected && (
                <CheckBadgeIcon
                    className={
                        "h-5 w-5 text-green-500 absolute opacity-85 top-2 right-2"
                    }
                />
            )}
            <div>
                <div className={"mb-2"}>{icon}</div>
                <h3 className={"text-default"}>{title}</h3>
                <p className={"text-subtle-on-light-block mb-6"}>
                    {description}
                </p>
            </div>
            <Button
                outline={comingSoon}
                type={"primary"}
                onClick={(_, setBanner) => !!onClick && onClick(setBanner)}
            >
                {comingSoon ? (
                    <>
                        <BellIcon />
                        {willBeNotified ? (
                            <span className={"ml-2"}>You will be notified</span>
                        ) : (
                            "Notify me"
                        )}
                    </>
                ) : connected ? (
                    "Manage"
                ) : canConnect ? (
                    "Connect"
                ) : (
                    "Set up"
                )}
            </Button>
        </div>
    );
};

const SuggestIntegrationBlock = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const analytics = useAnalytics();

    return (
        <div
            className={
                "w-full dark:border-white/10 border max-w-60 border-dashed rounded-lg p-4 flex flex-col justify-between relative"
            }
        >
            <div>
                <div className={"mb-2"}>
                    <PlusCircleIcon className={"h-6 w-6"} />
                </div>
                <h3 className={"text-default"}>Suggest new integration</h3>
                <p className={"text-subtle-on-light-block mb-4"}>
                    Is your integration missing? Let us know!
                </p>
                <Input
                    ref={inputRef}
                    className={"mb-4"}
                    placeholder={"E.g. my payment provider"}
                />
            </div>
            <Button
                type={"primary"}
                onClick={(_, setBanner) => {
                    if (!inputRef.current) {
                        throw new Error("Input ref is null");
                    }
                    const value = inputRef.current.value;
                    analytics.trackSuggestedIntegrationEvent(value);
                    inputRef.current.value = "";
                    setBanner("Thanks!");
                    toast.success("Suggestion sent!");
                }}
            >
                Submit
            </Button>
        </div>
    );
};
