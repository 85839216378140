export default function YahooIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <path d="M31.073,6.703l-3.65,1.226c-0.544,0.242-2.021,1.955-4.583,5.263c-2.72,3.425-4.118,5.624-4.353,6.727  l-0.153,3.061l-0.08,1.833l0.314,4.648l4.815,0.121L22.749,32H8.421l0.28-2.172l2.251-0.126c1.168-0.121,1.866-0.366,2.099-0.732  c0.155-0.241,0.231-1.717,0.231-4.285v-1.591l-0.076-3.178c-0.155-0.731-1.398-3.426-3.806-8.073  C6.915,7.07,5.283,4.258,4.505,3.277l-4.506-0.977V0h15.25L15.07,1.935l-4.737,0.734c1.476,3.424,3.729,8.192,6.757,14.188  c3.964-4.771,6.061-7.829,6.137-9.172l-4.038-0.98L18.999,4.5h13L31.073,6.703z" />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
}
