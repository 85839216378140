import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useGetContactsQuery } from "@/services/apis/data.ts";
import { assertNotUndefined } from "@/util/typeguards";

import DataGrid from "@/common/DataGrid";
import { Avatar } from "@/catalyst/avatar.tsx";
import { Contact } from "@/types/generated/Contact.ts";
import Money from "@/common/Money";
import Result from "@/common/Result";
import { CalculatorIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import Tooltip from "@/common/Tooltip";
import { useMemo, useState } from "react";
import ContactDetailsPanel from "../../common/ContactDetailsPanel";
import { Field } from "@/catalyst/fieldset.tsx";
import { Input } from "@/catalyst/input.tsx";
import { clsx } from "clsx";
import ListBox from "@/common/ListBox";
import { FullHeightMainContainer } from "@/common/Container";
import { useUiProperty } from "@/features/UiState.slice.ts";
import { useSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

export default function List() {
    const selectedWorkspace = useSelectedWorkspace();

    const { data, isLoading } = useGetContactsQuery({
        workspaceId: selectedWorkspace.id,
        defaultCurrency: selectedWorkspace.defaultCurrency,
        cutOffEvents: selectedWorkspace.cutOffEvents,
        reportingCurrency: selectedWorkspace.reportingCurrency || null,
    });

    // Undefined = nothing selected
    // Null = Row had no user id
    // String = Selected user id
    const [selectedUserIdOrEmail, setSelectedUserIdOrEmailOrEmail] = useState<
        undefined | null | string
    >(undefined);

    // We can get a way with frontend based filtering for now since the data won't bee too large to start with
    // this should probably be done server side in the future
    const [filterString, setFilterString] = useState<undefined | string>(
        undefined,
    );

    const [currency, setCurrency] = useState(
        selectedWorkspace.reportingCurrency ||
            selectedWorkspace.defaultCurrency,
    );

    const context = useMemo(() => ({ currency }), [currency]);
    const resultCurrencyCount = data?.currencies?.length || 0;
    const [isDarkMode] = useUiProperty<boolean>("darkMode");

    return (
        <FullHeightMainContainer>
            <div className={"mb-4 flex justify-between"}>
                <div className={"w-48 flex flex-col"}>
                    <div className={"text-subtle text-xs mb-2"}>Search</div>
                    <Field className={"flex items-center"}>
                        <MagnifyingGlassIcon
                            className={
                                "absolute h-4 w-4 ml-[calc(theme(spacing[3.5])-1px)] sm:ml-[calc(theme(spacing[3])-1px)] text-zinc-500 z-10"
                            }
                        />
                        <Input
                            inputClassName={
                                "!pl-[calc(theme(spacing[3.5])-1px+theme(spacing[4])+3px)]"
                            }
                            placeholder={"Search"}
                            name="filter_string"
                            onChange={(event) =>
                                setFilterString(event.target.value)
                            }
                        />
                    </Field>
                </div>
                <div
                    className={clsx(
                        "flex flex-col transition-all opacity-0",
                        resultCurrencyCount > 1 && "opacity-100",
                    )}
                >
                    <div
                        className={
                            "text-subtle text-xs mb-2 flex flex-row justify-end"
                        }
                    >
                        Currency
                    </div>

                    <ListBox
                        value={currency}
                        buttonBold={isDarkMode}
                        options={(data?.currencies || []).map((value) => ({
                            value,
                            label: value,
                        }))}
                        onChange={setCurrency}
                    />
                </div>
            </div>
            <DataGrid
                rowData={data?.data}
                columnDefs={colDefs}
                onRowClicked={(row) =>
                    setSelectedUserIdOrEmailOrEmail(
                        row.data.user || row.data.email || null,
                    )
                }
                loading={isLoading}
                quickFilterText={filterString}
                context={context}
            />
            <ContactDetailsPanel
                userIdOrEmail={selectedUserIdOrEmail}
                setOpened={() => setSelectedUserIdOrEmailOrEmail(undefined)}
            />
        </FullHeightMainContainer>
    );
}

const colDefs = [
    {
        headerName: "Name",
        field: "Name",
        minWidth: 100,
        valueGetter: (params: ICellRendererParams<Contact>) =>
            params.data?.firstName || params.data?.lastName
                ? `${params.data?.firstName} ${params.data?.lastName}`
                : null,
        cellRenderer: (params: ICellRendererParams<Contact>) => {
            const initials = `${params.data?.firstName[0] || "?"}${params.data?.lastName[0] || "?"}`;
            return (
                <div className={"flex items-center h-full flex-row"}>
                    <Avatar
                        className={"mr-3 w-7 h-7"}
                        initials={initials}
                        generateBgColor={true}
                        square
                    />
                    <span>
                        {params.value || (
                            <span className={"text-subtle"}>Unknown</span>
                        )}
                    </span>
                </div>
            );
        },
    },
    {
        headerName: "Converted at",
        field: "convertedAt",
        minWidth: 100,
        valueFormatter: (params: { value: string }) =>
            new Date(params.value).toLocaleDateString(),
    },
    {
        headerName: "User id",
        field: "userId",
        minWidth: 100,
    },
    {
        field: "email",
        minWidth: 100,
    },
    {
        field: "plan",
        minWidth: 100,
    },
    {
        headerName: "LTV",
        field: "ltv",
        minWidth: 100,
        cellRenderer: (params: ICellRendererParams) => (
            <Result amount={params.value}>
                <Money
                    amount={params.value}
                    currency={params.context.currency}
                />
            </Result>
        ),
        valueGetter: (params: ICellRendererParams) =>
            params.data.ltvs[params.context.currency] || 0,
    },
    {
        headerName: "Payments",
        field: "paymentCount",
        minWidth: 100,
    },
    {
        headerName: "Average Payment",
        cellRenderer: (params: ICellRendererParams<Contact>) => {
            assertNotUndefined(params.data);
            const contact: Contact = params.data;
            const paymentCount = parseInt(contact.paymentCount);
            const ltv = parseFloat(
                contact.ltvs[params.context.currency] || "0",
            );

            const mrr = paymentCount > 0 ? ltv / paymentCount : 0;
            return <Money amount={mrr} currency={params.context.currency} />;
        },
    },
    {
        headerName: "MRR",
        field: "mrr",
        minWidth: 100,
        cellRenderer: (params: ICellRendererParams<Contact>) => {
            assertNotUndefined(params.data);
            const contact: Contact = params.data;
            if (!contact.mrr && !contact.arr) {
                return null;
            }
            const isCalculated = contact.mrr === null;

            const arr = contact.mrr
                ? parseFloat(contact.mrr)
                : parseFloat(contact.arr!) / 12;

            return (
                <Tooltip
                    content={
                        "No explicit MRR was sent for this Contact. MRR is calculated as ARR / 12"
                    }
                >
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Money
                            amount={arr}
                            currency={params.context.currency}
                        />
                        {isCalculated && (
                            <CalculatorIcon className={"h-3 w-3 text-subtle"} />
                        )}
                    </div>
                </Tooltip>
            );
        },
    },
    {
        headerName: "ARR",
        field: "arr",
        minWidth: 100,
        cellRenderer: (params: ICellRendererParams<Contact>) => {
            assertNotUndefined(params.data);
            const contact: Contact = params.data;
            if (!contact.mrr && !contact.arr) {
                return null;
            }
            const isCalculated = contact.arr === null;

            const arr = contact.arr
                ? parseFloat(contact.arr)
                : parseFloat(contact.mrr!) * 12;
            return (
                <Tooltip
                    content={
                        "No explicit ARR was sent for this Contact. ARR is calculated as MRR * 12"
                    }
                >
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Money
                            amount={arr}
                            currency={params.context.currency}
                        />
                        {isCalculated && (
                            <CalculatorIcon className={"h-3 w-3 text-subtle"} />
                        )}
                    </div>
                </Tooltip>
            );
        },
    },
] as ColDef[];
