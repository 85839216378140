import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedWorkspace } from "@/features/Workspace/Workspace.slice.ts";

import { components } from "@/types/generated/api-schema";
import Skeleton from "@/common/Skeleton/Skeleton.tsx";
import { useGetWorkspacesQuery } from "@/services/apis/api.ts";
import { Avatar } from "@/catalyst/avatar.tsx";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "@/catalyst/dropdown.tsx";
import { SidebarItem, SidebarLabel } from "@/catalyst/sidebar.tsx";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/16/solid";
import { useOptionalSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";

type OrganizationDomain = components["schemas"]["Workspace"];

interface WorkspaceProps {
    setNewWorkspaceDialogOpen: (
        value: ((prevState: boolean) => boolean) | boolean,
    ) => void;
}

export default function WorkspacePicker({
    setNewWorkspaceDialogOpen,
}: WorkspaceProps) {
    const { data, isLoading } = useGetWorkspacesQuery(undefined);

    const selectedWorkspace = useOptionalSelectedWorkspace();

    const selected = data?.find((d) => d.id === selectedWorkspace?.id);
    const dispatch = useDispatch();

    const hasNoWorkspacesYet = data?.length === 0 && !isLoading;

    useEffect(() => {
        if (hasNoWorkspacesYet) {
            setNewWorkspaceDialogOpen(true);
        }

        if (!data || selected) {
            return;
        }

        if (typeof data[0] !== "undefined") {
            dispatch(setSelectedWorkspace(data[0]));
            setNewWorkspaceDialogOpen(false);
        }
    }, [data, hasNoWorkspacesYet]);

    return typeof data === "undefined" ||
        typeof selected === "undefined" ||
        isLoading ? (
        <Dropdown>
            <DropdownButton as={SidebarItem}>
                <Avatar
                    initials={"aa"}
                    className="bg-transparent text-transparent"
                />
                <SidebarLabel>
                    <Skeleton />
                </SidebarLabel>
                <ChevronDownIcon />
            </DropdownButton>
        </Dropdown>
    ) : (
        <LoadedWorkspacePicker
            selected={selected}
            workspaces={data}
            setNewWorkspaceDialogOpen={setNewWorkspaceDialogOpen}
        />
    );
}

function LoadedWorkspacePicker({
    selected,
    workspaces,
    setNewWorkspaceDialogOpen,
}: {
    selected: OrganizationDomain;
    workspaces: OrganizationDomain[];
    setNewWorkspaceDialogOpen: (
        value: ((prevState: boolean) => boolean) | boolean,
    ) => void;
}) {
    const dispatch = useDispatch();

    return (
        <Dropdown>
            <DropdownButton as={SidebarItem}>
                <Avatar
                    src={selected.favicon}
                    initials={selected.name.slice(0, 2)}
                    className="bg-near-white"
                    textClassName={"text-near-black"}
                />
                <SidebarLabel>{selected.name}</SidebarLabel>
                <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu
                className="min-w-80 lg:min-w-64"
                anchor="bottom start"
            >
                {workspaces.map((workspace) => (
                    <DropdownItem
                        key={workspace.id}
                        onClick={() =>
                            dispatch(setSelectedWorkspace(workspace))
                        }
                    >
                        <Avatar
                            slot="icon"
                            src={workspace.favicon}
                            initials={workspace.name.slice(0, 2)}
                            className="bg-near-white"
                            textClassName={"text-near-black"}
                        />
                        <DropdownLabel>{workspace.name}</DropdownLabel>
                    </DropdownItem>
                ))}
                <DropdownDivider />
                <DropdownItem onClick={() => setNewWorkspaceDialogOpen(true)}>
                    <PlusIcon />
                    <DropdownLabel>New workspace&hellip;</DropdownLabel>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
