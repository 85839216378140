import { DateRangeStrings } from "@/util/date.ts";
import { addDays, format, max, subDays, subMonths } from "date-fns";
import { differenceInDays } from "date-fns";

export const getCompareDateRange = (
    dateRange: DateRangeStrings,
): DateRangeStrings => {
    const from = new Date(dateRange.from);

    const to = new Date(dateRange.to);
    const daysBetween = differenceInDays(to, from);

    return {
        from: format(subDays(from, daysBetween + 1), "yyyy-MM-dd"),
        to: format(subDays(from, 1), "yyyy-MM-dd"),
    };
};

export const getDateRangeWithMinDate = (
    dateRange: DateRangeStrings,
    minDate: string,
): DateRangeStrings => {
    const min = new Date(minDate);
    const from = new Date(dateRange.from);
    const fromDate = max([from, min]);

    return {
        from: format(fromDate, "yyyy-MM-dd"),
        to: dateRange.to,
    };
};

export const getDateRangeOneYearAgoWithMinDate = (
    minDate: string,
): DateRangeStrings => {
    const fromDateOneYearAgo = addDays(subMonths(new Date(), 12), 1);

    return getDateRangeWithMinDate(
        {
            from: format(fromDateOneYearAgo, "yyyy-MM-dd"),
            to: format(new Date(), "yyyy-MM-dd"),
        },
        minDate,
    );
};

export const getDefaultDateRangeWithMinDate = (
    minDate: string,
): { dateRange: DateRangeStrings } => {
    // Subtract one day to account for TZ differences between user and UTC
    const min = format(subDays(new Date(minDate), 1), "yyyy-MM-dd");
    return { dateRange: getDateRangeOneYearAgoWithMinDate(min) };
};

export const getDefaultDateRangeAndCompareWithMinDate = (
    minDate: string,
): {
    dateRange: DateRangeStrings;
    compareDateRange: DateRangeStrings;
} => {
    // Subtract one day to account for TZ differences between user and UTC
    const min = format(subDays(new Date(minDate), 1), "yyyy-MM-dd");
    const dateRange = getDateRangeOneYearAgoWithMinDate(min);
    return {
        dateRange,
        compareDateRange: getCompareDateRange(dateRange),
    };
};
