import { useContext, useState } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { capitalize } from "@/util/case.ts";
import Button from "@/common/Button";
import Tooltip from "@/common/Tooltip";
import Text from "@/common/Text";
import {
    useGetAllTeamMembersQuery,
    useGetManageSubscriptionPortalSessionMutation,
    useUpdateTeamMemberMutation,
} from "@/services/apis/api.ts";
import UserContext from "@/app/UserContext.ts";
import FeatureFlagManagerContext from "@/services/featureFlags/FeatureFlagManagerContext.ts";
import UpgradeDialog from "@/common/UpgradeDialog";
import WithFeatureFlagCheck from "@/common/WithFeatureFlagCheck";
import Loading from "@/common/Loading";
import { User } from "@/model/User.ts";
import { UserPermissionsTypeEnum } from "@/types/generated/api-enums.ts";
import EditableField from "@/common/EditableField";

import { components } from "@/types/generated/api-schema";
import NewTeamMemberDialog from "@/features/Workspace/TeamMembers/NewTeamMemberDialog.tsx";
import { Avatar } from "@/catalyst/avatar.tsx";
import { produce } from "immer";
import { assertNotUndefined } from "@/util/typeguards";
import Panel from "@/common/Panel/Panel.tsx";
import { Container } from "@/common/Container";
type UserPermissionsType = components["schemas"]["UserPermissionsType"];

export default function OrganizationSettings() {
    return (
        <Container className={"space-y-8"}>
            <h2 className="text-3xl font-medium text-default leading-6">
                Organization settings
            </h2>
            <SectionAccount />
            <SectionTeam />
        </Container>
    );
}

const SectionAccount = () => {
    const user = useContext(UserContext);

    assertNotUndefined(user);

    // Load portal session for registered Admins
    const [
        getManageSubscriptionPortalSession,
        { isLoading: isLoadingPortalSession },
    ] = useGetManageSubscriptionPortalSessionMutation({});

    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

    const isRegisteredCustomer =
        user.organization?.isRegisteredCustomer || false;

    return (
        <section>
            <div className="sm:overflow-hidden sm:rounded-md">
                <Panel
                    title={"Subscription"}
                    subtitle={"Manage account & subscription"}
                >
                    <div
                        className={
                            "divide-x justify-evenly divide-zinc-950/10 dark:divide-white/10 grid grid-cols-2 gap-12 mt-6"
                        }
                    >
                        <div
                            className={classNames(
                                "relative overflow-hidden rounded-lg px-4 pt-5 sm:px-6 sm:pt-6 bg-transparent dark:bg-white/5",
                                "shadow-sm dark:shadow-none rounded-lg bg-transparent border-zinc-950/10 dark:border-white/10 border-solid border",
                                !user.organization?.plan && "pb-12",
                            )}
                        >
                            <dt>
                                <div className="absolute rounded-md bg-green-100 p-3">
                                    <StarIcon
                                        className="h-6 w-6 text-green-600"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-subtle">
                                    Your Plan
                                </p>
                            </dt>
                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-default">
                                    {user.organization?.plan
                                        ? capitalize(user.organization?.plan)
                                        : "Beta"}
                                </p>
                                {!user.organization?.plan && (
                                    <div className="absolute inset-x-0 bottom-0 dark:bg-white/5 bg-zinc-50 px-4 py-4 sm:px-6">
                                        <div className="text-sm">
                                            <a
                                                href="#"
                                                className="font-medium text-indigo-600 dark:text-default"
                                                onClick={() =>
                                                    setUpgradeDialogOpen(true)
                                                }
                                            >
                                                Upgrade Now
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </dd>
                        </div>
                        <div className={"pl-12"}>
                            <h2 className="text-lg font-medium text-default">
                                Subscription portal
                            </h2>{" "}
                            <p className="mt-1 mb-6 text-sm text-subtle">
                                Manage your billing details, invoices and plan
                            </p>
                            <Tooltip
                                content={
                                    !isRegisteredCustomer
                                        ? "You're not yet a Spectacle Customer. Upgrade first to gain access to the subscription portal"
                                        : "Only Admin users can have access to the Subscription Portal"
                                }
                                enabled={
                                    !user.organization?.isRegisteredCustomer ||
                                    !user.hasAdminPermissions()
                                }
                            >
                                <Button
                                    loading={isLoadingPortalSession}
                                    disabled={
                                        !user.organization
                                            ?.isRegisteredCustomer ||
                                        !user.hasAdminPermissions()
                                    }
                                    onClick={async () => {
                                        const result =
                                            await getManageSubscriptionPortalSession(
                                                undefined,
                                            );
                                        assertNotUndefined(result.data);
                                        window.location.href = result.data.url;
                                    }}
                                >
                                    Go to Portal
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </Panel>

                {upgradeDialogOpen && (
                    <UpgradeDialog
                        onClose={() => setUpgradeDialogOpen(false)}
                        open={upgradeDialogOpen}
                        userIsAdmin={user.hasAdminPermissions()}
                    />
                )}
            </div>
        </section>
    );
};

const SectionTeam = () => {
    const { data: teamMembers, isLoading } =
        useGetAllTeamMembersQuery(undefined);

    const [updateTeamMember] = useUpdateTeamMemberMutation();
    const userModel = useContext(UserContext);
    const [newTeamMemberDialogOpen, setNewTeamMemberDialogOpen] =
        useState(false);
    const featureFlagManager = useContext(FeatureFlagManagerContext);

    if (!userModel) {
        throw new Error("Expected user here");
    }

    return (
        <>
            <section>
                <div className="sm:rounded-md">
                    <Panel
                        title={"Team"}
                        subtitle={"Invite team members to collaborate"}
                        action={
                            <div className={"sm:flex-none"}>
                                <Tooltip
                                    enabled={!userModel.hasAdminPermissions()}
                                    content={
                                        "Only Admin users can add new Team Members"
                                    }
                                >
                                    <WithFeatureFlagCheck
                                        passesCheck={featureFlagManager.isWithinThreshold(
                                            "team-member-limit",
                                        )}
                                        opener={
                                            "action-button-team-member-limit"
                                        }
                                    >
                                        {(needsToUpgrade) => (
                                            <Button
                                                disabled={
                                                    !userModel.hasAdminPermissions()
                                                }
                                                onClick={() =>
                                                    setNewTeamMemberDialogOpen(
                                                        true,
                                                    )
                                                }
                                                type={"primary"}
                                                icon={
                                                    needsToUpgrade
                                                        ? StarIcon
                                                        : undefined
                                                }
                                            >
                                                Add Team Member
                                            </Button>
                                        )}
                                    </WithFeatureFlagCheck>
                                </Tooltip>
                            </div>
                        }
                    >
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <div className="mt-8 flow-root">
                                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-default sm:pl-0"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-default"
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-default"
                                                    >
                                                        Role
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {!teamMembers?.length && (
                                                    <tr>
                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                            <Text
                                                                type={"subtle"}
                                                            >
                                                                No team
                                                                members&hellip;
                                                            </Text>
                                                        </td>
                                                    </tr>
                                                )}
                                                {!!teamMembers?.length &&
                                                    teamMembers.map((tm) => {
                                                        const teamMember =
                                                            User.fromApiUser(
                                                                tm,
                                                            );

                                                        const isActive =
                                                            teamMember.getCurrentUserOrganization()
                                                                .active;
                                                        return (
                                                            <tr
                                                                key={
                                                                    teamMember.email
                                                                }
                                                                data-id={
                                                                    teamMember.id
                                                                }
                                                            >
                                                                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                                    <div className="flex items-center">
                                                                        <div className="h-11 w-11 flex-shrink-0">
                                                                            <Avatar
                                                                                src={
                                                                                    teamMember.picture
                                                                                }
                                                                                name={
                                                                                    teamMember.name
                                                                                }
                                                                                generateBgColor={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <div className="font-medium text-default">
                                                                                {
                                                                                    teamMember.name
                                                                                }
                                                                            </div>
                                                                            <div className="mt-1 text-subtle">
                                                                                {
                                                                                    teamMember.email
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="whitespace-nowrap relative px-3 py-5 text-sm text-subtle">
                                                                    <EditableField
                                                                        nativeType={
                                                                            "select"
                                                                        }
                                                                        displayAs={(
                                                                            textContent,
                                                                        ) => (
                                                                            <span
                                                                                className={classNames(
                                                                                    "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
                                                                                    {
                                                                                        ["bg-green-50 text-green-700 ring-green-600/20"]:
                                                                                            isActive,
                                                                                        ["bg-gray-50 text-gray-700 ring-gray-600/20"]:
                                                                                            !isActive,
                                                                                    },
                                                                                )}
                                                                            >
                                                                                {
                                                                                    textContent
                                                                                }
                                                                            </span>
                                                                        )}
                                                                        readOnly={
                                                                            !userModel.hasAdminPermissions() ||
                                                                            teamMember.isOrganizationOwner()
                                                                        }
                                                                        options={{
                                                                            "1": "Enabled",
                                                                            "0": "Disabled",
                                                                        }}
                                                                        onSave={(
                                                                            newValue,
                                                                        ) => {
                                                                            updateTeamMember(
                                                                                produce(
                                                                                    teamMember.getData(),
                                                                                    (
                                                                                        draft,
                                                                                    ) => {
                                                                                        if (
                                                                                            !draft.userOrganizations
                                                                                        ) {
                                                                                            return;
                                                                                        }

                                                                                        assertNotUndefined(
                                                                                            draft
                                                                                                .userOrganizations[0],
                                                                                        );

                                                                                        draft.userOrganizations[0].active =
                                                                                            newValue !==
                                                                                            "0";
                                                                                    },
                                                                                ),
                                                                            );
                                                                        }}
                                                                        value={
                                                                            isActive
                                                                                ? "1"
                                                                                : "0"
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="relative w-32 whitespace-nowrap px-3 py-5 text-sm text-subtle">
                                                                    <EditableField
                                                                        nativeType={
                                                                            "select"
                                                                        }
                                                                        readOnly={
                                                                            !userModel.hasAdminPermissions() ||
                                                                            teamMember.isOrganizationOwner()
                                                                        }
                                                                        options={UserPermissionsTypeEnum.filter(
                                                                            (
                                                                                t,
                                                                            ) =>
                                                                                t !==
                                                                                "owner",
                                                                        ).reduce(
                                                                            (
                                                                                a,
                                                                                v,
                                                                            ) => ({
                                                                                ...a,
                                                                                [v]: capitalize(
                                                                                    v,
                                                                                ),
                                                                            }),
                                                                            {},
                                                                        )}
                                                                        onSave={(
                                                                            v,
                                                                        ) => {
                                                                            const newValue =
                                                                                v?.toLowerCase();
                                                                            if (
                                                                                !newValue ||
                                                                                newValue ===
                                                                                    "owner" ||
                                                                                !UserPermissionsTypeEnum.includes(
                                                                                    newValue,
                                                                                )
                                                                            ) {
                                                                                throw new Error(
                                                                                    `Unexpected new key value '${newValue}'`,
                                                                                );
                                                                            }

                                                                            updateTeamMember(
                                                                                produce(
                                                                                    teamMember.getData(),
                                                                                    (
                                                                                        draft,
                                                                                    ) => {
                                                                                        if (
                                                                                            !draft.userOrganizations
                                                                                        ) {
                                                                                            return;
                                                                                        }
                                                                                        assertNotUndefined(
                                                                                            draft
                                                                                                .userOrganizations[0],
                                                                                        );
                                                                                        draft.userOrganizations[0].permissionsType =
                                                                                            newValue as UserPermissionsType;
                                                                                    },
                                                                                ),
                                                                            );
                                                                        }}
                                                                        value={teamMember.getPermissionsType()}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Panel>
                </div>
            </section>
            <NewTeamMemberDialog
                open={newTeamMemberDialogOpen}
                onClose={() => setNewTeamMemberDialogOpen(false)}
            />
        </>
    );
};
