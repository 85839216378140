import { components } from "@/types/generated/api-schema";
type TrackableEvent = components["schemas"]["TrackableEvent"];
type TrackableEventWrapper = components["schemas"]["TrackableEventWrapper"];
type TrackableEventType = components["schemas"]["TrackableEventType"];
type UserSignedInEvent = components["schemas"]["UserSignedInEvent"];
type UserSignedOutEvent = components["schemas"]["UserSignedOutEvent"];
type UserUpgradedEvent = components["schemas"]["UserUpgradedEvent"];
type ExpressedIntegrationInterestEvent =
    components["schemas"]["ExpressedIntegrationInterestEvent"];
type SuggestedIntegrationEvent =
    components["schemas"]["SuggestedIntegrationEvent"];
type ErrorEncounteredEvent = components["schemas"]["ErrorEncounteredEvent"];
type Organization = components["schemas"]["Organization"];
type UpgradeDialogOpenedEvent =
    components["schemas"]["UpgradeDialogOpenedEvent"];
type ExpressedRoadmapFeatureInterestEvent =
    components["schemas"]["ExpressedRoadmapFeatureInterestEvent"];

type SetupGuideOpenedEvent = components["schemas"]["SetupGuideOpenedEvent"];
type SetupGuideStepCompletedEvent =
    components["schemas"]["SetupGuideStepCompletedEvent"];

class TrackableEventFactory {
    public static createUserSignedInEvent(
        properties: UserSignedInEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "User Signed In",
            properties,
            organization,
        );
    }

    public static createUserSignedOutEvent(
        properties: UserSignedOutEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "User Signed Out",
            properties,
            organization,
        );
    }

    public static createUserUpgradedEvent(
        properties: UserUpgradedEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "User Upgraded",
            properties,
            organization,
        );
    }

    public static createExpressedIntegrationInterestEvent(
        properties: ExpressedIntegrationInterestEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Expressed Integration Interest",
            properties,
            organization,
        );
    }

    public static createSuggestedIntegrationEvent(
        properties: SuggestedIntegrationEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Suggested Integration",
            properties,
            organization,
        );
    }

    public static createExpressedRoadmapFeatureInterest(
        properties: ExpressedRoadmapFeatureInterestEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Expressed Roadmap Feature Interest",
            properties,
            organization,
        );
    }

    public static createUpgradeDialogOpenedEvent(
        properties: UpgradeDialogOpenedEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Upgrade Dialog Opened",
            properties,
            organization,
        );
    }

    public static createSetupGuideOpenedEvent(
        properties: SetupGuideOpenedEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Setup Guide Opened",
            properties,
            organization,
        );
    }

    public static createSetupGuideStepCompletedEvent(
        properties: SetupGuideStepCompletedEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return TrackableEventFactory.create(
            "Setup Guide Step Completed",
            properties,
            organization,
        );
    }

    /**
     * Since this event can be thrown from the top level ErrorBoundary
     * we do not always have access to the organization
     */
    public static createErrorEncounteredEvent(
        properties: ErrorEncounteredEvent,
        organization?: Organization,
    ) {
        return {
            event: "Error Encountered",
            properties,
            context: {
                groupId: organization?.id,
            },
        };
    }

    private static create(
        event: TrackableEventType,
        properties: TrackableEvent,
        organization: Organization,
    ): TrackableEventWrapper {
        return {
            event,
            properties,
            context: {
                groupId: organization.id,
            },
        };
    }
}

export default TrackableEventFactory;
