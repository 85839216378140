import Spinner from "@/common/Spinner/Spinner";

const Loading = () => {
    return (
        <div className={"flex h-full grow items-center justify-center"}>
            <Spinner />
        </div>
    );
};

export default Loading;
