import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import List from "@/features/Reporting/List.tsx";
import ContactsList from "@/features/Contacts/List.tsx";
import App from "@/App.tsx";
import { store } from "@/app/store.ts";
import Dashboard from "@/features/Dashboard/Dashboard.tsx";
import Settings from "@/features/Workspace/Settings";
import { UserModelProvider } from "@/app/UserModelProvider.tsx";
import Loading from "@/common/Loading";
import { FeatureFlagManagerProvider } from "@/services/featureFlags/FeatureFlagManagerProvider.tsx";
import AccountSettings from "@/features/AccountSettings";
import { AnalyticsProvider } from "@/services/analytics/analytics-context";
import TrackableEventFactory from "@/services/analytics/trackableEventFactory.ts";

import * as Sentry from "@sentry/react";
import UncaughtError from "@/common/Error/UncaughtError.tsx";
import CognitoAuthenticator from "@/app/CognitoAuthenticator.tsx";
import OrganizationSettings from "@/features/OrganizationSettings";
import { Authenticator } from "@aws-amplify/ui-react";
import { IdentifiedUser } from "@/app/IdentifiedUser.tsx";
import PaymentMethodProvided from "./features/Subscription/PaymentMethodProvided";
import Upgraded from "@/features/Subscription/Upgraded.tsx";
import Detail from "@/features/Funnel/Detail.tsx";
import WorkspaceDependentRoute from "@/common/WorkspaceDependentRoute";

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <UncaughtError />,
        children: [
            {
                path: "/",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <Dashboard
                                key={`Dashboard${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/reporting",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <List key={`List${selectedWorkspace?.id}}`} />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/contacts",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <ContactsList
                                key={`ContactsList${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/customers",
                element: <Navigate replace to="/contacts" />,
            },
            {
                path: "/funnels",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <Detail key={`Detail${selectedWorkspace?.id}`} />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/settings",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <Settings
                                key={`Settings${selectedWorkspace?.id}}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/account",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <AccountSettings
                                key={`AccountSettings${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/organization",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <OrganizationSettings
                                key={`OrganizationSettings${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/subscription/upgraded",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <Upgraded
                                key={`Upgraded${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/subscription/payment-method-provided",
                element: (
                    <WorkspaceDependentRoute>
                        {(selectedWorkspace) => (
                            <PaymentMethodProvided
                                key={`PaymentMethodProvided${selectedWorkspace?.id}`}
                            />
                        )}
                    </WorkspaceDependentRoute>
                ),
            },
            {
                path: "/signup",
                element: <Navigate replace to="/" />,
            },
            {
                path: "/login",
                element: <Navigate replace to="/" />,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Authenticator.Provider>
            <AnalyticsProvider>
                {(analytics) => (
                    <Sentry.ErrorBoundary
                        fallback={<UncaughtError />}
                        onError={(error) => {
                            const trackableEvent =
                                TrackableEventFactory.createErrorEncounteredEvent(
                                    {
                                        message: ((error as { message: string })
                                            .message || error) as string,
                                    },
                                );
                            analytics?.track(
                                trackableEvent.event,
                                trackableEvent.properties,
                            );
                        }}
                    >
                        <Provider store={store}>
                            <CognitoAuthenticator>
                                <UserModelProvider>
                                    {(isLoading, user) => {
                                        return isLoading ? (
                                            <Loading />
                                        ) : (
                                            <IdentifiedUser>
                                                <FeatureFlagManagerProvider
                                                    user={user!}
                                                >
                                                    <RouterProvider
                                                        router={router}
                                                    />
                                                </FeatureFlagManagerProvider>
                                            </IdentifiedUser>
                                        );
                                    }}
                                </UserModelProvider>
                            </CognitoAuthenticator>
                        </Provider>
                    </Sentry.ErrorBoundary>
                )}
            </AnalyticsProvider>
        </Authenticator.Provider>
    </React.StrictMode>,
);
