import "./index.css";
import { Avatar } from "@/catalyst/avatar";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "@/catalyst/dropdown";
import { SidebarLayout } from "@/catalyst/sidebar-layout";
import {
    Navbar,
    NavbarItem,
    NavbarSection,
    NavbarSpacer,
} from "@/catalyst/navbar";
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from "@/catalyst/sidebar";
import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    Cog8ToothIcon,
    MoonIcon,
    UserIcon,
} from "@heroicons/react/16/solid";
import {
    Cog6ToothIcon,
    Squares2X2Icon,
    QuestionMarkCircleIcon,
    SparklesIcon,
} from "@heroicons/react/20/solid";
import DarkModeToggle from "@/common/DarkModeToggle/DarkModeToggle.tsx";
import { useUiProperty } from "@/features/UiState.slice.ts";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    BookOpenIcon,
    BuildingOfficeIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import {
    useGetWorkspacesQuery,
    useUpdateUserMutation,
} from "@/services/apis/api.ts";
import { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import NoWorkspaceEmptyState from "@/common/EmptyState/NoWorkspaceEmptyState.tsx";
import NewWorkspaceDialog from "@/features/Workspace/NewWorkspaceDialog.tsx";
import WorkspacePicker from "@/common/WorkspacePicker";
import UserContext from "@/app/UserContext.ts";
import { assertNotUndefined } from "@/util/typeguards";
import Toast from "@/common/Toast";
import Tooltip from "@/common/Tooltip";
import useAnalytics from "@/services/analytics/useAnalytics.ts";
import ContinueSetupBanner from "@/features/Onboarding/ContinueSetupBanner.tsx";
import { clsx } from "clsx";
import { useSignOut } from "@/hooks/use-signout.ts";
import TrialSidebarWidget from "@/common/TrialSidebarWidget";
import support from "@/services/support";
import ReportingIcon from "@/common/Icon/Other/ReportingIcon.tsx";
import FunnelReportIcon from "@/common/Icon/Other/FunnelReportIcon.tsx";
import { useOptionalSelectedWorkspace } from "@/hooks/use-selected-workspace.ts";
import { usePrevious } from "@uidotdev/usehooks";

export default function App() {
    const analytics = useAnalytics();
    analytics.usePageViews();

    const [darkMode, setDarkMode] = useUiProperty<boolean>("darkMode");
    const { data, isLoading } = useGetWorkspacesQuery(undefined);
    const noWorkspaceSetup = !isLoading && !data?.length;
    const [newWorkspaceDialogOpen, setNewWorkspaceDialogOpen] = useState(false);
    const user = useContext(UserContext);
    const [updateUser] = useUpdateUserMutation();

    // Check if we have cut off events
    const selectedWorkspace = useOptionalSelectedWorkspace();
    const shouldConfigureCutOffEvents =
        selectedWorkspace?.cutOffEvents.length === 0;

    const { signOut } = useSignOut();
    assertNotUndefined(user);

    useEffect(() => {
        if (!user.timezone) {
            updateUser({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }

        if (typeof darkMode === "undefined") {
            setDarkMode(
                window.matchMedia("(prefers-color-scheme: dark)").matches,
            );
        }
    }, []);

    const shouldSetup = selectedWorkspace?.installationCompleted === false;
    support.configure(user);

    const location = useLocation();
    const navigate = useNavigate();
    const prevWorkspace = usePrevious(selectedWorkspace);

    useEffect(() => {
        if (prevWorkspace && prevWorkspace.id !== selectedWorkspace?.id) {
            // remove q= query param from url
            navigate(window.location.pathname);
        }
    }, [navigate, prevWorkspace, selectedWorkspace?.id]);

    return (
        <div className={clsx("h-full", darkMode ? "dark" : "")}>
            <Toast />
            <SidebarLayout
                navbar={
                    <Navbar>
                        <NavbarSpacer />
                        <NavbarSection>
                            {/*<NavbarItem href="/search" aria-label="Search">*/}
                            {/*    <MagnifyingGlassIcon />*/}
                            {/*</NavbarItem>*/}
                            {/*<NavbarItem href="/inbox" aria-label="Inbox">*/}
                            {/*    <InboxIcon />*/}
                            {/*</NavbarItem>*/}
                            <Dropdown>
                                <DropdownButton as={NavbarItem}>
                                    <Avatar
                                        src={user.picture}
                                        square
                                        generateBgColor={true}
                                        name={user.name}
                                    />
                                </DropdownButton>
                                <DropdownMenu
                                    className="min-w-64"
                                    anchor="bottom end"
                                >
                                    <DropdownItem to="/account">
                                        <Cog8ToothIcon />
                                        <DropdownLabel>
                                            Account settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownItem to="/organization">
                                        <BuildingOfficeIcon />
                                        <DropdownLabel>
                                            Organization settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem onClick={signOut}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Sign out</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </NavbarSection>
                    </Navbar>
                }
                sidebar={
                    <Sidebar>
                        <SidebarHeader>
                            <WorkspacePicker
                                setNewWorkspaceDialogOpen={
                                    setNewWorkspaceDialogOpen
                                }
                            />
                            {/*<SidebarSection className="max-lg:hidden">*/}
                            {/*    <SidebarItem href="/search">*/}
                            {/*        <MagnifyingGlassIcon />*/}
                            {/*        <SidebarLabel>Search</SidebarLabel>*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/inbox">*/}
                            {/*        <InboxIcon />*/}
                            {/*        <SidebarLabel>Inbox</SidebarLabel>*/}
                            {/*    </SidebarItem>*/}
                            {/*</SidebarSection>*/}
                        </SidebarHeader>
                        <SidebarBody>
                            <SidebarSection>
                                <SidebarItem
                                    className="relative"
                                    current={location.pathname === "/"}
                                    to="/"
                                >
                                    <Squares2X2Icon />
                                    <SidebarLabel>Dashboard</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/reporting"}
                                    to="/reporting"
                                >
                                    <ReportingIcon className={"h-7 w-7"} />
                                    <SidebarLabel>Reporting</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/funnels"}
                                    to="/funnels"
                                    className="relative"
                                >
                                    <FunnelReportIcon />
                                    <SidebarLabel>Funnels</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/contacts"}
                                    to="/contacts"
                                >
                                    <UserIcon />
                                    <SidebarLabel>Contacts</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    current={location.pathname === "/settings"}
                                    to="/settings"
                                >
                                    <Cog6ToothIcon />
                                    <SidebarLabel>
                                        Settings
                                        {shouldConfigureCutOffEvents && (
                                            <Tooltip
                                                content={
                                                    "You haven't configured any cut off events yet. This will affect your revenue attribution. Please configure a cut off event in settings."
                                                }
                                            >
                                                <ExclamationTriangleIcon
                                                    className={
                                                        "ml-4 text-yellow-500 h-5 w-5 inline-block"
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </SidebarLabel>
                                </SidebarItem>
                            </SidebarSection>
                            {/*<SidebarSection className="max-lg:hidden">*/}
                            {/*    <SidebarHeading>Upcoming Events</SidebarHeading>*/}
                            {/*    <SidebarItem href="/events/1">*/}
                            {/*        Bear Hug: Live in Concert*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/2">*/}
                            {/*        Viking People*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/3">*/}
                            {/*        Six Fingers — DJ Set*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/4">*/}
                            {/*        We All Look The Same*/}
                            {/*    </SidebarItem>*/}
                            {/*    <SidebarItem href="/events/4">*/}
                            {/*        */}
                            {/*    </SidebarItem>*/}
                            {/*</SidebarSection>*/}
                            <SidebarSpacer />
                            {!user.organization.hasPaymentMethod &&
                                !user.organization.isFreePlan() && (
                                    <SidebarSection>
                                        <TrialSidebarWidget user={user} />
                                    </SidebarSection>
                                )}
                            <SidebarSection>
                                <SidebarItem
                                    className={"cursor-pointer"}
                                    onClick={() => {
                                        support.openChat();
                                    }}
                                >
                                    <QuestionMarkCircleIcon />
                                    <SidebarLabel>Support</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    href="https://www.spectaclehq.com/docs"
                                    target="_blank"
                                >
                                    <BookOpenIcon />
                                    <SidebarLabel>Docs</SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    // href="/changelog"
                                    id={"changelog-button"}
                                >
                                    <SparklesIcon />
                                    <SidebarLabel>Changelog</SidebarLabel>
                                </SidebarItem>
                            </SidebarSection>
                        </SidebarBody>
                        <SidebarFooter className="max-lg:hidden">
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <span
                                        className="flex min-w-0 items-center gap-3"
                                        data-id={user.id}
                                    >
                                        <Avatar
                                            src={user.picture}
                                            square
                                            className="size-10"
                                            generateBgColor={true}
                                            name={user.name}
                                        />
                                        <span className="min-w-0">
                                            <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                                                {user.name}
                                            </span>
                                            <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                                {user.email}
                                            </span>
                                        </span>
                                    </span>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <DropdownMenu
                                    className="min-w-64"
                                    anchor="top start"
                                >
                                    <DropdownItem to="/account">
                                        <UserIcon />
                                        <DropdownLabel>
                                            Account settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownItem to="/organization">
                                        <BuildingOfficeIcon />
                                        <DropdownLabel>
                                            Organization settings
                                        </DropdownLabel>
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem>
                                        <MoonIcon />
                                        <DarkModeToggle />
                                    </DropdownItem>
                                    <DropdownDivider />
                                    <DropdownItem onClick={signOut}>
                                        <ArrowRightStartOnRectangleIcon />
                                        <DropdownLabel>Sign out</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </SidebarFooter>
                    </Sidebar>
                }
            >
                {!isLoading && (
                    <div
                        className={classNames(
                            "flex h-full grow flex-col overflow-hidden",
                            noWorkspaceSetup && "items-center justify-center",
                        )}
                    >
                        {noWorkspaceSetup ? (
                            <NoWorkspaceEmptyState
                                onNewButtonClick={() =>
                                    setNewWorkspaceDialogOpen(true)
                                }
                            />
                        ) : (
                            <>
                                {shouldSetup && <ContinueSetupBanner />}{" "}
                                <Outlet />
                            </>
                        )}
                    </div>
                )}
                <NewWorkspaceDialog
                    open={newWorkspaceDialogOpen}
                    onClose={() => setNewWorkspaceDialogOpen(false)}
                />
            </SidebarLayout>
        </div>
    );
}
