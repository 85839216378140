import { components } from "@/types/generated/api-schema";
import { assertNotUndefined } from "@/util/typeguards";

type FF = components["schemas"]["FeatureFlag"]; // Having Trouble with Nelmio on this one so defining type manually here
type ValueType = number | boolean;
type FeatureFlag = {
    type: FF["type"];
    value: ValueType;
    currentValue?: ValueType;
};
type Feature = components["schemas"]["Feature"];
export type FeatureFlagSet = Record<Feature | never, FeatureFlag>;

export default class FeatureFlagManager {
    private featureFlagSet?: FeatureFlagSet;

    constructor(featureFlagSet?: FeatureFlagSet) {
        this.featureFlagSet = featureFlagSet;
    }

    public isEnabled(feature: Feature) {
        if (!this.featureFlagSet) {
            throw new Error("Not set");
        }

        const featureFlag = this.featureFlagSet[feature];

        assertNotUndefined(featureFlag);

        if (typeof featureFlag.value !== "boolean") {
            throw new Error("Boolean expected");
        }

        return featureFlag.value;
    }

    public isWithinThreshold(feature: Feature) {
        if (!this.featureFlagSet) {
            throw new Error("Not set");
        }

        const featureFlag = this.featureFlagSet[feature];

        assertNotUndefined(featureFlag);
        assertNotUndefined(featureFlag.currentValue);

        if (typeof featureFlag.value !== "number") {
            throw new Error("Number expected");
        }

        if (typeof featureFlag.currentValue !== "number") {
            throw new Error("Number expected");
        }

        return featureFlag.currentValue < featureFlag.value;
    }
}
