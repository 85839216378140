import FeatureFlagManagerContext from "./FeatureFlagManagerContext";
import FeatureFlagManager from "./featureFlagManager";
import { ReactNode } from "react";
import { User } from "@/model/User.ts";
import { objectKeysToKebab } from "@/util/case.ts";

export const FeatureFlagManagerProvider = ({
    user,
    children,
}: {
    user: User;
    children: ReactNode;
}) => {
    const featureFlagManager = new FeatureFlagManager(
        // @ts-expect-error Nelmio api doc cannot output the correct object structure
        // Also need to re-kebab the feature flag keys as we globally camelize api responses
        objectKeysToKebab(user.featureFlags),
    );

    return (
        <FeatureFlagManagerContext.Provider value={featureFlagManager}>
            {children}
        </FeatureFlagManagerContext.Provider>
    );
};
