import { ReactNode, useContext } from "react";
import useAnalytics from "../services/analytics/useAnalytics";
import UserContext from "./UserContext";

export const IdentifiedUser = ({ children }: { children: ReactNode }) => {
    const analytics = useAnalytics();
    const userModel = useContext(UserContext);

    if (!userModel) {
        throw new Error("Should have UserModel here");
    }

    const organization = userModel.getCurrentOrganization();
    analytics.useIdentifiedUser(userModel, organization);

    return children;
};
