import { ICellRendererParams } from "ag-grid-community";
import { MouseEventHandler } from "react";
import { FunnelIcon } from "@heroicons/react/24/solid";
import PlatformIcon from "../PlatformIcon";
import { capitalize, snakeToSentence } from "@/util/case.ts";
import { Strong, Text } from "@/catalyst/text.tsx";
import { AttributionDimension } from "@/types/generated/AttributionDimension.ts";
import { AttributionFilter } from "@/types/generated/AttributionFilter.ts";
import { SetFilters } from "@/common/ReportTable/types.ts";

export type CellRendererExtraParams = {
    setFilters: SetFilters;
    filters: AttributionFilter[];
    dimension: AttributionDimension;
};

export type CellRendererParams = ICellRendererParams & CellRendererExtraParams;

const getCellValue = (params: CellRendererParams) => {
    const { value } = params;
    if (params.node.field === "source") {
        return snakeToSentence(value);
    }

    if (params.node.field === "sourceType") {
        return capitalize(value);
    }

    return value;
};

export const FilterAddCellRenderer = (params: CellRendererParams) => {
    return (
        <div
            className={
                "mr-4 flex items-center justify-between h-full group gap-2"
            }
        >
            <span>{getCellValue(params)}</span>
            <AddFilterCellIcon
                onClick={() =>
                    params.setFilters([
                        ...params.filters,
                        {
                            property: params.node.field as AttributionDimension,
                            comparator: "equals",
                            value: params.value,
                        },
                    ])
                }
            />
        </div>
    );
};

export const SourceCellRenderer = (params: CellRendererParams) => {
    return (
        <div className={"flex items-center justify-between h-full group gap-2"}>
            <div className={"flex items-center"}>
                <PlatformIcon
                    className={"mr-3 w-7 h-7"}
                    platform={params.value.replace(/_organic$/, "")}
                />
                <span>{snakeToSentence(params.value)}</span>
            </div>
            <AddFilterCellIcon
                onClick={() => {
                    params.setFilters([
                        ...params.filters,
                        {
                            property: params.node.field as AttributionDimension,
                            comparator: "equals",
                            value: params.value,
                        },
                    ]);
                }}
            />
        </div>
    );
};

export const AdNetworkEntityCellRenderer = (params: CellRendererParams) => {
    // We need to get the campaign title this way because we're showing grouped rows
    const name = params.value || "Not attributed";
    return (
        <div
            className={
                "mr-4 flex items-center justify-between h-full group gap-2"
            }
        >
            <span>{name}</span>
            <AddFilterCellIcon
                onClick={() => {
                    params.setFilters([
                        ...params.filters,
                        {
                            property: params.node.field as AttributionDimension,
                            comparator: "equals",
                            value: params.value,
                        },
                    ]);
                }}
            />
        </div>
    );
};

export const NotAttributedCellRenderer = () => (
    <div className={"italic"}>Not attributed</div>
);

export const TotalCellRenderer = () => {
    return (
        <div className={"flex items-center justify-start h-full"}>
            <Text>
                <Strong>Total</Strong>
            </Text>
        </div>
    );
};

const AddFilterCellIcon = ({ onClick }: { onClick: MouseEventHandler }) => (
    <div
        className={
            "flex-row items-center cursor-pointer opacity-50 hidden group-hover:flex"
        }
        onClick={onClick}
    >
        <span>+</span> <FunnelIcon className={"ml-1 h-4 w-4"} />
    </div>
);
