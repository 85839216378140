import { assertNotUndefined } from "@/util/typeguards.ts";
import { FunnelStepMaybeNew } from "@/features/Funnel/types.ts";

export const generateFunnelName = (steps: FunnelStepMaybeNew[]) => {
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];
    assertNotUndefined(firstStep);
    assertNotUndefined(lastStep);

    return `${firstStep.name} → ${lastStep.name}`;
};
