import { assertNotUndefined } from "@/util/typeguards.ts";
import {
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    format,
    parse,
} from "date-fns";
import { Resolution } from "@/types/generated/Resolution.ts";

export type DateRangeStrings = { from: string; to: string };

export const sortYYYYMMArray = (a: string, b: string) => {
    const yearAString = a.split("-")[0];
    const yearBString = b.split("-")[0];
    assertNotUndefined(yearAString);
    assertNotUndefined(yearBString);
    const yearA = parseInt(yearAString);
    const yearB = parseInt(yearBString);
    if (yearA < yearB) {
        return -1;
    }
    if (yearA > yearB) {
        return 1;
    }
    const monthAString = a.split("-")[1];
    const monthBString = b.split("-")[1];
    assertNotUndefined(monthAString);
    assertNotUndefined(monthBString);
    const monthA = parseInt(monthAString);
    const monthB = parseInt(monthBString);

    if (monthA < monthB) {
        return -1;
    }
    if (monthA > monthB) {
        return 1;
    }
    return 0;
};

export const dateToPretty = (value: string) =>
    format(parse(value, "yyyy-MM-dd", new Date()), "MMM d");

export const yearMonthToPretty = (value: string) =>
    format(
        parse(
            value.slice(0, 4) + "-" + value.slice(5, 7) + "-" + "01",
            "yyyy-MM-dd",
            new Date(),
        ),
        "MMM yy",
    );

export const getFormatterForResolution = (resolution: Resolution) => {
    switch (resolution) {
        case "Days":
            return dateToPretty;
        case "Months":
            return yearMonthToPretty;
        case "Years":
            return (value: string) => value;
        default:
            throw new Error("Unexpected resolution");
    }
};

export const getDateRangeDescription = (
    dateRange: DateRangeStrings,
    resolution?: Resolution,
) => {
    switch (resolution) {
        case "Months":
            return (
                differenceInMonths(
                    new Date(dateRange.to),
                    new Date(dateRange.from),
                ) + " months"
            );
        case "Years":
            return (
                differenceInYears(
                    new Date(dateRange.to),
                    new Date(dateRange.from),
                ) + " years"
            );
        case "Days":
        default:
            return (
                differenceInDays(
                    new Date(dateRange.to),
                    new Date(dateRange.from),
                ) + " days"
            );
    }
};
