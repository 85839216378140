import { Radio, RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import LastTouchIcon from "@/common/Icon/AttributionModel/LastTouchIcon.tsx";
import UShapedIcon from "@/common/Icon/AttributionModel/UShapedIcon.tsx";
import FirstTouchIcon from "@/common/Icon/AttributionModel/FirstTouchIcon.tsx";
import LinearIcon from "@/common/Icon/AttributionModel/LinearIcon.tsx";
import Tooltip from "@/common/Tooltip";
import { Strong, Text } from "@/catalyst/text.tsx";
import { FloatingDelayGroup } from "@floating-ui/react";
import { ReactNode } from "react";
import { AttributionModel } from "@/types/generated/AttributionModel.ts";

type AttributionModelPickerOption = {
    value: AttributionModel;
    label: string;
    tooltip: string;
    icon: ReactNode;
};

const options: AttributionModelPickerOption[] = [
    {
        value: "linear",
        label: "Linear",
        tooltip: "Distributes revenue evenly across all touchpoints",
        icon: <LinearIcon className={"h-5 w-5 text-default"} />,
    },
    {
        value: "firstTouch",
        label: "First Touch",
        tooltip: "Assigns all revenue to the first touchpoint",
        icon: <FirstTouchIcon className={"h-5 w-5 text-default"} />,
    },
    {
        value: "lastTouch",
        label: "Last Touch",
        tooltip: "Assigns all revenue to the last touchpoint",
        icon: <LastTouchIcon className={"h-5 w-5 text-default"} />,
    },
    {
        value: "uShaped",
        label: "U-Shaped",
        tooltip:
            "Assigns 40% of revenue to the first and last touchpoints, and the remaining 20% to the rest",
        icon: <UShapedIcon className={"h-5 w-5 text-default"} />,
    },
];

export default function AttributionModelPicker({
    model,
    onChange,
}: {
    model: AttributionModel;
    onChange: (model: AttributionModel) => void;
}) {
    return (
        <FloatingDelayGroup delay={250}>
            <RadioGroup
                value={model}
                onChange={onChange}
                className={classNames(
                    "group relative w-full flex flex-1 flex-row",
                    // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
                )}
            >
                {options.map((option) => (
                    <Tooltip
                        content={
                            <Text>
                                <Strong>{option.label}</Strong> {option.tooltip}
                            </Text>
                        }
                        key={option.value}
                    >
                        <Radio
                            value={option.value}
                            className={({ checked }) =>
                                classNames(
                                    "flex mr-2 aspect-square text-center items-center justify-center border text-zinc-50 p-1 grow text-xs h-full cursor-pointer",
                                    "border border-zinc-950/10 group-data-[active]:border-zinc-950/20 group-data-[hover]:border-zinc-950/20 dark:border-white/10 dark:group-data-[active]:border-white/20 dark:group-data-[hover]:border-white/20",
                                    // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
                                    "focus:outline-none",
                                    "rounded-lg",
                                    // Focus ring
                                    "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:data-[focus]:ring-2 after:data-[focus]:ring-blue-500",
                                    // Disabled state
                                    "data-[disabled]:opacity-50 before:data-[disabled]:bg-zinc-950/5 before:data-[disabled]:shadow-none",
                                    checked && "bg-zinc-200 dark:bg-zinc-800",
                                )
                            }
                        >
                            {option.icon}
                        </Radio>
                    </Tooltip>
                ))}
            </RadioGroup>
        </FloatingDelayGroup>
    );
}
