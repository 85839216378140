import {
    useCreateWorkspaceIntegrationMutation,
    useDeleteWorkspaceIntegrationMutation,
    useGetWorkspaceIntegrationSelectableAccountsMutation,
    useUpdateWorkspaceIntegrationMutation,
} from "@/services/apis/api.ts";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { components } from "@/types/generated/api-schema.ts";
import Panel from "@/common/Panel/Panel.tsx";
import Button from "@/common/Button";
import { useSelector } from "react-redux";
import { getUiProperty } from "@/features/UiState.slice.ts";
import nango from "@/services/nango";
import {
    platformBranding,
    IntegrationConfig,
} from "@/common/PlatformIcon/platformBranding.ts";
import { toast } from "@/common/Toast";
type Integration = components["schemas"]["Integration"];
type WorkspaceIntegration = components["schemas"]["WorkspaceIntegration"];
type WorkspaceIntegrationSelectableAccounts =
    components["schemas"]["WorkspaceIntegrationSelectableAccounts"];
import * as Headless from "@headlessui/react";
import {
    DialogActions,
    DialogDescription,
    DialogTitle,
} from "@/catalyst/dialog.tsx";
import Text from "@/common/Text";
import classNames from "classnames";
import { Input } from "@/catalyst/input.tsx";
import { Square2StackIcon } from "@heroicons/react/24/solid";
import {
    IntegrationDetails,
    integrations,
} from "@/features/Workspace/Settings/integrations.tsx";
import { BellIcon } from "@heroicons/react/20/solid";
import { assertIsError, assertNotUndefined } from "@/util/typeguards.ts";
import useAnalytics from "@/services/analytics/useAnalytics.ts";
import { capitalize, snakeToSentence } from "@/util/case.ts";
import { Switch } from "@/catalyst/switch.tsx";
import { decorateExistingTrackingTemplate } from "@/services/integrations/trackingTemplateDecorator.ts";
import { clsx } from "clsx";

type OnConnectOptions = { apiKey?: string };

export default function IntegrationDetailsPanel({
    onClose: onCloseProp,
    selectedIntegration,
    connectedAccounts,
}: {
    onClose: () => void;
    selectedIntegration?: Integration;
    connectedAccounts?: WorkspaceIntegration[];
}): ReactNode {
    const isDarkMode = useSelector(getUiProperty("darkMode"));
    const [createWorkspaceIntegration, { isLoading: isCreating }] =
        useCreateWorkspaceIntegrationMutation();
    const [updateWorkspaceIntegration, { isLoading: isUpdating }] =
        useUpdateWorkspaceIntegrationMutation();
    const analytics = useAnalytics();
    const canConnect =
        !!selectedIntegration &&
        !integrations[selectedIntegration]!.comingSoon &&
        integrations[selectedIntegration]!.canConnect;

    const [
        getWorkspaceIntegrationSelectableAccounts,
        {
            isLoading: isFetchingSelectableAccounts,
            reset: resetSelectableAccounts,
        },
    ] = useGetWorkspaceIntegrationSelectableAccountsMutation();

    const [hasExistingTemplate, setHasExistingTemplate] = useState(false);
    const [currentTemplateValue, setCurrentTemplateValue] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [selectableAccounts, setSelectableAccounts] = useState<
        WorkspaceIntegrationSelectableAccounts | undefined
    >(undefined);
    const [accountBeingSelected, setAccountBeingSelected] = useState<
        string | undefined
    >(undefined);

    const onClose = useCallback(() => {
        resetSelectableAccounts();
        setSelectableAccounts(undefined);
        setAccountBeingSelected(undefined);
        setCurrentTemplateValue("");
        setHasExistingTemplate(false);
        setApiKey("");

        // Close nango modal if any
        nango.win?.modal?.close();

        onCloseProp();
    }, [setApiKey, onCloseProp, resetSelectableAccounts]);

    const [deleteWorkspaceMigration] = useDeleteWorkspaceIntegrationMutation();

    const onConnect = useCallback(
        async (id: Integration, _options?: OnConnectOptions) => {
            const newWorkspaceIntegration = await createWorkspaceIntegration({
                integration: id,
            }).unwrap();

            const hasAccountSelector =
                integrations[id]?.hasAccountSelector || false;

            try {
                const options: Parameters<typeof nango.auth>[2] & {
                    params?: { appDomain: string };
                    authorization_params?: { config_id: string };
                } & { credentials?: { apiKey: string } } = {
                    detectClosedAuthWindow: true,
                };

                if (id === "stripe") {
                    // This needs to be added while running external tests for our Stripe App
                    options.params = {
                        appDomain:
                            "channellink*AZFGwNA2RgAAAIs8%23EhcKFWFjY3RfMVBteTV6RnBybHdvNVZraw",
                    };
                }

                if (id === "meta_ads") {
                    options.authorization_params = {
                        config_id: "877681930528232",
                    };
                }

                if (_options?.apiKey) {
                    options.credentials = {
                        apiKey: _options.apiKey,
                    };
                }

                await nango.auth(id, newWorkspaceIntegration.id, options);

                await updateWorkspaceIntegration({
                    id: newWorkspaceIntegration.id,
                    status: hasAccountSelector
                        ? "pending_account_selection"
                        : "active",
                });

                if (hasAccountSelector) {
                    const fetchedSelectableAccounts =
                        await getWorkspaceIntegrationSelectableAccounts({
                            id: newWorkspaceIntegration.id,
                        }).unwrap();

                    if (
                        Object.keys(fetchedSelectableAccounts.accounts)
                            .length === 0
                    ) {
                        await updateWorkspaceIntegration({
                            id: newWorkspaceIntegration.id,
                            status: "connection_failed",
                        });
                        toast.error(
                            `No accounts found for ${id}. Please try again later.`,
                        );
                        return;
                    }

                    setSelectableAccounts(fetchedSelectableAccounts);
                    return;
                }

                onClose();
                resetSelectableAccounts();
            } catch (e) {
                await updateWorkspaceIntegration({
                    id: newWorkspaceIntegration.id,
                    status: "connection_failed",
                });

                assertIsError(e);
                if (
                    !e.message.includes("The authorization window was closed")
                ) {
                    console.log("Failed to connect to integration", e);
                    toast.error(`Failed to connect to integration: ${e}`);
                }
            }
        },
        [],
    );

    const onAccountSelected = useCallback(
        async (
            accountId: string,
            accountName: string,
            metaData: Parameters<
                typeof updateWorkspaceIntegration
            >[0]["metaData"],
        ) => {
            if (!selectableAccounts) {
                throw new Error("No selectable accounts in state");
            }

            setAccountBeingSelected(accountId);
            await updateWorkspaceIntegration({
                id: selectableAccounts.workspaceIntegrationId,
                externalId: accountId,
                accountName,
                status: "active",
                metaData,
            });
            setAccountBeingSelected(undefined);

            setSelectableAccounts(undefined);

            onClose();
            resetSelectableAccounts();
        },
        [selectableAccounts],
    );

    const brandingInput: IntegrationConfig | undefined = selectedIntegration
        ? platformBranding[selectedIntegration]
        : undefined;

    const brandingRef = useRef<IntegrationConfig>(undefined);
    const integrationDetailsRef = useRef<IntegrationDetails>(undefined);
    const connectedAccountsRef = useRef<WorkspaceIntegration[]>(undefined);
    let integrationDetails = selectedIntegration
        ? integrations[selectedIntegration]
        : undefined;

    // Prevent flickering when closing modal
    useEffect(() => {
        brandingRef.current = brandingInput;
        integrationDetailsRef.current = integrationDetails;
        connectedAccountsRef.current = connectedAccounts;
    }, [brandingInput, integrationDetails, connectedAccounts]);

    const branding = brandingInput || brandingRef.current;
    integrationDetails = integrationDetails || integrationDetailsRef.current;
    connectedAccounts = connectedAccounts
        ? connectedAccounts
        : connectedAccountsRef.current || [];

    const trackingTemplate =
        integrationDetails?.type === "ad_network"
            ? hasExistingTemplate && !!currentTemplateValue
                ? decorateExistingTrackingTemplate(
                      currentTemplateValue,
                      integrationDetails.trackingParameters,
                  )
                : Object.entries(integrationDetails.trackingParameters)
                      .map(([key, value]) => `${key}=${value}`)
                      .join("&")
            : undefined;

    const isApiKey = integrationDetails?.connectionMethod === "api_key";

    return (
        <>
            <Headless.Transition appear show={!!selectedIntegration}>
                <Headless.Dialog onClose={onClose}>
                    <Headless.TransitionChild
                        enter="ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-zinc-950/25 px-2 py-2 focus:outline-0 sm:px-6 sm:py-8 lg:px-8 lg:py-16 dark:bg-zinc-950/50" />
                    </Headless.TransitionChild>

                    <div
                        className={classNames(
                            "fixed inset-0 w-screen overflow-y-auto pt-6 sm:pt-0",
                            isDarkMode ? "dark" : "",
                        )}
                    >
                        <div className="grid min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[1fr_auto_3fr] sm:p-4">
                            <Headless.TransitionChild
                                enter="ease-out duration-100"
                                enterFrom="opacity-0 translate-y-12 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-100"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-12 sm:translate-y-0"
                            >
                                <Headless.DialogPanel
                                    className={classNames(
                                        "sm:max-w-xl",
                                        "row-start-2 w-full min-w-0 rounded-t-3xl bg-white shadow-lg ring-1 ring-zinc-950/10 sm:mb-auto sm:rounded-2xl dark:bg-zinc-900 dark:ring-white/10 forced-colors:outline",
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            "w-full h-24 flex items-center pl-8 rounded-t-lg",
                                            branding?.bgColorClass,
                                        )}
                                    >
                                        {branding?.icon && (
                                            <branding.icon
                                                className={classNames(
                                                    "h-12 w-12",
                                                    branding?.iconColorClass,
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={
                                            "p-[--gutter] [--gutter:theme(spacing.8)] text-default text-sm"
                                        }
                                    >
                                        {!selectableAccounts && (
                                            <>
                                                <DialogTitle>
                                                    {integrationDetails?.title}
                                                </DialogTitle>
                                                <DialogDescription>
                                                    {integrationDetails?.detail}
                                                </DialogDescription>
                                                {integrationDetails?.type ===
                                                    "ad_network" && (
                                                    <div className={"mt-6"}>
                                                        <h2 className="font-medium leading-6">
                                                            Tracking template
                                                        </h2>
                                                        <p className="mt-1 text-sm text-subtle mb-2">
                                                            {
                                                                integrationDetails.trackingTemplateInstructions
                                                            }
                                                        </p>
                                                        <div
                                                            className={
                                                                "flex gap-1 mt-4 mb-2 items-center"
                                                            }
                                                        >
                                                            <Switch
                                                                className={
                                                                    "mr-1"
                                                                }
                                                                name={
                                                                    "existing"
                                                                }
                                                                checked={
                                                                    hasExistingTemplate
                                                                }
                                                                onChange={
                                                                    setHasExistingTemplate
                                                                }
                                                            />
                                                            <span
                                                                className={
                                                                    "text-subtle text-xs"
                                                                }
                                                            >
                                                                I already have
                                                                my own template
                                                            </span>
                                                        </div>
                                                        <Input
                                                            className={clsx(
                                                                "mb-2",
                                                                !hasExistingTemplate &&
                                                                    "hidden",
                                                            )}
                                                            value={
                                                                currentTemplateValue
                                                            }
                                                            onChange={(e) =>
                                                                setCurrentTemplateValue(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            placeholder={
                                                                "Current template"
                                                            }
                                                        />
                                                        <div
                                                            className={
                                                                "w-full flex justify-between"
                                                            }
                                                        >
                                                            <Input
                                                                readOnly
                                                                value={
                                                                    trackingTemplate
                                                                }
                                                            />
                                                            <Button
                                                                className={
                                                                    "ml-2"
                                                                }
                                                                type={"primary"}
                                                                outline
                                                                icon={
                                                                    Square2StackIcon
                                                                }
                                                                onClick={async (
                                                                    _,
                                                                    setBanner,
                                                                ) => {
                                                                    assertNotUndefined(
                                                                        trackingTemplate,
                                                                    );
                                                                    await navigator.clipboard.writeText(
                                                                        trackingTemplate,
                                                                    );

                                                                    setBanner(
                                                                        "Copied!",
                                                                    );
                                                                }}
                                                            >
                                                                Copy
                                                            </Button>
                                                        </div>
                                                        {integrationDetails.trackingTemplateInstructionsHelp && (
                                                            <p className="mt-4 text-sm text-subtle">
                                                                {
                                                                    integrationDetails.trackingTemplateInstructionsHelp
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                                {!canConnect && (
                                                    <div className={"mt-6"}>
                                                        <h2 className="font-medium leading-6">
                                                            Ad spend import
                                                        </h2>
                                                        <p className="mt-1 text-sm text-subtle mb-2">
                                                            Ad spend import is
                                                            not yet available
                                                            for{" "}
                                                            {snakeToSentence(
                                                                selectedIntegration ||
                                                                    "",
                                                            )}
                                                        </p>
                                                        <Button
                                                            outline={true}
                                                            type={"primary"}
                                                            onClick={(
                                                                _,
                                                                setBanner,
                                                            ) => {
                                                                assertNotUndefined(
                                                                    selectedIntegration,
                                                                );
                                                                analytics.trackExpressedIntegrationInterestEvent(
                                                                    selectedIntegration,
                                                                );
                                                                setBanner(
                                                                    "You will be notified",
                                                                );
                                                            }}
                                                        >
                                                            <BellIcon />
                                                            Notify me
                                                        </Button>
                                                    </div>
                                                )}

                                                {!!connectedAccounts.length && (
                                                    <div className={"mt-6"}>
                                                        <h2 className="font-medium leading-6">
                                                            Connected accounts
                                                        </h2>
                                                        {connectedAccounts.map(
                                                            (account) => (
                                                                <Panel
                                                                    key={
                                                                        account.id
                                                                    }
                                                                    className={
                                                                        "mt-2"
                                                                    }
                                                                    compact={
                                                                        true
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            "flex justify-between items-center"
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {capitalize(
                                                                                account.accountName,
                                                                            )}{" "}
                                                                            <Text
                                                                                className={
                                                                                    "mr-2"
                                                                                }
                                                                                type={
                                                                                    "subtle"
                                                                                }
                                                                            >
                                                                                (
                                                                                {
                                                                                    account.externalId
                                                                                }

                                                                                )
                                                                            </Text>
                                                                        </span>
                                                                        <Button
                                                                            outline
                                                                            onClick={() =>
                                                                                deleteWorkspaceMigration(
                                                                                    {
                                                                                        id: account.id,
                                                                                    },
                                                                                )
                                                                            }
                                                                        >
                                                                            Disconnect
                                                                        </Button>
                                                                    </div>
                                                                </Panel>
                                                            ),
                                                        )}
                                                    </div>
                                                )}
                                                {isApiKey && (
                                                    <div className={"mt-6"}>
                                                        <h2 className="font-medium leading-6">
                                                            API Key
                                                        </h2>
                                                        {integrationDetails?.apiKeyInstructions && (
                                                            <p className="mt-1 text-sm text-subtle mb-3">
                                                                {
                                                                    integrationDetails.apiKeyInstructions
                                                                }
                                                            </p>
                                                        )}
                                                        <Input
                                                            className={clsx(
                                                                "my-2",
                                                            )}
                                                            value={apiKey}
                                                            onChange={(e) =>
                                                                setApiKey(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            placeholder={`Your ${integrationDetails?.title} API key`}
                                                        />
                                                    </div>
                                                )}
                                                <DialogActions>
                                                    <Button
                                                        outline
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {canConnect && (
                                                        <Button
                                                            type={"primary"}
                                                            disabled={
                                                                isApiKey &&
                                                                apiKey === ""
                                                            }
                                                            loading={
                                                                isCreating ||
                                                                isUpdating ||
                                                                isFetchingSelectableAccounts
                                                            }
                                                            onClick={() =>
                                                                onConnect(
                                                                    selectedIntegration!,
                                                                    isApiKey
                                                                        ? {
                                                                              apiKey,
                                                                          }
                                                                        : undefined,
                                                                )
                                                            }
                                                        >
                                                            Connect{" "}
                                                            {connectedAccounts.length >
                                                                0 && `more`}
                                                        </Button>
                                                    )}
                                                </DialogActions>
                                            </>
                                        )}
                                        {!!selectableAccounts?.accounts && (
                                            <>
                                                <DialogTitle>
                                                    Select account to connect
                                                </DialogTitle>{" "}
                                                {selectableAccounts.accounts.map(
                                                    ({
                                                        id,
                                                        name,
                                                        metaData,
                                                    }) => (
                                                        <Panel
                                                            key={id}
                                                            className={"mt-2"}
                                                            compact={true}
                                                        >
                                                            <div
                                                                className={
                                                                    "flex justify-between items-center"
                                                                }
                                                            >
                                                                <span>
                                                                    <span
                                                                        className={
                                                                            "mr-2"
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </span>
                                                                    <Text
                                                                        className={
                                                                            "mr-2"
                                                                        }
                                                                        type={
                                                                            "subtle"
                                                                        }
                                                                    >
                                                                        ({id})
                                                                    </Text>
                                                                </span>
                                                                <Button
                                                                    type={
                                                                        "primary"
                                                                    }
                                                                    loading={
                                                                        isUpdating &&
                                                                        accountBeingSelected ===
                                                                            id
                                                                    }
                                                                    onClick={async () => {
                                                                        await onAccountSelected(
                                                                            id,
                                                                            name,
                                                                            metaData ||
                                                                                undefined,
                                                                        );
                                                                    }}
                                                                >
                                                                    {connectedAccounts.find(
                                                                        (
                                                                            account,
                                                                        ) =>
                                                                            account.externalId ===
                                                                            id,
                                                                    )
                                                                        ? "Reconnect"
                                                                        : "Connect"}
                                                                </Button>
                                                            </div>
                                                        </Panel>
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Headless.DialogPanel>
                            </Headless.TransitionChild>
                        </div>
                    </div>
                </Headless.Dialog>
            </Headless.Transition>
        </>
    );
}
