import { components } from "@/types/generated/api-schema";
type UserPermissionsType = components["schemas"]["UserPermissionsType"];
type UserOrganization = components["schemas"]["UserOrganization"];
type Organization = components["schemas"]["Organization"];

export class UserOrganizationModel implements UserOrganization {
    organization: Organization;
    permissionsType: UserPermissionsType;
    active: boolean;

    constructor({ organization, permissionsType, active }: UserOrganization) {
        this.organization = organization;
        this.permissionsType = permissionsType;
        this.active = active;
    }

    static create(organizationData: UserOrganization): UserOrganization {
        return new this(organizationData);
    }
}
